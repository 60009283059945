import { BASE_URL, ENCRYPTION_KEY } from '../../constants/constants';
import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { toastr } from 'react-redux-toastr';
import * as  contentActions from '../../actions/contentActions';
import * as  audioActions from '../../actions/audioActions';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class FileUpload extends Component {

    constructor() {
        super()
        this.state = {
            files: [],
            login_user_id: null
        };

        this.postFiles = this.postFiles.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.reUpload = this.reUpload.bind(this);
    }

    onDrop(files) {

        if (this.props.action == "add-audio" || this.props.action == "update-audio") {
            if (this.state.files.length == 1) {
                return;
            }
        }
        files.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            computedSize: this.bytesToSize(file.size),
            loaded: 0,
            error: false
        }))

        files.forEach(file => {
            this.state.files.push(file);
        })

        this.setState(this.state);
    }

    bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
    }

    componentDidMount() {
        this.state.login_user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
        this.setState(this.state);
    }

    componentWillUnmount() {
        this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
    }

    postFiles() {
        this.props.dispatch(contentActions.ShowLoading());

        let that = this;
        if (this.props.action != 'update-audio') {
            this.state.files.forEach(function (file, index) {

                const formData = new FormData();
                var url = BASE_URL + "";

                if (that.props.action == "add") {
                    url = BASE_URL + "content/admincontentadd";
                    if (that.props.folder_id !== undefined && that.props.folder_id > 0) {
                        formData.append('folder_id', that.props.folder_id);
                    }
                    formData.append('content', file, file.name);
                }
                else if (that.props.action == "update") {
                    url = BASE_URL + "content/admincontentupdate";
                    formData.append('content_id', that.props.Id);
                    formData.append('content', file, file.name);
                } else if (that.props.action == "add-audio") {
                    url = BASE_URL + "trial/addtalktracksaudio";
                    formData.append('name', that.props.audio.name);
                    formData.append('description', that.props.audio.description);
                    formData.append('type', that.props.queryParams.type);
                    if (that.props.queryParams.type == "specialty") {
                        formData.append('speciality_id', that.props.queryParams.id);
                        formData.append('stakeholder_id', '');
                    }
                    else {
                        formData.append('stakeholder_id', that.props.queryParams.id);
                        formData.append('speciality_id', '');
                    }
                    formData.append('product_id', that.props.queryParams.product_id);
                    formData.append('file', file, file.name);

                } else if (that.props.action == "update-audio") {
                    url = BASE_URL + "trial/edittalktracksaudiofile";
                    formData.append('file_id', that.props.id);
                    formData.append('file', file, file.name);
                }

                formData.append('user_id', that.state.login_user_id);

                axios.post(url, formData, {
                    onUploadProgress: ProgressEvent => {

                        that.state.files[index].loaded = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100);
                        that.setState(that.state);
                    }
                })
                    .then(function (res) {
                        if (res.data.success === "1") {
                            that.state.files[index].uploaded = true;
                            that.setState(that.state);
                        }
                        else {
                            that.state.files[index].error = true;
                            that.state.files[index].errorMsg = res.data.message;
                            that.setState(that.state);
                        }

                        var errContent = that.state.files.filter(x => x.error === true);
                        if (index === that.state.files.length - 1) {
                            if (errContent.length > 0) {
                                toastr.error("Error", errContent.length + " Errors in file upload !");
                            }
                            else {
                                toastr.success("Success", res.data.message);
                                let self = that;
                                setTimeout(function () {
                                    if (that.props.action == "add") {
                                        var model = {};
                                        model.content_type = self.props.type;
                                        //model.sort_by = self.props.sortby;
                                        //model.order_by = self.props.orderby;
                                        //model.search = self.props.search;
                                        //model.page = self.props.page;
                                        model.page = 1;
                                        if (self.props.folder_id !== undefined && self.props.folder_id > 0) {
                                            model.folder_id = self.props.folder_id;
                                        }

                                        self.props.dispatch(contentActions.GetContents(model));
                                        self.props.enableScroll();
                                    }
                                    else if (that.props.action == "update") {
                                        var model = {};
                                        model.content_id = self.props.Id;
                                        model.content_type = self.props.type;
                                        model.sort_by = self.props.sortby;
                                        model.order_by = self.props.orderby;
                                        model.search = self.props.search;
                                        model.page = self.props.page;
                                        self.props.dispatch(contentActions.GetContents(model));
                                        self.props.getUpdatedDetails();
                                    }
                                    self.props.closeModal();
                                    if (that.props.action == 'add-audio') {
                                        that.props.onAddSuccess();
                                    }
                                }, 1000);
                            }
                            that.props.dispatch(contentActions.HideLoading());
                        }

                    })
                    .catch(function (error) {
                        that.state.files[index].error = true;
                        that.state.files[index].errorMsg = error;
                        that.setState(that.state);
                    });
            })
        }


        else if (this.props.action == 'update-audio') {
            const formData = new FormData();
            var url = BASE_URL + "trial/edittalktracksaudiofile";
            formData.append('file_id', that.props.id);
            if (that.state.files.length > 0) {
                formData.append('file', that.state.files[0], that.state.files[0].name);
            }
            formData.append('name', that.props.audio.name);
            formData.append('description', that.props.audio.description);
            formData.append('user_id', that.state.login_user_id);

            axios.post(url, formData, {
                onUploadProgress: ProgressEvent => {
                    if (this.state.files.length > 0) {
                        that.state.files[0].loaded = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100);
                    }
                    that.setState(that.state);
                }
            })
                .then(function (res) {
                    if (res.data.success === "1") {
                        toastr.success("Success", res.data.message);
                        if (that.state.files.length > 0) {
                            that.state.files[0].uploaded = true;
                        }
                        that.props.closeModal()
                        that.setState(that.state);
                        var model = {};
                        model.id = that.props.id;
                        model.name = that.props.audio.name;
                        model.description = that.props.audio.description;
                        that.props.onUpdateSuccess(model);
                    }
                    else {
                        var errContent = that.state.files.filter(x => x.error === true);
                        toastr.error("Error", errContent.length + " Errors in file upload !");
                        if (that.state.files.length > 0) {
                            that.state.files[0].error = true;
                            that.state.files[0].errorMsg = res.data.message;
                        }
                        that.setState(that.state);
                    }

                    // var errContent = that.state.files.filter(x => x.error === true);
                    // if (0 === that.state.files.length - 1) {
                    //     if (errContent.length > 0) {
                    //         toastr.error("Error", errContent.length + " Errors in file upload !");
                    //     }
                    //     else {
                    //         toastr.success("Success", res.data.message);
                    //         let self = that;
                    //         setTimeout(function () {
                    //             if (that.props.action == "add") {
                    //                 var model = {};
                    //                 model.content_type = self.props.type;
                    //                 //model.sort_by = self.props.sortby;
                    //                 //model.order_by = self.props.orderby;
                    //                 //model.search = self.props.search;
                    //                 //model.page = self.props.page;
                    //                 model.page = 1;
                    //                 if (self.props.folder_id !== undefined && self.props.folder_id > 0) {
                    //                     model.folder_id = self.props.folder_id;
                    //                 }

                    //                 self.props.dispatch(contentActions.GetContents(model));
                    //                 self.props.enableScroll();
                    //             }
                    //             else if (that.props.action == "update") {
                    //                 var model = {};
                    //                 model.content_id = self.props.Id;
                    //                 model.content_type = self.props.type;
                    //                 model.sort_by = self.props.sortby;
                    //                 model.order_by = self.props.orderby;
                    //                 model.search = self.props.search;
                    //                 model.page = self.props.page;
                    //                 self.props.dispatch(contentActions.GetContents(model));
                    //                 self.props.getUpdatedDetails();
                    //             }
                    //             self.props.closeModal();
                    //         }, 1000);
                    //     }
                    //     that.props.dispatch(contentActions.HideLoading());
                    // }

                })
                .catch(function (error) {
                    if (that.state.files.length > 0) {
                        that.state.files[0].error = true;
                        that.state.files[0].errorMsg = error;
                    }
                    that.setState(that.state);
                });
            that.props.dispatch(contentActions.HideLoading());
        }

    }

    reUpload(e) {
        var index = e.target.dataset.index;
        var pageId = this.state.id;
        let that = this;
        const formData = new FormData();
        var page_id = [];
        page_id.push(pageId);
        var file = this.state.files[index];
        var url = BASE_URL + "";

        if (that.props.action == "add") {

            url = BASE_URL + "content/admincontentadd";
            if (that.props.folder_id !== undefined && that.props.folder_id > 0) {
                formData.append('folder_id', that.props.folder_id);
            }
            formData.append('content', file, file.name);

        } else if (that.props.action == "update") {

            url = BASE_URL + "content/admincontentupdate";
            formData.append('content_id', that.props.Id);
            formData.append('content', file, file.name);

        } else if (that.props.action == "add-audio") {
            url = BASE_URL + "trial/addtalktracksaudio";
            formData.append('name', that.props.audio.name);
            formData.append('description', that.props.audio.description);
            formData.append('type', that.props.queryParams.type);
            if (that.props.queryParams.type == "specialty") {
                formData.append('speciality_id', that.props.queryParams.id);
                formData.append('stakeholder_id', '');
            }
            else {
                formData.append('stakeholder_id', that.props.queryParams.id);
                formData.append('speciality_id', '');
            }
            formData.append('product_id', that.props.queryParams.product_id);
            formData.append('file', file, file.name);

        } else if (that.props.action == "update-audio") {

            url = BASE_URL + "trial/edittalktracksaudiofile";
            formData.append('file_id', that.props.id);
            formData.append('file', file, file.name);

        }

        formData.append('user_id', that.state.login_user_id);

        this.props.dispatch(contentActions.ShowLoading());
        axios.post(url, formData, {
            onUploadProgress: ProgressEvent => {
                that.state.files[index].loaded = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100);
                that.setState(that.state);
            }
        })
            .then(function (res) {

                if (res.data.success === "1") {
                    that.state.files[index].uploaded = true;
                    that.setState(that.state);
                }
                else {
                    that.state.files[index].error = true;
                    that.state.files[index].errorMsg = res.data.message;
                    that.setState(that.state);
                    toastr.error("Error", res.data.message);
                }
                that.props.dispatch(contentActions.HideLoading());
            })
            .catch(function (error) {
                that.state.files[index].error = true;
                that.state.files[index].errorMsg = error;
                that.setState(that.state);
                this.props.dispatch(contentActions.HideLoading());
            });
    }

    removeFile(e) {
        var i = parseInt(e.target.dataset.index);
        this.state.files.splice(i, 1);
        this.setState(this.state);
    }

    render() {

        const { files } = this.state;
        const thumbs = files.map((file, index) => (
            <li key={file.name}>
                <a data-index={index} className="remove-file" onClick={this.removeFile}><i data-index={index} className="la la-times-circle"></i></a>
                <div className="file-info">
                    <p className="file-name">{file.name}</p>
                    <ul className="file-progress">
                        <li>{file.computedSize}</li>
                        {file.loaded > 0 && file.loaded < 100 && <li>{file.loaded}%... </li>}
                        {file.uploaded === true && <li className="text-success"><i className="la la-check-circle"></i> uploaded </li>}
                        {file.error === true && <li className="text-danger"><i className="la la-exclamation-triangle"></i> {file.errorMsg} <a href="javascript:void(0);" data-index={index} onClick={this.reUpload} className="btn-retry text-danger">Retry</a></li>}
                    </ul>
                </div>
                <div className="progress">
                    <div className="progress-bar" role="progressbar" aria-valuenow={file.loaded}
                        aria-valuemin="0" aria-valuemax="100" style={{ width: '' + file.loaded + '%' }}>
                        <span className="sr-only">{file.loaded}% Complete</span>
                    </div>
                </div>
            </li>
        ));

        var actionName = "";
        var desc = "";
        if (this.props.action === "add") {
            actionName = "Add Content";
            desc = "Choose either of the methods below to upload content.";
        }
        else {
            actionName = "Update Content";
            desc = "Choose either of the methods below to upload updated content. This will remove and replace the current content.";
        }

        return (
            <div>

                <Dropzone
                    multiple={(this.props.action == "add") ? true : false}
                    onDrop={this.onDrop.bind(this)} >
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="upload-img custom-border">
                                <i className="la la-cloud-upload"></i>
                                <p>Drag and drop content here</p>
                                <span>or</span>
                                <span className="btn black-btn choose-file">CHOOSE FILES</span>
                            </div>
                        </div>
                    )}
                </Dropzone>
                <div className="content-upload upload-detail mCustomScrollbar" data-mcs-theme="dark">
                    <ul className="upload-list">
                        {thumbs}
                    </ul>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" onClick={this.props.closeModal}>CANCEL</button>
                    {(this.props.action == "add" || this.props.action == "update") && <button type="button" className="btn btn-primary blue-btn" onClick={this.postFiles} disabled={files.length === 0}>Done</button>}
                    {this.props.action != "add" && this.props.action != "update" && <button type="button" className="btn btn-primary blue-btn" onClick={this.postFiles} disabled={!this.props.valid || files.length === 0 && this.props.action != 'update-audio'}>Done</button>}
                </div>
            </div>
        );
    }
}

export default connect()(FileUpload);