import { ENCRYPTION_KEY } from '../../constants/constants';
import { BASE_URL, errOptions } from '../../constants/constants'
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import InfiniteScroll from "../common/infinitescroll";

import DeleteConfirmModal from '../common/deleteConfirmModal';
import AddUpdateCategoryModal from './addUpdateCategoryModel';
import AudioActions from './audioActions';
import AudioFilter from './audioFilter';
import { toastr } from 'react-redux-toastr';

import * as  audioActions from '../../actions/audioActions';
import * as menuActions from '../../actions/menuActions';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class Audio extends Component {

    constructor() {
        super();
        this.state = {
            type: 'specialty',
            selectedTab: 'specialty',
            audio_base_url: BASE_URL.replace("api/v7/", "") + "uploads/audio/",
            selectedAudio: [],
            IsDeleteConfirmModalOpen: false,
            IsCategoryAddUpdateModelOpen: false,
            order_by: 'ASC',
            sort_by: 'name',
            search: '',
            isSelectAll: false,
            activePage: 1,
            isSearch: false,
            items: [],
            isScroll: true,
            totalRecords: null,
            hasMore: true,
            randomError: '',
            expandAll: 'false',
            noRecordsFound: false,
            productName: "",
            categoryId: 0
        }

        this.audioSelection = this.audioSelection.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.clearAudioSelection = this.clearAudioSelection.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.removeItems = this.removeItems.bind(this);
        this.updateItem = this.updateItem.bind(this);
        this.enableScroll = this.enableScroll.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.updateProduct = this.updateProduct.bind(this);

        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
    }

    play(e) {
        this.refs[e.target.dataset.id].currentTime = 0;
        this.refs[e.target.dataset.id].play();
        this.state.items.map(item => {
            if (item.id == e.target.dataset.id) {
                item.status = 'play';
            } else {
                this.refs[item.id].pause();
                item.status = 'pause';
            }
        });
        this.setState(this.state);
    }

    changeTab(e) {
        var tab = e.target.dataset.tab;
        this.setState({ selectedTab: tab });
        this.setState({ type: tab, activePage: 1, search: '', items: [], isScroll: true, selectedAudio: [], totalRecords: null, hasMore: true });
    }

    pause(e) {
        this.refs[e.target.dataset.id].pause();
        this.state.items.map(item => {
            if (item.id == e.target.dataset.id) {
                item.status = 'pause';
            }
        });
        this.setState(this.state);
    }

    addProduct(e) {
        this.state.productName = e.target.value;
        if (e.key == "Enter") {
            this.state.categoryId = e.target.dataset.id;
            var model = {};
            model.id = e.target.dataset.id;
            model.type = this.state.selectedTab;
            model.name = this.state.productName;
            this.props.dispatch(audioActions.AddProductForCategory(model));
        }
    }

    generateRandomMesage(randomError) {
        var str = errOptions[Math.floor(Math.random() * Math.floor(5))];
        if (randomError == str) {
            str = this.generateRandomMesage();
        }
        return str;
    }

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    openCategoryAddUpdateModal = () => {
        this.setState({ IsCategoryAddUpdateModelOpen: true });
    };

    closeCategorAddUpdateModal = () => {
        this.setState({ IsCategoryAddUpdateModelOpen: false });
    };

    componentDidMount() {
        this.setState({ sortby: "name", orderby: "ASC" });
    }

    getAllSpeciality() {
        this.setState({ activePage: 1, items: [], isScroll: true, totalRecords: null, hasMore: true });
        var model = {};
        model.sort_by = this.state.sort_by;
        model.order_by = this.state.order_by;
        model.page = this.state.activePage;
        model.search = this.state.search;
        this.props.dispatch(audioActions.GetSpecialitiesForList(model));
    }

    getAllStackHolders() {
        this.setState({ activePage: 1, items: [], isScroll: true, totalRecords: null, hasMore: true });
        var model = {};
        model.sort_by = this.state.sort_by;
        model.order_by = this.state.order_by;
        model.page = this.state.activePage;
        model.search = this.state.search;
        this.props.dispatch(audioActions.GetStackholdersForList(model));
    }

    componentWillReceiveProps() {
        if (this.props.audiolist && this.props.audiolist.records && this.props.audiolist.records.length === 0 && this.state.items.length === 0) {
            this.setState({ noRecordsFound: true });
        } else if (this.props.audiolist && this.props.audiolist.records && this.props.audiolist.records.length > 0 && this.state.items.length === 0) {
            this.setState({ noRecordsFound: false });
        }
        // this.props.dispatch(buActions.ClearBUList());
    }

    changeOrder(e) {
        var orderby = "";
        if (this.state.sort_by === e.target.dataset.sort_by) {
            orderby = this.state.order_by === "DESC" ? "ASC" : "DESC";
        }
        else {
            orderby = "DESC";
        }

        this.state.order_by = orderby;
        this.state.sort_by = e.target.dataset.sort_by;

        this.setState({ isScroll: true, selectedBU: [], totalRecords: null, hasMore: true });
        this.state.activePage = 1;
        this.state.items = [];
        this.getDataMethod();
    }

    keyPress(e) {
        var randomError = this.generateRandomMesage(this.state.randomError);
        this.setState({ search: e.target.value.toLowerCase(), items: [], isScroll: true, selectedBU: [], totalRecords: null, hasMore: true, randomError: randomError });
        this.state.activePage = 1;
        var model = {};
        this.state.search = e.target.value.toLowerCase();
        this.getDataMethod();
    }

    search() {
        this.state.isSearch = !this.state.isSearch;
        if (this.state.isSearch) {
            this.searchInput.focus();
        }
        else {
            this.setState({ items: [], isScroll: true, selectedBU: [], totalRecords: null, hasMore: true });
            this.state.activePage = 1;
            this.state.search = '';
            this.getDataMethod();
        }
    }

    audioSelection(e) {
        var id = e.target.id;
        if (e.target.checked) {
            if (this.state.selectedAudio.length + 1 === this.state.items.length) {
                this.state.selectedAudio.push(id);
                this.state.isSelectAll = true;
                this.setState(this.state);
            }
            else {
                this.state.selectedAudio.push(id);
                this.setState(this.state);
            }
        }
        else {
            this.setState({
                isSelectAll: false,
                selectedAudio: this.state.selectedAudio.filter((id) => id !== id)
            });
        }
    }
    onAddSuccess = () => {
        this.state.activePage = 1;
        this.state.items = [];
        this.getDataMethod();
    }

    selectAll(e) {
        if (e.target.checked) {
            this.state.selectedAudio = this.state.items.map((item) => {
                return item.id;
            });
            this.state.isSelectAll = true;
        }
        else {
            this.state.selectedAudio = [];
            this.state.isSelectAll = false;
        }
        this.setState(this.state);
    }

    handleSearchChange(e) {
        if (e.target.name === "search") {
            this.setState({ search: e.target.value });
        }
    }

    clearAudioSelection() {
        this.state.isSelectAll = false;
        this.state.selectedAudio = [];
        this.setState(this.state);
    }

    handlePageChange(pageNumber) {

        this.setState({ activePage: pageNumber, selectedAudio: [], isSelectAll: false });
        var model = {};
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        if (this.state.search.toLowerCase() !== "") {
            model.search = this.state.search.toLowerCase();
        }
        model.page = pageNumber;
        this.getDataMethod();
        window.scrollTo(0, 0);
    }

    updateProduct(itemData) {
        var index = this.state.items.findIndex(x => x.id == this.state.categoryId);
        var category = this.state.items.find(x => x.id == this.state.categoryId);
        let items = [...this.state.items];
        let item = { ...items[index] };
        item.product.push(itemData);
        items[index] = item;
        this.setState({ items });
    }

    fetchMoreData() {
        this.state.activePage += 1;
        this.state.isScroll = true;
        this.getMoreDataMethod();
    }

    getDataMethod() {
        switch (this.state.selectedTab) {
            case "specialty":
                this.getAllSpeciality();
                break;
            case "stakeholder":
                this.getAllStackHolders();
                break;
        }
    }

    getMoreDataMethod() {
        switch (this.state.selectedTab) {
            case "specialty":
                this.getMoreSpeciality();
                break;
            case "stakeholder":
                this.getMoreStackHolders();
                break;
        }
    }

    getMoreSpeciality() {
        var model = {};
        model.sort_by = this.state.sort_by;
        model.order_by = this.state.order_by;
        model.page = this.state.activePage;
        model.search = this.state.search;
        this.props.dispatch(audioActions.GetSpecialitiesForList(model));
    }

    getMoreStackHolders() {
        var model = {};
        model.sort_by = this.state.sort_by;
        model.order_by = this.state.order_by;
        model.page = this.state.activePage;
        model.search = this.state.search;
        this.props.dispatch(audioActions.GetStackholdersForList(model));
    }


    removeItems(items) {

        if (items[0].id) {
            items.forEach(id => {
                var index = this.state.items.findIndex(x => x.id == id);
                this.state.items.splice(index, 1);
            });
        } else {
            items.forEach(id => {
                var index = this.state.items.findIndex(x => x.id == id);
                this.state.items.splice(index, 1);
            });
        }

        this.state.totalRecords--;
    }

    updateItem(item) {
        var index = this.state.items.findIndex(x => x.id == item.id);
        this.state.items[index].name = item.name;
        this.state.items[index].description = item.description;
    }

    enableScroll() {
        this.setState({ items: [], isScroll: true, selectedAudio: [], totalRecords: null, hasMore: true });
    }

    handleToggle(e) {

        let items = [...this.state.items];
        let item = { ...items[e.target.dataset.index] };
        item.isExpanded = e.target.dataset.status;
        items[e.target.dataset.index] = item;
        this.setState({ items });
    }

    expandCollapseAllData(e) {
        this.setState({ expandAll: e.target.dataset.status });
        this.state.items.forEach((item, index) => {
            let items = [...this.state.items];
            item.isExpanded = e.target.dataset.status;
            items[index] = item;
            this.setState({ items });
        })
    }

    render() {

        if (this.props.location.state !== undefined) {
            this.getDataMethod();
            this.props.location.state = undefined;
        }


        const { activePage, parents, isSelectAll, audio_base_url, sort_by, order_by, selectedAudio, IsChangeShareSettingModalOpen, IsCategoryAddUpdateModelOpen, IsDeleteConfirmModalOpen, IsAddToPagesModalOpen, IsCreateGroupModalOpen, search, isSearch,
            isScroll, items, totalRecords, hasMore, randomError, type, selectedTab, expandAll, noRecordsFound, productName } = this.state;
        const { loading, error, audiolist, specialitylist, stackholderlist, productlist, res } = this.props;


        if (!loading && isScroll && audiolist != null && audiolist.records && audiolist.records.length > 0 && this.state.items.length < audiolist.total_records) {

            var hasMoreRef = ((items.length + audiolist.records.length) == audiolist.total_records) ? false : true;

            this.setState({
                isScroll: false,
                totalRecords: audiolist.total_records,
                // selectedUsers: selectedUsers.concat(selectedUserList),
                items: items.concat(audiolist.records),
                hasMore: hasMoreRef
            });


        }

        if (res != null && res.success === "1" && res.data) {
            toastr.success("Success !", res.message);
            var model = {};
            model.sort_by = "name";
            model.order_by = "ASC";
            this.props.dispatch(audioActions.initiateState());
            this.updateProduct(res.data);
            // this.getDataMethod();
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        if (this.state.type !== undefined) {

            switch (this.state.type) {
                case "specialty":
                    this.getAllSpeciality();
                    break;
                case "stakeholder":
                    this.getAllStackHolders();
                    break;
            }
            this.state.type = undefined;
        }

        const loadingClass = { 'textAlign': 'center', 'fontSize': '30px' };

        const wordBreak = {
            wordBreak: 'break-all',
            whiteSpace: 'pre-line'
        };

        let isError = noRecordsFound;

        return (

            <div>
                <div className="container">
                    <div className="main-title title-with-search">
                        <h1>Talk Tracks</h1>
                    </div>

                    <div className="content-bredcrum-search">
                        <div className="tab-parent">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className={selectedTab === 'specialty' ? 'active' : ''} data-tab="specialty" onClick={this.changeTab.bind(this)}>
                                    <a href="javascript:void();" data-tab="specialty" onClick={this.changeTab.bind(this)}>BY SPECIALTY</a>
                                </li>
                                <li role="presentation" className={selectedTab === 'stakeholder' ? 'active' : ''} data-tab="stakeholder" onClick={this.changeTab.bind(this)}>
                                    <a href="javascript:void();" data-tab="stakeholder" onClick={this.changeTab.bind(this)}>BY Stakeholder</a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab_search_box">
                            <div className="search-form clearfix">
                                <input ref={(searchInput) => { this.searchInput = searchInput; }}
                                    type="search" name="search" value={search}
                                    className="form-control"
                                    onChange={this.keyPress} />
                                <button ref={(searchButton) => { this.searchButton = searchButton; }}
                                    type="submit" className="search-btn" onClick={this.search}>
                                    <i className="fa fa-search" aria-hidden="true" ></i>
                                </button>
                            </div>
                        </div>

                    </div>


                    <div className="tab-audio talk-tracks-wrap">
                        <div role="tabpanel" className="tab-pane active" id="home">
                            <div className="action-row audio-action-row">
                                <button className="btn btn-rounded btn-primary" onClick={this.openCategoryAddUpdateModal}>
                                    <i className="la la-plus"></i>
                                </button>
                                <div className="action_btn_group">
                                    <button className="btn btn-default" data-status='true' onClick={this.expandCollapseAllData.bind(this)}>
                                        <i className="la la-arrows-alt" data-status='true' onClick={this.expandCollapseAllData.bind(this)}></i>EXPAND ALL
                                    </button>

                                    <button className="btn btn-default" data-status='false' onClick={this.expandCollapseAllData.bind(this)}>
                                        <i className="la la-compress" data-status='false' onClick={this.expandCollapseAllData.bind(this)}></i>COLLAPSE ALL
                                    </button>

                                    {/* <button className="btn btn-default btn-delete" onClick={this.openDeleteConfirmModal} disabled={selectedAudio.length === 0}>
                                        <i className="la la-trash-o"></i>DELETE FILES
                                    </button> */}
                                </div>

                                <div className="search-form btn-filter">
                                    {specialitylist.records && stackholderlist.records && productlist.records &&
                                        <AudioFilter page={activePage}
                                            enableScroll={this.enableScroll}
                                            orderby={order_by}
                                            specialitylist={specialitylist.records}
                                            stackholderlist={stackholderlist.records}
                                            productlist={productlist.records}
                                            sortby={sort_by}
                                            search={this.search} />}
                                </div>
                            </div>


                            <div className="table-container table-responsive bu-table-wrap trackaudio-table">
                                <table className="table hover-row">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span className={(sort_by === 'name' && order_by === 'ASC') ? 'bottom-border th-name active-asc' : ((sort_by === 'name' && order_by === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sort_by="name" onClick={this.changeOrder.bind(this)}>
                                                    Name
                                                </span>
                                            </th>
                                            <th>
                                                <span className={(sort_by === 'updated_date' && order_by === 'ASC') ? 'bottom-border th-name active-asc' : ((sort_by === 'updated_date' && order_by === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sort_by="updated_date" onClick={this.changeOrder.bind(this)}>
                                                    Last Modified
                                                </span>
                                            </th>
                                            <th>
                                                <span className={(sort_by === 'talktrack' && order_by === 'ASC') ? 'bottom-border th-name active-asc' : ((sort_by === 'talktrack' && order_by === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sort_by="talktrack" onClick={this.changeOrder.bind(this)}>
                                                    Talk tracks
                                                </span>
                                            </th>
                                            <th className="actions"></th>
                                        </tr>
                                    </thead>
                                    {/* {items.length > 0 && <InfiniteScroll
                                        target="table"
                                        dataLength={items.length}
                                        scrollThreshold="10px"
                                        next={this.fetchMoreData}
                                        hasMore={hasMore}
                                        loader={<tr><td colSpan="8" className="bottom-0" style={loadingClass}>Loading...</td></tr>} > */}
                                    <tbody>
                                        {items.map((item, i) => (
                                            <tr>
                                                <td colSpan="4" className="trackaudio-table-tr">
                                                    <table className="table">
                                                        <tr>
                                                            <td className="tracks-panel-name">
                                                                <a href="javascript:void(0);" className="panel-arrow-icon">
                                                                    {item.isExpanded == 'false' && <i className="la la-angle-down" data-status='true' data-index={i} data-id={item.id} onClick={this.handleToggle.bind(this)}></i>}
                                                                    {item.isExpanded == 'true' && <i className="la la-angle-up" data-status='false' data-index={i} data-id={item.id} onClick={this.handleToggle.bind(this)}></i>}
                                                                </a><span className="truncate"> {item.name} </span>
                                                            </td>
                                                            <td><span className="truncate">{item.updated_date}</span></td>
                                                            <td><span className="truncate">{item.talktrack}</span></td>
                                                            <td className="actions">
                                                                <AudioActions Id={item.id} item={item}
                                                                    orderby={order_by}
                                                                    sortby={sort_by}
                                                                    type={selectedTab}
                                                                    onAddSuccess={this.onAddSuccess}
                                                                    updateItem={this.updateItem}
                                                                    removeItems={this.removeItems} />
                                                            </td>
                                                        </tr>
                                                        {item.isExpanded == 'true' && <tbody>
                                                            {
                                                                item.product.map((product, i) => (
                                                                    <tr className="tracks-panel-data">
                                                                        <td className="tracks-panel-name"><span className="truncate">
                                                                            <Link to={{ pathname: this.props.match.url + '/details?id=' + item.id + '&product_id=' + product.id + '&type=' + this.state.selectedTab, state: { item: item, orderby: order_by, sortby: sort_by } }} target="_self">
                                                                                {product.name}
                                                                            </Link>
                                                                        </span>
                                                                            {item.product.length == (i = i + 1) &&
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" data-id={item.id} id="name" placeholder="Add a new Talk Track..." onKeyDown={this.addProduct} />
                                                                                </div>}
                                                                        </td>
                                                                        <td><span className="truncate">{product.updated_date}</span></td>
                                                                        <td><span className="truncate"></span></td>
                                                                        <td className="actions text-right">
                                                                            <Link to={{ pathname: this.props.match.url + '/details?id=' + item.id + '&product_id=' + product.id + '&type=' + this.state.selectedTab, state: { item: item, orderby: order_by, sortby: sort_by } }} target="_self">
                                                                                <i class="la la-angle-right"></i>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                            {item.product.length == 0 && <tr className="tracks-panel-data">
                                                                <td className="tracks-panel-name">
                                                                    {item.product.length == 0 && <div className="form-group">
                                                                        <input type="text" className="form-control" data-id={item.id} id="name" placeholder="Add a new Talk Track..." onKeyDown={this.addProduct} />
                                                                    </div>}
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>}
                                                        </tbody>
                                                        }

                                                    </table>
                                                </td>
                                            </tr>


                                        ))}
                                        {/* </InfiniteScroll>} */}
                                    </tbody>
                                </table>
                            </div>

                            {(items.length !== totalRecords) && items.length > 0 &&
                                <button className="btn btn-primary loadmore-btn" onClick={this.fetchMoreData}>
                                    Load More <i className="la la-angle-right"></i>
                                </button>
                            }



                            {audiolist !== null && audiolist.records !== undefined && audiolist.records.length === 0 && <div className="text-center">
                                {isSearch && <label className="lblNoRecord">{randomError}</label>}
                                {!isSearch && <label className="lblNoRecord">There are no Audio Tracks available</label>}
                            </div>}
                        </div>
                    </div>
                </div>

                <Modal open={IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal}
                        title="Delete Files"
                        message="Are you sure you want to delete selected file(s)?"
                        selectedAudio={selectedAudio}
                        action="multiple-audio-delete"
                        clearAudioSelection={this.clearAudioSelection}
                        search={search} page={activePage}
                        removeItems={this.removeItems}
                        orderby={order_by}
                        sortby={sort_by} />
                </Modal>

                <Modal open={IsCategoryAddUpdateModelOpen} center showCloseIcon={false} onClose={this.closeCategorAddUpdateModal}>
                    <AddUpdateCategoryModal closeCategorAddUpdateModal={this.closeCategorAddUpdateModal} action="add"
                        search={search} page={activePage}
                        enableScroll={this.enableScroll}
                        orderby={order_by}
                        typeCategory={this.state.selectedTab}
                        onAddSuccess={this.onAddSuccess}
                        sortby={sort_by} />
                </Modal>
            </div >
        );
    }
}

const mapStateToProps = state => ({
    loading: state.audioReducer.loading,
    error: state.audioReducer.error,
    audiolist: state.audioReducer.audiolist,
    specialitylist: state.audioReducer.specialitylist,
    stackholderlist: state.audioReducer.stackholderlist,
    productlist: state.audioReducer.productlist,
    res: state.audioReducer.res
});

export default connect(mapStateToProps)(Audio);