import { ENCRYPTION_KEY, errOptions } from '../../constants/constants';
import React, { Component } from "react";
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as usersActions from '../../actions/usersActions';
import * as menuActions from '../../actions/menuActions';
import UserActions from './userActions';
import DeleteConfirmModal from '../common/deleteConfirmModal';
import ChangeUserRoleModal from './changeUserRoleModal';
import Popover from 'react-simple-popover';
import InfiniteScroll from "../common/infinitescroll";

const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class Users extends Component {

    constructor() {
        super();
        this.state = {
            role: 'all',
            IsDeleteConfirmModalOpen: false,
            IsChangeRoleModalOpen: false,
            selectedUsers: [],
            orderby: 'ASC',
            sortby: 'first_name',
            search: '',
            isSelectAll: false,
            activePage: 1,
            isSearch: false,
            items: [],
            isScroll: true,
            totalRecords: null,
            hasMore: true,
            randomError: '',
            noRecordsFound: false
        }

        this.userSelection = this.userSelection.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.clearUserSelection = this.clearUserSelection.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.removeItems = this.removeItems.bind(this);
        this.updateItem = this.updateItem.bind(this);
    }

    keyPress(e) {
        var randomError = this.generateRandomMesage(this.state.randomError);
        this.setState({ search: e.target.value.toLowerCase(), activePage: 1, items: [], isScroll: true, selectedUsers: [], totalRecords: null, hasMore: true, randomError: randomError });
        var model = {};
        model.usertype = this.state.role;
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        model.search = e.target.value.toLowerCase();
        model.page = 1;
        this.props.dispatch(usersActions.GetUsers(model));
    }

    generateRandomMesage(randomError) {
        var str = errOptions[Math.floor(Math.random() * Math.floor(5))];
        if (randomError == str) {
            str = this.generateRandomMesage();
        }
        return str;
    }

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    openChangeRoleModal = () => {
        this.setState({ IsChangeRoleModalOpen: true });
    };

    closeChangeRoleModal = () => {
        this.setState({ IsChangeRoleModalOpen: false });
    };

    clearUserSelection() {
        this.state.isSelectAll = false;
        this.state.selectedUsers = [];
        this.setState(this.state);
    }

    componentWillReceiveProps() {
        if (this.props.userlist && this.props.userlist.records && this.props.userlist.records.length === 0 && this.state.items.length === 0) {
            this.setState({ noRecordsFound: true });
        } else if (this.props.userlist && this.props.userlist.records && this.props.userlist.records.length > 0 && this.state.items.length === 0) {
            this.setState({ noRecordsFound: false });
        }
        this.props.dispatch(usersActions.ClearUserList());
    }

    componentDidMount() {

        var model = {};
        model.usertype = this.state.role;
        model.sort_by = "first_name";
        model.order_by = "ASC";
        model.page = 1;
        this.props.dispatch(usersActions.GetUsers(model));

        if (Storage.get('user') !== null) {
            this.setState({ sortby: "first_name", orderby: "ASC", login_user_type: JSON.parse(cryptr.decrypt(Storage.get('user'))).user_type });
        }
        else {
            this.setState({ sortby: "first_name", orderby: "ASC" });
        }
    }

    userSelection(e) {
        var id = e.target.id;
        if (e.target.checked) {
            if (this.state.selectedUsers.length + 1 === this.state.items.length) {
                this.setState({ isSelectAll: true, selectedUsers: [...this.state.selectedUsers, id] });
            } else {
                this.setState({ selectedUsers: [...this.state.selectedUsers, id] });
            }
        }
        else {
            this.setState({
                isSelectAll: false,
                selectedUsers: this.state.selectedUsers.filter((eid) => eid !== id)
            });
        }
    }

    getAllUsers() {
        if (this.state.isSearch) {
            this.searchButton.click();
        }
        this.setState({ role: 'all', activePage: 1, search: '', items: [], isScroll: true, selectedUsers: [], totalRecords: null, hasMore: true });
        var model = {};
        model.usertype = "all";
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        model.page = 1;
        this.props.dispatch(usersActions.GetUsers(model));
        this.props.dispatch(menuActions.ActivatePage('AllUsers'));
    }

    getAdmins() {
        if (this.state.isSearch) {
            this.searchButton.click();
        }
        this.setState({ role: 'admin', activePage: 1, search: '', items: [], isScroll: true, selectedUsers: [], totalRecords: null, hasMore: true });
        var model = {};
        model.usertype = "admin";
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        model.page = 1;
        this.props.dispatch(usersActions.GetUsers(model));
        this.props.dispatch(menuActions.ActivatePage('Admin'));
    }

    getUsers() {
        if (this.state.isSearch) {
            this.searchButton.click();
        }
        this.setState({ role: 'user', activePage: 1, search: '', items: [], isScroll: true, selectedUsers: [], totalRecords: null, hasMore: true });
        var model = {};
        model.usertype = "user";
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        model.page = 1;
        this.props.dispatch(usersActions.GetUsers(model));
        this.props.dispatch(menuActions.ActivatePage('Users'));
    }

    handleSearchChange(e) {
        if (e.target.name === "search") {
            this.setState({ search: e.target.value });
        }
    }

    search() {

        this.state.isSearch = !this.state.isSearch;
        if (this.state.isSearch) {
            this.searchInput.focus();
        }
        else {
            this.setState({ activePage: 1, search: '', items: [], isScroll: true, selectedUsers: [], totalRecords: null, hasMore: true });
            var model = {};
            model.usertype = this.state.role;
            model.sort_by = this.state.sortby;
            model.order_by = this.state.orderby;
            model.search = '';
            model.page = 1;
            this.props.dispatch(usersActions.GetUsers(model));
        }
    }

    changeOrder(e) {
        var orderby = "";
        if (this.state.sortby === e.target.dataset.sortby) {
            orderby = this.state.orderby === "DESC" ? "ASC" : "DESC";
        }
        else {
            orderby = "DESC";
        }

        this.setState({ activePage: 1, orderby: orderby, sortby: e.target.dataset.sortby, items: [], isScroll: true, selectedUsers: [], totalRecords: null, hasMore: true });

        var model = {};
        model.usertype = this.state.role;
        model.sort_by = e.target.dataset.sortby;
        model.order_by = orderby;
        model.page = 1;
        this.props.dispatch(usersActions.GetUsers(model));

    }

    selectAll(e) {
        if (e.target.checked) {
            this.state.selectedUsers = this.state.items.map((item) => {
                return item.id;
            });
            this.state.isSelectAll = true;
        }
        else {
            this.state.selectedUsers = [];
            this.state.isSelectAll = false;
        }
        this.setState(this.state);
    }

    fetchMoreData() {
        this.state.activePage += 1;
        this.state.isScroll = true;
        var model = {};
        model.usertype = this.state.role;
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        model.search = this.state.search;
        model.page = this.state.activePage;
        this.props.dispatch(usersActions.GetUsers(model));
    }

    removeItems(Ids) {
        Ids.forEach(id => {
            var index = this.state.items.findIndex(x => x.id == id);
            this.state.items.splice(index, 1);
        });
        this.state.totalRecords--;
    }

    updateItem(item) {
        var index = this.state.items.findIndex(x => x.id == item.id);
        this.state.items[index].first_name = item.first_name;
        this.state.items[index].last_name = item.last_name;
        this.state.items[index].email = item.email;
        this.state.items[index].user_type = item.user_type;
    }

    componentDidUpdate(prevProps, prevState) {

    }

    render() {

        if (this.props.location.state !== undefined) {
            switch (this.props.location.state.role) {
                case "all":
                    this.getAllUsers();
                    break;
                case "admin":
                    this.getAdmins();
                    break;
                case "user":
                    this.getUsers();
                    break;
            }
            this.props.location.state = undefined;
        }

        const { activePage, isSelectAll, sortby, orderby, selectedUsers, role, search, isSearch, isScroll, items, totalRecords, hasMore, randomError, noRecordsFound } = this.state;
        const { loading, error, userlist } = this.props;

        if (!loading && isScroll && userlist != null && userlist.records && userlist.records.length > 0 && this.state.items.length < userlist.total_records) {
            if (!items.find(x => x.id == userlist.records[0].id)) {
                var hasMoreRef = ((items.length + userlist.records.length) == userlist.total_records) ? false : true;

                var selectedUserList = [];
                if (isSelectAll) {
                    selectedUserList = userlist.records.map((item) => {
                        return item.id;
                    });
                }

                this.setState({
                    isScroll: false,
                    totalRecords: userlist.total_records,
                    selectedUsers: selectedUsers.concat(selectedUserList),
                    items: items.concat(userlist.records),
                    hasMore: hasMoreRef
                });

            }
        }

        const loadingClass = { 'textAlign': 'center', 'fontSize': '30px' };
        const truncateWidth = { 'width': '10vw', 'maxWidth': '10vw', 'display': 'block' };
        const header = { 'display': 'flex', 'height': '90px' }
        let isError = noRecordsFound;

        return (
            <div className="container">
                {/* <div style={header}>
                    <h1 className="page-title">Users</h1>
                </div> */}

                <div className="main-title">
                    <h1>Users</h1>
                </div>

                <div className="tab-parent">

                    <ul className="nav nav-tabs" role="tablist">
                        <li role="presentation" className={this.state.role === 'all' ? 'active' : ''}><a href="javascript:void(0);" onClick={this.getAllUsers.bind(this)}>All USERS</a></li>
                        <li role="presentation" className={this.state.role === 'admin' ? 'active' : ''}><a href="javascript:void(0);" onClick={this.getAdmins.bind(this)}>admins</a></li>
                        <li role="presentation" className={this.state.role === 'user' ? 'active' : ''}><a href="javascript:void(0);" onClick={this.getUsers.bind(this)}>users</a></li>
                        <li role="presentation" className="tab_search_box">
                            <div className="search-form">
                                <input ref={(searchInput) => { this.searchInput = searchInput; }}
                                    type="search" name="search" value={search}
                                    className="form-control"
                                    onChange={this.keyPress} />
                                <button ref={(searchButton) => { this.searchButton = searchButton; }}
                                    type="submit" className="search-btn" onClick={this.search}>
                                    <i className="fa fa-search" aria-hidden="true" ></i>
                                </button>
                            </div>
                        </li>
                    </ul>
                    <div className="tab-content bottom-0">
                        <div role="tabpanel" className="tab-pane bottom-0 active" id="allusers">
                            <div className="action-row content-action-row user_action_row">
                                <Link to={'/users/add'} to={{ pathname: '/users/add', state: { action: 'add' } }} className="btn btn-rounded btn-primary">
                                    <i className="la la-plus"></i>
                                </Link>
                                <div className="action_btn_group">
                                    {this.state.login_user_type !== "admin" && <button className="btn btn-default" disabled={selectedUsers.length === 0} onClick={this.openChangeRoleModal} disabled={selectedUsers.length === 0}>
                                        <i className="la la-refresh"></i> CHANGE ROLES
                                    </button>}
                                    <button className="btn btn-default delete_btn btn-delete" disabled={selectedUsers.length === 0} onClick={this.openDeleteConfirmModal} disabled={selectedUsers.length === 0}>
                                        <i className="la la-trash-o"></i> DELETE PEOPLE
                                        </button>
                                </div>
                            </div>

                            {items.length > 0 &&
                                <div className="table-container user-table table-responsive">
                                    <table className="table bottom-0" id="userTable">
                                        <thead>
                                            <tr>
                                                <th className="custom-checkbox">
                                                    <input type="checkbox" id="chk1" onChange={this.selectAll} checked={isSelectAll} />
                                                    <label htmlFor="chk1"></label>
                                                </th>
                                                <th className="profile-pic"></th>
                                                <th>
                                                    <span className={(sortby === 'first_name' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'first_name' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="first_name" onClick={this.changeOrder.bind(this)}>
                                                        First name
                                                </span>
                                                </th>
                                                <th>
                                                    <span className={(sortby === 'last_name' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'last_name' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="last_name" onClick={this.changeOrder.bind(this)}>Last name</span>
                                                </th>
                                                <th>
                                                    <span className={(sortby === 'email' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'email' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="email" onClick={this.changeOrder.bind(this)} style={{ whiteSpace: 'nowrap' }}>EMAIL</span>
                                                </th>
                                                <th>
                                                    <span className={(sortby === 'login_date' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'login_date' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="login_date" onClick={this.changeOrder.bind(this)} style={{ whiteSpace: 'nowrap' }}>LAST ACTIVE</span>
                                                </th>
                                                <th>
                                                    <span className={(sortby === 'user_type' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'user_type' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="user_type" onClick={this.changeOrder.bind(this)} style={{ whiteSpace: 'nowrap' }}>ROLE</span>
                                                </th>
                                                <th className="actions"></th>
                                            </tr>
                                        </thead>

                                        {/* {items.length > 0 && <InfiniteScroll
                                            target="table"
                                            dataLength={items.length}
                                            scrollThreshold="10px"
                                            next={this.fetchMoreData}
                                            hasMore={hasMore}
                                            loader={<tr><td colSpan="8" className="bottom-0" style={loadingClass}>Loading...</td></tr>} > */}
                                        <tbody>

                                            {items.map((item, i) => (
                                                <tr key={item.id} className={(selectedUsers.indexOf(item.id) > -1) ? 'active' : ''}>
                                                    <td className="custom-checkbox">
                                                        <input type="checkbox" id={item.id} onChange={this.userSelection} checked={(selectedUsers.indexOf(item.id) > -1)} />
                                                        <label htmlFor={item.id}></label>
                                                    </td>
                                                    <td className="profile-pic">
                                                        <span className={(item.user_type == 'user' ? 'userIconClass' : 'otherIconClass')}>{(item.first_name.slice(0, 1) + item.last_name.slice(0, 1))}</span>
                                                    </td>
                                                    <td><span style={truncateWidth} className="align-middle truncate"> {item.first_name} </span></td>
                                                    <td><span style={truncateWidth} className="align-middle truncate"> {item.last_name} </span></td>
                                                    <td><span style={{ width: '10vw', maxWidth: '10vw', display: 'block' }} className="truncate">{item.email}</span></td>
                                                    <td>{item.login_date}</td>
                                                    <td>{item.user_type}</td>
                                                    <td className="actions">
                                                        <UserActions Id={item.id} role={role}
                                                            UserDetails={item} orderby={orderby}
                                                            sortby={sortby} search={search} page={activePage}
                                                            removeItems={this.removeItems} updateItem={this.updateItem} />
                                                    </td>
                                                </tr>
                                            ))}
                                            {/* </InfiniteScroll>} */}
                                        </tbody>
                                    </table>
                                </div>}

                            {(items.length !== this.state.totalRecords) && items.length > 0 &&
                                <button className="btn btn-primary loadmore-btn" onClick={this.fetchMoreData}>
                                    Load More <i className="la la-angle-right"></i>
                                </button>
                            }
                            {!loading && noRecordsFound && <div className="text-center">
                                {isSearch && <label className="lblNoRecord">{randomError}</label>}
                                {!isSearch && <label className="lblNoRecord">No Record Found!</label>}
                            </div>}
                            {userlist && userlist.records && userlist.records.length > 0 && items.length === 0 && <div className="text-center">
                                <label className="lblNoRecord">Loading ...</label>
                            </div>}
                        </div>
                    </div>
                </div>

                <Modal open={this.state.IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal} >
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal}
                        title="Delete People(s)"
                        message="Are you sure you want to delete selected people(s)?"
                        selectedUsers={selectedUsers} action="multiple-user-delete" role={role} search={search} page={activePage}
                        orderby={orderby} sortby={sortby} clearUserSelection={this.clearUserSelection}
                        removeItems={this.removeItems} />
                </Modal>

                <Modal open={this.state.IsChangeRoleModalOpen} center showCloseIcon={false} onClose={this.closeChangeRoleModal} >
                    <ChangeUserRoleModal closeChangeRoleModal={this.closeChangeRoleModal} selectedUsers={selectedUsers} role={role}
                        orderby={orderby} sortby={sortby} search={search} page={activePage} clearUserSelection={this.clearUserSelection} />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    res: state.usersReducer.res,
    loading: state.usersReducer.loading,
    error: state.usersReducer.error,
    userlist: state.usersReducer.userlist
});

export default connect(mapStateToProps)(Users);