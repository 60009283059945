import { ENCRYPTION_KEY } from '../../constants/constants';
import { BASE_URL } from '../../constants/constants';
import { IMAGE_FORMATS, AUDIO_FORMATS, VIDEO_FORMATS } from '../../constants/constants';
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router-dom';
import DeleteConfirmModal from '../common/deleteConfirmModal';
import AddUpdateContentModal from './addUpdateContentModal';
import * as  contentActions from '../../actions/contentActions';
import queryString from 'query-string';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class ContentDetails extends Component {

    constructor() {
        super();
        this.state = {
            image_base_url: BASE_URL.replace("api/v7/", "") + "uploads/pages/",
            imageFormats: IMAGE_FORMATS,
            audioFormats: AUDIO_FORMATS,
            videoFormats: VIDEO_FORMATS,
            type: '',
            orderby: '',
            sortby: '',
            id: '',
            content_name: '',
            file_name: '',
            file_path: '',
            is_sharing: '',
            file_size: '',
            uploaded_date: '',
            updated_date: '',
            folder_path: '',
            extension: '',
            IsContentAddUpdateModalOpen: false,
            IsDeleteConfirmModalOpen: false,
            redirect: false,
            search: '',
            selectedPages: [],
            numPages: null,
            pageNumber: 1,
            isSearch: false,
            isDownloading: false,
            selectedTab: 'details',
            bu: []
        }

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.pageSelection = this.pageSelection.bind(this);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.download = this.download.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.getUpdatedDetails = this.getUpdatedDetails.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.updateTags = this.updateTags.bind(this);
        this.changeShaing = this.changeShaing.bind(this);
    }

    keyPress(e) {
        //if (e.keyCode == 13) {

        //}
        var model = {};
        model.sort_by = "name";
        model.order_by = "DESC";
        model.search = this.state.search;
        this.props.dispatch(contentActions.GetPages(model));
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/content' />
        }
    }

    openContentAddUpdateModal = () => {
        this.setState({ IsContentAddUpdateModalOpen: true });
    };

    closeContentAddUpdateModal = () => {
        this.setState({ IsContentAddUpdateModalOpen: false });
    };

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);

        if (values.content_id) {
            this.state.id = values.content_id;
            this.props.dispatch(contentActions.GetContentDetails({ content_id: values.content_id }));
            this.props.dispatch(contentActions.GetContentPages({ content_id: values.content_id }));
        }

        if (this.props.location.state !== undefined) {
            this.state.type = this.props.location.state.type;
            this.state.orderby = this.props.location.state.orderby;
            this.state.sortby = this.props.location.state.sortby;
            this.setState(this.state);
        }

        var model = {};
        model.sort_by = "name";
        model.order_by = "DESC";
        model.search = this.state.search;
        this.props.dispatch(contentActions.GetPages(model));
    }

    handleSearchChange(e) {
        if (e.target.name === "search") {
            this.setState({ search: e.target.value });
        }
    }

    search() {
        this.state.isSearch = !this.state.isSearch;
        if (this.state.isSearch) {
            this.searchInput.focus();
        }
        else {
            this.setState({ search: '' });
            var model = {};
            model.sort_by = "name";
            model.order_by = "DESC";
            model.search = '';
            this.props.dispatch(contentActions.GetPages(model));
        }
    }

    pageSelection(e) {

        var model = {};
        model.content_id = this.state.id;
        model.page_id = e.target.id;

        if (e.target.checked) {
            model.action = "insert";
        }
        else {
            model.action = "delete";
        }

        this.props.dispatch(contentActions.UpdateContentToPage(model));
    }

    async download() {
        var url = '';

        if (this.state.folder_path) {
            url = this.state.image_base_url + this.state.folder_path + '/' + this.state.file_name;
        } else {
            url = this.state.image_base_url + this.state.file_name;
        }

        this.setState({ isDownloading: true });
        let blob = await fetch(url).then(r => r.blob());
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob, { type: blob.type });
        a.download = this.state.file_name;
        document.body.appendChild(a);
        a.click();
        this.setState({ isDownloading: false })
        document.body.removeChild(a);
    }

    getUpdatedDetails() {
        const values = queryString.parse(this.props.location.search);
        if (values.content_id) {
            this.props.dispatch(contentActions.GetContentDetails({ content_id: values.content_id }));
        }
    }

    changeTab(e) {
        var tab = e.target.dataset.tab;
        this.setState({ selectedTab: tab });
    }

    updateTags(e) {
        var model = {};
        model.content_id = this.state.id;
        model.bu_id = parseInt(e.target.dataset.buid);
        model.status = e.target.dataset.status == 'false' ? true : false;
        model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
        this.props.dispatch(contentActions.ContentBusinessUnitUpdate(model));
        this.props.dispatch(contentActions.GetContentDetails({ content_id: this.state.id }));
    }
    changeShaing() {
        ;
        var model = {};
        if (this.state.is_sharing == '0') {
            model.is_sharing = '1'
        } else if (this.state.is_sharing == '1') {
            model.is_sharing = '0'
        }
        model.content_id = this.state.id;
        model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
        this.props.dispatch(contentActions.ChangeSharing(model));
        
    }

    render() {

        if (this.props.location.search === undefined || this.props.location.search === '') {
            return <Redirect to="/content" />
        }

        const { pageNumber, type, sortby, orderby, id, content_name, file_name, file_path, is_sharing, file_size, uploaded_date, updated_date, extension, image_base_url, search,
            audioFormats, imageFormats, videoFormats, isSearch, folder_path, isDownloading, selectedTab, bu } = this.state;

        const { details, contentpagelist, selectedpages, res } = this.props;

        if (res != null && res.success === "1") {
            toastr.success("Success !", res.message);
            this.props.dispatch(contentActions.GetContentPages({ content_id: id }));
            this.props.dispatch(contentActions.GetContentDetails({ content_id: this.state.id }));
            var model = {};
            model.sort_by = "name";
            model.order_by = "DESC";
            model.search = this.state.search;
            this.props.dispatch(contentActions.GetPages(model));
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        const bytesToSize = (bytes) => {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes === 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
        }
        ;
        if (details !== null && details !== undefined) {
            ;
            this.state.id = details.id;
            this.state.content_name = details.content_name;
            this.state.file_name = details.file_name;
            this.state.file_path = details.file_path;
            this.state.extension = this.state.content_name.slice(this.state.content_name.lastIndexOf('.'), this.state.length).replace('.', '');
            this.state.is_sharing = details.is_sharing;
            this.state.file_size = bytesToSize(details.file_size);
            this.state.updated_date = details.updated_date;
            this.state.uploaded_date = details.created_date;
            if (details.folder_path) {
                this.state.folder_path = details.folder_path;
            }
            this.state.bu = details.bu;
            this.setState(this.state);
            this.props.dispatch(contentActions.Clear());
        }

        const header = { 'display': 'flex', 'height': '90px' }

        return (
            <div className="container">
                {this.renderRedirect()}

                <div className="detail-title">
                    <Link to='/content' className="btn btn-default">
                        <i className="la la-mail-reply"></i> BACK
                    </Link>
                    <h1>{content_name}</h1>
                </div>

                <div className="action-row clearfix add-action-row mb0">
                    <div className=" btn-left">
                        {/* <Link to='/content' className="btn btn-default btn-border">
                            <i className="la la-mail-reply"></i> BACK
                        </Link> */}

                        <button className="btn btn-default save-btn" onClick={this.download}>
                            <i className="la la-download"></i>
                            {!isDownloading && <span>DOWNLOAD</span>}
                            {isDownloading && <span>Downloading ...</span>}
                        </button>
                        <button className="btn btn-default black-btn" onClick={this.openContentAddUpdateModal}>
                            <i className="la la-rotate-right"></i> UPDATE
                        </button>
                    </div>
                    <div className="btn-right">

                        <button className="btn btn-default red-btn" onClick={this.openDeleteConfirmModal}>
                            <i className="la la-trash-o"></i> DELETE
                        </button>
                    </div>
                </div>
                <div className="row preview-row width_set no-flex content-detail-wrap">
                    <div className="col-md-8 col-sm-12 left_width">

                        {file_path != "" && imageFormats.indexOf(extension.toLowerCase()) == -1 && videoFormats.indexOf(extension.toLowerCase()) == -1 && audioFormats.indexOf(extension.toLowerCase()) == -1 && <div className="preview-box-doc">
                            <iframe src={"https://docs.google.com/gview?url=" + file_path + "&embedded=true"}></iframe>
                        </div>}
                        {file_path == "" && imageFormats.indexOf(extension.toLowerCase()) == -1 && videoFormats.indexOf(extension.toLowerCase()) == -1 && audioFormats.indexOf(extension.toLowerCase()) == -1 && <div className="preview-box"><img src="../images/no-file.jpg" className="img-responsive" /></div>}
                        {imageFormats.indexOf(extension.toLowerCase()) > -1 && <div className="preview-box"><img src={(file_name === '') ? "../images/no-image.png" : file_path} className="img-responsive" /></div>}
                        {videoFormats.indexOf(extension.toLowerCase()) > -1 && <div className="preview-box"><video controls style={{ width: '100%' }}>
                            <source src={(folder_path == '') ? image_base_url + file_name : image_base_url + folder_path + '/' + file_name} type="video/mp4" />
                        </video></div>}
                        {audioFormats.indexOf(extension.toLowerCase()) > -1 && <div className="preview-box"><audio controls style={{ width: '100%' }}>
                            <source src={(folder_path == '') ? image_base_url + file_name : image_base_url + folder_path + '/' + file_name} type="audio/mpeg" />
                        </audio></div>}
                    </div>
                    <div className="col-md-4 col-sm-12 right_width">
                        <div className="tab-parent sidebar-tab">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className={selectedTab === 'details' ? 'active' : ''} data-tab="details" onClick={this.changeTab.bind(this)}>
                                    <a href="javascript:void();" data-tab="details" onClick={this.changeTab.bind(this)}>Details</a>
                                </li>
                                <li role="presentation" className={selectedTab === 'pages' ? 'active' : ''} data-tab="pages" onClick={this.changeTab.bind(this)}>
                                    <a href="javascript:void();" data-tab="pages" onClick={this.changeTab.bind(this)}>Assigned Pages</a>
                                </li>
                            </ul>
                        </div>
                        {selectedTab == 'details' && <div className="form-container">
                            <form>
                                <div className="form-group">
                                    <label>File Name</label>
                                    <input type="text" value={content_name} className="form-control" disabled />
                                </div>
                                <div className=" form-group">
                                    <label>SHARING</label>
                                    <select className="form-control" onChange={this.changeShaing} value={is_sharing}>
                                        <option className="text-success" value="1">On</option>
                                        <option className="text-danger" value="0">Off</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Size</label>
                                    <input type="text" value={file_size} className="form-control" disabled />
                                </div>
                                <div className="form-group">
                                    <label>UPLOADED</label>
                                    <input type="text" value={uploaded_date} className="form-control" disabled />
                                </div>
                                <div className="form-group">
                                    <label>LAST MODIFIED</label>
                                    <input type="text" value={updated_date} className="form-control" disabled />
                                </div>
                                {bu && bu.length > 0 && <div className="form-group">
                                    <label>TAGS</label>
                                    <div className="ub-list">
                                        {bu.map(item => (
                                            <div Key={item.id} className={item.status == 'true' ? 'ub active' : 'ub'} data-buid={item.id} data-status={item.status} onClick={this.updateTags.bind(this)}>{item.name}</div>
                                        ))}
                                    </div>
                                </div>}

                            </form>
                        </div>}
                        {selectedTab == 'pages' && <div className="assigned-pages">
                            <div className="action-row clearfix">
                                <div className="search-form search-left">
                                    <input ref={(searchInput) => { this.searchInput = searchInput; }}
                                        type="text" className="form-control" value={search} name="search"
                                        onKeyUp={this.keyPress} onChange={this.handleSearchChange} />
                                    <button type="submit" className="search-btn" onClick={this.search}>
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            {contentpagelist !== null && contentpagelist.records !== undefined && contentpagelist.records.length > 0 &&
                                <div className="pages-list mCustomScrollbar" data-mcs-theme="dark">
                                    <ul>
                                        {contentpagelist != null && contentpagelist.records !== undefined && contentpagelist.records.map(item => (
                                            <li key={item.id}>
                                                <div className="custom-checkbox">
                                                    <input type="checkbox" id={item.id} onChange={this.pageSelection} checked={(selectedpages.indexOf(item.id) > -1)} />
                                                    <label htmlFor={item.id}>
                                                        <img src="../images/camera.png" className="file-img img-responsive" alt="" />
                                                        <span className="page-info" style={{ cursor: 'pointer' }}>
                                                            <span className="page-name truncate">{item.name}</span>
                                                            <span className="ellipsis page-text truncate">{item.subtitle}</span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>}
                            {contentpagelist !== null && contentpagelist.records !== undefined && contentpagelist.records.length === 0 && <div className="text-center">
                                {isSearch && <label className="lblNoRecord">Nothing matches that search. Sorry!</label>}
                                {!isSearch && <label className="lblNoRecord">No Record Found!</label>}
                            </div>}
                        </div>}


                    </div>
                </div>
                <Modal open={this.state.IsContentAddUpdateModalOpen} center showCloseIcon={false} onClose={this.closeContentAddUpdateModal}>
                    <AddUpdateContentModal closeContentAddUpdateModal={this.closeContentAddUpdateModal} action="update" Id={id} getUpdatedDetails={this.getUpdatedDetails} />
                </Modal>
                <Modal open={this.state.IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal} onDeleteSuccess={this.setRedirect} title="Delete Content" message="Are you sure you want to delete this content?"
                        Id={id} action="content-delete-from-detail" type={type}
                        orderby={orderby} sortby={sortby} />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.contentReducer.loading,
    error: state.contentReducer.error,
    details: state.contentReducer.details,
    contentpagelist: state.contentReducer.contentpagelist,
    selectedpages: state.contentReducer.selectedpages,
    res: state.contentReducer.res,
    details: state.contentReducer.details
});

export default connect(mapStateToProps)(ContentDetails);
