import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router-dom';
import DeleteConfirmModal from '../common/deleteConfirmModal';
import * as  buActions from '../../actions/buActions';
import BusinessUnitSpecialities from './businessUnitSpecialities';
import BusinessUnitCompititors from './businessUnitCompititors';
import queryString from 'query-string';

class BusinessUnitDetails extends Component {

    constructor() {
        super();
        this.state = {
            IsDeleteConfirmModalOpen: false,
            item: {},
            orderby: '',
            sortby: '',
            selectedTab: 'details',
            search: '',
            name: '',
            validations: {
                name: ''
            },
            isValidated: true
        }

        this.changeTab = this.changeTab.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);

        if (values.bu_id) {
            this.props.dispatch(buActions.GetBUDetails({ id: values.bu_id }));
        }
    }

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/tags' />
        }
    }

    changeTab(e) {
        var tab = e.target.dataset.tab;
        this.setState({ selectedTab: tab });
    }

    handleChange(e) {

        if (e.target.value) {
            this.setState({ isValidated: true, name: e.target.value, validations: { ...this.state.validations, name: '' } });
        } else {
            this.setState({ isValidated: false, name: e.target.value, validations: { ...this.state.validations, name: 'Name is required' } });
        }
    }

    handleSubmit(e) {
        var model = {};
        model.id = this.state.item.id;
        model.name = this.state.name;
        this.props.dispatch(buActions.EditBU(model));
    }

    render() {

        if (this.props.location.search === undefined || this.props.location.search === '') {
            return <Redirect to="/tags" />
        }

        const { name, validations, isValidated, item, orderby, sortby, selectedTab } = this.state;

        const { details, editbures } = this.props;

        if (details !== null && details !== undefined) {
            this.state.item = details;
            this.state.name = details.name;
            this.setState(this.state);
            this.props.dispatch(buActions.Clear());
        }

        if (editbures != null && editbures.success === "1") {
            this.props.dispatch(buActions.initiateState());
            toastr.success("Success !", editbures.message);
            var model = {};
            model.id = item.id;
            this.props.dispatch(buActions.GetBUDetails(model));            
        }
        else if (editbures != null && editbures.success === "0") {
            toastr.error('Error', editbures.message);
        }

        const header = { 'display': 'flex', 'height': '90px' }
        
        return (
            <div className="container">
                {this.renderRedirect()}

                <div className="detail-title">
                    <Link to='/tags' className="btn btn-default">
                        <i className="la la-mail-reply"></i> BACK
                    </Link>
                    <h1>{item.name}</h1>
                </div>

                <div className="action-row clearfix add-action-row mb0">
                    <button className="btn btn-default save-btn" disabled={!isValidated} onClick={this.handleSubmit}>
                        <i className="la la-check-circle"></i> SAVE CHANGES
                    </button>

                    <button className="btn btn-default btn-delete" onClick={this.openDeleteConfirmModal}>
                        <i className="la la-trash-o"></i> DELETE
                    </button>
                </div>
                
                <div className="tag-detail-wrap">
                    
                        <div className="tab-parent sidebar-tab">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className={selectedTab === 'details' ? 'active' : ''} data-tab="details" onClick={this.changeTab.bind(this)}>
                                    <a href="javascript:void();" data-tab="details" onClick={this.changeTab.bind(this)}>Details</a>
                                </li>
                                <li role="presentation" className={selectedTab === 'specialities' ? 'active' : ''} data-tab="specialities" onClick={this.changeTab.bind(this)}>
                                    <a href="javascript:void();" data-tab="specialities" onClick={this.changeTab.bind(this)}>Specialties</a>
                                </li>
                                <li role="presentation" className={selectedTab === 'compititors' ? 'active' : ''} data-tab="compititors" onClick={this.changeTab.bind(this)}>
                                    <a href="javascript:void();" data-tab="compititors" onClick={this.changeTab.bind(this)}>Competitors</a>
                                </li>
                            </ul>
                        </div>
                        {selectedTab == 'details' && <div className="form-container tag-dtl-form-wrap">
                            <form>
                                <div className="form-group">
                                    <label>Name</label>
                                    <input ref={(nameInput) => { this.nameInput = nameInput; }}
                                        type="search" name="search" value={name}
                                        className="form-control"
                                        onChange={this.handleChange} />
                                    {validations.name !== "" && <span className={validations.name === "" ? "hidden" : "has-error"}>{validations.name}</span>}
                                </div>
                            </form>
                        </div>}
                        {selectedTab == 'specialities' && <div className="form-container">
                            <BusinessUnitSpecialities buid={item.id}></BusinessUnitSpecialities>
                        </div>}
                        {selectedTab == 'compititors' && <div className="form-container">
                            <BusinessUnitCompititors buid={item.id}></BusinessUnitCompititors>
                        </div>}
                    
                </div>

                <Modal open={this.state.IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal} title="Delete Business Unit" message="Are you sure you want to delete this business unit?"
                        Id={item.id} action="bu-delete-from-detail"
                        orderby={orderby} sortby={sortby} setRedirect={this.setRedirect} />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.buReducer.loading,
    error: state.buReducer.error,    
    editbures: state.buReducer.editbures,
    details: state.contentReducer.details
});

export default connect(mapStateToProps)(BusinessUnitDetails);
