import { returnResponse } from './baseReducer';

const initialState = {    
    loading: false,
    error: null,
    buspres: null,
    specialitylist: {},    
};

export default function specialityReducer(state = initialState, action) {
    return returnResponse(state, action);
}