import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import * as usersActions from '../../actions/usersActions';

class ChangeUserRoleModal extends Component {

    constructor() {
        super();

        this.state = {    
            user_type: '0',
            validations: {
                role: ''
            },
            isValidated: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        
        
        switch (e.target.id){
            case "role":
                if (e.target.value === "0") {                    
                    this.setState({ isValidated: false, user_type: e.target.value, validations: { ...this.state.validations, role: 'Role is required' } });
                }
                else {                   
                    this.setState({ isValidated: true, user_type: e.target.value, validations: { ...this.state.validations, role: '' } });
                }
                break;
            default:
                break;
        }
    }


    handleSubmit(e) {        
        var model = {};
        model.user_id = this.props.selectedUsers;
        model.role = this.state.user_type;        
        this.props.dispatch(usersActions.ChangeRole(model));        
    }

    render() {
        const { user_type, validations, isValidated } = this.state;
        
        const { res } = this.props;

        if (res != null && res.success === "1") {
            
            toastr.success("Success !", res.message);
            this.props.clearUserSelection();
            this.props.closeChangeRoleModal();            
            var model = {};
            model.usertype = this.props.role;
            model.sort_by = this.props.sortby;
            model.order_by = this.props.orderby;
            model.search = this.props.search;
            model.page = this.props.page;
            this.props.dispatch(usersActions.GetUsers(model));
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        return (
            
            <div className="modal-dialog" style={{ margin: '0px' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Change role for selected user(s)</h4>
                    </div>
                    <div className="modal-body form-container">                        
                            <div className="form-group">
                                <label>ROLE</label>
                                <select className="form-control" id="role" value={user_type} onChange={this.handleChange}>
                                    <option value="0">--Select role--</option>
                                    <option value="admin">Admin</option>
                                    <option value="user">User</option>
                                </select>
                                {validations.role !== "" && <span className={validations.role === "" ? "hidden" : "has-error"}>{validations.role}</span>}
                            </div>                        
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={this.props.closeChangeRoleModal}>CANCEL</button>
                        <button type="button" className="btn btn-primary" disabled={!isValidated} onClick={this.handleSubmit}>SAVE CHANGES</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.usersReducer.loading,
    error: state.usersReducer.error,
    res: state.usersReducer.res
});

export default connect(mapStateToProps)(ChangeUserRoleModal);