import { BEGIN, FAILURE, BASE_URL, ALL_USER_LIST, ADMIN_LIST, USER_LIST, RES, CLEAR_USER_LIST} from '../constants/constants';
import { fetchBegin, fetchSuccess, fetchFailure } from '../actions/actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import axios from 'axios';

export const GetUsers = (model) => dispatch => {        
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "user/adminuserlist";    
    dispatch(showLoading());
    axios.post(url, model )
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, ALL_USER_LIST));        
    })
        .catch(function (error) {     
            dispatch(hideLoading());       
            dispatch(fetchFailure(error, FAILURE));        
    });   
}

export const AddUser = (model) => dispatch =>
{   
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "user/adminuseradd";
    dispatch(showLoading());
    axios.post(url, { data: model })
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));        
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));        
        });   
}

export const UpdateUser = (model) => dispatch => {
    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "user/adminuseradd";
    dispatch(showLoading());
    axios.post(url, { data: model })
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));                
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));        
        });
}

export const DeleteUser = (user_id) => dispatch => {    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "user/adminuserdelete";
    dispatch(showLoading());
    axios.post(url, { user_id: user_id })
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));                
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));            
        });
}

export const ChangeRole = (model) => dispatch => {
    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "user/adminuserchangerole";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));        
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));        
        });
}

export const initiateState = () => dispatch => {    
    dispatch(fetchBegin(BEGIN));    
}

export const ClearUserList = () => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_USER_LIST));
}
