//export const BASE_URL = "http://192.168.1.199/crush/phase6/public/api/v7/"; // Local
//export const BASE_URL = "http://beta-crush.s2client.com/api/v7/"; //Beta
//export const BASE_URL = "http://alpha-crush.s2client.com/api/v7/"; //Alpha
//export const BASE_URL = "http://crush21.sykcrush.com/api/v7/"; // Production
export const BASE_URL = "https://api.sykcrush.com/api/v7/"; // Production

//export const BASE_PATH = "http://localhost:3006/"; //Local
//export const BASE_PATH = "http://localhost:3006/"; //Local
//export const BASE_PATH = "http://beta-admin.sykcrush.com/"; //Beta
//export const BASE_PATH = "http://alpha-admin.sykcrush.com/"; //Alpha
export const BASE_PATH = "http://admin.sykcrush.com/"; // Production


export const BEGIN = "BEGIN";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";

export const ACTIVE_PAGE = "ACTIVE_PAGE";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_DETAIL = "LOGIN_DETAIL";
export const RES = "RES";

export const ALL_USER_LIST = "ALL_USER_LIST";
export const USER_LIST = "USER_LIST";
export const ADMIN_LIST = "ADMIN_LIST";
export const CLEAR_USER_LIST = "CLEAR_USER_LIST";

export const CLEAR_CONTENT_DETAILS = "CLEAR_CONTENT_DETAILS";
export const CONTENT_DETAILS = "CONTENT_DETAILS";
export const CONTENT_LIST = "CONTENT_LIST";
export const CLEAR_CONTENT_LIST = "CLEAR_CONTENT_LIST";
export const CONTENT_PAGE_LIST = "CONTENT_PAGE_LIST";
export const CLEAR_CONTENT_PAGE_LIST = "CLEAR_CONTENT_PAGE_LIST";
export const CONTENT_PAGE_ASSIGNED = "CONTENT_PAGE_ASSIGNED";
export const DOCUMENT_LIST = "DOCUMENT_LIST";
export const MEDIA_LIST = "MEDIA_LIST";


export const BU_DETAILS = "BU_DETAILS";
export const CLEAR_BU_DETAILS = "CLEAR_BU_DETAILS";
export const ADD_BU = "ADD_BU";
export const BU_LIST = "BU_LIST";
export const CLEAR_BU_LIST = "CLEAR_BU_LIST";
export const BU_SP_LIST = "BU_SP_LIST";
export const CLEAR_SP_LIST = "CLEAR_SP_LIST";
export const BU_CP_LIST = "BU_CP_LIST";
export const CLEAR_CP_LIST = "CLEAR_CP_LIST";
export const SP_RES = "SP_RES";
export const CP_RES = "CP_RES";

export const EDIT_BU_RES = "EDIT_BU_RES";
export const BU_SP_RES = "BU_SP_RES";
export const BU_CP_RES = "BU_CP_RES";


export const CLEAR_PAGE_CONTENT_LIST = "CLEAR_PAGE_CONTENT_LIST";
export const PAGE_CONTENT_ASSIGNED = "PAGE_CONTENT_ASSIGNED";
export const PAGE_CONTENT_LIST = "PAGE_CONTENT_LIST";
export const CLEAR_PAGE_LIST = "CLEAR_PAGE_LIST";
export const PAGE_LIST = "PAGE_LIST";
export const PAGE_BU_LIST = "PAGE_BU_LIST";

export const AUDIO_LIST = "AUDIO_LIST";
export const CLEAR_AUDIO_LIST = "CLEAR_AUDIO_LIST";
export const AUDIO_DETAILS = "AUDIO_DETAILS";
export const CLEAR_AUDIO_DETAILS = "CLEAR_AUDIO_DETAILS";
export const AUDIO_BU_LIST = "AUDIO_BU_LIST";
export const ADD_AUDIO_CATEGORY = "ADD_AUDIO_CATEGORY";
export const SUB_AUDIO_LIST = "SUB_AUDIO_LIST";
export const ADD_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY";

export const SPECIALITYLIST = "SPECIALITYLIST";
export const STACKHOLDER_LIST = "STACKHOLDER_LIST";
export const PRODUCT_LIST = "PRODUCT_LIST";

export const SPECIALITY_LIST = "SPECIALITY_LIST";
export const CLEAR_SPECIALITY_LIST = "CLEAR_SPECIALITY_LIST";
export const COMPITITOR_LIST = "COMPITITOR_LIST";
export const CLEAR_COMPITITOR_LIST = "CLEAR_COMPITITOR_LIST";

export const ENCRYPTION_KEY = "CRUSH@123!";

export const errOptions = ['Nothing matches that search. Sorry!',
    'Shoot! Nothing matches that search.',
    'Aghh... Nothing matches that search. Try again?',
    'Well, this is embarrassing... Nothing matches that search',
    'Nada! Try searching again.'];

export const IMAGE_FORMATS = ['jpeg',
    'jpg',
    'jif',
    'jfif',
    'tif',
    'tiff',
    'gif',
    'jp2',
    'jpx',
    'j2k',
    'j2c',
    'fpx',
    'pcd',
    'png'];

export const AUDIO_FORMATS = [
    "wav",
    "aif",
    "mp3",
    "mid",
    "0cc",
    "2sf",
    "2sflib",
    "3ga",
    "3gpa",
    "4mp",
    "5xb",
    "5xe",
    "5xs",
    "669",
    "6cm",
    "8cm",
    "8med",
    "8svx",
    "a2b",
    "a2i",
    "a2m",
    "a2p",
    "a2t",
    "a2w",
    "a52",
    "aa",
    "aa3",
    "aac",
    "aax",
    "ab",
    "abc",
    "abm",
    "ac3",
    "acb",
    "acd",
    "acd-bak",
    "acd-zip",
    "acm",
    "acp",
    "act",
    "adg",
    "adt",
    "adts",
    "adv",
    "adx",
    "afc",
    "agm",
    "agr",
    "ahx",
    "aif",
    "aifc",
    "aiff",
    "aimppl",
    "ais",
    "aix",
    "akp",
    "al",
    "alac",
    "alaw",
    "alc",
    "all",
    "als",
    "amf",
    "amr",
    "ams",
    "ams",
    "amxd",
    "amz",
    "ang",
    "aob",
    "ape",
    "apf",
    "apl",
    "aria",
    "ariax",
    "asd",
    "ase",
    "at3",
    "atrac",
    "au",
    "au",
    "aud",
    "audionote",
    "aup",
    "avastsounds",
    "avr",
    "awb",
    "awb",
    "awc",
    "axa",
    "ay",
    "b4s",
    "band",
    "bap",
    "bcs",
    "bcstm",
    "bdd",
    "bfstm",
    "bidule",
    "bmml",
    "bnk",
    "bonk",
    "box",
    "brr",
    "brstm",
    "bun",
    "bwf",
    "bwg",
    "bww",
    "c01",
    "caf",
    "caff",
    "cda",
    "cdda",
    "cdlx",
    "cdo",
    "cdr",
    "cel",
    "cfa",
    "cfxr",
    "cgrp",
    "cidb",
    "ckb",
    "ckf",
    "cmf",
    "conform",
    "copy",
    "cpr",
    "cpt",
    "csh",
    "cts",
    "cwb",
    "cwp",
    "cws",
    "cwt",
    "d00",
    "d01",
    "dcf",
    "dcm",
    "dct",
    "ddt",
    "dewf",
    "df2",
    "dfc",
    "dff",
    "dig",
    "dig",
    "djr",
    "dls",
    "dm",
    "dmc",
    "dmf",
    "dmsa",
    "dmse",
    "dra",
    "drg",
    "ds",
    "ds2",
    "dsf",
    "dsm",
    "dsp",
    "dss",
    "dtm",
    "dts",
    "dtshd",
    "dvf",
    "dw",
    "dwa",
    "dwd",
    "ear",
    "edl",
    "efa",
    "efe",
    "efk",
    "efq",
    "efs",
    "efv",
    "emd",
    "emp",
    "emx",
    "emy",
    "eop",
    "erb",
    "esps",
    "evr",
    "evrc",
    "expressionmap",
    "exs",
    "f2r",
    "f32",
    "f3r",
    "f4a",
    "f64",
    "far",
    "fda",
    "fdp",
    "fev",
    "fff",
    "flac",
    "flm",
    "flp",
    "flp",
    "fls",
    "fpa",
    "fpr",
    "frg",
    "fsb",
    "fsc",
    "fsm",
    "fti",
    "ftm",
    "ftm",
    "ftmx",
    "fuz",
    "fzb",
    "fzf",
    "fzv",
    "g721",
    "g723",
    "g726",
    "gbproj",
    "gbs",
    "gig",
    "gio",
    "gio",
    "gm",
    "gmc",
    "gp5",
    "gpbank",
    "gpk",
    "gpx",
    "gro",
    "groove",
    "gsf",
    "gsflib",
    "gsm",
    "gsm",
    "gym",
    "h0",
    "h3b",
    "h3e",
    "h4b",
    "h4e",
    "h5b",
    "h5e",
    "h5s",
    "hbb",
    "hbe",
    "hbs",
    "hca",
    "hdp",
    "hma",
    "hmi",
    "hps",
    "hsb",
    "iaa",
    "ics",
    "iff",
    "igp",
    "igr",
    "imf",
    "imp",
    "ins",
    "ins",
    "ins",
    "isma",
    "it",
    "iti",
    "itls",
    "its",
    "jam",
    "jam",
    "jo\tjo",
    "jo-7z",
    "jspf",
    "k25",
    "k26",
    "kar",
    "kfn",
    "kin",
    "kit",
    "kmp",
    "koz",
    "koz",
    "kpl",
    "krz",
    "ksc",
    "ksd",
    "ksf",
    "ksm",
    "kt2",
    "kt3",
    "ktp",
    "l",
    "la",
    "lof",
    "logic",
    "logicx",
    "lqt",
    "lso",
    "lvp",
    "lwv",
    "m",
    "m1a",
    "m2",
    "m3u",
    "m3u8",
    "m4a",
    "m4b",
    "m4p",
    "m4r",
    "m5p",
    "ma1",
    "mbr",
    "mdc",
    "mdl",
    "mdr",
    "med",
    "mgv",
    "mid",
    "midi",
    "mini2sf",
    "minigsf",
    "minincsf",
    "minipsf",
    "minipsf2",
    "miniusf",
    "mka",
    "mlp",
    "mmf",
    "mmlp",
    "mmm",
    "mmp",
    "mmp",
    "mmpz",
    "mo3",
    "mod",
    "mogg",
    "mp1",
    "mp2",
    "mp3",
    "mp_",
    "mpa",
    "mpc",
    "mpdp",
    "mpga",
    "mpu",
    "mscx",
    "mscz",
    "msmpl_all",
    "msmpl_bank",
    "msv",
    "mt2",
    "mt9",
    "mte",
    "mtf",
    "mti",
    "mtm",
    "mtp",
    "mts",
    "mu3",
    "mui",
    "mus",
    "mus",
    "mus",
    "musa",
    "musx",
    "mux",
    "mux",
    "muz",
    "mwand",
    "mws",
    "mx3",
    "mx4",
    "mx5",
    "mx5template",
    "mxl",
    "mxmf",
    "myr",
    "mzp",
    "naac",
    "nap",
    "narrative",
    "nbs",
    "ncw",
    "nfa",
    "nkb",
    "nkc",
    "nki",
    "nkm",
    "nks",
    "nkx",
    "nml",
    "nmsv",
    "note",
    "npl",
    "nra",
    "nrt",
    "nsa",
    "nsf",
    "nst",
    "ntn",
    "nus3bank",
    "nvf",
    "nwc",
    "obw",
    "odm",
    "ofr",
    "oga",
    "ogg",
    "okt",
    "oma",
    "omf",
    "omg",
    "omx",
    "opus",
    "orc",
    "ots",
    "ove",
    "ovw",
    "ovw",
    "pac",
    "paf",
    "pandora",
    "pat",
    "pbf",
    "pca",
    "pcast",
    "pcg",
    "pcm",
    "pd",
    "peak",
    "pek",
    "pho",
    "phy",
    "pjunoxl",
    "pk",
    "pkf",
    "pla",
    "pls",
    "plst",
    "ply",
    "pmpl",
    "pna",
    "pno",
    "ppc",
    "ppcx",
    "prg",
    "prg",
    "psf",
    "psf1",
    "psf2",
    "psm",
    "psy",
    "ptcop",
    "ptf",
    "ptm",
    "pts",
    "ptt",
    "ptx",
    "ptxt",
    "pvc",
    "pvf",
    "q1",
    "q2",
    "qcp",
    "r",
    "r1m",
    "ra",
    "rad",
    "ram",
    "raw",
    "rax",
    "rbs",
    "rbs",
    "rcy",
    "record",
    "rex",
    "rfl",
    "rgrp",
    "rip",
    "rmf",
    "rmi",
    "rmj",
    "rmm",
    "rmx",
    "rng",
    "rns",
    "rol",
    "rsf",
    "rsn",
    "rso",
    "rta",
    "rti",
    "rtm",
    "rts",
    "rvx",
    "rx2",
    "s16",
    "s3i",
    "s3m",
    "s3z",
    "sabl",
    "saf",
    "sam",
    "sap",
    "sb",
    "sbg",
    "sbi",
    "sbk",
    "sc2",
    "scs11",
    "sd",
    "sd",
    "sd2",
    "sd2f",
    "sdat",
    "sdii",
    "sds",
    "sdt",
    "sdx",
    "seg",
    "seq",
    "ses",
    "sesx",
    "sf",
    "sf2",
    "sfap0",
    "sfk",
    "sfl",
    "sfpack",
    "sfs",
    "sfx",
    "sfz",
    "sgp",
    "shn",
    "sib",
    "sid",
    "slp",
    "slx",
    "sma",
    "smf",
    "smp",
    "smp",
    "smpx",
    "snd",
    "snd",
    "snd",
    "sng",
    "sng",
    "sngx",
    "sns",
    "snsf",
    "song",
    "sou",
    "sph",
    "sppack",
    "sprg",
    "spx",
    "sseq",
    "sseq",
    "ssm",
    "ssnd",
    "st3",
    "stap",
    "sth",
    "sti",
    "stm",
    "stw",
    "stx",
    "sty",
    "sty",
    "svd",
    "svq",
    "svx",
    "sw",
    "swa",
    "swav",
    "sxt",
    "syh",
    "syn",
    "syn",
    "syw",
    "syx",
    "tak",
    "tak",
    "td0",
    "tfmx",
    "tg",
    "thx",
    "tm2",
    "tm8",
    "tmc",
    "toc",
    "trak",
    "tsp",
    "tta",
    "tun",
    "txw",
    "u",
    "u8",
    "uax",
    "ub",
    "ulaw",
    "ult",
    "ulw",
    "uni",
    "usf",
    "usflib",
    "ust",
    "uw",
    "uwf",
    "v2m",
    "vag",
    "val",
    "vap",
    "vb",
    "vc3",
    "vce",
    "vdj",
    "vgm",
    "vgz",
    "vip",
    "vlc",
    "vmd",
    "vmf",
    "vmf",
    "vmo",
    "voc",
    "voc",
    "voi",
    "vox",
    "voxal",
    "vpl",
    "vpm",
    "vpr",
    "vpw",
    "vqf",
    "vrf",
    "vsq",
    "vsqx",
    "vtx",
    "vyf",
    "w01",
    "w64",
    "wand",
    "wav",
    "wav",
    "wave",
    "wax",
    "wem",
    "wfb",
    "wfd",
    "wfm",
    "wfp",
    "wma",
    "wow",
    "wpk",
    "wpp",
    "wproj",
    "wrk",
    "wtpl",
    "wtpt",
    "wus",
    "wut",
    "wv",
    "wvc",
    "wve",
    "wwu",
    "wyz",
    "xa",
    "xa",
    "xbmml",
    "xfs",
    "xi",
    "xm",
    "xma",
    "xmf",
    "xmi",
    "xms",
    "xmu",
    "xmz",
    "xp",
    "xpf",
    "xrns",
    "xsb",
    "xsp",
    "xspf",
    "xt",
    "xwb",
    "ym",
    "yookoo",
    "zab",
    "zgr",
    "zpa",
    "zpl",
    "zvd",
    "zvr"
];

export const VIDEO_FORMATS = [
    "264",
    "3g2",
    "3gp",
    "3gp2",
    "3gpp",
    "3gpp2",
    "3mm",
    "3p2",
    "60d",
    "787",
    "890",
    "aaf",
    "aec",
    "aecap",
    "aegraphic",
    "aep",
    "aepx",
    "aet",
    "aetx",
    "ajp",
    "ale",
    "am",
    "amc",
    "amv",
    "amx",
    "anim",
    "anx",
    "aqt",
    "arcut",
    "arf",
    "asf",
    "asx",
    "av",
    "av3",
    "avb",
    "avc",
    "avchd",
    "avd",
    "ave",
    "avi",
    "avm",
    "avp",
    "avr",
    "avs",
    "avs",
    "avv",
    "awlive",
    "axm",
    "axv",
    "bdm",
    "bdmv",
    "bdt2",
    "bdt3",
    "bik",
    "bin",
    "bix",
    "blz",
    "bmc",
    "bmk",
    "bnp",
    "box",
    "bs4",
    "bsf",
    "bu",
    "bvr",
    "byu",
    "camproj",
    "camrec",
    "camv",
    "ced",
    "cel",
    "cine",
    "cip",
    "clk",
    "clpi",
    "cme",
    "cmmp",
    "cmmtpl",
    "cmproj",
    "cmrec",
    "cmv",
    "cpi",
    "cpvc",
    "crec",
    "cst",
    "cvc",
    "cx3",
    "d2v",
    "d3v",
    "dad",
    "dash",
    "dat",
    "dav",
    "db2",
    "dce",
    "dck",
    "dcr",
    "dcr",
    "ddat",
    "dif",
    "dir",
    "divx",
    "dlx",
    "dmb",
    "dmsd",
    "dmsd3d",
    "dmsm",
    "dmsm3d",
    "dmss",
    "dmx",
    "dnc",
    "dpa",
    "dpg",
    "dream",
    "dsy",
    "dv",
    "dv-avi",
    "dv4",
    "dvdmedia",
    "dvr",
    "dvr-ms",
    "dvx",
    "dxr",
    "dzm",
    "dzp",
    "dzt",
    "edl",
    "evo",
    "evo",
    "exo",
    "exp",
    "eye",
    "eyetv",
    "ezt",
    "f4f",
    "f4m",
    "f4p",
    "f4v",
    "fbr",
    "fbr",
    "fbz",
    "fcarch",
    "fcp",
    "fcproject",
    "ffd",
    "ffm",
    "flc",
    "flh",
    "fli",
    "flic",
    "flv",
    "flx",
    "fpdx",
    "ftc",
    "fvt",
    "g2m",
    "g64",
    "g64x",
    "gcs",
    "gfp",
    "gifv",
    "gl",
    "gom",
    "grasp",
    "gts",
    "gvi",
    "gvp",
    "gxf",
    "h264",
    "hdmov",
    "hdv",
    "hevc",
    "hkm",
    "ifo",
    "imovielibrary",
    "imoviemobile",
    "imovieproj",
    "imovieproject",
    "inp",
    "int",
    "ircp",
    "irf",
    "ism",
    "ismc",
    "ismclip",
    "ismv",
    "iva",
    "ivf",
    "ivr",
    "ivs",
    "izz",
    "izzy",
    "jdr",
    "jmv",
    "jnr",
    "jss",
    "jts",
    "jtv",
    "k3g",
    "kdenlive",
    "kmv",
    "ktn",
    "lrec",
    "lrv",
    "lsf",
    "lsx",
    "lvix",
    "m15",
    "m1pg",
    "m1v",
    "m21",
    "m21",
    "m2a",
    "m2p",
    "m2t",
    "m2ts",
    "m2v",
    "m4e",
    "m4u",
    "m4v",
    "m75",
    "mani",
    "meta",
    "mgv",
    "mj2",
    "mjp",
    "mjpeg",
    "mjpg",
    "mk3d",
    "mkv",
    "mmv",
    "mnv",
    "mob",
    "mod",
    "modd",
    "moff",
    "moi",
    "moov",
    "mov",
    "movie",
    "movie",
    "mp21",
    "mp21",
    "mp2v",
    "mp4",
    "mp4.infovid",
    "mp4v",
    "mpe",
    "mpeg",
    "mpeg1",
    "mpeg2",
    "mpeg4",
    "mpf",
    "mpg",
    "mpg2",
    "mpg4",
    "mpgindex",
    "mpl",
    "mpl",
    "mpls",
    "mproj",
    "mpsub",
    "mpv",
    "mpv2",
    "mqv",
    "msdvd",
    "mse",
    "msh",
    "mswmm",
    "mt2s",
    "mts",
    "mtv",
    "mvb",
    "mvc",
    "mvd",
    "mve",
    "mvex",
    "mvp",
    "mvp",
    "mvy",
    "mxf",
    "mxv",
    "mys",
    "n3r",
    "ncor",
    "nfv",
    "nsv",
    "ntp",
    "nut",
    "nuv",
    "nvc",
    "ogm",
    "ogv",
    "ogx",
    "orv",
    "osp",
    "otrkey",
    "pac",
    "par",
    "pds",
    "pgi",
    "photoshow",
    "piv",
    "pjs",
    "playlist",
    "plproj",
    "pmf",
    "pmv",
    "pns",
    "ppj",
    "prel",
    "pro",
    "pro4dvd",
    "pro5dvd",
    "proqc",
    "prproj",
    "prtl",
    "psb",
    "psh",
    "pssd",
    "psv",
    "pva",
    "pvr",
    "pxv",
    "pz",
    "qt",
    "qtch",
    "qtindex",
    "qtl",
    "qtm",
    "qtz",
    "r3d",
    "rcd",
    "rcproject",
    "rcrec",
    "rcut",
    "rdb",
    "rec",
    "rm",
    "rmd",
    "rmd",
    "rmp",
    "rms",
    "rmv",
    "rmvb",
    "roq",
    "rp",
    "rsx",
    "rts",
    "rts",
    "rum",
    "rv",
    "rvid",
    "rvl",
    "san",
    "sbk",
    "sbt",
    "sbz",
    "scc",
    "scm",
    "scm",
    "scn",
    "screenflow",
    "sdv",
    "sec",
    "sec",
    "sedprj",
    "seq",
    "ser",
    "sfd",
    "sfera",
    "sfvidcap",
    "siv",
    "smi",
    "smi",
    "smil",
    "smk",
    "sml",
    "smv",
    "snagproj",
    "spl",
    "sqz",
    "srt",
    "ssf",
    "ssm",
    "stl",
    "str",
    "stx",
    "svi",
    "swf",
    "swi",
    "swt",
    "tda3mt",
    "tdt",
    "tdx",
    "theater",
    "thp",
    "tid",
    "tivo",
    "tix",
    "tod",
    "tp",
    "tp0",
    "tpd",
    "tpr",
    "trec",
    "trp",
    "ts",
    "tsp",
    "tsv",
    "ttxt",
    "tvlayer",
    "tvrecording",
    "tvs",
    "tvshow",
    "usf",
    "usm",
    "v264",
    "vbc",
    "vc1",
    "vcpf",
    "vcr",
    "vcv",
    "vdo",
    "vdr",
    "vdx",
    "veg",
    "vem",
    "vep",
    "vf",
    "vft",
    "vfw",
    "vfz",
    "vgz",
    "vid",
    "video",
    "viewlet",
    "viv",
    "vivo",
    "vix",
    "vlab",
    "vmlf",
    "vmlt",
    "vob",
    "vp3",
    "vp6",
    "vp7",
    "vpj",
    "vr",
    "vro",
    "vs4",
    "vse",
    "vsp",
    "vtt",
    "w32",
    "wcp",
    "webm",
    "wfsp",
    "wgi",
    "wlmp",
    "wm",
    "wmd",
    "wmmp",
    "wmv",
    "wmx",
    "wot",
    "wp3",
    "wpl",
    "wsve",
    "wtv",
    "wve",
    "wvm",
    "wvx",
    "wxp",
    "xej",
    "xel",
    "xesc",
    "xfl",
    "xlmv",
    "xml",
    "xmv",
    "xvid",
    "y4m",
    "yog",
    "yuv",
    "zeg",
    "zm1",
    "zm2",
    "zm3",
    "zmv"
];