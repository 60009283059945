import * as $ from 'jquery'; 
export const RemoveClass = () => {
    $('body').removeClass('menu-open');
}
export const Initialize = () => {
    var menuLeft = $('#cbp-spmenu-s1'),
        showLeft = $('#showMenu'),
        menuRight = $('#cbp-spmenu-s2'),
        bodySel = $('body'),
        hideLeft = $('#hideMenu'),
        showRight = $('#showRight');

    $(document.body).on('click', '#showMenu', function () {
        $('#cbp-spmenu-s1').addClass('cbp-spmenu-open');
        $('body').css('overflow', 'hidden');
    });
    //showLeft.on('click', function () {
    //    menuLeft.addClass('cbp-spmenu-open');
    //    $('body').css('overflow', 'hidden');
    //});
    hideLeft.on('click', function () {
        menuLeft.removeClass('cbp-spmenu-open');
        $('body').css('overflow', 'auto');
        $('body').removeClass('menu-open');
    });
    $('#showRight').click(function () {
        $('#cbp-spmenu-s2').addClass('cbp-spmenu-open');
        $('body').css('overflow', 'hidden');
    });
    $('#hideNotifications').click(function () {
        $('#cbp-spmenu-s2').removeClass('cbp-spmenu-open');
        $('body').css('overflow', 'auto');
    });

    //$(document).click(function (event) {
    //    //if you click on anything except the modal itself or the "open modal" link, close the modal
    //    if ($(event.target).closest("aside").length && $('aside').hasClass('cbp-spmenu-open')) {
    //        $("body").find(".cbp-spmenu-open").removeClass("cbp-spmenu-open");
    //        $('body').css('overflow', 'auto');
    //    }
    //});

    $(".sidebar-menu .children li a").click(function () {
        //$(".cbp-spmenu").removeClass('cbp-spmenu-open');
        $('body').css('overflow', 'auto');
    });
    $(".sidebar-menu .parent.no-collaps a").click(function () {
        //$(".cbp-spmenu").removeClass('cbp-spmenu-open');
        $('body').css('overflow', 'auto');
    });

    $('#logout').on("click", function (e) {        
        $('body').removeClass('menu-open');
    });

    $("#showMenu").on("click", function (e) {
        $("#cbp-spmenu-s1").addClass('cbp-spmenu-open');
        e.stopPropagation();
        $('body').addClass('menu-open');
    });
    $(document).on("click", function (e) {

        if ($(e.target).is(".cbp-spmenu-vertical") == true) {
            $(".cbp-spmenu").removeClass('cbp-spmenu-open');
            $('body').css('overflow', 'auto');
        }
    });

    window.onscroll = function () { stickyHeader(); footerSticky() };
    
    var header = document.getElementById("headerSticky");
    var footer = document.getElementById("stickyFooter");
    var stickyHead = header.offsetTop;
    var stickyFoot = footer.offsetTop;


    function stickyHeader() {
        if (window.pageYOffset > stickyHead) {
            header.classList.add("sticky-header");
        } else {
            header.classList.remove("sticky-header");
        }
    }
    function footerSticky() {
        if (window.pageYOffset < stickyFoot) {
            footer.classList.add("sticky-footer");
        } else {
            footer.classList.remove("sticky-footer");
        }

    }
    $(function () {
        $('table [type="checkbox"]').each(function () {
            $(this).click(function () {
                $(this).closest('tr').addClass('active');
            });
        })
    });

    $(document.body).on('click', '.search-btn', function () {
        $(this).parent().addClass("visible_field");
        $(this).addClass('close_search');
    });
    $(document.body).on('click', '.search-btn.close_search', function () {
        $(this).parent().removeClass("visible_field");
        $(this).removeClass('close_search');
    }); 
    //$(document).on('click', '.sb-content .parent > span', function () {
    //    $(this).next("ul").slideToggle();
    //    $(this).toggleClass("open_menu");
    //});

    //$(".sb-content .parent > span").click(function () {
    //    $(this).next("ul").slideToggle();
    //    $(this).toggleClass("open_menu");
    //});

        (function ($, window, document) {

            "use strict";

            var pluginName = "scrollBox",
                defaults = {
                    containerClass: "sb-container",
                    containerNoScrollClass: "sb-container-noscroll",
                    contentClass: "sb-content",
                    scrollbarContainerClass: "sb-scrollbar-container",
                    scrollBarClass: "sb-scrollbar"

                };

            // plugin constructor
            function Plugin(element, options) {
                this.element = element;

                this.settings = $.extend({}, defaults, options);
                this._defaults = defaults;
                this._name = pluginName;
                this.init();

            }

            // Avoid Plugin.prototype conflicts
            $.extend(Plugin.prototype, {
                init: function () {

                    this.addScrollbar();
                    this.addEvents();
                    this.onResize();
                },

                addScrollbar: function () {
                    $(this.element).addClass(this.settings.containerClass);
                    this.wrapper = $("<div class='" + this.settings.contentClass + "' />");
                    this.wrapper.append($(this.element).contents());
                    $(this.element).append(this.wrapper);

                    this.scollbarContainer = $("<div class='" + this.settings.scrollbarContainerClass + "' />");
                    this.scrollBar = $("<div class='" + this.settings.scrollBarClass + "' />");
                    this.scollbarContainer.append(this.scrollBar);
                    $(this.element).prepend(this.scollbarContainer);
                },
                addEvents: function () {

                    this.wrapper.on("scroll." + pluginName, $.proxy(this.onScroll, this))
                    $(window).on("resize." + pluginName, $.proxy(this.onResize, this))

                    this.scrollBar.on('mousedown.' + pluginName, $.proxy(this.onMousedown, this));
                    this.scrollBar.on('touchstart.' + pluginName, $.proxy(this.onTouchstart, this));
                },

                onTouchstart: function (ev) {
                    var me = this;

                    ev.preventDefault();
                    var y = me.scrollBar[0].offsetTop;

                    var onMove = function (end) {
                        var delta = end.touches[0].pageY - ev.touches[0].pageY;
                        me.scrollBar[0].style.top = Math.min(me.scollbarContainer[0].clientHeight - me.scrollBar[0].clientHeight, Math.max(0, y + delta)) + 'px';
                        me.wrapper[0].scrollTop = (me.wrapper[0].scrollHeight * me.scrollBar[0].offsetTop / me.scollbarContainer[0].clientHeight);
                    };

                    $(document).on("touchmove." + pluginName, onMove);
                    $(document).on("touchend." + pluginName, function () {
                        $(document).off("touchmove." + pluginName);
                        $(document).off("touchend." + pluginName);
                    });
                },

                onMousedown: function (ev) {
                    var me = this;

                    ev.preventDefault();
                    var y = me.scrollBar[0].offsetTop;

                    var onMove = function (end) {
                        var delta = end.pageY - ev.pageY;
                        me.scrollBar[0].style.top = Math.min(me.scollbarContainer[0].clientHeight - me.scrollBar[0].clientHeight, Math.max(0, y + delta)) + 'px';
                        me.wrapper[0].scrollTop = (me.wrapper[0].scrollHeight * me.scrollBar[0].offsetTop / me.scollbarContainer[0].clientHeight);
                    };

                    $(document).on("mousemove." + pluginName, onMove);
                    $(document).on("mouseup." + pluginName, function () {
                        $(document).off("mousemove." + pluginName);
                        $(document).off("mouseup." + pluginName);
                    });
                },

                onResize: function () {

                    this.wrapper.css("max-height", $(this.element).height());

                    var wrapper_client_height = this.wrapper[0].clientHeight;

                    this.scrollBar.css("height", this.scollbarContainer[0].clientHeight * wrapper_client_height / this.wrapper[0].scrollHeight + "px");
                    if (this.scollbarContainer[0].clientHeight <= this.scrollBar[0].clientHeight) {
                        $(this.element).addClass(this.settings.containerNoScrollClass);
                    } else {
                        $(this.element).removeClass(this.settings.containerNoScrollClass);
                    }

                    this.onScroll();
                },

                onScroll: function () {

                    this.scrollBar.css("top", Math.min(this.scollbarContainer[0].clientHeight - this.scrollBar[0].clientHeight, this.scollbarContainer[0].clientHeight * this.wrapper[0].scrollTop / this.wrapper[0].scrollHeight) + "px");

                }

            });

            // A really lightweight plugin wrapper around the constructor,
            // preventing against multiple instantiations
            $.fn[pluginName] = function (options) {
                return this.each(function () {
                    if (!$.data(this, "plugin_" + pluginName)) {
                        $.data(this, "plugin_" +
                            pluginName, new Plugin(this, options));
                    }
                });
            };
            
        })($, window, document);

    $(".sidebar-menu").scrollBox();
    //$(".pages-list").scrollBox();
    //$(".addcontent_modal .pages-list").scrollBox();    
}