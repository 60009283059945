import { BEGIN, FAILURE, BASE_URL, RES, SPECIALITY_LIST, CLEAR_SPECIALITY_LIST, BU_SP_RES} from '../constants/constants';
import { fetchBegin, fetchSuccess, fetchFailure } from './actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import axios from 'axios';

export const GetSpecialityList = (model) => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_SPECIALITY_LIST));
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "businessunit/admingetspeciality";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, SPECIALITY_LIST));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));            
        });
}

export const AddSpecialityToBU = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "businessunit/adminbuaddspecialties";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, BU_SP_RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const ShowLoading = () => dispatch => {    
    dispatch(showLoading());
}

export const HideLoading = () => dispatch => {
    dispatch(hideLoading());
}

export const initiateState = () => dispatch => {
    dispatch(fetchBegin(BEGIN));
}

export const ClearSpecialityList = () => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_SPECIALITY_LIST));
}

