import { BASE_URL, errOptions } from '../../constants/constants'
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import InfiniteScroll from "../common/infinitescroll";

import DeleteConfirmModal from '../common/deleteConfirmModal';
import AddUpdateContentModal from './addUpdateContentModal';
import AddToPagesModal from './addToPagesModal'
import ContentActions from './contentActions';
import ChangeShareSettingModal from './changeShareSettingModal';
import CreateGroupModal from './createGroupModal';
import * as  contentActions from '../../actions/contentActions';
import * as menuActions from '../../actions/menuActions';

class Content extends Component {

    constructor() {
        super();
        this.state = {
            image_base_url: BASE_URL.replace("api/v7/", "") + "uploads/pages/",
            content_type: 'all',
            IsChangeShareSettingModalOpen: false,
            IsContentAddUpdateModalOpen: false,
            IsAddToPagesModalOpen: false,
            IsDeleteConfirmModalOpen: false,
            IsCreateGroupModalOpen: false,
            selectedContents: [],
            orderby: 'ASC',
            sortby: 'content_name',
            search: '',
            isSelectAll: false,
            parents: [{ id: 0, name: 'HOME' }],
            activePage: 1,
            isSearch: false,
            items: [],
            isScroll: true,
            totalRecords: null,
            hasMore: true,
            randomError: '',
            isImageUpdated: false,
            isInitiated: false
        }

        this.contentSelection = this.contentSelection.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.clearContentSelection = this.clearContentSelection.bind(this);
        this.addParent = this.addParent.bind(this);
        this.getFolderFiles = this.getFolderFiles.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.removeItems = this.removeItems.bind(this);
        this.updateItem = this.updateItem.bind(this);
        this.enableScroll = this.enableScroll.bind(this);
        this.getUpdatedDetails = this.getUpdatedDetails.bind(this);
    }

    keyPress(e) {
        var randomError = this.generateRandomMesage(this.state.randomError);
        this.setState({ activePage: 1, items: [], isScroll: true, selectedContents: [], totalRecords: null, hasMore: true, randomError: randomError });
        var model = {};
        model.content_type = this.state.content_type;
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        model.search = this.state.search.toLowerCase();
        model.page = 1;
        this.props.dispatch(contentActions.GetContents(model));
    }

    generateRandomMesage(randomError) {
        var str = errOptions[Math.floor(Math.random() * Math.floor(5))];
        if (randomError == str) {
            str = this.generateRandomMesage();
        }
        return str;
    }

    openCreateGroupModal = () => {
        this.setState({ IsCreateGroupModalOpen: true });
    };

    closeCreateGroupModal = () => {
        this.setState({ IsCreateGroupModalOpen: false });
    };

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    openContentAddUpdateModal = () => {
        this.setState({ IsContentAddUpdateModalOpen: true });
    };

    closeContentAddUpdateModal = () => {
        this.setState({ IsContentAddUpdateModalOpen: false });
    };

    openAddToPagesModal = () => {
        this.setState({ IsAddToPagesModalOpen: true });
    };

    closeAddToPagesModal = () => {
        this.setState({ IsAddToPagesModalOpen: false });
    };

    openChangeShareSettingModal = () => {
        this.setState({ IsChangeShareSettingModalOpen: true });
    };

    closeChangeShareSettingModal = () => {
        this.setState({ IsChangeShareSettingModalOpen: false });
    };

    componentDidMount() {
        var localModel = JSON.parse(localStorage.getItem('localModel'));

        if (localModel) {
            this.setState({ parents: localModel.parents, sortby: localModel.sortby, orderby: localModel.orderby });
        } else {
            this.setState({ sortby: "content_name", orderby: "ASC", isInitiated: true });
        }

        var model = {};
        model.content_type = this.state.content_type;
        model.sort_by = "content_name";
        model.order_by = "ASC";
        model.page = this.state.activePage;

        if (localModel && localModel.folder_id && localModel.folder_id != '0') {
            model.folder_id = localModel.folder_id;
        }
        this.props.dispatch(contentActions.GetContents(model));
    }

    getAllContents() {
        if (this.state.isSearch) {
            this.searchButton.click();
        }
        this.state.parents = this.state.parents.slice(0, 1);
        this.setState({ content_type: 'all', search: '', items: [], isScroll: true, selectedContents: [], totalRecords: null, hasMore: true });
        this.state.activePage =  1;
        var model = {};
        model.content_type = "all";
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        model.page = this.state.activePage;
        this.props.dispatch(contentActions.GetContents(model));
        this.props.dispatch(menuActions.ActivatePage('AllContents'));
    }

    getAllDocuments() {
        if (this.state.isSearch) {
            this.searchButton.click();
        }
        this.state.parents = this.state.parents.slice(0, 1);
        this.setState({ content_type: 'files', search: '', items: [], isScroll: true, selectedContents: [], totalRecords: null, hasMore: true });
        this.state.activePage =  1;
        var model = {};
        model.content_type = "files";
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        model.page = this.state.activePage;
        this.props.dispatch(contentActions.GetContents(model));
        this.props.dispatch(menuActions.ActivatePage('Files'));
    }

    getAllMedia() {
        if (this.state.isSearch) {
            this.searchButton.click();
        }
        this.state.parents = this.state.parents.slice(0, 1);
        this.setState({ content_type: 'video', search: '', items: [], isScroll: true, selectedContents: [], totalRecords: null, hasMore: true });
        this.state.activePage =  1;
        var model = {};
        model.content_type = "video";
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        model.page = this.state.activePage;
        this.props.dispatch(contentActions.GetContents(model));
        this.props.dispatch(menuActions.ActivatePage('Video'));
    }

    changeOrder(e) {
        var orderby = "";
        if (this.state.sortby === e.target.dataset.sortby) {
            orderby = this.state.orderby === "DESC" ? "ASC" : "DESC";
        }
        else {
            orderby = "DESC";
        }

        this.setState({ activePage: 1, orderby: orderby, sortby: e.target.dataset.sortby, items: [], isScroll: true, selectedContents: [], totalRecords: null, hasMore: true });

        var model = {};
        model.content_type = this.state.content_type;
        model.sort_by = e.target.dataset.sortby;
        model.order_by = orderby;
        if (this.state.search.toLowerCase() !== "") {
            model.search = this.state.search.toLowerCase();
        }
        if (this.state.parents.length > 0 && this.state.parents[this.state.parents.length - 1].id > 0) {
            model.folder_id = this.state.parents[this.state.parents.length - 1].id;
        }
        model.page = 1;

        var localModel = {};
        localModel.parents = this.state.parents;
        localModel.sortby = model.sort_by;
        localModel.orderby = model.order_by;
        localModel.folder_id = model.folder_id;
        localStorage.setItem('localModel', JSON.stringify(localModel));

        this.props.dispatch(contentActions.GetContents(model));
    }

    contentSelection(e) {
        var id = e.target.id;
        var type = e.target.dataset.type;
        if (e.target.checked) {
            if (this.state.selectedContents.length + 1 === this.state.items.length) {
                this.setState({ isSelectAll: true, selectedContents: [...this.state.selectedContents, { id: id, type: type }] });
            }
            else {
                this.setState({ selectedContents: [...this.state.selectedContents, { id: id, type: type }] });
            }
        }
        else {
            this.setState({
                isSelectAll: false,
                selectedContents: this.state.selectedContents.filter((item) => item.id !== id)
            });
        }
    }

    selectAll(e) {

        if (e.target.checked) {
            this.state.selectedContents = this.state.items.map((item) => {
                return { id: item.id, type: item.content_type };
            });
            this.state.isSelectAll = true;
        }
        else {
            this.state.selectedContents = [];
            this.state.isSelectAll = false;
        }
        this.setState(this.state);
    }

    handleSearchChange(e) {
        if (e.target.name === "search") {
            this.setState({ search: e.target.value });
        }
    }

    search() {
        this.state.isSearch = !this.state.isSearch;
        if (this.state.isSearch) {
            this.searchInput.focus();
        }
        else {
            this.setState({ activePage: 1, search: '', items: [], isScroll: true, selectedContents: [], totalRecords: null, hasMore: true });
            var model = {};
            model.content_type = this.state.content_type;
            model.sort_by = this.state.sortby;
            model.order_by = this.state.orderby;
            model.search = '';
            model.page = 1;
            this.props.dispatch(contentActions.GetContents(model));
        }
    }

    clearContentSelection() {
        this.state.isSelectAll = false;
        this.state.selectedContents = [];
        this.setState(this.state);
    }

    addParent(e) {
        var localModel = {};
        var model = {};
        model.id = e.target.dataset.id;
        model.name = e.target.dataset.name;


        switch (this.state.content_type) {
            case 'all':
                this.state.parents[0].name = 'All Competitive Intel';
                break;
            case 'files':
                this.state.parents[0].name = 'FILES & DOCUMENTS';
                break;
            case 'video':
                this.state.parents[0].name = 'VIDEOS';
                break;
        }

        localModel.parents = [...this.state.parents, model];

        this.setState({ parents: [...this.state.parents, model], activePage: 1, search: '', items: [], isScroll: true, selectedContents: [], totalRecords: null, hasMore: true });
        var model = {};
        model.content_type = this.state.content_type;
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        model.folder_id = e.target.dataset.id;
        model.page = 1;

        localModel.folder_id = e.target.dataset.id;
        localModel.sortby = this.state.sortby;
        localModel.orderby = this.state.orderby;

        localStorage.setItem('localModel', JSON.stringify(localModel));
        this.props.dispatch(contentActions.GetContents(model));

    }

    getFolderFiles(e) {

        this.state.parents = this.state.parents.slice(0, this.state.parents.findIndex(x => x.id == e.target.dataset.id) + 1);
        this.state.search = "";
        this.state.items = [];
        this.state.isScroll = true;
        this.state.totalRecords = null;
        this.state.hasMore = true;
        this.setState(this.state);

        var model = {};

        model.content_type = this.state.content_type;
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        if (e.target.dataset.id > 0) {
            model.folder_id = e.target.dataset.id;
        }
        model.page = 1;
        localStorage.setItem('folder_id', e.target.dataset.id);

        var localModel = {};
        localModel.parents = this.state.parents;
        localModel.folder_id = e.target.dataset.id;
        localModel.sortby = this.state.sortby;
        localModel.orderby = this.state.orderby;
        localStorage.setItem('localModel', JSON.stringify(localModel));

        this.props.dispatch(contentActions.GetContents(model));

    }

    handlePageChange(pageNumber) {

        this.setState({ activePage: pageNumber, selectedContents: [], isSelectAll: false });
        var model = {};

        model.content_type = this.state.content_type;
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        if (this.state.search.toLowerCase() !== "") {
            model.search = this.state.search.toLowerCase();
        }
        if (this.state.parents.length > 0 && this.state.parents[this.state.parents.length - 1].id > 0) {
            model.folder_id = this.state.parents[this.state.parents.length - 1].id;
        }
        model.page = pageNumber;
        this.props.dispatch(contentActions.GetContents(model));
        window.scrollTo(0, 0);
    }

    fetchMoreData() {
        this.state.activePage += 1;
        this.state.isScroll = true;

        var model = {};
        model.content_type = this.state.content_type;
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        if (this.state.search.toLowerCase() !== "") {
            model.search = this.state.search.toLowerCase();
        }
        if (this.state.parents.length > 0 && this.state.parents[this.state.parents.length - 1].id > 0) {
            model.folder_id = this.state.parents[this.state.parents.length - 1].id;
        }
        model.page = this.state.activePage;
        this.props.dispatch(contentActions.GetContents(model));
    }

    removeItems(items) {
        if (items[0].id) {
            items.forEach(item => {
                var index = this.state.items.findIndex(x => x.id == item.id);
                this.state.items.splice(index, 1);
            });
        } else {
            items.forEach(id => {
                var index = this.state.items.findIndex(x => x.id == id);
                this.state.items.splice(index, 1);
            });
        }

        this.state.totalRecords--;
    }

    updateItem(items, action) {
        var a = this.state;
        if (action === 'change_share_settings') {
            let that = this;
            items.content_id.forEach(id => {
                var index = that.state.items.findIndex(x => x.id == id);
                if (that.state && that.state.items[index] && that.state.items[index].is_sharing) {
                    that.state.items[index].is_sharing = items.status;
                }
            });
        }

    }

    enableScroll() {
        this.setState({ items: [], isScroll: true, selectedContents: [], totalRecords: null, hasMore: true });
    }

    getUpdatedDetails() {
        this.setState({ isImageUpdated: true });
    }

    render() {

        if (this.props.location.state !== undefined) {
            switch (this.props.location.state.content_type) {
                case "all":
                    this.getAllContents();
                    break;
                case "files":
                    this.getAllDocuments();
                    break;
                case "video":
                    this.getAllMedia();
                    break;
            }
            this.props.location.state = undefined;
        }

        const { activePage, parents, isSelectAll, image_base_url, content_type, sortby, orderby, selectedContents, IsChangeShareSettingModalOpen, IsContentAddUpdateModalOpen, IsDeleteConfirmModalOpen, IsAddToPagesModalOpen, IsCreateGroupModalOpen, search, isSearch,
            isScroll, items, totalRecords, hasMore, randomError, isImageUpdated, isInitiated } = this.state;
        const { loading, error, contentlist } = this.props;

        let message = "";
        if (selectedContents.length > 1) {
            message = 'Are you sure you want to delete ' + selectedContents.length + ' files?'
        }
        else {
            message = 'Are you sure you want to delete ' + selectedContents.length + ' file?'
        }

        const bytesToSize = (bytes) => {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes === 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
        }

        if (contentlist !== null && contentlist.records !== undefined) {

            contentlist.records.map(x => {
                x.computedSize = bytesToSize(parseInt(x.file_size));
            });

            contentlist.records.map(x => {
                if (x.content_type !== 'folder' && x.file_name !== null) {
                    x.extension = x.file_name.slice(x.file_name.lastIndexOf('.')).replace('.', '')
                }
            });
        }

        if (!loading && isScroll && contentlist != null && contentlist.records && contentlist.records.length > 0 && this.state.items.length < contentlist.total_records) {
            if (!items.find(x => x.id == contentlist.records[0].id)) {
                var hasMoreRef = ((items.length + contentlist.records.length) == contentlist.total_records) ? false : true;

                var selectedContentsList = [];
                if (isSelectAll) {

                    selectedContentsList = contentlist.records.map((item) => {
                        return { id: item.id, type: item.content_type };
                    });
                }

                this.setState({
                    isScroll: false,
                    totalRecords: contentlist.total_records,
                    selectedContents: selectedContents.concat(selectedContentsList),
                    items: items.concat(contentlist.records),
                    hasMore: hasMoreRef
                });
            }
        } else if (!isScroll && contentlist != null && contentlist.records && contentlist.records.length > 0 && isImageUpdated) {
            this.setState({ items: contentlist.records, isImageUpdated: false });
        } else if (!loading && isInitiated && contentlist != null && contentlist.records && contentlist.records.length > 0) {
            this.setState({ items: contentlist.records, isInitiated: false });
        }

        const loadingClass = { 'textAlign': 'center', 'fontSize': '30px' };
        const header = { 'display': 'flex', 'height': '90px' }

        const wordBreak = {
            wordBreak: 'break-all',
            whiteSpace: 'pre-line'
        };

        return (

            <div>


                <div className="container">

                    <div className="main-title">
                        <h1>Competitive Intel</h1>
                    </div>

                    <div className="content-bredcrum-search">
                        {parents.length === 1 && <div className="tab-parent">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className={this.state.content_type === 'all' ? 'active' : ''}><a href="javascript:void(0);" onClick={this.getAllContents.bind(this)}>All Competitive Intel</a></li>
                                <li role="presentation" className={this.state.content_type === 'files' ? 'active' : ''}><a href="javascript:void(0);" onClick={this.getAllDocuments.bind(this)}>FILES & DOCUMENTS</a></li>
                                <li role="presentation" className={this.state.content_type === 'video' ? 'active' : ''}><a href="javascript:void(0);" onClick={this.getAllMedia.bind(this)}>VIDEOS</a></li>
                            </ul>
                        </div>}

                        {parents.length > 1 && <div className="bredcrum_block">

                            <div className="bredcrum">
                                <ul className="clearfix">
                                    {parents.map((item, index) => (
                                        <li key={index} className={(index === parents.length - 1) ? 'active' : ''}>
                                            {(index === parents.length - 1) && <a href="javascript:void(0);">{item.name}</a>}
                                            {(index !== parents.length - 1) && <a href="javascript:void(0);" data-id={item.id} onClick={this.getFolderFiles}>{item.name}</a>}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>}

                        <div className="tab_search_box">
                            <div className="search-form clearfix">
                                <input ref={(searchInput) => { this.searchInput = searchInput; }}
                                    type="search" name="search" value={search} className="form-control"
                                    onKeyUp={this.keyPress} onChange={this.handleSearchChange} />
                                <button ref={(searchButton) => { this.searchButton = searchButton; }}
                                    type="submit" className="search-btn" onClick={this.search}>
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="home">
                            <div className="action-row content-action-row">
                                <button className="btn btn-rounded btn-primary" onClick={this.openContentAddUpdateModal}>
                                    <i className="la la-plus"></i>
                                </button>
                                <div className="action_btn_group">
                                    <button className="btn btn-default" onClick={this.openAddToPagesModal} disabled={selectedContents.length === 0}>
                                        <i className="la la-external-link"></i>ADD TO PAGES
                                </button>

                                    <button className="btn btn-default" onClick={this.openCreateGroupModal} disabled={selectedContents.length === 0}>
                                        <i className="la la-level-up"></i>CREATE GROUP
                                </button>
                                    <button className="btn btn-default" onClick={this.openChangeShareSettingModal} disabled={selectedContents.length === 0}>
                                        <i className="la la-pencil"></i>BULK EDIT
                                </button>

                                    <button className="btn btn-default btn-delete" onClick={this.openDeleteConfirmModal} disabled={selectedContents.length === 0}>
                                        <i className="la la-trash-o"></i>DELETE FILES
                                </button>

                                </div>

                            </div>
                            {items.length > 0 &&
                                <div className="table-container content-table table-responsive">
                                    <table className="table hover-row" id="userTable">
                                        <thead>
                                            <tr>
                                                <th className="custom-checkbox">
                                                    <input type="checkbox" id="chk1" onChange={this.selectAll} checked={isSelectAll} />
                                                    <label htmlFor="chk1"></label>
                                                </th>
                                                <th className="content-icon"></th>
                                                <th>
                                                    <span className={(sortby === 'content_name' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'content_name' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="content_name" onClick={this.changeOrder.bind(this)}>
                                                        Name
                                                </span>
                                                </th>
                                                <th>
                                                    <span className={(sortby === 'updated_date' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'updated_date' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="updated_date" onClick={this.changeOrder.bind(this)} style={{ whiteSpace: 'nowrap' }}>
                                                        Last Modified
                                                </span>
                                                </th>
                                                <th>
                                                    <span className={(sortby === 'file_size' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'file_size' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="file_size" onClick={this.changeOrder.bind(this)}>
                                                        Size
                                                </span>
                                                </th>
                                                <th>
                                                    <span className={(sortby === 'pages' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'pages' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="pages" onClick={this.changeOrder.bind(this)}>
                                                        Pages
                                                </span>
                                                </th>
                                                <th>
                                                    <span className={(sortby === 'is_sharing' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'is_sharing' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="is_sharing" onClick={this.changeOrder.bind(this)} style={{ whiteSpace: 'nowrap' }}>
                                                        Sharing
                                                </span>
                                                </th>
                                                <th className="actions"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {items.length > 0 && <InfiniteScroll
                                            target="table"
                                            dataLength={items.length}
                                            scrollThreshold="10px"
                                            next={this.fetchMoreData}
                                            hasMore={hasMore}
                                            loader={<tr><td colSpan="8" className="bottom-0" style={loadingClass}>Loading...</td></tr>} > */}

                                            {items.map((item, i) => (
                                                <tr key={item.id} className={(selectedContents.indexOf(item.id) > -1) ? 'active' : ''}>
                                                    <td className="custom-checkbox">
                                                        <input type="checkbox" id={item.id} data-type={item.content_type} onChange={this.contentSelection} checked={selectedContents.filter(c => c.id == item.id).length > 0} />
                                                        <label htmlFor={item.id}></label>
                                                    </td>
                                                    <td className="content-icon">
                                                        {item.content_type === 'folder' && item.is_child === true && <img src="../images/content-file.png" style={{ cursor: 'pointer' }} className="img-responsive" data-id={item.id} data-name={item.content_name} onClick={this.addParent} />}
                                                        {item.content_type === 'folder' && item.is_child === false && <img src="../images/content-file-o.png" style={{ cursor: 'pointer' }} className="img-responsive" data-id={item.id} data-name={item.content_name} onClick={this.addParent} />}
                                                        {item.content_type !== 'folder' && <Link to={{ pathname: this.props.match.url + '/details?content_id=' + item.id, state: { item: item, type: content_type, orderby: orderby, sortby: sortby } }} target="_self">
                                                            {/* file-icon */}
                                                            {item.content_type === 'files' && item.file_name === '' && <img src="../images/no-image.png" className="img-responsive" />}
                                                            {item.content_type === 'files' && item.file_name !== '' && (item.extension === 'png' || item.extension === 'jpg' || item.extension === 'jpeg' || item.extension === 'pjpeg') && <img src={item.file_path} className="img-responsive" />}
                                                            {item.content_type === 'files' && item.file_name !== '' && (item.extension !== 'png' && item.extension !== 'jpg' && item.extension !== 'jpeg' && item.extension !== 'pjpeg') && <img src="../images/file-icon-48X48.png" className="img-responsive" />}
                                                            {item.content_type === 'videos' && <img src="../images/content-video.png" className="img-responsive" />}
                                                            {/* {item.content_type === 'files' && item.extension === 'mp3' && <img src="../images/content-video.png" className="img-responsive" />} */}
                                                            {/*{item.content_type === 'files' && item.extension === 'xlsx' && <img src="../images/excel.png" className="img-responsive" />}
                                                            {item.content_type === 'files' && item.extension === 'docx' && <img src="../images/word.png" className="img-responsive" />}
                                                            {item.content_type === 'files' && item.extension === 'pdf' && <img src="../images/pdf.png" className="img-responsive" />}
                                                            {item.path !== null && item.content_type === 'files' && (item.extension !== 'mp4' && item.extension !== 'mp3' && item.extension !== 'xlsx' && item.extension !== 'docx' && item.extension !== 'pdf') && <img src={image_base_url + parents.map(x => x.name).join('/').replace('HOME/', '') + '/' + item.file_name} className="img-responsive" onError={(e) => { e.target.onerror = null; e.target.src = "../images/no-image.png" }} />}
                                                            {item.path === null && item.content_type === 'files' && (item.extension !== 'mp4' && item.extension !== 'mp3' && item.extension !== 'xlsx' && item.extension !== 'docx' && item.extension !== 'pdf') && <img src={item.file_path} className="img-responsive" onError={(e) => { e.target.onerror = null; e.target.src = "../images/no-image.png" }} />}
                                                            {item.path === null && item.content_type === 'files' && item.file_name === '' && (item.extension !== 'mp4' && item.extension !== 'mp3' && item.extension !== 'xlsx' && item.extension !== 'docx' && item.extension !== 'pdf') && <img src="../images/no-image.png" className="img-responsive" />} */}
                                                        </Link>}
                                                    </td>
                                                    <td>
                                                        {item.content_type === 'folder' && item.is_child === true && <div data-id={item.id} data-name={item.content_name} onClick={this.addParent}><span data-id={item.id} data-name={item.content_name} style={{ maxWidth: '24vw', width: '24vw', cursor: 'pointer' }} className="align-middle truncate" > {item.content_name} </span></div>}
                                                        {item.content_type === 'folder' && item.is_child === false && <div><span style={{ maxWidth: '24vw', width: '24vw', cursor: 'pointer' }} className="align-middle truncate" > {item.content_name} </span></div>}
                                                        {item.content_type !== 'folder' && <Link to={{ pathname: this.props.match.url + '/details?content_id=' + item.id, state: { item: item, type: content_type, orderby: orderby, sortby: sortby } }} target="_self">
                                                            <span style={{ maxWidth: '24vw', width: '24vw' }} className="align-middle truncate"> {item.content_name} </span>
                                                        </Link>}

                                                    </td>
                                                    <td>
                                                        {item.content_type === 'folder' && item.is_child === true && <div data-id={item.id} data-name={item.content_name} onClick={this.addParent}><span className="text-gray" data-id={item.id} data-name={item.content_name}>{item.updated_date}</span></div>}
                                                        {item.content_type === 'folder' && item.is_child === false && <span className="text-gray">{item.updated_date}</span>}
                                                        {item.content_type !== 'folder' && <Link to={{ pathname: this.props.match.url + '/details?content_id=' + item.id, state: { item: item, type: content_type, orderby: orderby, sortby: sortby } }} target="_self">
                                                            <span className="text-gray">{item.updated_date}</span>
                                                        </Link>}

                                                    </td>
                                                    <td>
                                                        {item.content_type === 'folder' && item.is_child === true && <div data-id={item.id} data-name={item.content_name} onClick={this.addParent}><span className="text-gray" data-id={item.id} data-name={item.content_name}>{item.computedSize}</span></div>}
                                                        {item.content_type === 'folder' && item.is_child === false && <span className="text-gray">{item.computedSize}</span>}
                                                        {item.content_type !== 'folder' && <Link to={{ pathname: this.props.match.url + '/details?content_id=' + item.id, state: { item: item, type: content_type, orderby: orderby, sortby: sortby } }} target="_self">
                                                            <span className="text-gray">{item.computedSize}</span>
                                                        </Link>}

                                                    </td>
                                                    <td>
                                                        {item.content_type === 'folder' && item.is_child === true && <div data-id={item.id} data-name={item.content_name} onClick={this.addParent}><span className="text-gray" data-id={item.id} data-name={item.content_name}>{item.pages}</span></div>}
                                                        {item.content_type === 'folder' && item.is_child === false && <span className="text-gray">{item.pages}</span>}
                                                        {item.content_type !== 'folder' && <Link to={{ pathname: this.props.match.url + '/details?content_id=' + item.id, state: { item: item, type: content_type, orderby: orderby, sortby: sortby } }} target="_self">
                                                            <span className="text-gray">{item.pages}</span>
                                                        </Link>}

                                                    </td>
                                                    <td>
                                                        {item.content_type === 'folder' && item.is_child === true && item.is_sharing === "1" && <div data-id={item.id} data-name={item.content_name} onClick={this.addParent}><span className="text-success" data-id={item.id} data-name={item.content_name}>On</span></div>}
                                                        {item.content_type === 'folder' && item.is_child === true && item.is_sharing === "0" && <div data-id={item.id} data-name={item.content_name} onClick={this.addParent}><span className="text-danger" data-id={item.id} data-name={item.content_name}>Off</span></div>}
                                                        {item.content_type === 'folder' && item.is_child === false && item.is_sharing === "1" && <span className="text-success">On</span>}
                                                        {item.content_type === 'folder' && item.is_child === false && item.is_sharing === "0" && <span className="text-danger">Off</span>}
                                                        {item.content_type !== 'folder' && <Link to={{ pathname: this.props.match.url + '/details?content_id=' + item.id, state: { item: item, type: content_type, orderby: orderby, sortby: sortby } }} target="_self">
                                                            {item.is_sharing === "1" && <span className="text-success">On</span>}
                                                            {item.is_sharing === "0" && <span className="text-danger">Off</span>}
                                                        </Link>}
                                                    </td>
                                                    <td className="actions">
                                                        <ContentActions Id={item.id} action="content-delete"
                                                            content_name={item.content_name} content_type={item.content_type}
                                                            type={content_type} search={search} page={activePage}
                                                            orderby={orderby}
                                                            sortby={sortby}
                                                            filesCount={item.files_count}
                                                            removeItems={this.removeItems}
                                                            enableScroll={this.enableScroll}
                                                            getUpdatedDetails={this.getUpdatedDetails}
                                                            folder_id={(parents.length > 0 && parents[parents.length - 1].id > 0) ? parents[this.state.parents.length - 1].id : 0}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                            {/* </InfiniteScroll>} */}
                                        </tbody>
                                    </table>
                                </div>}
                                
                            {(items.length !== this.state.totalRecords) && items.length > 0 &&
                                <button className="btn btn-primary loadmore-btn" onClick={this.fetchMoreData}>
                                    Load More <i className="la la-angle-right"></i>
                                </button>
                            }
                            
                            {contentlist !== null && contentlist.records !== undefined && contentlist.records.length === 0 && <div className="text-center">
                                {isSearch && <label className="lblNoRecord">{randomError}</label>}
                                {!isSearch && <label className="lblNoRecord">This Folder Is Empty</label>}
                            </div>}
                        </div>
                    </div>
                </div>

                <Modal open={IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal}
                        title="Delete Files"
                        message={message}
                        selectedContents={selectedContents}
                        action="multiple-content-delete"
                        clearContentSelection={this.clearContentSelection}
                        type={content_type} search={search} page={activePage}
                        removeItems={this.removeItems}
                        folder_id={(parents.length > 0 && parents[parents.length - 1].id > 0) ? parents[this.state.parents.length - 1].id : 0}
                        orderby={orderby}
                        sortby={sortby} />
                </Modal>

                <Modal open={IsContentAddUpdateModalOpen} center showCloseIcon={false} onClose={this.closeContentAddUpdateModal}>
                    <AddUpdateContentModal closeContentAddUpdateModal={this.closeContentAddUpdateModal} action="add"
                        type={content_type} search={search} page={activePage}
                        enableScroll={this.enableScroll}
                        folder_id={(parents.length > 0 && parseInt(parents[parents.length - 1].id) > 0) ? parseInt(parents[this.state.parents.length - 1].id) : 0}
                        orderby={orderby}
                        sortby={sortby} />
                </Modal>

                <Modal open={IsAddToPagesModalOpen} center showCloseIcon={false} onClose={this.closeAddToPagesModal}>
                    <AddToPagesModal closeAddToPagesModal={this.closeAddToPagesModal} selectedContents={selectedContents} type={content_type}
                        orderby={orderby} search={search} page={activePage}
                        sortby={sortby}
                        folder_id={(parents.length > 0 && parents[parents.length - 1].id > 0) ? parents[this.state.parents.length - 1].id : 0}
                        clearContentSelection={this.clearContentSelection} />
                </Modal>

                <Modal open={IsChangeShareSettingModalOpen} center showCloseIcon={false} onClose={this.closeChangeShareSettingModal}>
                    <ChangeShareSettingModal closeChangeShareSettingModal={this.closeChangeShareSettingModal}
                        selectedContents={selectedContents}
                        type={content_type} search={search} page={activePage}
                        orderby={orderby}
                        sortby={sortby}
                        updateItem={this.updateItem}
                        folder_id={(parents.length > 0 && parents[parents.length - 1].id > 0) ? parents[this.state.parents.length - 1].id : 0}
                        clearContentSelection={this.clearContentSelection} />
                </Modal>

                <Modal open={IsCreateGroupModalOpen} center showCloseIcon={false} onClose={this.closeCreateGroupModal}>
                    <CreateGroupModal closeChangeShareSettingModal={this.closeCreateGroupModal}
                        selectedContents={selectedContents}
                        type={content_type} search={search} page={activePage}
                        orderby={orderby}
                        sortby={sortby}
                        enableScroll={this.enableScroll}
                        folder_id={(parents.length > 0 && parents[parents.length - 1].id > 0) ? parents[this.state.parents.length - 1].id : 0}
                        clearContentSelection={this.clearContentSelection} />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.contentReducer.loading,
    error: state.contentReducer.error,
    contentlist: state.contentReducer.contentlist
});

export default connect(mapStateToProps)(Content);