import { BASE_URL, errOptions } from '../../constants/constants';
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import PageActions from './pageActions';
import Modal from 'react-responsive-modal';
import DeleteConfirmModal from '../common/deleteConfirmModal';
import ChangePageVisibilityModal from './changePageVisibilityModal';
import * as pagesActions from '../../actions/pagesActions';
import * as menuActions from '../../actions/menuActions';
import InfiniteScroll from "../common/infinitescroll";

class Pages extends Component {

    constructor() {
        super();
        this.state = {
            image_base_url: BASE_URL.replace("api/v7/", "") + "uploads/pages/",
            IsDeleteConfirmModalOpen: false,
            IsChnageVisibilityModalOpen: false,
            sortby: 'name',
            orderby: 'ASC',
            selectedPages: [],
            search: '',
            isSelectAll: false,
            activePage: 1,
            isSearch: false,
            items: [],
            isScroll: true,
            totalRecords: null,
            hasMore: true,
            randomError: ''
        }

        this.pageSelection = this.pageSelection.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.clearPageSelection = this.clearPageSelection.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.removeItems = this.removeItems.bind(this);
        this.updateVisibility = this.updateVisibility.bind(this);
    }

    keyPress(e) {
        var randomError = this.generateRandomMesage(this.state.randomError);
        this.setState({ activePage: 1, items: [], isScroll: true, selectedPages: [], totalRecords: null, hasMore: true, randomError: randomError });
        var model = {};
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        model.search = this.state.search.toLowerCase();
        model.page = 1;
        this.props.dispatch(pagesActions.GetPages(model));
    }

    generateRandomMesage(randomError) {
        var str = errOptions[Math.floor(Math.random() * Math.floor(5))];
        if (randomError == str) {
            str = this.generateRandomMesage();
        }
        return str;
    }

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    openChangeVisibilityModal = () => {
        this.setState({ IsChnageVisibilityModalOpen: true });
    };

    closeChangeVisibilityModal = () => {
        this.setState({ IsChnageVisibilityModalOpen: false });
    };

    clearPageSelection() {
        this.state.isSelectAll = false;
        this.state.selectedPages = [];
        this.setState(this.state);
    }

    componentWillReceiveProps() {
        this.props.dispatch(pagesActions.ClearPageList());
    }

    componentDidMount() {
        var model = {};
        model.sort_by = "name";
        model.order_by = "ASC";
        model.page = this.state.activePage;
        this.props.dispatch(pagesActions.GetPages(model));
        this.props.dispatch(menuActions.ActivatePage("Pages"));
    }

    pageSelection(e) {
        var id = e.target.id;
        if (e.target.checked) {
            if (this.state.selectedPages.length + 1 === this.state.items.length) {
                this.setState({ isSelectAll: true, selectedPages: [...this.state.selectedPages, id] });
            } else {
                this.setState({ selectedPages: [...this.state.selectedPages, id] });
            }
        }
        else {
            this.setState({
                isSelectAll: false,
                selectedPages: this.state.selectedPages.filter((eid) => eid !== id)
            });
        }
    }

    selectAll(e) {
        if (e.target.checked) {
            this.state.selectedPages = this.state.items.map((item) => {
                return item.id;
            });
            this.state.isSelectAll = true;
        }
        else {
            this.state.isSelectAll = false;
            this.state.selectedPages = [];
        }
        this.setState(this.state);
    }

    changeOrder(e) {

        var orderby = "";
        if (this.state.sortby === e.target.dataset.sortby) {
            orderby = this.state.orderby === "DESC" ? "ASC" : "DESC";
        }
        else {
            orderby = "DESC";
        }

        this.setState({ activePage: 1, orderby: orderby, sortby: e.target.dataset.sortby, items: [], isScroll: true, selectedPages: [], totalRecords: null, hasMore: true });

        var model = {};
        model.sort_by = e.target.dataset.sortby;
        model.order_by = orderby.toLowerCase();
        model.page = 1;

        if (this.state.search) {
            model.search = this.state.search;
        }

        this.props.dispatch(pagesActions.GetPages(model));
    }

    handleSearchChange(e) {
        if (e.target.name === "search") {
            this.setState({ search: e.target.value });
        }
    }

    search() {
        this.state.isSearch = !this.state.isSearch;
        if (this.state.isSearch) {
            this.searchInput.focus();
        }
        else {
            this.setState({ activePage: 1, search: '', items: [], isScroll: true, selectedPages: [], totalRecords: null, hasMore: true });
            var model = {};
            model.sort_by = this.state.sortby;
            model.order_by = this.state.orderby;
            model.search = '';
            model.page = 1;
            this.props.dispatch(pagesActions.GetPages(model));
        }
    }

    handlePageChange(pageNumber) {

        this.setState({ activePage: pageNumber, selectedPages: [], isSelectAll: false });
        var model = {};
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        model.search = this.state.search.toLowerCase();
        model.page = pageNumber;
        this.props.dispatch(pagesActions.GetPages(model));
        window.scrollTo(0, 0);
    }

    fetchMoreData() {
        this.state.activePage += 1;
        this.state.isScroll = true;
        var model = {};
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby.toLowerCase();
        if (this.state.search !== "") {
            model.search = this.state.search;
        }
        model.page = this.state.activePage;
        this.props.dispatch(pagesActions.GetPages(model));
    }

    removeItems(Ids) {
        Ids.forEach(id => {
            var index = this.state.items.findIndex(x => x.id == id);
            this.state.items.splice(index, 1);
        });
        this.state.totalRecords--;
    }

    updateVisibility(item) {
        item.id.forEach(i => {
            var index = this.state.items.findIndex(x => x.id == i);
            this.state.items[index].status = item.status;
        });
    }

    render() {

        const { activePage, isSelectAll, image_base_url, sortby, orderby, selectedPages, IsChnageVisibilityModalOpen, IsDeleteConfirmModalOpen, search, isSearch,
            isScroll, items, hasMore, randomError } = this.state;

        const { loading, pagelist } = this.props;

        if (pagelist !== null && pagelist.records !== undefined && pagelist.records.length > 0) {
            pagelist.records.map(item => {
                if (item.description.length > 200) {
                    item.description_display = item.description.slice(0, 100).concat(" ...");
                }
            });
        }

        if (!loading && isScroll && pagelist != null && pagelist.records && pagelist.records.length > 0 && this.state.items.length < pagelist.total_records) {

            if (!items.find(x => x.id == pagelist.records[0].id)) {

                var hasMoreRef = ((items.length + pagelist.records.length) == pagelist.total_records) ? false : true;
                var selectedPageList = [];
                if (isSelectAll) {
                    selectedPageList = pagelist.records.map((item) => {
                        return item.id;
                    });
                }
                this.setState({
                    isScroll: false,
                    totalRecords: pagelist.total_records,
                    selectedPages: selectedPages.concat(selectedPageList),
                    items: items.concat(pagelist.records),
                    hasMore: hasMoreRef
                });
            }
        }

        const loadingClass = { 'textAlign': 'center', 'fontSize': '30px' };
        const header = { 'display': 'flex', 'height': '90px' }
        const wordBreak = {
            wordBreak: 'break-all',
            whiteSpace: 'pre-line'
        };

        return (
            <div className="container">
                {/* <div style={header}>
                    <h1 className="page-title">Pages</h1>
                </div> */}

                <div className="main-title title-with-search">
                    <h1>Pages</h1>
                    <div className="tab_search_box">
                        <div className="search-form">
                            <input ref={(searchInput) => { this.searchInput = searchInput; }}
                                type="search" name="search" value={search} className="form-control"
                                onKeyUp={this.keyPress} onChange={this.handleSearchChange} />
                            <button type="submit" className="search-btn" onClick={this.search}>
                                <i className="fa fa-search" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="tab-parent">
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active">
                            <div className="action-row content-action-row">
                                <Link to={{ pathname: this.props.match.url + '/add', state: { action: 'add' } }} className="btn btn-rounded btn-primary">
                                    <i className="la la-plus"></i>
                                </Link>

                                <div className="action_btn_group">
                                    <button className="btn btn-default" onClick={this.openChangeVisibilityModal} disabled={selectedPages.length === 0}>
                                        <i className="la la-eye"></i> CHANGE VISIBILITY
                                    </button>
                                    <button className="btn btn-default btn-delete" onClick={this.openDeleteConfirmModal} disabled={selectedPages.length === 0}>
                                        <i className="la la-trash-o"></i> DELETE PAGES
                                    </button>
                                </div>

                            </div>

                            {items.length > 0 &&
                                <div className="table-container user-table pages-table table-responsive">
                                    <table className="table hover-row" id="userTable">
                                        <thead>
                                            <tr>
                                                <th className="custom-checkbox">
                                                    <input type="checkbox" id="chk1" onChange={this.selectAll} checked={isSelectAll} />
                                                    <label htmlFor="chk1"></label>
                                                </th>
                                                <th className="profile-pic"></th>
                                                <th>
                                                    <span className={(sortby === 'name' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'name' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="name" onClick={this.changeOrder.bind(this)}>
                                                        Name
                                                </span>
                                                </th>
                                                <th>
                                                    <span className={(sortby === 'updated_date' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'updated_date' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="updated_date" onClick={this.changeOrder.bind(this)}>
                                                        Last Modified
                                                </span>
                                                </th>
                                                <th>
                                                    <span className={(sortby === 'products' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'products' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="products" onClick={this.changeOrder.bind(this)}>
                                                        Products
                                                </span>
                                                </th>
                                                <th>
                                                    <span className={(sortby === 'status' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'status' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="status" onClick={this.changeOrder.bind(this)} style={{ whiteSpace: 'nowrap' }}>
                                                        Visibility
                                                </span>
                                                </th>
                                                <th className="actions"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {items.length > 0 && <InfiniteScroll
                                        target="table"
                                        dataLength={items.length}
                                        scrollThreshold="10px"
                                        next={this.fetchMoreData}
                                        hasMore={hasMore}
                                        loader={<tr><td colSpan="8" className="bottom-0" style={loadingClass}>Loading...</td></tr>} > */}

                                            {items.map((item, i) => (
                                                <tr key={item.id} className={(selectedPages.indexOf(item.id) > -1) ? 'active' : ''}>
                                                    <td className="custom-checkbox">
                                                        <input type="checkbox" id={item.id} onChange={this.pageSelection} checked={(selectedPages.indexOf(item.id) > -1)} />
                                                        <label htmlFor={item.id}></label>
                                                    </td>
                                                    <td className="content-icon">
                                                        <Link to={{ pathname: this.props.match.url + '/details', state: { item: item } }}>
                                                            {item.page_logo !== '' && <img height="49" src={image_base_url + item.page_logo} className="" data-id={item.id} onError={(e) => { e.target.onerror = null; e.target.src = "images/no-image.png" }} />}
                                                            {item.page_logo === '' && <img height="49" src="images/camera.png" className="img-responsive" />}
                                                        </Link>
                                                    </td>
                                                    <td style={{ maxWidth: '24vw', width: '24vw', cursor: 'pointer' }}>
                                                        <Link to={{ pathname: this.props.match.url + '/details', state: { item: item } }}>
                                                            <span className="align-middle truncate">{item.name}</span>
                                                            {item.description_display === undefined && <p className="truncate">{item.description}</p>}
                                                            {item.description_display !== undefined && <p className="truncate">{item.description_display}</p>}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to={{ pathname: this.props.match.url + '/details', state: { item: item } }}>
                                                            {item.updated_date}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to={{ pathname: this.props.match.url + '/details', state: { item: item } }} className="pages-product-column">
                                                            <span className="align-middle">{item.products} </span>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to={{ pathname: this.props.match.url + '/details', state: { item: item } }}>
                                                            {item.status === "1" && <span className="text-success">Active</span>}
                                                            {item.status === "0" && <span className="text-danger">Hidden</span>}
                                                        </Link>
                                                    </td>
                                                    <td className="actions">
                                                        <PageActions Id={item.id} action="page-delete" item={item}
                                                            orderby={orderby}
                                                            sortby={sortby} search={search} page={activePage}
                                                            removeItems={this.removeItems} />
                                                    </td>
                                                </tr>
                                            ))}
                                            {/* </InfiniteScroll>} */}
                                        </tbody>
                                    </table>
                                </div>}

                            {(items.length !== this.state.totalRecords) && items.length > 0 &&
                                <button className="btn btn-primary loadmore-btn" onClick={this.fetchMoreData}>
                                    Load More <i className="la la-angle-right"></i>
                                </button>
                            }
                            {pagelist !== null && pagelist.records !== undefined && pagelist.records.length === 0 && <div className="text-center">
                                {isSearch && <label className="lblNoRecord">{randomError}</label>}
                                {!isSearch && <label className="lblNoRecord">No Record Found!</label>}
                            </div>}
                        </div>
                    </div>
                </div>
                <Modal open={IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal}
                        title="Delete Pages"
                        message="Are you sure you want to delete selected page(s)?"
                        selectedPages={selectedPages}
                        action="multiple-page-delete"
                        orderby={orderby}
                        sortby={sortby}
                        search={search} page={activePage}
                        clearPageSelection={this.clearPageSelection}
                        removeItems={this.removeItems}
                    />
                </Modal>

                <Modal open={IsChnageVisibilityModalOpen} center showCloseIcon={false} >
                    <ChangePageVisibilityModal closeChangeVisibilityModal={this.closeChangeVisibilityModal} selectedPages={selectedPages}
                        orderby={orderby}
                        sortby={sortby}
                        search={search} page={activePage}
                        clearPageSelection={this.clearPageSelection}
                        updateVisibility={this.updateVisibility} />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.pagesReducer.loading,
    error: state.pagesReducer.error,
    pagelist: state.pagesReducer.pagelist
});

export default connect(mapStateToProps)(Pages);