import { ENCRYPTION_KEY } from '../../constants/constants';
import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr'
import * as contentActions from '../../actions/contentActions';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class UngroupConfirmModal extends Component {

    constructor() {
        super();
        this.ungroup = this.ungroup.bind(this);
    }

    ungroup() {     
        var model = {};
        model.folder_id = this.props.folder_id;
        model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;        
        this.props.dispatch(contentActions.UnGroup(model));        
    }

    componentDidUpdate() {
        this.props.dispatch(contentActions.Clear());
    }

    render() {

        const { res } = this.props;

        if (res != null && res.success === "1") {
            toastr.success("Success !", res.message);
            this.props.closeUnGroupConfirmModal();
            var model = {};
            model.content_type = this.props.type;
            model.sort_by = this.props.sortby;
            model.order_by = this.props.orderby;
            model.search = this.props.search;
            model.page = this.props.page;
            this.props.dispatch(contentActions.GetContents(model));
            this.props.enableScroll();
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }
        
        return (
            <div className="addcontent_modal">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">FOLDER UNGROUP</h4>
                        </div>
                        <div className="modal-body">
                            <h2 className="modal-para">
                                <p>Are you sure you want to ungroup ?</p>
                                <p><b>This cannot be undone.</b></p>
                            </h2>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" onClick={this.props.closeUnGroupConfirmModal}>CANCEL</button>
                            <button type="button" className="btn btn-primary btn-delete" onClick={this.ungroup}>UNGROUP</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.contentReducer.loading,
    error: state.contentReducer.error,
    res: state.contentReducer.res
});

export default connect(mapStateToProps)(UngroupConfirmModal);