import React from 'react';
import { string, func, element, oneOfType } from 'prop-types';
import Dropzone from 'react-dropzone';
const PapaParse = require('papaparse/papaparse.min.js');


const CSVReader = ({
  cssClass = 'csv-reader-input',
    label,
    onFileLoaded,
    onError,
    inputId = null,
    inputStyle = {},
    parserOptions = {}
    }) => {
    let fileContent = undefined;

    const onDrop = (files) => {
        if (files.length > 0) {
            let reader = new FileReader();
            const filename = files[0].name;
            const bytes = files[0].size;
            
            if (filename.slice(filename.length - 3, filename.length) === 'csv') {
                reader.onload = event => {
                    const csvData = PapaParse.parse(
                        event.target.result,
                        Object.assign(parserOptions, {
                            error: onError
                        })
                    );                    

                    csvData.data = csvData.data.filter(x => (x[0] !== "" || x[1] !== "" || x[2] !== "" || x[3] !== "") && (x[0] !== undefined && x[1] !== undefined && x[2] !== undefined && x[3] !== undefined));
                    var isEmpty = true;
                    for (var i = 1; i < csvData.data.length; i++) {
                        if (csvData.data[i][0] !== "" || csvData.data[i][1] !== "" || csvData.data[i][2] !== "" || csvData.data[i][3] !== "") {
                            isEmpty = false;
                        }
                    }
                    
                    if (!isEmpty) {
                        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                        if (bytes === 0) return '0 Byte';
                        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
                        const fileSize = Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];

                        onFileLoaded(csvData.data, filename, fileSize);
                    } else {
                        onError('CSV is Empty');
                    }
                };

                reader.readAsText(files[0]);
            } else {
                onError('Please Select a CSV File');
            }
        } else {
            onError('Please Select a CSV File');
        }  
    };

    return (       
        <div>
            <Dropzone            
                accept=".csv"
                multiple={false}
                onDrop={onDrop} >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps() }>
                        <input {...getInputProps() } />
                        <div className="upload-img custom-border">
                            <i className="la la-cloud-upload"></i>
                            <p>Drag and drop content here</p>
                            <span>or</span>
                            <span className="btn black-btn choose-file">CHOOSE FILES</span>
                        </div>
                    </div>
                )}
            </Dropzone>
        </div>
    );
};

CSVReader.propTypes = {
    cssClass: string,
    label: oneOfType([string, element]),
    onFileLoaded: func,
    onError: func,
    inputId: string
};

export default CSVReader;