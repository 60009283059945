import React, { Component } from "react";
import Modal from 'react-responsive-modal';
import DeleteConfirmModal from '../common/deleteConfirmModal';
import AddUpdateCategoryModal from './addUpdateCategoryModel';
import AddUpdateAudioModal from './addUpdateAudioModal';
import Popover from 'react-simple-popover';

class SubAudioActions extends Component {

    constructor() {
        super();
        this.state = {
            IsCategoryAddUpdateModelOpen: false,
            IsAudioContentUpdateModalOpen: false,
            IsAudioUpdateModalOpen: false,
            IsDeleteConfirmModalOpen: false,
            IsAudioAddUpdateModelOpen: false,
            open: false
        }

        //  this.onUpdateSuccess = this.onUpdateSuccess.bind(this);
    }

    handleClick(e) {
        this.setState({ open: !this.state.open });
    }

    handleClose(e) {
        this.setState({ open: false });
    }

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    // openAudioAddUpdateModal = () => {
    //     this.setState({ IsCategoryAddUpdateModelOpen: true });
    // };

    // closeAudioAddUpdateModal = () => {
    //     this.setState({ IsCategoryAddUpdateModelOpen: false });
    // };

    openAduioAddUpdateModal = () => {
        this.setState({ IsAudioAddUpdateModelOpen: true });
    };

    closeAudioAddUpdateModal = () => {
        this.setState({ IsAudioAddUpdateModelOpen: false });
    };

    // openAudioUpdateModal = () => {
    //     this.setState({ IsAudioUpdateModalOpen: true });
    // };

    onUpdateSuccess(item) {
        this.props.onUpdateSuccess(item);
    }

    render() {
        const { activePage, parents, isSelectAll, audio_base_url, sortby, orderby, selectedAudio, IsChangeShareSettingModalOpen, IsAudioAddUpdateModelOpen, IsDeleteConfirmModalOpen, IsAddToPagesModalOpen, IsCreateGroupModalOpen, search, isSearch,
            isScroll, items, totalRecords, hasMore, randomError, type, selectedTab } = this.state;

        const { queryParams, item } = this.props;
        var deleteMessage = "Are you sure you want to delele this file?"
        return (
            <div>
                <button type="button" className="btn btn-default" ref="useraction" onClick={this.handleClick.bind(this)}>
                    <i className="la la-ellipsis-h"></i>
                </button>
                <Popover
                    placement='bottom'
                    target={this.refs.useraction}
                    show={this.state.open}
                    style={{ padding: '0px' }}
                    containerStyle={{ marginLeft: '20px', zIndex: 999 }}
                    onHide={this.handleClose.bind(this)} >

                    {/* <button className="edit-action" onClick={this.openAudioUpdateModal}>
                        <i className="la la-pencil"></i> EDIT AUDIO
                            </button> */}
                    <button className="edit-action" onClick={this.openAduioAddUpdateModal}>
                        <i className="la la-pencil"></i> EDIT
                            </button>
                    <button className="delete-action" onClick={this.openDeleteConfirmModal}>
                        <i className="la la-trash-o"></i> DELETE
                            </button>
                </Popover>
                <Modal open={this.state.IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal} title="Delete File" message={deleteMessage}
                        Id={this.props.item.id} action={this.props.action} search={this.props.search} page={this.props.page}
                        removeItems={this.props.removeItems}
                        orderby={this.props.orderby} sortby={this.props.sortby} />
                </Modal>
                <Modal open={IsAudioAddUpdateModelOpen} center showCloseIcon={false} onClose={this.closeAudioAddUpdateModal}>
                    <AddUpdateAudioModal closeAudioAddUpdateModal={this.closeAudioAddUpdateModal} action="update-audio"
                        enableScroll={this.enableScroll}
                        queryParams={queryParams}
                        id={item.id}
                        item={item}
                        onUpdateSuccess={this.onUpdateSuccess.bind(this)} />
                </Modal>
                {/* <Modal open={this.state.IsAudioUpdateModalOpen} center showCloseIcon={false} onClose={this.closeAudioUpdateModal}>                    
                    <AddUpdateAudioModal closeAudioUpdateModal={this.closeAudioUpdateModal} action="update-audio"
                        search={this.props.search} page={this.props.page}
                        orderby={this.props.orderby} sortby={this.props.sortby}
                        id={this.props.item.id} />
                </Modal> */}


            </div>
        );
    }
}

export default SubAudioActions;