import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import * as buActions from '../../actions/buActions';

class BusinessUnitAddEdit extends Component {

    constructor() {
        super();

        this.state = {
            name: '',
            validations: {
                name: ''
            },
            isValidated: false,
            action: '',
            sp_list: [],
            cp_list: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateSpecialityTags = this.updateSpecialityTags.bind(this);
        this.updateCompititorTags = this.updateCompititorTags.bind(this);
    }

    componentDidMount() {
        this.setState({ action: this.props.action });

        let model = {};
        let specialityUrl = "";
        let compititorUrl = "";

        if (this.props.action === 'edit') {
            this.setState({ name: this.props.item.name, isValidated: true });
            model.bu_id = [];
            model.bu_id.push(this.props.Id);
            specialityUrl = "businessunit/adminbusinessunitspecialtieslist";
            compititorUrl = "businessunit/adminbusinessunitcompetitorslist";
        } else {
            specialityUrl = "businessunit/admingetspeciality";
            compititorUrl = "businessunit/admingetcompetitors";
        }

        this.props.dispatch(buActions.GetBUSpecialityList(model, specialityUrl));
        this.props.dispatch(buActions.GetBUCompititorList(model, compititorUrl));
    }

    handleChange(e) {

        if (e.target.value) {
            this.setState({ isValidated: true, name: e.target.value, validations: { ...this.state.validations, name: '' } });
        } else {
            this.setState({ isValidated: false, name: e.target.value, validations: { ...this.state.validations, name: 'Name is required' } });
        }
    }

    handleSubmit(e) {
        if (this.props.action === 'add') {
            var model = {};
            model.name = this.state.name;
            model.sp_id = this.state.sp_list;
            model.cp_id = this.state.cp_list;
            this.props.dispatch(buActions.AddBU(model));
        } else {
            var model = {};
            model.id = this.props.Id;
            model.name = this.state.name;
            this.props.dispatch(buActions.EditBU(model));
        }
    }

    updateSpecialityTags(e) {
        var spid = e.target.dataset.spid;
        var status = e.target.dataset.status;

        if (status == '1') {
            this.state.sp_list.splice(this.state.sp_list.indexOf(spid), 1);
        } else {
            this.state.sp_list.push(spid);
        }

        this.setState(this.state);
    }

    updateCompititorTags(e) {
        var cpid = e.target.dataset.cpid;
        var status = e.target.dataset.status;

        if (status == '1') {
            this.state.cp_list.splice(this.state.cp_list.indexOf(cpid), 1);
        } else {
            this.state.cp_list.push(cpid);
        }

        this.setState(this.state);
    }

    render() {

        const { name, validations, isValidated, action, sp_list, cp_list } = this.state;

        const { splist, cplist, res } = this.props;
        if (res != null && res.success === "1") {
            toastr.success("Success !", res.message);
            var model = {};
            model.sort_by = "name";
            model.order_by = "ASC";
            this.props.dispatch(buActions.GetBUList(model));
            this.props.dispatch(buActions.initiateState());
            this.props.closeEditBUModal();
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        return (

            <div className="modal-dialog" style={{ margin: '0px' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">
                            {action === "edit" && <span>Update</span>}
                            {action === "add" && <span>Add</span>}
                            &nbsp;Business Unit</h4>
                    </div>
                    <div className="modal-body form-container">
                        <div className="form-group">
                            <label>Name</label>
                            <input ref={(searchInput) => { this.searchInput = searchInput; }}
                                type="search" name="search" value={name}
                                className="form-control"
                                onChange={this.handleChange} />
                            {validations.name !== "" && <span className={validations.name === "" ? "hidden" : "has-error"}>{validations.name}</span>}
                        </div>
                        {splist && splist.length > 0 && <div className="form-group">
                            <label>SPECIALITY TAGS</label>
                            <div className="ub-list">
                                {splist.map(item => (
                                    <div key={item.id} className={(sp_list.indexOf(item.id) > -1) || (item.is_status == '1') ? 'ub active' : 'ub'} data-spid={item.id} data-status={item.is_status} onClick={this.updateSpecialityTags.bind(this)}>{item.name}</div>
                                ))}
                            </div>
                        </div>}

                        {cplist && cplist.length > 0 && <div className="form-group">
                            <label>COMPITITOR TAGS</label>
                            <div className="ub-list">
                                {cplist.map(item => (
                                    <div key={item.id} className={(cp_list.indexOf(item.id) > -1) || item.is_status == '1' ? 'ub active' : 'ub'} data-cpid={item.id} data-status={item.is_status} onClick={this.updateCompititorTags.bind(this)}>{item.name}</div>
                                ))}
                            </div>
                        </div>}

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={this.props.closeEditBUModal}>CANCEL</button>
                        <button type="button" className="btn btn-primary" disabled={!isValidated} onClick={this.handleSubmit}>SAVE CHANGES</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.buReducer.loading,
    error: state.buReducer.error,
    res: state.buReducer.res,
    splist: state.buReducer.splist,
    cplist: state.buReducer.cplist
});

export default connect(mapStateToProps)(BusinessUnitAddEdit);