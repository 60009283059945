import { returnResponse } from './baseReducer';

const initialState = {    
    loading: false,
    error: null,
    res: null,
    editbures: null,
    details: null,  
    bulist: {},
    splist: [],
    cplist: []
};

export default function buReducer(state = initialState, action) {
    return returnResponse(state, action);
}