import { ENCRYPTION_KEY } from '../../constants/constants';
import React, { Component } from "react";
import * as  audioActions from '../../actions/audioActions';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class AddUpdateCategoryModal extends Component {
    constructor() {
        super();

        this.state = {
            id: null,
            activeIds: [],
            audiobulistItem: [],
            bu: [],
            name: '',
            type: 0,
            selectedTags: [],
            validations: {
                name: '',
                type: '',

            },
            login_user_type: '',
            valid: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        if (this.props.typeCategory != "") {
            this.state.type = this.props.typeCategory;
        }

        this.setState({ action: this.props.action });
        var model = {};
        model.sort_by = "name";
        model.order_by = "ASC";
        this.props.dispatch(audioActions.GetAudioBUList(model));

        this.setState({ isValidated: true });
        if (this.props.action === 'edit') {
            this.state.name = this.props.item.name;
            this.state.type = this.props.typeCategory;
            this.state.bu = this.props.item.business_unit;
            if (this.state.bu && this.state.bu.length > 0) {
                this.state.bu.forEach((item) => {
                    this.state.activeIds.push(item.id);
                })
            }
            this.setState(this.state);
            this.props.dispatch(audioActions.Clear());
        } else {

        }
    }

    // componentDidUpdate(prevProps) {
    //     this.setState({ audiobulistItem: this.props.audiobulist });
    // }

    handleSubmit(e) {
        if (this.props.action === 'add') {
            var model = {};
            model.name = this.state.name;
            model.type = this.state.type;
            model.bu_id = this.state.activeIds;
            if (model.bu_id.length > 0) {
                model.status = 'true'
            }
            else {
                model.status = 'false'
            }
            this.props.dispatch(audioActions.AddAudioCategory(model));
        } else {
            var model = {};
            model.id = this.props.item.id;
            model.name = this.state.name;
            model.type = this.state.type;
            model.bu_id = this.state.activeIds;
            if (model.bu_id.length > 0) {
                model.status = 'true'
            }
            else {
                model.status = 'false'
            }
            this.props.dispatch(audioActions.UpdateAudioCategory(model));
        }
    }

    handleClick(event, id) {
        if (this.state.activeIds.indexOf(id.target.dataset.buid) > -1) {
            this.setState({
                activeIds: this.state.activeIds.filter((eid) => eid !== id.target.dataset.buid)
            });

        }
        else {
            this.setState({ activeIds: [...this.state.activeIds, id.target.dataset.buid] });
        }
    }

    handleChange(e) {
        switch (e.target.id) {
            case "name":
                if (e.target.value === "") {
                    this.setState({ name: e.target.value, validations: { ...this.state.validations, name: 'Name is required' } });
                }
                else {
                    this.setState({ name: e.target.value, validations: { ...this.state.validations, name: '' } });
                }
                break;
            case "type":
                if (e.target.value === "0") {
                    this.setState({ type: e.target.value, validations: { ...this.state.validations, type: 'Type is required' } });
                }
                else {
                    this.setState({ type: e.target.value, validations: { ...this.state.validations, type: '' } });
                }
                break;
            default:
                this.setState({ [e.target.id]: e.target.value });
                break;
        }
    }

    render() {

        const { name, type, validations, valid, audiobulistItem, activeIds } = this.state;
        const { loading, error, audiobulist, res, action, item, typeCategory } = this.props;
        var isValidated = false;
        if (name !== "" && type !== "") {
            isValidated = true;
        }
        else {
            isValidated = false;
        }

        if (res != null && res.success === "1") {
            toastr.success("Success !", res.message);
            var model = {};
            model.sort_by = "name";
            model.order_by = "ASC";
            // this.props.dispatch(buActions.GetBUList(model));
            this.props.dispatch(audioActions.initiateState());
            this.props.closeCategorAddUpdateModal();
            this.props.onAddSuccess();
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        if (this.state.name != '' &&
            this.state.type != 0) {
            this.state.valid = true;

        } else {
            this.state.valid = false;
        }

        if (audiobulist && audiobulist.records && audiobulist.records.length > 0) {
            ;
            audiobulist.records.forEach(element => {
                element['class'] = 'ub';
            });
        }

        if (action == 'add') {
            var actionName = "Add Category";
        } else if (action == 'edit') {
            var actionName = "Edit Category";
        }
        var desc = "Choose either of the methods below to upload talk track audio.";

        return (
            <div className="addcontent_modal audioadd-modal">
                <div className="modal-dialog" style={{ margin: '0px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{actionName}</h4>
                        </div>
                        <div className="modal-body">

                            <div className="form-group">
                                <label>Name</label>
                                <input type="text" value={name} className="form-control" id="name" placeholder="Name" onChange={this.handleChange} />
                                {validations.name !== "" && <span className={validations.name === "" ? "hidden" : "has-error"}>{validations.name}</span>}
                            </div>
                            <div className="form-group">
                                <label>Category Type</label>
                                <select className="form-control" id="type" value={type} onChange={this.handleChange}>
                                    <option value="">--Select Type--</option>
                                    <option value="stakeholder">Stakeholder</option>
                                    <option value="specialty">Specialty</option>
                                </select>
                                {validations.type !== 0 && <span className={validations.type === 0 ? "hidden" : "has-error"}>{validations.type}</span>}
                            </div>
                            {audiobulist && audiobulist.records && audiobulist.records.length > 0 && <div className="col-md-12 form-group">
                                <label>Tags</label>
                                <div className="ub-list">
                                    {audiobulist.records.map((item, i) => (
                                        <div Key={item.id} className={(activeIds.indexOf(item.id) > -1) ? 'ub active' : 'ub'} data-index={i} data-buid={item.id} data-status={item.status} onClick={this.handleClick.bind(this, item.id)}>{item.name}</div>
                                    ))}
                                </div>
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" onClick={this.props.closeCategorAddUpdateModal}>CANCEL</button>
                            <button type="button" className="btn btn-primary" disabled={!isValidated} onClick={this.handleSubmit}>SAVE CHANGES</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    res: state.audioReducer.res,
    audiobulist: state.audioReducer.audiobulist,
});

export default connect(mapStateToProps)(AddUpdateCategoryModal);