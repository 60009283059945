import { returnResponse } from './baseReducer';

const initialState = {    
    loading: false,
    error: null,
    res: null,
    userlist: {}  
};

export default function usersReducer(state = initialState, action) {
    return returnResponse(state, action);
}