import { errOptions } from '../../constants/constants'
import React, { Component } from "react";
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import * as  buActions from '../../actions/buActions';
import InfiniteScroll from "../common/infinitescroll";
import SpecialityModal from "../speciality/specialityModal";

class BusinessUnitSpecialities extends Component {

    constructor() {
        super();
        this.state = {
            IsSpecialityModalOpen: false,
            sortby: 'name',
            orderby: 'ASC',
            search: '',
            isSelectAll: false,
            activePage: 1,
            isSearch: false,
            items: [],
            isScroll: true,
            totalRecords: null,
            hasMore: true,
            randomError: '',
            view: 'list',
            noRecordsFound: false
        }

        this.changeOrder = this.changeOrder.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.enableScroll = this.enableScroll.bind(this);
        this.changeView = this.changeView.bind(this);
    }

    componentDidMount() {
        var model = {};
        model.bu_id = [];
        model.bu_id.push(this.props.buid);
        model.sort_by = "name";
        model.order_by = "ASC";
        model.page = this.state.activePage;
        model.search = "";
        this.props.dispatch(buActions.GetBUSpecialityList(model));
    }

    componentWillReceiveProps() {
        if (this.props.splist && this.props.splist.records && this.props.splist.records.length === 0 && this.state.items.length === 0) {
            this.setState({ noRecordsFound: true });
        } else if (this.props.splist && this.props.splist.records && this.props.splist.records.length > 0 && this.state.items.length === 0) {
            this.setState({ noRecordsFound: false });
        }
        this.props.dispatch(buActions.ClearBUSpecialityList());
    }

    openSpecialityModal = () => {
        this.setState({ IsSpecialityModalOpen: true });
    };

    closeSpecialityModal = () => {
        this.setState({ IsSpecialityModalOpen: false });
    };

    keyPress(e) {
        var randomError = this.generateRandomMesage(this.state.randomError);
        this.setState({ search: e.target.value.toLowerCase(), activePage: 1, items: [], isScroll: true, totalRecords: null, hasMore: true, randomError: randomError });
        var model = {};
        model.bu_id = [];
        model.bu_id.push(this.props.buid);
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        model.search = e.target.value.toLowerCase();
        model.page = 1;
        this.props.dispatch(buActions.GetBUSpecialityList(model));
    }

    generateRandomMesage(randomError) {
        var str = errOptions[Math.floor(Math.random() * Math.floor(5))];
        if (randomError == str) {
            str = this.generateRandomMesage();
        }
        return str;
    }

    handleSearchChange(e) {
        if (e.target.name === "search") {
            this.setState({ search: e.target.value });
        }
    }

    search() {
        this.state.isSearch = !this.state.isSearch;
        if (this.state.isSearch) {
            this.searchInput.focus();
        }
        else {
            this.setState({ activePage: 1, search: '', items: [], isScroll: true, totalRecords: null, hasMore: true });
            var model = {};
            model.bu_id = [];
            model.bu_id.push(this.props.buid);
            model.sort_by = this.state.sortby;
            model.order_by = this.state.orderby;
            model.search = '';
            model.page = 1;
            this.props.dispatch(buActions.GetBUSpecialityList(model));
        }
    }

    fetchMoreData() {
        this.state.activePage += 1;
        this.state.isScroll = true;
        var model = {};
        model.bu_id = [];
        model.bu_id.push(this.props.buid);
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby.toLowerCase();
        if (this.state.search !== "") {
            model.search = this.state.search;
        }
        model.page = this.state.activePage;
        this.props.dispatch(buActions.GetBUSpecialityList(model));
    }

    changeOrder(e) {

        var orderby = "";
        if (this.state.sortby === e.target.dataset.sortby) {
            orderby = this.state.orderby === "DESC" ? "ASC" : "DESC";
        }
        else {
            orderby = "DESC";
        }

        this.setState({ activePage: 1, orderby: orderby, sortby: e.target.dataset.sortby, items: [], isScroll: true, totalRecords: null, hasMore: true });

        var model = {};
        model.bu_id = [];
        model.bu_id.push(this.props.buid);
        model.sort_by = e.target.dataset.sortby;
        model.order_by = orderby.toLowerCase();
        model.search = this.state.search;
        this.props.dispatch(buActions.GetBUSpecialityList(model));
    }

    enableScroll() {
        this.setState({ items: [], isScroll: true, totalRecords: null, hasMore: true });
    }

    changeView(e) {
        this.setState({ view: e.target.dataset.view });
    }

    render() {

        const { activePage, sortby, orderby, IsSpecialityModalOpen, search, isSearch,
            isScroll, items, hasMore, randomError, view, noRecordsFound } = this.state;

        const { loading, splist } = this.props;


        if (!loading && isScroll && splist != null && splist.records && splist.records.length > 0 && this.state.items.length < splist.total_records) {
            if (!items.find(x => x.id == splist.records[0].id)) {

                var hasMoreRef = ((items.length + splist.records.length) == splist.total_records) ? false : true;

                splist.records.forEach(item => {
                    if (item.name === 'Urology') {
                        item.name = 'Uro';
                    }
                    item.class = item.name.toLowerCase().replace(' ', '-').replace('&', '-').replace('/', '-');
                });

                this.setState({
                    isScroll: false,
                    totalRecords: splist.total_records,
                    items: items.concat(splist.records),
                    hasMore: hasMoreRef
                });
            }
        }

        const loadingClass = { 'textAlign': 'center', 'fontSize': '30px' };
        const header = { 'display': 'flex', 'height': '90px' }
        let isError = noRecordsFound;
        const wordBreak = {
            wordBreak: 'break-all',
            whiteSpace: 'pre-line'
        };

        return (

            <div>
                <div className="tab-parent">
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active">
                            <div className="action-row content-action-row">
                                <button className="btn btn-rounded btn-primary" onClick={this.openSpecialityModal}>
                                    <i className="la la-plus"></i>
                                </button>

                                <div className="bu-list-head-action">
                                    <div className="search-form">
                                        <input ref={(searchInput) => { this.searchInput = searchInput; }}
                                            type="search" name="search" value={search}
                                            className="form-control"
                                            onChange={this.keyPress} />
                                        <button ref={(searchButton) => { this.searchButton = searchButton; }}
                                            type="submit" className="search-btn" onClick={this.search}>
                                            <i className="fa fa-search" aria-hidden="true" ></i>
                                        </button>
                                    </div>
                                    <button className={view === 'list' ? 'btn active' : 'btn'} data-view="list" onClick={this.changeView.bind(this)}>
                                        <i className="la la-th-list" data-view="list"></i>
                                    </button>
                                    <button className={view === 'grid' ? 'btn active' : 'btn'} data-view="grid" onClick={this.changeView.bind(this)}>
                                        <i className="la la-th-large" data-view="grid"></i>
                                    </button>
                                </div>
                            </div>

                            {view == 'grid' && items.length > 0 && <div className="sort-by-row">
                                Sort by:  <span className={(sortby === 'name' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'name' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="name" onClick={this.changeOrder.bind(this)}>
                                    Name
                                                </span>
                            </div>
                            }

                            {view == 'list' && items.length > 0 &&
                                <div className="table-container bu-table table-responsive bu-table-wrap">
                                    <table className="table hover-row" id="userTable">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span className={(sortby === 'name' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'name' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="name" onClick={this.changeOrder.bind(this)}>
                                                        Name
                                                </span>
                                                </th>
                                                <th>

                                                    <span className={(sortby === 'updated_date' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'updated_date' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="updated_date" onClick={this.changeOrder.bind(this)}>
                                                        Last Modified
                                                </span>
                                                </th>
                                                <th className="actions"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.map((item, i) => (
                                                <tr key={item.id} >
                                                    <td className="bu-icon-name">
                                                        <span className="truncate">
                                                            <i className={'icon-' + item.class}></i>
                                                            {item.name}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        <span className="truncate">{item.updated_date}</span>
                                                    </td>
                                                    <td className="actions">

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>}

                            {(items.length !== this.state.totalRecords) && items.length > 0 && view == 'list' &&
                                <button className="btn btn-primary loadmore-btn" onClick={this.fetchMoreData}>
                                    Load More <i className="la la-angle-right"></i>
                                </button>
                            }

                            <div className="list-grid-view">
                                {view == 'grid' && <ul>
                                    {items.map((item, i) => (
                                        <li key={item.id}>
                                            <div className="grid-view-box">
                                                <i className={'icon-' + item.class}></i>
                                            </div>
                                            <h3>{item.name}</h3>
                                        </li>

                                    ))}
                                </ul>}
                            </div>
                            {(items.length !== this.state.totalRecords) && items.length > 0 && view == 'grid' &&
                                <button className="btn btn-primary loadmore-btn" onClick={this.fetchMoreData}>
                                    Load More <i className="la la-angle-right"></i>
                                </button>
                            }


                            {items !== null && noRecordsFound && <div className="text-center">
                                {isSearch && <label className="lblNoRecord">{randomError}</label>}
                                {!isSearch && <label className="lblNoRecord">No Record Found!</label>}
                            </div>}
                        </div>
                    </div>
                </div>
                <Modal open={IsSpecialityModalOpen} center showCloseIcon={false} onClose={this.closeSpecialityModal}>
                    <SpecialityModal closeSpecialityModal={this.closeSpecialityModal} buid={this.props.buid}
                        orderby={orderby}
                        sortby={sortby}
                        activePage={activePage}
                        enableScroll={this.enableScroll}
                        selectedSpecialities={items} />
                </Modal>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    loading: state.buReducer.loading,
    error: state.buReducer.error,
    splist: state.buReducer.splist
});

export default connect(mapStateToProps)(BusinessUnitSpecialities);