import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router-dom';
import DeleteConfirmModal from '../common/deleteConfirmModal';
import SubAudio from './subAudio';
import * as  audioActions from '../../actions/audioActions';
import queryString from 'query-string';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
ClassicEditor
    .create(document.querySelector('#snippet-image'), {
        removePlugins: ['ImageToolbar', 'ImageCaption', 'ImageStyle'],
        image: {}
    })

class AudioCategoryDetails extends Component {

    constructor() {
        super();
        this.state = {
            IsDeleteConfirmModalOpen: false,
            item: {},
            id: 0,
            productId: 0,
            orderby: '',
            sortby: '',
            activeIds: [],
            selectedTab: 'details',
            search: '',
            name: '',
            type: '',
            bu: [],
            probing_questions: '',
            talking_points: '',
            validations: {
                name: '',
                type: '',
            },
            isValidated: true
        }

        this.changeTab = this.changeTab.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        this.setState({ id: values.id, productId: values.product_id });
        if (values.id && values.product_id && values.type != '') {
            let model = {};
            model.id = values.id;
            model.product_id = values.product_id;
            model.type = values.type;
            this.props.dispatch(audioActions.GetTalkTrackDetails(model));
        }

        let modelAudio = {}
        modelAudio.page = '1';
        if (values.type == 'specialty') {
            modelAudio.speciality_id = values.id;
            modelAudio.stakeholder_id = "";
        }
        else if (values.type == 'stakeholder') {
            modelAudio.speciality_id = "";
            modelAudio.stakeholder_id = values.id;
        }
        modelAudio.product_id = values.product_id;
        modelAudio.order_by = 'desc';
        modelAudio.sort_by = 'name';
        modelAudio.search = '';
        this.props.dispatch(audioActions.GetSubAudioList(modelAudio));
    }

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/trackaudio' />
        }
    }

    changeTab(e) {
        var tab = e.target.dataset.tab;
        this.setState({ selectedTab: tab });
    }

    handleChange(e) {
        ;
        switch (e.target.id) {
            case "name":
                if (e.target.value === "") {
                    this.setState({ name: e.target.value, validations: { ...this.state.validations, name: 'Name is required' } });
                }
                else {
                    this.setState({ name: e.target.value, validations: { ...this.state.validations, name: '' } });
                }
                break;
            case "type":
                if (e.target.value === "0") {
                    this.setState({ type: e.target.value, validations: { ...this.state.validations, type: 'Type is required' } });
                }
                else {
                    this.setState({ type: e.target.value, validations: { ...this.state.validations, type: '' } });
                }
                break;
            default:
                this.setState({ [e.target.id]: e.target.value });
                break;
        }

    }

    handleChangeProbing(e) {
    }

    handleClick(event, id) {
        if (this.state.activeIds.indexOf(id.target.dataset.buid) > -1) {
            this.setState({
                activeIds: this.state.activeIds.filter((eid) => eid !== id.target.dataset.buid)
            });

        }
        else {
            this.setState({ activeIds: [...this.state.activeIds, id.target.dataset.buid] });
        }
    }

    handleSubmit() {
        const values = queryString.parse(this.props.location.search);
        if (this.state.selectedTab == 'details') {
            var model = {};
            model.id = values.id;
            model.product_id = values.product_id;
            model.bu_id = this.state.activeIds;
            model.status = this.state.activeIds.length > 0 ? 'true' : 'false';
            model.type = this.state.type;
            model.name = this.state.name;
            this.props.dispatch(audioActions.updateAudioDetail(model));
        } else if (this.state.selectedTab == 'probing_questions') {
            var model = {};
            model.id = values.id;
            model.product_id = values.product_id;
            model.probing_questions = this.state.probing_questions;
            model.type = this.state.type;
            this.props.dispatch(audioActions.updateAudioProbingQuestion(model));

        } else if (this.state.selectedTab == 'talking_points') {
            var model = {};
            model.id = values.id;
            model.product_id = values.product_id;
            model.talking_point = this.state.talking_points;
            model.type = this.state.type;
            this.props.dispatch(audioActions.updateAudioTalkingPoints(model));

        } else if (this.state.selectedTab == 'audio_tracks') {

        }
        // var model = {};
        // model.id = this.state.item.id;
        // model.name = this.state.name;
        // this.props.dispatch(audioActions.EditBU(model));
    }

    render() {

        if (this.props.location.search === undefined || this.props.location.search === '') {
            return <Redirect to="/trackaudio" />
        }

        const { name, validations, isValidated, item, orderby, sortby, deleteMessage, selectedTab, type, bu, activeIds, probing_questions, talking_points, id, productId } = this.state;

        const { details, res } = this.props;

        if (details !== null && details !== undefined) {
            // this.state.deleteMessage = "Are you sure you want to delele " + details.records.detail.name + "? This will delete all talk tracks under this category."
            this.state.deleteMessage = "Are you sure you want to delele this product?"
            this.state.item = details;
            this.state.name = details.records.detail.name;
            this.state.type = details.records.detail.category_type;
            this.state.bu = details.records.bu;
            if (details.records.probing_questions && details.records.probing_questions !== null && details.records.probing_questions.content) {
                this.state.probing_questions = details.records.probing_questions.content;
            }
            if (details.records.talking_points && details.records.talking_points !== null && details.records.talking_points.content) {
                this.state.talking_points = details.records.talking_points.content;
            }
            if (this.state.bu && this.state.bu.length > 0) {
                let activeTag = [];
                activeTag = this.state.bu.filter(x => x.status == 'true');
                activeTag.forEach((item) => {
                    this.state.activeIds.push(item.id);
                })
            }
            this.setState(this.state);
            this.props.dispatch(audioActions.Clear());
        }

        ;
        if (res != null && res.success === "1") {
            this.props.dispatch(audioActions.initiateState());
            toastr.success("Success !", res.message);
            var model = {};
            model.id = item.id;
            // this.props.dispatch(audioActions.GetBUDetails(model));
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        const header = { 'display': 'flex', 'height': '90px' }

        return (
            <div className="container">
                {this.renderRedirect()}

                <div className="detail-title">
                    <Link to='/trackaudio' className="btn btn-default">
                        <i className="la la-mail-reply"></i> BACK
                    </Link>
                    {/* <h1>{item.name}</h1> */}
                    <h1>{name}</h1>
                </div>

                <div className="action-row clearfix add-action-row mb0">
                    <button className="btn btn-default save-btn" disabled={!isValidated} onClick={this.handleSubmit}>
                        <i className="la la-check-circle"></i> SAVE CHANGES
                    </button>

                    <button className="btn btn-default btn-delete" onClick={this.openDeleteConfirmModal}>
                        <i className="la la-trash-o"></i> DELETE
                    </button>
                </div>

                <div className="tag-detail-wrap">

                    <div className="tab-parent sidebar-tab">
                        <ul className="nav nav-tabs" role="tablist">
                            <li role="presentation" className={selectedTab === 'details' ? 'active' : ''} data-tab="details" onClick={this.changeTab.bind(this)}>
                                <a href="javascript:void();" data-tab="details" onClick={this.changeTab.bind(this)}>Details</a>
                            </li>
                            <li role="presentation" className={selectedTab === 'probing_questions' ? 'active' : ''} data-tab="probing_questions" onClick={this.changeTab.bind(this)}>
                                <a href="javascript:void();" data-tab="probing_questions" onClick={this.changeTab.bind(this)}>Probing Questions</a>
                            </li>
                            <li role="presentation" className={selectedTab === 'talking_points' ? 'active' : ''} data-tab="talking_points" onClick={this.changeTab.bind(this)}>
                                <a href="javascript:void();" data-tab="talking_points" onClick={this.changeTab.bind(this)}>Talking Points</a>
                            </li>
                            <li role="presentation" className={selectedTab === 'audio_tracks' ? 'active' : ''} data-tab="audio_tracks" onClick={this.changeTab.bind(this)}>
                                <a href="javascript:void();" data-tab="audio_tracks" onClick={this.changeTab.bind(this)}>Audio Tracks</a>
                            </li>

                        </ul>
                    </div>
                    {selectedTab == 'details' && <div className="form-container tag-dtl-form-wrap">
                        <form>
                            <div className="form-group">
                                <label>Name</label>
                                <input ref={(nameInput) => { this.nameInput = nameInput; }}
                                    type="search" name="search" value={name}
                                    className="form-control"
                                    id="name"
                                    onChange={this.handleChange} />
                                {validations.name !== "" && <span className={validations.name === "" ? "hidden" : "has-error"}>{validations.name}</span>}
                            </div>
                            <div className="form-group">
                                <label>Category Type</label>
                                <select disabled className="form-control" id="type" value={type} onChange={this.handleChange}>
                                    <option value="0">--Select Type--</option>
                                    <option value="stakeholder">Stakeholder</option>
                                    <option value="specialty">Specialty</option>
                                </select>
                                {validations.type !== 0 && <span className={validations.type === 0 ? "hidden" : "has-error"}>{validations.type}</span>}
                            </div>
                            {bu && bu.length > 0 && <div className="form-group">
                                <label>Tags</label>
                                <div className="ub-list">
                                    {bu.map((item, i) => (
                                        <div key={item.id} className={(activeIds.indexOf(item.id) > -1) ? 'ub active' : 'ub'} data-index={i} data-buid={item.id} data-status={item.status} onClick={this.handleClick.bind(this, item.id)}>{item.name}</div>
                                    ))}
                                </div>
                            </div>}
                        </form>
                    </div>}
                    {selectedTab == 'probing_questions' && <div className="form-container">
                        <CKEditor
                            editor={ClassicEditor}
                            data={probing_questions}
                            onChange={(event, editor) => {

                                { this.setState({ probing_questions: editor.getData() }) }
                            }}
                        />
                    </div>}
                    {selectedTab == 'talking_points' && <div className="form-container">
                        <CKEditor
                            editor={ClassicEditor}
                            data={talking_points}
                            onChange={(event, editor) => {
                                { this.setState({ talking_points: editor.getData() }) }
                            }}
                        />
                    </div>}
                    {selectedTab == 'audio_tracks' && <div className="form-container">
                        <SubAudio queryParams={queryString.parse(this.props.location.search)}></SubAudio>
                    </div>}

                </div>

                <Modal open={this.state.IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal} title="Delete Product" message={deleteMessage}
                        product_id={productId}
                        type={this.state.type}
                        id = {id}
                        action='delete-product'
                        orderby={orderby} sortby={sortby} onDeleteSuccess={this.setRedirect} />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.audioReducer.loading,
    error: state.audioReducer.error,
    res: state.audioReducer.res,
    details: state.audioReducer.details,
    subAudiolist: state.audioReducer.subAudiolist
});

export default connect(mapStateToProps)(AudioCategoryDetails);
