import { BASE_URL, errOptions } from '../../constants/constants';
import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Pagination from "react-js-pagination";
import * as  pageActions from '../../actions/pagesActions';
import InfiniteScroll from "../common/infinitescroll";

class AddToContentModal extends Component {

    constructor() {
        super()
        this.state = {
            image_base_url: BASE_URL.replace("api/v7/", "") + "uploads/pages/",
            selectedContents: [],
            search: '',
            parents: [{ id: 0, name: 'HOME' }],
            isSelectAll: false,
            activePage: 1,
            isSearch: false,
            items: [],
            isScroll: true,
            totalRecords: null,
            hasMore: true,
            randomError: ''
        };

        this.contentSelection = this.contentSelection.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addParent = this.addParent.bind(this);
        this.getFolderFiles = this.getFolderFiles.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
    }

    componentDidMount() {
        this.setState({ sortby: "content_name", orderby: "ASC" });
        var model = {};
        model.content_type = this.state.content_type;
        model.sort_by = 'content_name';
        model.order_by = 'ASC';
        model.page = this.state.activePage;
        this.props.dispatch(pageActions.GetContents(model));
        this.state.selectedContents = this.props.updatedContent;
        this.setState(this.state);
    }

    keyPress(e) {
        var randomError = this.generateRandomMesage(this.state.randomError);
        this.setState({ activePage: 1, items: [], isScroll: true, totalRecords: null, hasMore: true, randomError: randomError });
        var model = {};
        model.search = this.state.search.toLowerCase();
        model.content_type = "all";
        model.sort_by = 'content_name';
        model.order_by = 'ASC';
        if (this.state.parents.length > 1) {
            model.folder_id = this.state.parents[this.state.parents.length - 1].id;
        }
        model.page = this.state.activePage;
        this.props.dispatch(pageActions.GetContents(model));
    }

    generateRandomMesage(randomError) {
        var str = errOptions[Math.floor(Math.random() * Math.floor(5))];
        if (randomError == str) {
            str = this.generateRandomMesage();
        }
        return str;
    }

    contentSelection(e) {

        var id = e.target.id.replace("chk", "");
        if (e.target.checked) {
            var content = this.state.items.filter(x => x.id == id);
            var model = {};
            if (content.length > 0) {

                model.id = content[0].id;
                var bytes = content[0].file_size;
                var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                if (bytes === 0) return '0 Byte';
                var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

                model.computedSize = Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
                model.name = content[0].content_name;
                model.existing = true;
                model.uploaded = false;
            }

            if (this.state.selectedContents.length + 1 === this.state.items.length) {
                this.setState({ isSelectAll: true, selectedContents: [...this.state.selectedContents, model] });
            }
            else {
                this.setState({ selectedContents: [...this.state.selectedContents, model] });
            }
        }
        else {
            this.setState({
                isSelectAll: false,
                selectedContents: this.state.selectedContents.filter((item) => item.id !== id)
            });
        }
    }

    selectAll(e) {
        if (e.target.checked) {
            this.state.selectedContents = this.state.items.filter(x => x.content_type !== 'folder').map((item) => {

                var model = {};
                model.id = item.id;
                var bytes = item.file_size;
                var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                if (bytes === 0) return '0 Byte';
                var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

                model.computedSize = Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
                model.name = item.content_name;
                model.existing = true;
                model.uploaded = false;
                return model;
            });
            this.state.isSelectAll = true;
        }
        else {
            this.state.selectedContents = [];
            this.state.isSelectAll = false;
        }
        this.setState(this.state);
    }

    handleSearchChange(e) {
        if (e.target.name === "search") {
            this.setState({ search: e.target.value });
        }
    }

    search() {
        this.state.isSearch = !this.state.isSearch;
        if (this.state.isSearch) {
            this.searchInput.focus();
        }
        else {
            this.setState({ search: '', activePage: 1, items: [], isScroll: true, totalRecords: null, hasMore: true });
            var model = {};
            model.search = '';
            model.content_type = "all";
            model.sort_by = 'content_name';
            model.order_by = 'ASC';
            if (this.state.parents.length > 1) {
                model.folder_id = this.state.parents[this.state.parents.length - 1].id;
            }
            model.page = this.state.activePage;
            this.props.dispatch(pageActions.GetContents(model));
        }
    }

    handleSubmit() {

        this.props.appendExistingContent(this.state.selectedContents);
        this.props.closeAddToContentsModal();
    }

    addParent(e) {
        var model = {};
        model.id = e.target.dataset.id;
        model.name = e.target.dataset.name;
        this.setState({ parents: [...this.state.parents, model], activePage: 1, search: '', items: [], isScroll: true, totalRecords: null, hasMore: true });

        var model = {};
        model.content_type = "all";
        model.sort_by = 'content_name';
        model.order_by = 'ASC';
        if (this.state.search !== "") {
            model.search = this.state.search;
        }
        model.folder_id = e.target.dataset.id;
        model.page = 1;
        this.props.dispatch(pageActions.GetContents(model));
    }

    getFolderFiles(e) {

        this.state.parents = this.state.parents.slice(0, this.state.parents.findIndex(x => x.id == e.target.dataset.id) + 1);
        this.state.search = "";
        this.state.activePage = 1;
        this.state.items = [];
        this.state.isScroll = true;
        this.state.totalRecords = null;
        this.state.hasMore = true;
        this.setState(this.state);

        var model = {};

        model.content_type = "all";
        model.sort_by = 'content_name';
        model.order_by = 'ASC';
        if (this.state.search !== "") {
            model.search = this.state.search;
        }
        if (e.target.dataset.id > 0) {
            model.folder_id = e.target.dataset.id;
        }
        model.page = 1;
        this.props.dispatch(pageActions.GetContents(model));
    }

    handlePageChange(pageNumber) {

        this.setState({ activePage: pageNumber, isSelectAll: false });
        var model = {};
        model.search = this.state.search;
        model.content_type = "all";
        model.sort_by = 'content_name';
        model.order_by = 'ASC';
        if (this.state.parents.length > 1) {
            model.folder_id = this.state.parents[this.state.parents.length - 1].id;
        }
        model.page = pageNumber;
        this.props.dispatch(pageActions.GetContents(model));
    }

    fetchMoreData() {
        this.state.activePage += 1;
        this.state.isScroll = true;

        var model = {};
        model.content_type = this.state.content_type;
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        if (this.state.search.toLowerCase() !== "") {
            model.search = this.state.search.toLowerCase();
        }
        if (this.state.parents.length > 0 && this.state.parents[this.state.parents.length - 1].id > 0) {
            model.folder_id = this.state.parents[this.state.parents.length - 1].id;
        }
        model.page = this.state.activePage;
        this.props.dispatch(pageActions.GetContents(model));
    }

    render() {

        const { activePage, isSelectAll, image_base_url, selectedContents, parents, search, isSearch,
            isScroll, items, totalRecords, hasMore, randomError } = this.state;
        const { loading, error, pagecontentlist } = this.props;

        if (pagecontentlist !== undefined && pagecontentlist.records !== undefined) {
            pagecontentlist.records.map(x => {
                if (x.content_type !== 'folder' && x.file_name !== null) {
                    x.extension = x.file_name.slice(x.file_name.indexOf('.')).replace('.', '')
                }
            });
        }

        if (!loading && isScroll && pagecontentlist != null && pagecontentlist.records && pagecontentlist.records.length > 0 && this.state.items.length < pagecontentlist.total_records) {

            if (!items.find(x => x.id == pagecontentlist.records[0].id)) {
                var hasMoreRef = ((items.length + pagecontentlist.records.length) == pagecontentlist.total_records) ? false : true;

                this.setState({
                    isScroll: false,
                    totalRecords: pagecontentlist.total_records,
                    items: items.concat(pagecontentlist.records),
                    hasMore: hasMoreRef
                });
            }
        }

        const loadingClass = { 'textAlign': 'center', 'fontSize': '30px' };

        return (
            <div className="addcontent_modal">
                <div className="modal-dialog" style={{ margin: '0px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add To Pages</h4>
                        </div>
                        <div className="modal-body">
                            <h2 className="modal-para-small">
                                <p>Select which pages you would like to add this content to.</p>
                            </h2>
                            <div className="assigned-pages">

                                {/*<div className="bredcrum_block">
                                    <div className="bredcrum">
                                        <ul className="clearfix">
                                            {parents.map((item, index) => (
                                                <li key={index} className={(index === parents.length - 1) ? 'active' : ''}><a href="javascript:void(0);" data-id={item.id} onClick={this.getFolderFiles}>{item.name}</a></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>*/}

                                <div className="action-row clearfix action-row-checkbox">
                                    <div className="custom-checkbox pull-left">
                                        <input type="checkbox" id="chk11" onChange={this.selectAll} checked={isSelectAll} />
                                        <label htmlFor="chk11"></label>
                                    </div>
                                    <div className="search-form search-form-modal pull-right">
                                        <input ref={(searchInput) => { this.searchInput = searchInput; }}
                                            type="text" className="form-control" value={search} name="search"
                                            onKeyUp={this.keyPress} onChange={this.handleSearchChange} />
                                        <button type="submit" className="search-btn" onClick={this.search}>
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                {items.length > 0 &&

                                    <div id="scrollableDiv" className="pages-list add-pages-list mCustomScrollbar" data-mcs-theme="dark">
                                        {items.length > 0 && <InfiniteScroll
                                            target="list"
                                            dataLength={items.length}
                                            scrollThreshold="300px"
                                            next={this.fetchMoreData}
                                            hasMore={hasMore}
                                            scrollableTarget="scrollableDiv"
                                            loader={<li className="bottom-0" style={loadingClass}>Loading...</li>} >

                                            {items.map((item, i) => (
                                                <li key={item.id}>
                                                    {item.content_type === 'folder' &&
                                                        <div className="custom-checkbox" >
                                                            {item.is_child === true && <span style={{ display: 'block' }} data-id={item.id} data-name={item.content_name} onClick={this.addParent}>
                                                                <label data-id={item.id} data-name={item.content_name}>
                                                                    <img src="../images/content-file.png" className="file-img img-responsive" data-id={item.id} data-name={item.content_name} />
                                                                    <span className="page-info" data-id={item.id} data-name={item.content_name}>
                                                                        <span className="page-name truncate" data-id={item.id} data-name={item.content_name}>{item.content_name}</span>
                                                                    </span>
                                                                </label>
                                                            </span>}
                                                            {item.is_child === false && <span style={{ display: 'block' }}>
                                                                <label>
                                                                    <img src="../images/content-file.png" className="file-img img-responsive" data-id={item.id} data-name={item.content_name} />
                                                                    <span className="page-info">
                                                                        <span className="page-name truncate">{item.content_name}</span>
                                                                    </span>
                                                                </label>
                                                            </span>}
                                                        </div>}
                                                    {item.content_type !== 'folder' &&
                                                        <div className="custom-checkbox">
                                                            <input type="checkbox" id={'chk' + item.id} onChange={this.contentSelection} checked={selectedContents.filter(i => i.id === item.id).length === 1} />
                                                            <label htmlFor={'chk' + item.id}>
                                                                {item.content_type === 'videos' && item.extension === 'mp4' && <img src="../images/content-video.png" className="file-img img-responsive" />}
                                                                {item.content_type === 'files' && item.extension === 'mp3' && <img src="../images/content-video.png" className="file-img img-responsive" />}
                                                                {item.content_type === 'files' && item.extension === 'xlsx' && <img src="../images/excel.png" className="file-img img-responsive" />}
                                                                {item.content_type === 'files' && item.extension === 'docx' && <img src="../images/word.png" className="file-img img-responsive" />}
                                                                {item.path !== null && item.content_type === 'files' && (item.extension !== 'mp4' && item.extension !== 'mp3' && item.extension !== 'xlsx' && item.extension !== 'docx') && <img src={image_base_url + item.path + item.file_name} className="file-img img-responsive" onError={(e) => { e.target.onerror = null; e.target.src = "../images/no-image.png" }} />}
                                                                {item.path === null && item.content_type === 'files' && (item.extension !== 'mp4' && item.extension !== 'mp3' && item.extension !== 'xlsx' && item.extension !== 'docx') && <img src={image_base_url + item.file_name} className="file-img img-responsive" onError={(e) => { e.target.onerror = null; e.target.src = "../images/no-image.png" }} />}
                                                                <span className="page-info">
                                                                    <span className="page-name truncate">{item.content_name}</span>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    }
                                                </li>
                                            ))}
                                        </InfiniteScroll>}
                                    </div>
                                }
                                {pagecontentlist !== null && pagecontentlist.records !== undefined && pagecontentlist.records.length === 0 && <div className="text-center" style={{ marginTop: '60px' }}>
                                    {isSearch && <label className="lblNoRecord">{randomError}</label>}
                                    {!isSearch && <label className="lblNoRecord">This Folder Is Empty</label>}
                                </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="modal-footer-inner clearfix">
                                <button type="button" className="btn btn-default" onClick={this.props.closeAddToContentsModal}>CANCEL</button>
                                <button type="button" className="btn btn-primary" onClick={this.handleSubmit} disabled={selectedContents.length === 0}>SELECT</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.pagesReducer.loading,
    error: state.pagesReducer.error,
    pagecontentlist: state.pagesReducer.pagecontentlist
});

export default connect(mapStateToProps)(AddToContentModal);