import { ENCRYPTION_KEY } from '../../constants/constants';
import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import * as contentActions from '../../actions/contentActions';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class ChangeShareSettingModal extends Component {

    constructor() {
        super();

        this.state = {    
            name: '',
            validations: {
                status: ''
            },
            isValidated: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        switch (e.target.name){
            case "groupname":
                if (e.target.value === "") {
                    this.setState({ isValidated: false, name: e.target.value, validations: { ...this.state.validations, status: 'Group name is required' } });
                }
                else {
                    this.setState({ isValidated: true, name: e.target.value, validations: { ...this.state.validations, status: '' } });
                }
                break;
            default:
                break;
        }
    }

    handleSubmit(e) {        
        var model = {};        
        model.content_id = this.props.selectedContents.map(function (item) {
            return item.id;
        });
        model.folder_name = this.state.name;  
        model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
        if (this.props.folder_id) {
            model.folder_id = this.props.folder_id;
        }        
        this.props.dispatch(contentActions.CreateGroup(model));        
    }

    render() {
        const { status, validations, isValidated } = this.state;
        
        const { res } = this.props;
        
        if (res != null && res.success === "1") {      
            toastr.success("Success !", res.message);
            this.props.clearContentSelection();
            this.props.closeChangeShareSettingModal();            
            var model = {};
            model.content_type = this.props.type;
            model.sort_by = this.props.sortby;
            model.order_by = this.props.orderby;
            model.search = this.props.search;
            if (this.props.folder_id > 0) {
                model.folder_id = this.props.folder_id;
            }
            model.page = 1;
            this.props.enableScroll();
            this.props.dispatch(contentActions.GetContents(model));
        }
        else if (res != null && res.success === "0") {
            
            toastr.error('Error', res.message);
            this.props.dispatch(contentActions.Clear());
        }

        return (
            
            <div className="modal-dialog" style={{ margin: '0px' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Create Group</h4>
                    </div>
                    <div className="modal-body form-container">                        
                            <div className="form-group">
                            <label>GROUP NAME</label>
                            <input type="text" className="form-control" name="groupname" onChange={this.handleChange} />
                                {validations.status !== "" && <span className={validations.status === "" ? "hidden" : "has-error"}>{validations.status}</span>}
                            </div>                        
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={this.props.closeChangeShareSettingModal}>CANCEL</button>
                        <button type="button" className="btn btn-primary" disabled={!isValidated} onClick={this.handleSubmit}>SAVE CHANGES</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.usersReducer.loading,
    error: state.usersReducer.error,
    res: state.usersReducer.res
});

export default connect(mapStateToProps)(ChangeShareSettingModal);