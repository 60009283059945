import React, { Component } from "react";
import FileUpload from '../common/fileUpload';

class AddUpdateContentModal extends Component {


    render() {

        var actionName = "";
        var desc = "";
        if (this.props.action === "add") {
            actionName = "Add Content";
            desc = "Choose either of the methods below to upload content.";
        }
        else {
            actionName = "Update Content";
            desc = "Choose either of the methods below to upload updated content. This will remove and replace the current content.";
        }
        
        return (
            <div className="addcontent_modal">
                <div className="modal-dialog" style={{ margin: '0px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{actionName}</h4>
                        </div>
                        <div className="modal-body">

                            <h2 className="modal-para-small">{desc}</h2>
                            <FileUpload closeModal={this.props.closeContentAddUpdateModal} Id={this.props.Id} action={this.props.action} type={this.props.type}
                                orderby={this.props.orderby}
                                sortby={this.props.sortby}
                                search={this.props.search}
                                folder_id={this.props.folder_id}
                                page={this.props.page}
                                enableScroll={this.props.enableScroll}
                                getUpdatedDetails={this.props.getUpdatedDetails} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddUpdateContentModal;