import { BASE_PATH, ENCRYPTION_KEY } from '../constants/constants';
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import * as loginActions from '../actions/loginActions';
import * as menuActions from '../actions/menuActions';
import * as init from './Init';
import * as toggle from './toggle';

import Users from './users/users';
import AddUser from './users/addUser';

import Content from './content/content';
import ContentDetails from './content/contentDetails';

import Pages from './pages/pages';
import PageDetails from './pages/pageDetails';
import AddPage from './pages/addPage';

import BusinessUnits from './businessUnit/businessUnits';
import BusinessUnitDetails from './businessUnit/businessUnitDetails';

import Audio from './audio/audio';
import AudioDetails from './audio/audioDetails';
import AudioCategoryDetails from './audio/audioDetailPage';

import Popover from 'react-simple-popover';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);

var Storage = require('local-storage');

var isInitialized = false;

class Dashboard extends Component {

    constructor() {

        super();
        this.state = {
            isLeftMenuOpen: false,
            pageName: 'Users',
            email: '',
            password: '',
            validations: {
                email: '',
                password: '',
            },
            open: false
        }

        this.currentPage = this.currentPage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.keyPress = this.keyPress.bind(this);
    }

    keyPress(e) {
        if (e.keyCode == 13) {
            if (this.state.validations.email === "" && this.state.validations.password === "") {
                if (this.state.email !== "" && this.state.password !== "") {
                    this.login();
                }
            }
        }
    }

    handleClick(e) {
        this.setState({ open: !this.state.open });
    }

    handleClose(e) {
        this.setState({ open: false });
    }

    componentDidMount() {
        var path = window.location.href.replace(BASE_PATH, "");

        if (path.indexOf("users") > -1) {
            this.state.pageName = "Users";
            this.setState(this.state);
        } else if (path.indexOf("content") > -1) {
            this.state.pageName = "Content";
            this.setState(this.state);
        }
        else if (path.indexOf("pages") > -1) {
            this.state.pageName = "Pages";
            this.setState(this.state);
        } else if (path.indexOf("tags") > -1) {
            this.state.pageName = "Tags";
            this.setState(this.state);
        }
        else if (path.indexOf("trackaudio") > -1) {
            this.state.pageName = "Talk Tracks";
            this.setState(this.state);
        } else {
            this.state.pageName = "Users";
            this.setState(this.state);
        }
    }

    componentDidUpdate() {

        if (Storage.get('user') !== null) {
            if (isInitialized == false) {
                isInitialized = true;
                toggle.Initialize();
            }
        }
        init.Initialize();
    }

    currentPage(event) {
        if (event.target.dataset.page != "Content") {
            localStorage.removeItem('localModel');
        }
        Storage.set('activePage', event.target.dataset.activepage);
        this.props.dispatch(menuActions.ActivatePage(event.target.dataset.activepage));
        this.setState({ pageName: event.target.dataset.page });
    }

    handleChange(e) {
        switch (e.target.name) {
            case "email":
                if (e.target.value === "") {
                    this.setState({ email: e.target.value, validations: { ...this.state.validations, email: 'Email is required' } });
                }
                else {
                    var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
                    if (regex.test(e.target.value)) {
                        this.setState({ email: e.target.value, validations: { ...this.state.validations, email: '' } });
                    } else {
                        this.setState({ email: e.target.value, validations: { ...this.state.validations, email: 'Enter valid email' } });
                    }

                }
                break;
            case "password":
                if (e.target.value === "") {
                    this.setState({ password: e.target.value, validations: { ...this.state.validations, password: 'Password is required' } });
                }
                else {
                    this.setState({ password: e.target.value, validations: { ...this.state.validations, password: '' } });
                }
                break;

            default:
                break;
        }

    }

    login() {
        var model = {};
        model.email = this.state.email;
        model.password = this.state.password;
        this.props.dispatch(loginActions.Login(model));
    }

    logout() {
        this.props.dispatch(loginActions.Logout());
        Storage.clear();
        this.setState({ open: false, email: '', password: '' });
    }

    render() {

        var isValidated = false;
        var isLoggedIn = false;
        if (this.props.location !== undefined) {
            this.state.pageName = "";
        }

        const { email, password, validations, pageName } = this.state;

        if (validations.email === "" && validations.password === "") {
            if (email !== "" && password !== "") {
                isValidated = true;
            }
        }
        else {
            isValidated = false;
        }

        const { logindetail, logout, activePage } = this.props;

        var loginUserDetails = {};
        if (Storage.get('user') !== null) {
            loginUserDetails = JSON.parse(cryptr.decrypt(Storage.get('user')));
            if (loginUserDetails !== null) {
                isLoggedIn = true;
            }
        }


        if (!isLoggedIn && logindetail !== null && logindetail !== undefined) {
            if (logindetail.success === "1") {
                loginUserDetails = logindetail.data;
                Storage.set('user', cryptr.encrypt(JSON.stringify(logindetail.data)));
            }
            else {
                this.props.dispatch(loginActions.Logout());
                toastr.error("Error", logindetail.message);
            }
        }
        return (
            <div>
                {loginUserDetails.id === undefined && <main className="clearfix">
                    <div className="content-wrapper">
                        <header className="top-header cbp-spmenu-top" id="headerSticky">
                            <div className="clearfix header-inner">
                                <div className="container">
                                    <div className="header-logo">
                                        <img src="../images/logo-grey.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </header>


                        <div className="content-area">
                            <div className="container">
                                <div className="titel_block">
                                    <h2 className="sub-title"> Login </h2>
                                    <div className="card_block login_block">
                                        <div className="form_wrapper">
                                            <div className="form_container">
                                                <div className="row clearfix">
                                                    <div className="col_half last">
                                                        <div className="input_field">
                                                            <span><i className="fa fa-envelope" aria-hidden="true"></i></span>
                                                            <input type="email" name="email" placeholder="Email" required="" onChange={this.handleChange} onKeyDown={this.keyPress} />
                                                            {validations.email !== "" && <span className={validations.email === "" ? "hidden" : "has-error"}>{validations.email}</span>}
                                                        </div>

                                                        <div className="input_field">
                                                            <span><i className="fa fa-lock" aria-hidden="true"></i></span>
                                                            <input type="password" name="password" placeholder="Password" required="" onChange={this.handleChange} onKeyDown={this.keyPress} />
                                                            {validations.password !== "" && <span className={validations.password === "" ? "hidden" : "has-error"}>{validations.password}</span>}
                                                        </div>

                                                        <input className="button" type="submit" value="Login" onClick={this.login} disabled={!isValidated} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer id="stickyFooter">
                        <div className="container">
                            <div className="footer-inner">
                                <a href="javascript:void(0);">Stryker</a>
                            </div>
                        </div>
                    </footer>
                </main>}
                {loginUserDetails.id !== undefined && <Router>
                    <div>
                        <aside className={this.state.isLeftMenuOpen === false ? "sidebar cbp-spmenu cbp-spmenu-vertical cbp-spmenu-left" : "sidebar cbp-spmenu cbp-spmenu-vertical cbp-spmenu-left cbp-spmenu-open"} id="cbp-spmenu-s1" >
                            <div className="clearfix sidebar-top">
                                <a href="JavaScript:void(0);" className="sidebar-toggle" id="hideMenu">
                                    <i className="la la-angle-left"></i>
                                    <i className="la la-bars"></i>
                                </a>
                                <a href="javascript:void(0)" className="logo-sidebar">
                                    <img src="/images/crush-logo.png" alt="" />
                                </a>
                            </div>
                            <div className="sidebar-menu">

                                <ul>
                                    <li className={((activePage == 'AllUsers') || (activePage == 'Admin') || (activePage == 'Users')) ? 'parent parent-active' : 'parent'}>
                                        <span href="javascript:void(0);" className="parent_class">
                                            <Link to={{ pathname: '/users', state: { role: 'all' } }} className="menu-header-link" data-page="Users" data-activepage="AllUsers" onClick={this.currentPage}>
                                                <i className="la la-home" data-page="Users" data-activepage="AllUsers" ></i>
                                                <span data-page="Users" data-activepage="AllUsers" >Users</span>
                                            </Link>
                                            <i className="fa fa-caret-down" aria-hidden="true"></i>
                                        </span>
                                        <ul className="children">
                                            <li>
                                                <Link to={{ pathname: '/users', state: { role: 'all' } }} className={activePage == 'AllUsers' ? 'active' : ''} data-page="Users" data-activepage="AllUsers" onClick={this.currentPage}>
                                                    <i className="la la-users" data-page="Users" data-activepage="AllUsers" ></i>
                                                    <span data-page="Users" data-activepage="AllUsers">ALL USERS</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={{ pathname: '/users', state: { role: 'admin' } }} className={activePage == 'Admin' ? 'active' : ''} data-page="Users" data-activepage="Admin" onClick={this.currentPage}>
                                                    <i className="la la-shield" data-page="Users" data-activepage="Admin" > </i>
                                                    <span data-page="Users" data-activepage="Admin">ADMINS</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={{ pathname: '/users', state: { role: 'user' } }} className={activePage == 'Users' ? 'active' : ''} data-page="Users" data-activepage="Users" onClick={this.currentPage}>
                                                    <i className="la la-user" data-page="Users" data-activepage="Users"></i>
                                                    <span data-page="Users" data-activepage="Users">USERS</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className={((activePage == 'AllContents') || (activePage == 'Files') || (activePage == 'Video')) ? 'parent parent-active' : 'parent'}>
                                        <span href="javascript:void(0);" className="parent_class">
                                            <Link to={{ pathname: '/content', state: { content_type: 'all' } }} className="menu-header-link" data-page="Content" data-activepage="AllContents" onClick={this.currentPage} >
                                                <i className="la la-cube" data-page="Content" data-activepage="AllContents"></i>
                                                <span data-page="Content" data-activepage="AllContents">Competitive Intel</span>
                                            </Link>
                                            <i className="fa fa-caret-down" aria-hidden="true"></i>
                                        </span>
                                        <ul className="children">
                                            <li>
                                                <Link to={{ pathname: '/content', state: { content_type: 'all' } }} className={activePage == 'AllContents' ? 'active' : ''} data-page="Content" data-activepage="AllContents" onClick={this.currentPage} >
                                                    <i className="la la-copy" data-page="Content" data-activepage="AllContents"></i>
                                                    <span data-page="Content" data-activepage="AllContents">ALL COMPETITIVE INTEL</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={{ pathname: '/content', state: { content_type: 'files' } }} className={activePage == 'Files' ? 'active' : ''} data-page="Content" data-activepage="Files" onClick={this.currentPage} >
                                                    <i className="la la-file" data-page="Content" data-activepage="Files"></i>
                                                    <span data-page="Content" data-activepage="Files">FILES & DOCUMENTS</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={{ pathname: '/content', state: { content_type: 'video' } }} className={activePage == 'Video' ? 'active' : ''} data-page="Content" data-activepage="Video" onClick={this.currentPage} >
                                                    <i className="la la-play-circle-o" data-page="Content" data-activepage="Video"></i>
                                                    <span data-page="Content" data-activepage="Video">VIDEOS</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="parent no-collaps">
                                        <Link to='/pages' className={activePage == 'Pages' ? 'active' : ''} data-page="Pages" data-activepage="Pages" onClick={this.currentPage}>
                                            <i className="la la-stack-overflow" data-page="Pages" data-activepage="Pages"></i>
                                            <span data-page="Pages" data-activepage="Pages">Pages</span>
                                        </Link>
                                    </li>
                                    <li className="parent no-collaps">
                                        <Link to='/trackaudio' className={activePage == 'Talk Tracks' ? 'active' : ''} data-page="Talk Tracks" data-activepage="Talk Tracks" onClick={this.currentPage}>
                                            <i className="la la-comment" data-page="Talk Tracks" data-activepage="Talk Tracks"></i>
                                            <span data-page="Talk Tracks" data-activepage="Talk Tracks">Talk Tracks</span>
                                        </Link>
                                    </li>
                                    <li className="parent no-collaps">
                                        <Link to='#' className={activePage == 'Engagement Emails' ? 'active' : ''} data-page="Engagement Emails" data-activepage="Engagement Emails" onClick={this.currentPage}>
                                            <i className="la la-envelope" data-page="Engagement Emails" data-activepage="Engagement Emails"></i>
                                            <span data-page="Engagement Emails" data-activepage="Engagement Emails">Engagement Emails</span>
                                        </Link>
                                    </li>
                                    <li className="parent no-collaps">
                                        <Link to='/tags' className={activePage == 'Tags' ? 'active' : ''} data-page="Tags" data-activepage="Tags" onClick={this.currentPage}>
                                            <i className="la la-tags" data-page="Tags" data-activepage="Tags"></i>
                                            <span data-page="Tags" data-activepage="Tags">Tags</span>
                                        </Link>
                                    </li>

                                    {/* 
                                    
                                     */}

                                </ul>

                            </div>
                            <div className="sidebar-bottom">
                                <a href="javascript:void(0);">Stryker</a>
                            </div>
                        </aside>
                        <div className="content-wrapper">
                            <header className="top-header cbp-spmenu-top" id="headerSticky">
                                <div className="clearfix header-inner">
                                    <div className="container">
                                        <div className="header-left">
                                            <a href="javaScript:void(0);" className="header_sidebar_toggle" id="showMenu">
                                                <i className="la la-bars"></i>
                                            </a>
                                            {/*
                                         <h1 className="page-title"> {this.state.pageName} </h1>
                                         */}


                                        </div>
                                        <div className="header-logo">
                                            {/*
                                         <img src="../images/logo-grey.png" alt="" />
                                         */}
                                        </div>

                                        <ul className="header-right nav">
                                            <li className="profile">
                                                <button type="button" ref="target" onClick={this.handleClick.bind(this)}>
                                                    {loginUserDetails.first_name.slice(0, 1).toUpperCase() + loginUserDetails.last_name.slice(0, 1).toUpperCase()}
                                                </button>
                                                <Popover
                                                    placement='bottom'
                                                    containerStyle={{ zIndex: '1000' }}
                                                    style={{ marginRight: '50px' }}
                                                    target={this.refs.target}
                                                    show={this.state.open}
                                                    onHide={this.handleClose.bind(this)} >
                                                    <button className="edit-action log-out" onClick={this.logout}>
                                                        <i className="la la-power-off"></i> LOGOUT
                                                         </button>
                                                </Popover>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </header>
                            <div className="content-area">

                                <Route exact path="/" component={Users} />
                                <Route exact path="/users" component={Users} />
                                <Route path="/users/add" component={AddUser} />
                                <Route exact path="/content" component={Content} />
                                <Route path="/content/details" component={ContentDetails} />

                                <Route exact path="/pages" component={Pages} />
                                <Route path="/pages/details" component={PageDetails} />
                                <Route path="/pages/add" component={AddPage} />
                                <Route path="/pages/edit" component={AddPage} />

                                <Route exact path="/tags" component={BusinessUnits} />
                                <Route exact path="/tags/details" component={BusinessUnitDetails} />


                                <Route exact path="/trackaudio" component={Audio} />
                                {/* <Route path="/trackaudio/details" component={AudioDetails} /> */}
                                <Route exact path="/trackaudio/details" component={AudioCategoryDetails} />


                            </div>
                        </div>
                        <footer id="stickyFooter">
                            <div className="container">
                                <div className="footer-inner">
                                    <a href="javascript:void(0);">Stryker</a>
                                </div>
                            </div>
                        </footer>
                    </div></Router>
                }
            </div>

        );

    }
}

const mapStateToProps = state => ({
    loading: state.loginReducer.loading,
    error: state.loginReducer.error,
    logindetail: state.loginReducer.logindetail,
    logout: state.loginReducer.logout,
    activePage: state.menuReducer.activePage
});

export default connect(mapStateToProps)(Dashboard);
//export default withRouter(connect(mapStateToProps)(Dashboard));