import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { toastr } from 'react-redux-toastr';
import ImportUserModal from './importUserModal';
import * as usersActions from '../../actions/usersActions';

class AddUser extends Component {

    constructor() {
        super();
        this.state = {
            IsImportUserModalOpen: false,
            csvData: []            
        }

        this.handleForce = this.handleForce.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addUser = this.addUser.bind(this);
        this.saveUsers = this.saveUsers.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.validateUsers = this.validateUsers.bind(this);
    }

    openImportUserModal = () => { this.setState({ IsImportUserModalOpen: true }) };
    closeImportUserModal = () => { this.setState({ IsImportUserModalOpen: false }) };

    addUser() {

        var model = {};
        model.count = this.state.csvData.length;
        model.firstName = "";
        model.firstNameError = "";
        model.lastName = "";
        model.lastNameError = "";
        model.email = "";
        model.emailError = "";
        model.role = "0";
        model.roleError = "";

        this.state.csvData.push(model);
        this.setState(this.state);
    }

    removeUser(e) { 
        this.state.csvData.splice(parseInt(e.target.dataset.index), 1);
        this.state.csvData.map((x, index) => {
            x.count = index;
        });
        this.setState(this.state);
    }

    handleChange(e) {  
        var count = parseInt(e.target.dataset.count);
        switch (e.target.id) {
            case "firstName":
                this.state.csvData[count].firstName = e.target.value;
                break;
            case "lastName":
                this.state.csvData[count].lastName = e.target.value;
                break;
            case "email":
                this.state.csvData[count].email = e.target.value;
                break;
            case "role":
                this.state.csvData[count].role = e.target.value;
                break;
            default:
                break;
        }
        this.setState(this.state);
    }

    saveUsers() {        
        var errorCounter = this.validateUsers();
        if (errorCounter == 0) {
            var data = [];
            data = this.state.csvData.filter(y => y.internalCounter == 0).map(x => {
                var d = {};
                d.first_name = x.firstName;
                d.last_name = x.lastName;
                d.email = x.email;
                d.user_type = x.role;
                return d;
            });

            if (data.length > 0) {
                this.props.dispatch(usersActions.AddUser(data));
            }        
        }        
    }

    validateEmail(email) {
        var regex = /[\w-]+@([\w-]+\.)+[\w-]+/;
        if (regex.test(email)) {
            return true;
        } else {
            return false;
        }
    }

    validateUsers() {
        var counter = 0;
        this.state.csvData.map(x => {

            x.internalCounter = 0;

            if (x.firstName == "") {
                x.firstNameError = "First Name required";
                x.internalCounter++;
            }
            else {
                x.firstNameError = "";
            }

            if (x.lastName == "") {
                x.lastNameError = "Last Name required";
                x.internalCounter++;
            }
            else {
                x.lastNameError = "";
            }

            if (x.email == "") {
                x.emailError = "Email required";
                x.internalCounter++;
            }
            else {
                if (!this.validateEmail(x.email)) {
                    x.emailError = "Invalid email";
                    x.internalCounter++;
                }
                else {
                    x.emailError = "";
                }
            }

            if (x.role == "0") {
                x.roleError = "Role required";
                x.internalCounter++;
            }
            else {
                x.roleError = "";
            }
            
            if (x.internalCounter > 3) {
                x.firstNameError = "";
                x.lastNameError = "";
                x.emailError = "";
                x.roleError = "";
            } else if (x.internalCounter !== 0){
                counter++;
            }

        });        

        this.forceUpdate();
        return counter;
    }

    handleForce(data) {        
        this.state.csvData = this.state.csvData.filter(x => !(x.firstName == '' && x.lastName == '' && x.email == '' && x.role == '0'));        
        this.setState({ csvData: this.state.csvData.concat(data) });
        this.closeImportUserModal();
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/users' ></Redirect>;
        }
    }

    componentDidMount() {
        if (this.props.location.state === undefined) {
            this.setRedirect();
        } else {
            this.preAddedRows();
        }
    }

    preAddedRows() {
        var model = {};
        model.count = this.state.csvData.length;
        model.firstName = "";
        model.firstNameError = "";
        model.lastName = "";
        model.lastNameError = "";
        model.email = "";
        model.emailError = "";
        model.role = "0";
        model.roleError = "";

        this.state.csvData.push(model);

        var model = {};
        model.count = this.state.csvData.length;
        model.firstName = "";
        model.firstNameError = "";
        model.lastName = "";
        model.lastNameError = "";
        model.email = "";
        model.emailError = "";
        model.role = "0";
        model.roleError = "";

        this.state.csvData.push(model);

        var model = {};
        model.count = this.state.csvData.length;
        model.firstName = "";
        model.firstNameError = "";
        model.lastName = "";
        model.lastNameError = "";
        model.email = "";
        model.emailError = "";
        model.role = "0";
        model.roleError = "";

        this.state.csvData.push(model);
        this.setState(this.state);
    }

    componentDidUpdate() {
        this.props.dispatch(usersActions.initiateState());
    }

    render() {

        const { IsImportUserModalOpen } = this.state;
        const { res } = this.props;


        if (res != null && res.success === "1") {

            toastr.success("Success !", res.message);
            return <Redirect to='/users' ></Redirect>;
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        const header = { 'display': 'flex', 'height': '90px' }

        return (
            <div className="container">
                {this.renderRedirect()}
                {/* <div style={header}>
                    <h1 className="page-title">Add Users</h1>
                </div> */}
               

                <div className="detail-title">
                    <Link to='/users' className="btn btn-default">
                        <i className="la la-mail-reply"></i> BACK
                    </Link>
                    <h1>Add Users</h1>
                </div>


                <div className="action-row clearfix add-action-row">
                    <div className=" btn-left">
                        <button className="btn btn-default save-btn" onClick={this.saveUsers}><i className="la la-check-circle"></i> Save</button>
                    </div>
                    <div className="btn-right">
                        <button className="btn btn-default black-btn" onClick={this.openImportUserModal}>
                            <i className="la la-sign-in"></i> IMPORT
                        </button>
                    </div>
                </div>
                <div className="form-container add-user-container">
                    <h3 className="form-title">
                        Who would you like to add?
                        </h3>

                    {this.state.csvData.map((item, index) => (
                        <div className="row" key={item.count}>
                            <div className="col-md-3 form-group">
                                <label>FIRST NAME</label>
                                <input type="text" id="firstName" className="form-control" value={item.firstName} data-count={item.count} onChange={this.handleChange} />
                                {item.internalCounter > 0  && item.firstNameError !== "" && <span className={item.firstNameError === "" ? "hidden" : "has-error"}>{item.firstNameError}</span>}
                            </div>
                            <div className="col-md-3 form-group">
                                <label>LAST NAME</label>
                                <input type="text" id="lastName" className="form-control" value={item.lastName} data-count={item.count} onChange={this.handleChange} />
                                {item.internalCounter > 0 && item.lastNameError !== "" && <span className={item.lastNameError === "" ? "hidden" : "has-error"}>{item.lastNameError}</span>}
                            </div>
                            <div className="col-md-3 form-group">
                                <label>EMAIL</label>
                                <input type="text" id="email" className="form-control" value={item.email} data-count={item.count} onChange={this.handleChange} />
                                {item.internalCounter > 0 && item.emailError !== "" && <span className={item.emailError === "" ? "hidden" : "has-error"}>{item.emailError}</span>}
                            </div>
                            <div className="col-md-2 form-group">
                                <label>ROLE</label>
                                <select id="role" className="form-control" value={item.role} data-count={item.count} onChange={this.handleChange}>
                                    <option value="0" >--Select Role--</option>
                                    <option value="user" >User</option>
                                    <option value="admin">Admin</option>
                                </select>
                                {item.internalCounter > 0 && item.roleError !== "" && <span className={item.roleError === "" ? "hidden" : "has-error"}>{item.roleError}</span>}
                            </div>
                            <div className="col-md-1 form-group remove-user-add-row">
                                <a href="javascript:void(0);" data-index={index} className="remove-more-btn pull-right" onClick={this.removeUser}>
                                    <i style={{lineHeight: '30px'}} className="la la-close" data-index={index}></i>
                                </a>
                            </div>
                        </div>
                    ))}

                    <button className="btn btn-default black-btn add-more-btn" onClick={this.addUser} style={{fontWeight: 700}}>Add more lines</button>
                </div>

                <Modal open={IsImportUserModalOpen} center showCloseIcon={false} onClose={this.closeImportUserModal}>
                    <ImportUserModal closeDeleteConfirmModal={this.closeImportUserModal} submitFile={this.handleForce} count={this.state.csvData.length - 1} />
                </Modal>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.usersReducer.loading,
    error: state.usersReducer.error,
    res: state.usersReducer.res
});

export default connect(mapStateToProps)(AddUser);