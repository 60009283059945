import { ENCRYPTION_KEY } from '../../constants/constants';
import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr'
import * as usersActions from '../../actions/usersActions';
import * as contentActions from '../../actions/contentActions';
import * as pagesActions from '../../actions/pagesActions';
import * as buActions from '../../actions/buActions';
import * as audioActions from '../../actions/audioActions';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class DeleteConfirmModal extends Component {

    constructor() {
        super();
        this.state = {
            IsImportUserModalOpen: false,
            csvData: []
        }

        this.deleteRecord = this.deleteRecord.bind(this);
    }

    deleteRecord() {
        switch (this.props.action) {
            case "user-delete":
                this.props.dispatch(usersActions.DeleteUser(this.props.Id));
                break;
            case "multiple-user-delete":
                this.props.dispatch(usersActions.DeleteUser(this.props.selectedUsers));
                break;
            case "page-delete":
                this.props.dispatch(pagesActions.DeletePage(this.props.Id));
                break;
            case "multiple-page-delete":
                this.props.dispatch(pagesActions.DeletePage(this.props.selectedPages));
                break;
            case "page-delete-from-detail":
                this.props.dispatch(pagesActions.DeletePage(this.props.Id));
                break;
            case "bu-delete":
            case "bu-delete-from-detail":
                this.props.dispatch(buActions.DeleteBU(this.props.Id));
                break;
            case "multiple-bu-delete":
                this.props.dispatch(buActions.DeleteBulkBU(this.props.selectedBU));
                break;
            case "content-delete":
                var model = {};
                model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
                model.folder_id = [];
                if (this.props.content_type == 'files') {
                    model.content_id = [];
                    model.content_id.push(this.props.Id);
                    this.props.dispatch(contentActions.DeleteContent(model));
                }
                else {
                    model.folder_id = [];
                    model.folder_id.push(this.props.Id);
                    this.props.dispatch(contentActions.DeleteFolder(model));
                }
                break;
            case "content-delete-from-detail":
                var model = {};
                model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
                model.folder_id = [];
                model.content_id = [];
                model.content_id.push(this.props.Id);
                this.props.dispatch(contentActions.DeleteContent(model));
                break;
            case "multiple-content-delete":
                var model = {};
                model.folder_id = [];
                model.content_id = [];
                this.props.selectedContents.forEach(c => {
                    if (c.type == 'folder') {
                        model.folder_id.push(c.id);
                    } else {
                        model.content_id.push(c.id);
                    }
                });
                model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
                this.props.dispatch(contentActions.DeleteContent(model));
                break;
            case "audio-delete":
                var model = {};
                model.id = [];
                model.id.push(this.props.Id);
                model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
                this.props.dispatch(audioActions.DeleteAudio(model));
                break;
            case "multiple-audio-delete":
                var model = {};
                model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
                model.id = this.props.selectedAudio;
                this.props.dispatch(audioActions.DeleteAudio(model));
                break;
            case "audio-specialty-delete":
                var model = {};
                model.id = [];
                model.id.push(this.props.Id);
                model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
                this.props.dispatch(audioActions.DeleteSpecialtyAudio(model));
                break;

            case "audio-detail-specialty-delete":
                var model = {};
                model.id = [];
                model.id.push(this.props.Id);
                model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
                this.props.dispatch(audioActions.DeleteSpecialtyAudio(model));
                break;
            case "audio-stackholder-delete":
                var model = {};
                model.id = [];
                model.id.push(this.props.Id);
                model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
                this.props.dispatch(audioActions.DeleteStackholderAudio(model));
                break;
            case "audio-detail-stackholder-delete":
                var model = {};
                model.id = [];
                model.id.push(this.props.Id);
                model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
                this.props.dispatch(audioActions.DeleteStackholderAudio(model));
                break;

            case "subaudio-delete":
                var model = {};
                model.id = [];
                model.id.push(this.props.Id);
                model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
                this.props.dispatch(audioActions.DeleteSubAudio(model));
                break;

            case "delete-product":
                var model = {};
                model.id = this.props.id;
                model.type = this.props.type;
                model.product_id = this.props.product_id;
                model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
                this.props.dispatch(audioActions.DeleteProduct(model));
                break;

            case "page-content-delete-from-detail":
                var model = {};
                model.page_id = this.props.Id;
                model.content_id = this.props.PageContentId;
                this.props.dispatch(pagesActions.DeletePageContent(model));
                break;

        }
    }

    componentDidUpdate() {
        this.props.dispatch(usersActions.initiateState());
    }

    render() {

        const { res } = this.props;
        if (res != null && res.success === "1") {

            switch (this.props.action) {
                case "user-delete":
                    this.props.removeItems([this.props.Id]);
                    this.props.closeDeleteConfirmModal();
                    break;
                case "multiple-user-delete":
                    this.props.clearUserSelection();
                    this.props.removeItems(this.props.selectedUsers);
                    this.props.closeDeleteConfirmModal();
                    break;
                case "page-delete":
                    this.props.removeItems([this.props.Id]);
                    this.props.closeDeleteConfirmModal();
                    break;
                case "page-delete-from-detail":
                    this.props.setRedirect();
                    this.props.closeDeleteConfirmModal();
                    break;
                case "multiple-page-delete":
                    this.props.clearPageSelection();
                    this.props.removeItems(this.props.selectedPages);
                    this.props.closeDeleteConfirmModal();
                    break;
                case "bu-delete":
                    this.props.removeItems([this.props.Id]);
                    this.props.closeDeleteConfirmModal();
                    break;
                case "bu-delete-from-detail":
                    this.props.setRedirect();
                    this.props.closeDeleteConfirmModal();
                    break;
                case "multiple-bu-delete":
                    this.props.clearBUSelection();
                    this.props.removeItems(this.props.selectedBU);
                    this.props.closeDeleteConfirmModal();
                    break;
                case "content-delete":
                    this.props.removeItems([this.props.Id]);
                    this.props.closeDeleteConfirmModal();
                    break;
                case "multiple-content-delete":
                    this.props.removeItems(this.props.selectedContents);
                    this.props.closeDeleteConfirmModal();
                    break;
                case "content-delete-from-detail":
                    var model = {};
                    model.content_type = this.props.type;
                    model.sort_by = this.props.sortby;
                    model.order_by = this.props.orderby;
                    this.props.onDeleteSuccess();
                    this.props.dispatch(contentActions.GetContents(model));
                    break;
                case "audio-delete":
                    this.props.removeItems([this.props.Id]);
                    this.props.closeDeleteConfirmModal();
                    break;
                case "multiple-audio-delete":
                    this.props.clearAudioSelection();
                    this.props.removeItems(this.props.selectedAudio);
                    this.props.closeDeleteConfirmModal();
                    break;
                case "audio-specialty-delete":
                    this.props.removeItems([this.props.Id]);
                    this.props.closeDeleteConfirmModal();
                    break;
                case "audio-detail-specialty-delete":
                    this.props.onDeleteSuccess();
                    break;
                case "audio-stackholder-delete":
                    this.props.removeItems([this.props.Id]);
                    this.props.closeDeleteConfirmModal();
                    break;
                case "audio-detail-stackholder-delete":
                    this.props.onDeleteSuccess();
                    break;
                case "subaudio-delete":
                    this.props.removeItems([this.props.Id]);
                    this.props.closeDeleteConfirmModal();
                    break;
                case "delete-product":
                    this.props.onDeleteSuccess();
                    break;
                case "page-content-delete-from-detail":
                    this.props.removeItems([this.props.PageContentId]);
                    this.props.closeDeleteConfirmModal();
                    break;
            }
            if (this.props.action !== "content-delete-from-detail" && this.props.action !== "page-delete-from-detail" && this.props.action !== "subaudio-delete" && this.props.action !== "delete-product" && this.props.action !== "page-content-delete-from-detail") {
                toastr.success("Success !", res.message);
            }
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        return (
            <div className="addcontent_modal">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{this.props.title}</h4>
                        </div>
                        <div className="modal-body">
                            <h2 className="modal-para">
                                <p>{this.props.message}</p>
                                <p><b>This cannot be undone.</b></p>
                            </h2>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" onClick={this.props.closeDeleteConfirmModal}>CANCEL</button>
                            <button type="button" className="btn btn-primary btn-delete" onClick={this.deleteRecord}>delete</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.usersReducer.loading,
    error: state.usersReducer.error,
    res: state.usersReducer.res
});

export default connect(mapStateToProps)(DeleteConfirmModal);