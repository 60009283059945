import { returnResponse } from './baseReducer';

const initialState = {    
    loading: false,
    error: null,
    res: null,
    pagelist: {},
    pagecontentlist: {},
    contentassigned: [],
    pagebulist: {}
};

export default function pagesReducer(state = initialState, action) {
    return returnResponse(state, action);
}