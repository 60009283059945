import { BASE_URL } from '../../constants/constants';
import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import * as  pageActions from '../../actions/pagesActions';

class PageDetails extends Component {

    constructor() {
        super()
        this.state = {
            image_base_url: BASE_URL.replace("api/v7/", "") + "uploads/pages/",
            pageDetail: {},
            redirect: false,
            search: '',
            isSearch: false
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.keyPress = this.keyPress.bind(this);    
    }

    keyPress(e) {        
        var model = {};
        model.page_id = this.state.pageDetail.id;
        model.search = this.state.search;
        this.props.dispatch(pageActions.GetPageContents(model));
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/pages' />
        }
    }

    componentDidMount() {
        if (this.props.location.state !== undefined) {            
            this.setState({ pageDetail: this.props.location.state.item });
            var model = {};
            model.page_id = this.props.location.state.item.id;
            this.props.dispatch(pageActions.GetPageContents(model));
        }
        else {
            this.setRedirect();
        }        
    }

    handleSearchChange(e) {
        if (e.target.name === "search") {
            this.setState({ search: e.target.value });
        }
    }

    search() {
        this.state.isSearch = !this.state.isSearch;
        if (this.state.isSearch) {
            this.searchInput.focus();
        }
        else {
            this.setState({ search: '' });
            var model = {};
            model.page_id = this.state.pageDetail.id;
            model.search = '';
            this.props.dispatch(pageActions.GetPageContents(model));
        }        
    }

    render() {

        const { pageDetail, image_base_url, search, isSearch } = this.state;
        const { contentassigned } = this.props;
        
        const bytesToSize = (bytes) => {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes === 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
        }

        contentassigned.map(x => {
            x.computedSize = bytesToSize(x.file_size)
        });

        contentassigned.map(x => {
            if (x.file_name !== null) {
                x.extension = x.file_name.slice(x.file_name.indexOf('.')).replace('.', '')
            }
        });      

        const pointer = { cursor: 'pointer' };
        const header = { 'display': 'flex', 'height': '90px' };

        return (
            <div className="container">
                {this.renderRedirect()}

                {/* <div style={header}>
                    <h1 className="page-title">Page Detail</h1>
                </div> */}

                <div className="detail-title">
                    <Link to='/pages' className="btn btn-default">
                        <i className="la la-mail-reply"></i> BACK
                    </Link>
                    <h1>Page Detail</h1>
                </div>

                <div className="action-row clearfix add-action-row">
                    <div className="btn-left">
                        {/* onClick={this.setRedirect} <button className="btn btn-default btn-border"><i className="la la-mail-reply"></i> BACK</button> */}

                        <Link to={{ pathname: '/pages/edit', state: { action: 'edit', id: pageDetail.id, item: pageDetail } }} className="btn btn-default black-btn">
                            <i className="la la-pencil"></i> Edit
                        </Link>  
                    </div>

                    <div className="btn-right">
                                                
                    </div>
                </div>
                <div className="form-container pageeditor_block">
                    <div className="col-md-6 detail-new-block">
                        <div className="page_info">
                            <div className="page_thumb">
                                {pageDetail.page_logo !== '' && <img src={image_base_url + pageDetail.page_logo} alt="Page Thumb" className="img-responsive" />}
                                {pageDetail.page_logo === '' && <img src="../images/camera.png" alt="Page Thumb" className="img-responsive"/>}
                            </div>
                            <h3 className="page_title">{pageDetail.name}</h3>
                            <h4 className="sub-title">{pageDetail.subtitle}</h4>                          
                            <p>{pageDetail.description}</p>
                            <ul className="hastag_list">
                                {pageDetail.tags !== undefined && pageDetail.tags !== '' && pageDetail.tags.split(',').map((tag, index) => (
                                    <li key={index}>{tag}{index < pageDetail.tags.split(',').length-1 && <span>,</span>}</li>    
                                    ))}                                
                            </ul>
                        </div>
                        <div className="edit_page_block">
                            <div className="action-row clearfix">
                                <h3 className="page_title">Content</h3>
                                <div className="search-form search-form-modal">
                                    <input ref={(searchInput) => { this.searchInput = searchInput; }}
                                        type="search" className="form-control" value={search} name="search"
                                        onKeyUp={this.keyPress} onChange={this.handleSearchChange} />
                                    <button type="submit" className="search-btn pageDetailSearch" onClick={this.search}>
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            {contentassigned !== null && contentassigned.length > 0 &&
                                <div className="pages-list mCustomScrollbar" data-mcs-theme="dark">
                                    <ul>
                                        {contentassigned.map((item, index) => (
                                        <li key={index} className="page-content-item">
                                            <Link  to={{ pathname: '/content/details?content_id=' + item.id, state: { item: item } }} target="_blank">
                                                <label style={{ cursor: 'pointer' }}>
                                                    {item.content_type === 'folder' && item.is_child === true && <img src="../images/content-file.png" style={pointer} className="file-img img-responsive" data-id={item.id} data-name={item.content_name} onClick={this.addParent} />}
                                                    {/* {item.content_type !== 'folder' && item.content_type === 'videos' && item.extension === 'mp4' && <img src="../images/content-video.png" className="file-img img-responsive" />}
                                                    {item.content_type !== 'folder' && item.content_type === 'files' && item.extension === 'mp3' && <img src="../images/content-video.png" className="file-img img-responsive" />}
                                                    {item.content_type !== 'folder' && item.content_type === 'files' && item.extension === 'xlsx' && <img src="../images/excel.png" className="file-img img-responsive" />}
                                                    {item.content_type !== 'folder' && item.content_type === 'files' && item.extension === 'docx' && <img src="../images/word.png" className="file-img img-responsive" />}
                                                    {item.content_type !== 'folder' && item.path !== null && item.content_type === 'files' && (item.extension !== 'mp4' && item.extension !== 'mp3' && item.extension !== 'xlsx' && item.extension !== 'docx') && <img src={image_base_url + item.path + item.file_name} className="file-img img-responsive" onError={(e) => { e.target.onerror = null; e.target.src = "../images/no-image.png" }} />}
                                                    {item.content_type !== 'folder' && item.path === null && item.content_type === 'files' && (item.extension !== 'mp4' && item.extension !== 'mp3' && item.extension !== 'xlsx' && item.extension !== 'docx') && <img src={image_base_url + item.file_name} className="file-img img-responsive" onError={(e) => { e.target.onerror = null; e.target.src = "../images/no-image.png" }} />} */}
                                                    {item.content_type === 'files' && item.file_name === '' && <img src="../images/no-image.png" className="img-responsive" />}
                                                    {item.content_type === 'files' && item.file_name !== '' && (item.extension === 'png' || item.extension === 'jpg' || item.extension === 'jpeg' || item.extension === 'pjpeg') && <img src={item.file_path} className="img-responsive" />}
                                                    {item.content_type === 'files' && item.file_name !== '' && (item.extension !== 'png' && item.extension !== 'jpg' && item.extension !== 'jpeg' && item.extension !== 'pjpeg') && <img src="../images/file-icon-48X48.png" className="img-responsive" />}
                                                    <span className="page-info">
                                                        <span className="page-name ellipsis">{item.content_name}</span>
                                                        <span className="ellipsis page-text">{item.computedSize}</span>
                                                    </span>
                                                </label>
                                            </Link>
                                                
                                            </li>
                                        ))}

                                    </ul>
                                </div>}
                            {contentassigned !== null && contentassigned.length === 0 && <div className="text-center">
                                {isSearch && <label className="lblNoRecord">Nothing matches that search. Sorry!</label>}
                                {!isSearch && <label className="lblNoRecord">No Record Found!</label>}
                            </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                );
    }
}

const mapStateToProps = state => ({
    loading: state.pagesReducer.loading,
    error: state.pagesReducer.error,
    contentassigned: state.pagesReducer.contentassigned   
});

export default connect(mapStateToProps)(PageDetails);