import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import store from './store/appStore';
import ReduxToastr from 'react-redux-toastr';
import 'babel-polyfill';

import './content/css/bootstrap.min.css';
import './content/css/font-awesome.min.css';
import './content/css/line-awesome.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import './content/css/style.css';
import './index.css';

import Dashboard from './components/dashboard';
import LoadingBar from 'react-redux-loading-bar'
import { } from 'react-router-dom';

const rootElement = document.getElementById("root");
ReactDOM.render(

    <Provider store={store}>
        <LoadingBar style={{ backgroundColor: '#009ddc', height: '3px' }} className="loading" />
        <ReduxToastr
            timeOut={3000}
            newestOnTop={false}
            preventDuplicates={false}
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar={true}
            showCloseButton={false}
            closeOnToastrClick={false} />
       
            <Dashboard />   
       
    </Provider>,
    rootElement
);