import { BEGIN, SUCCESS, FAILURE } from '../constants/constants';

export const fetchBegin = (action) => ({
    type: BEGIN,
    action: action
});

export const fetchSuccess = (res, action) => ({
    type: SUCCESS,
    action: action,
    payload: { res }
});

export const fetchFailure = (error, action) => ({
    type: FAILURE,
    action: action,
    payload: { error }
});