import { returnResponse } from './baseReducer';

const initialState = {    
    loading: false,
    error: null,
    bucpres: null,
    compititorlist: {}    
};

export default function compititorReducer(state = initialState, action) {
    return returnResponse(state, action);
}