import { ENCRYPTION_KEY } from '../../constants/constants';
import React, { Component } from "react";
import queryString from 'query-string';
import FileUpload from '../common/fileUpload';
import { connect } from 'react-redux';
import * as  audioActions from '../../actions/audioActions';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class AddUpdateAudioModal extends Component {

    constructor() {
        super();

        this.state = {
            id: null,
            audio: {
                name: '',
                description: '',
                type: 0,
                speciality_id: 0,
                stakeholder_id: 0,
                product_id: 0
            },
            validations: {
                name: '',
                description: '',
                type: '',
                speciality_id: '',
                stakeholder_id: '',
                product_id: '',
            },
            login_user_type: '',
            valid: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.action == 'update-audio') {
            this.state.audio.name = this.props.item.name;
            this.state.audio.description = this.props.item.description;
        }
    }

    onSuccessAdd = () => {
        this.props.onAddSuccess();
    }

    handleChange(e) {

        switch (e.target.id) {
            case "name":
                if (e.target.value === "") {
                    this.setState({ audio: { ...this.state.audio, name: e.target.value }, validations: { ...this.state.validations, name: 'Name is required' } });
                }
                else {
                    this.setState({ audio: { ...this.state.audio, name: e.target.value }, validations: { ...this.state.validations, name: '' } });
                }
                break;
            case "description":
                if (e.target.value === "") {
                    this.setState({ audio: { ...this.state.audio, description: e.target.value }, validations: { ...this.state.validations, description: 'Description is required' } });
                }
                else {
                    this.setState({ audio: { ...this.state.audio, description: e.target.value }, validations: { ...this.state.validations, description: '' } });
                }
                break;
            // case "type":
            //     if (e.target.value === "0") {
            //         this.setState({ audio: { ...this.state.audio, type: e.target.value }, validations: { ...this.state.validations, type: 'Type is required' } });
            //     }
            //     else {
            //         this.setState({ audio: { ...this.state.audio, type: e.target.value }, validations: { ...this.state.validations, type: '' } });
            //     }
            //     break;
            // case "speciality_id":
            //     if (e.target.value === "0") {
            //         this.setState({ audio: { ...this.state.audio, speciality_id: e.target.value }, validations: { ...this.state.validations, speciality_id: 'Specialty is required' } });
            //     }
            //     else {
            //         this.setState({ audio: { ...this.state.audio, speciality_id: e.target.value }, validations: { ...this.state.validations, speciality_id: '' } });
            //     }
            //     break;
            // case "stakeholder_id":
            //     if (e.target.value === "0") {
            //         this.setState({ audio: { ...this.state.audio, stakeholder_id: e.target.value }, validations: { ...this.state.validations, stakeholder_id: 'Stackholder is required' } });
            //     }
            //     else {
            //         this.setState({ audio: { ...this.state.audio, stakeholder_id: e.target.value }, validations: { ...this.state.validations, stakeholder_id: '' } });
            //     }
            //     break;
            // case "product_id":
            //     if (e.target.value === "0") {
            //         this.setState({ audio: { ...this.state.audio, product_id: e.target.value }, validations: { ...this.state.validations, product_id: 'Product is required' } });
            //     }
            //     else {
            //         this.setState({ audio: { ...this.state.audio, product_id: e.target.value }, validations: { ...this.state.validations, product_id: '' } });
            //     }
            //     break;
            default:
                break;
        }
    }

    render() {
        const { audio, validations, valid } = this.state;
        const { specialitylist, productlist, stackholderlist, queryParams, item, action, res } = this.props;

        if (action == 'update-audio') {
            this.state.valid = true;
        }
        else {
            if (this.state.audio.name != '' && this.state.audio.description != '') {
                this.state.valid = true;

            } else {
                this.state.valid = false;
            }
        }

        if (res != null && res.success === "1") {
            // toastr.success("Success !", res.message);
            var model = {};
            const values = this.props.queryParams;
            var model = {};
            model.sort_by = "name";
            model.order_by = "DESC";
            model.page = this.state.activePage;
            if (values.type == 'specialty') {
                model.speciality_id = values.id;
                model.stakeholder_id = '';
            } else if (values.type == 'stakeholder') {
                model.speciality_id = '';
                model.stakeholder_id = values.id;
            }
            model.product_id = values.product_id;
            model.search = '';
            this.props.dispatch(audioActions.GetAudios(model));
            // this.props.dispatch(audioActions.initiateState());
        }
        else if (res != null && res.success === "0") {
            // toastr.error('Error', res.message);
        }

        if (action == "update-audio") {
            var actionName = "Edit Talk Track Audio";
        }
        else {
            var actionName = "Add Talk Track Audio";
        }
        var desc = "Choose either of the methods below to upload talk track audio.";

        return (
            <div className="addcontent_modal audioadd-modal">
                <div className="modal-dialog" style={{ margin: '0px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{actionName}</h4>
                        </div>
                        <div className="modal-body">

                            <h2 className="modal-para modal-para-small">{desc}</h2>

                            <div className="form-group">
                                <label>Name</label>
                                <input type="text" value={audio.name} className="form-control" id="name" placeholder="Name" onChange={this.handleChange} />
                                {validations.name !== "" && <span className={validations.name === "" ? "hidden" : "has-error"}>{validations.name}</span>}
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <input type="text" value={audio.description} className="form-control" id="description" placeholder="Description" onChange={this.handleChange} />
                                {validations.name !== "" && <span className={validations.description === "" ? "hidden" : "has-error"}>{validations.description}</span>}
                            </div>
                            {/* <div className="form-group">
                                <label>Type</label>
                                <select className="form-control" id="type" value={audio.type} onChange={this.handleChange}>
                                    <option value="0">--Select Type--</option>
                                    <option value="st">Stackholder</option>
                                    <option value="sp">Specialty</option>
                                </select>
                                {validations.type !== 0 && <span className={validations.type === 0 ? "hidden" : "has-error"}>{validations.type}</span>}
                            </div>
                            <div className="form-group">
                                <label>Specialty</label>
                                <select className="form-control" id="speciality_id" value={audio.speciality_id} onChange={this.handleChange}>
                                    <option value="0">--Select Specialty--</option>
                                    {specialitylist && specialitylist.records.map((item) => (<option value={item.id}>{item.name}</option>))}
                                </select>
                                {validations.speciality_id !== 0 && <span className={validations.speciality_id === 0 ? "hidden" : "has-error"}>{validations.speciality_id}</span>}
                            </div>
                            <div className="form-group">
                                <label>Stackholder</label>
                                <select className="form-control" id="stakeholder_id" value={audio.stackholder_id} onChange={this.handleChange}>
                                    <option value="0">--Select Stackholder--</option>
                                    {stackholderlist && stackholderlist.records.map((item) => (<option value={item.id}>{item.options}</option>))}
                                </select>
                                {validations.stakeholder_id !== 0 && <span className={validations.stakeholder_id === 0 ? "hidden" : "has-error"}>{validations.stakeholder_id}</span>}
                            </div>
                            <div className="form-group">
                                <label>Product</label>
                                <select className="form-control" id="product_id" value={audio.product_id} onChange={this.handleChange}>
                                    <option value="0">--Select Product--</option>
                                    {productlist && productlist.products.map((item) => (<option value={item.id}>{item.name}</option>))}
                                </select>
                                {validations.product_id !== 0 && <span className={validations.product_id === 0 ? "hidden" : "has-error"}>{validations.product_id}</span>}
                            </div> */}
                            <FileUpload closeModal={this.props.closeAudioAddUpdateModal} action={this.props.action}
                                orderby={this.props.orderby}
                                sortby={this.props.sortby}
                                search={this.props.search}
                                page={this.props.page}
                                enableScroll={this.props.enableScroll}
                                getUpdatedDetails={this.props.getUpdatedDetails}
                                audio={audio}
                                id={action == "update-audio" ? this.props.id : 0}
                                onAddSuccess={this.onSuccessAdd}
                                onUpdateSuccess={this.props.onUpdateSuccess}
                                queryParams={queryParams}
                                valid={this.state.valid} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    error: state.audioReducer.error,
    res: state.audioReducer.res
});
export default connect(mapStateToProps)(AddUpdateAudioModal);