import {
    BEGIN, SUCCESS, FAILURE, ACTIVE_PAGE,
    ALL_USER_LIST, ADMIN_LIST, USER_LIST, CLEAR_USER_LIST,
    CLEAR_CONTENT_DETAILS, CONTENT_DETAILS, CONTENT_LIST, DOCUMENT_LIST, MEDIA_LIST, CONTENT_PAGE_LIST,
    PAGE_LIST, PAGE_CONTENT_LIST, CLEAR_PAGE_CONTENT_LIST, CLEAR_CONTENT_LIST,
    RES, LOGIN_DETAIL, LOGOUT, LOGIN,
    PAGE_CONTENT_ASSIGNED, CONTENT_PAGE_ASSIGNED, PAGE_BU_LIST,
    CLEAR_PAGE_LIST, CLEAR_CONTENT_PAGE_LIST,
    AUDIO_LIST, CLEAR_AUDIO_LIST, SPECIALITYLIST, STACKHOLDER_LIST, PRODUCT_LIST, AUDIO_BU_LIST, AUDIO_DETAILS, CLEAR_AUDIO_DETAILS, SUB_AUDIO_LIST,ADD_PRODUCT_CATEGORY,
    BU_DETAILS, CLEAR_BU_DETAILS, BU_LIST, CLEAR_BU_LIST, BU_SP_LIST, BU_CP_LIST, SP_RES, CP_RES, CLEAR_SP_LIST, CLEAR_CP_LIST, BU_SP_RES, BU_CP_RES, EDIT_BU_RES,
    SPECIALITY_LIST, CLEAR_SPECIALITY_LIST,
    COMPITITOR_LIST, CLEAR_COMPITITOR_LIST
} from '../constants/constants';

export function returnResponse(state, action) {
    switch (action.type) {
        case BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                res: null,
                spres: null,
                cpres: null,
                buspres: null,
                bucpres: null,
                editbures: null
            }
        case SUCCESS:

            switch (action.action) {

                case LOGIN:

                    return {
                        ...state,
                        loading: false,
                        logout: false,
                        logindetail: action.payload.res
                    }

                case LOGOUT:

                    return {
                        ...state,
                        loading: false,
                        logindetail: null,
                        logout: true
                    }

                case LOGIN_DETAIL:

                    return {
                        ...state,
                        loading: false,
                        logout: state.logout,
                        logindetail: state.logindetail
                    }

                case ACTIVE_PAGE:

                    return {
                        ...state,
                        activePage: action.payload.res
                    }

                case RES:
                    return {
                        ...state,
                        loading: false,
                        res: action.payload.res
                    }

                case ALL_USER_LIST:

                    return {
                        ...state,
                        loading: false,
                        role: 'all',
                        isScroll: true,
                        userlist: action.payload.res
                    }

                case ADMIN_LIST:
                    return {
                        ...state,
                        loading: false,
                        role: 'admin',
                        userlist: action.payload.res
                    }

                case USER_LIST:
                    return {
                        ...state,
                        loading: false,
                        role: 'user',
                        userlist: action.payload.res
                    }


                case CLEAR_USER_LIST:

                    return {
                        ...state,
                        res: null,
                        userlist: null
                    }

                case CLEAR_CONTENT_DETAILS:

                    return {
                        ...state,
                        loading: false,
                        details: null,
                        res: null
                    }
                case CONTENT_DETAILS:
                    return {
                        ...state,
                        loading: false,
                        details: action.payload.res.records
                    }
                case CONTENT_LIST:
                    return {
                        ...state,
                        loading: false,
                        contentlist: action.payload.res
                    }

                case CLEAR_CONTENT_LIST:
                    return {
                        ...state,
                        loading: false,
                        contentlist: null
                    }

                case DOCUMENT_LIST:
                    return {
                        ...state,
                        loading: false,
                        documentlist: action.payload.res
                    }

                case MEDIA_LIST:
                    return {
                        ...state,
                        loading: false,
                        medialist: action.payload.res
                    }

                case CONTENT_PAGE_ASSIGNED:
                    return {
                        ...state,
                        loading: false,
                        selectedpages: action.payload.res.records.selected_page.page_id !== null ? action.payload.res.records.selected_page.page_id.split(',') : ""
                    }

                case CONTENT_PAGE_LIST:
                    return {
                        ...state,
                        loading: false,
                        contentpagelist: action.payload.res
                    }

                case CLEAR_CONTENT_PAGE_LIST:
                    return {
                        ...state,
                        loading: false,
                        contentpagelist: null
                    }

                case PAGE_LIST:

                    return {
                        ...state,
                        loading: false,
                        pagelist: action.payload.res
                    }

                case CLEAR_PAGE_LIST:
                    return {
                        ...state,
                        loading: false,
                        pagelist: null
                    }

                case PAGE_CONTENT_LIST:

                    return {
                        ...state,
                        loading: false,
                        pagecontentlist: action.payload.res
                    }

                case PAGE_BU_LIST:

                    return {
                        ...state,
                        loading: false,
                        pagebulist: action.payload.res
                    }


                case PAGE_CONTENT_ASSIGNED:
                    return {
                        ...state,
                        loading: false,
                        contentassigned: action.payload.res.records.content
                    }

                case CLEAR_PAGE_CONTENT_LIST:
                    return {
                        ...state,
                        loading: false,
                        pagecontentlist: []
                    }

                case AUDIO_LIST:
                    return {
                        ...state,
                        loading: false,
                        audiolist: action.payload.res
                    }

                case SUB_AUDIO_LIST:
                    return {
                        ...state,
                        loading: false,
                        subAudiolist: action.payload.res
                    }

                case AUDIO_DETAILS:
                    return {
                        ...state,
                        loading: false,
                        details: action.payload.res
                    }

                case CLEAR_AUDIO_LIST:
                    return {
                        ...state,
                        loading: false,
                        audiolist: null
                    }

                case CLEAR_AUDIO_DETAILS:
                    return {
                        ...state,
                        loading: false,
                        audiolist: null,
                        details: null
                    }

                case SPECIALITYLIST:
                    return {
                        ...state,
                        loading: false,
                        specialitylist: action.payload.res
                    }

                case STACKHOLDER_LIST:
                    return {
                        ...state,
                        loading: false,
                        stackholderlist: action.payload.res
                    }

                case PRODUCT_LIST:
                    return {
                        ...state,
                        loading: false,
                        productlist: action.payload.res
                    }

                case BU_DETAILS:
                    return {
                        ...state,
                        loading: false,
                        details: action.payload.res
                    }

                case CLEAR_BU_DETAILS:

                    return {
                        ...state,
                        loading: false,
                        details: null,
                        res: null
                    }

                case BU_LIST:
                    return {
                        ...state,
                        loading: false,
                        bulist: action.payload.res
                    }

                case CLEAR_BU_LIST:
                    return {
                        ...state,
                        loading: false,
                        bulist: null
                    }

                case AUDIO_BU_LIST:
                    return {
                        ...state,
                        loading: false,
                        audiobulist: action.payload.res
                    }

                case CLEAR_SP_LIST:
                    return {
                        ...state,
                        res: false,
                        splist: null
                    }

                case CLEAR_CP_LIST:
                    return {
                        ...state,
                        res: false,
                        cplist: null
                    }

                case SPECIALITY_LIST:
                    return {
                        ...state,
                        loading: false,
                        specialitylist: action.payload.res
                    }

                case CLEAR_SPECIALITY_LIST:
                    return {
                        ...state,
                        loading: false,
                        specialitylist: null
                    }

                case COMPITITOR_LIST:
                    return {
                        ...state,
                        loading: false,
                        compititorlist: action.payload.res
                    }

                case CLEAR_COMPITITOR_LIST:
                    return {
                        ...state,
                        loading: false,
                        compititorlist: null
                    }

                case BU_SP_LIST:
                    return {
                        ...state,
                        loading: false,
                        splist: action.payload.res
                    }

                case EDIT_BU_RES:
                    return {
                        ...state,
                        loading: false,
                        editbures: action.payload.res
                    }

                case ADD_PRODUCT_CATEGORY:
                    return {
                        ...state,
                        loading: false,
                        res: action.payload.res
                    }

                case BU_SP_RES:
                    return {
                        ...state,
                        loading: false,
                        buspres: action.payload.res
                    }

                case BU_CP_RES:
                    return {
                        ...state,
                        loading: false,
                        bucpres: action.payload.res
                    }

                case SP_RES:
                    return {
                        ...state,
                        loading: false,
                        spres: action.payload.res
                    }

                case BU_CP_LIST:
                    return {
                        ...state,
                        loading: false,
                        cplist: action.payload.res
                    }

                case CP_RES:
                    return {
                        ...state,
                        loading: false,
                        cpres: action.payload.res
                    }

                default:
                    return state;
            }
        case FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
