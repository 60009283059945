import { ENCRYPTION_KEY } from '../../constants/constants';
import { BASE_URL, errOptions } from '../../constants/constants'
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import InfiniteScroll from "../common/infinitescroll";
import AddToContentModal from '../pages/addToContentModal';

import DeleteConfirmModal from '../common/deleteConfirmModal';
import AddUpdateAudioModal from './addUpdateAudioModal';
import SubAudioActions from './subAudioActions';
import AudioFilter from './audioFilter';
import queryString from 'query-string';

import * as  audioActions from '../../actions/audioActions';
import * as menuActions from '../../actions/menuActions';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    arrayMove,
} from 'react-sortable-hoc';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class SubAudio extends Component {

    constructor() {
        super();
        this.state = {
            audio_base_url: BASE_URL.replace("api/v7/", "") + "uploads/audio/",
            selectedAudio: [],
            IsDeleteConfirmModalOpen: false,
            IsAudioAddUpdateModelOpen: false,
            order_by: 'DESC',
            sort_by: 'name',
            search: '',
            isSelectAll: false,
            activePage: 1,
            isSearch: false,
            items: [],
            isScroll: true,
            totalRecords: null,
            hasMore: true,
            randomError: '',
            IsAddToContentsModalOpen: false,
            strexcontent: [],
            content: []
        }

        this.audioSelection = this.audioSelection.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.clearAudioSelection = this.clearAudioSelection.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.removeItems = this.removeItems.bind(this);
        this.enableScroll = this.enableScroll.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.appendExistingContent = this.appendExistingContent.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.setStates = this.setStates.bind(this);
        this.onAddSuccess = this.onAddSuccess.bind(this);
        this.onUpdateSuccess = this.onUpdateSuccess.bind(this);
    }

    play(e) {
        this.refs[e.target.dataset.id].currentTime = 0;
        this.refs[e.target.dataset.id].play();
        this.state.items.map(item => {
            if (item.id == e.target.dataset.id) {
                item.status = 'play';
            } else {
                this.refs[item.id].pause();
                item.status = 'pause';
            }
        });
        this.setState(this.state);
    }

    changeTab(e) {
        var tab = e.target.dataset.tab;
        this.setState({ selectedTab: tab });
        this.setState({ type: tab, activePage: 1, search: '', items: [], isScroll: true, selectedAudio: [], totalRecords: null, hasMore: true });
    }

    pause(e) {
        this.refs[e.target.dataset.id].pause();
        this.state.items.map(item => {
            if (item.id == e.target.dataset.id) {
                item.status = 'pause';
            }
        });
        this.setState(this.state);
    }

    generateRandomMesage(randomError) {
        var str = errOptions[Math.floor(Math.random() * Math.floor(5))];
        if (randomError == str) {
            str = this.generateRandomMesage();
        }
        return str;
    }

    openAddToContentsModal = () => {
        this.setState({ IsAddToContentsModalOpen: true });
    };

    closeAddToContentsModal = () => {
        this.setState({ IsAddToContentsModalOpen: false });
    };

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    openAduioAddUpdateModal = () => {
        this.setState({ IsAudioAddUpdateModelOpen: true });
    };

    closeAudioAddUpdateModal = () => {
        this.setState({ IsAudioAddUpdateModelOpen: false });
    };

    onAddSuccess() {
        this.state.isScroll = true;
        this.state.items = [];
        this.componentDidMount();
        // this.setStates();
        // this.state.item = [];
        // const values = this.props.queryParams;
        // var model = {};
        // model.sort_by = this.state.sort_by;
        // model.order_by = this.state.order_by;
        // model.page = this.state.activePage;
        // if (values.type == 'specialty') {
        //     model.speciality_id = values.id;
        //     model.stakeholder_id = '';
        // } else if (values.type == 'stakeholder') {
        //     model.speciality_id = '';
        //     model.stakeholder_id = values.id;
        // }
        // model.product_id = values.product_id;
        // model.search = '';
        // this.props.dispatch(audioActions.GetAudios(model));
    }

    setStates() {
        this.state.activePage = 1;
        this.state.order_by = this.state.order_by;
        this.state.isScroll = true;
        this.state.sort_by = this.state.sort_by;
        this.state.totalRecords = null;
        this.state.hasMore = true;
    }

    componentDidMount() {
        const values = this.props.queryParams;
        var model = {};
        model.sort_by = this.state.sort_by;
        model.order_by = this.state.order_by;
        model.page = this.state.activePage;
        if (values.type == 'specialty') {
            model.speciality_id = values.id;
            model.stakeholder_id = '';
        } else if (values.type == 'stakeholder') {
            model.speciality_id = '';
            model.stakeholder_id = values.id;
        }
        model.product_id = values.product_id;
        model.search = '';
        this.props.dispatch(audioActions.GetAudios(model));
    }

    componentWillReceiveProps() {
        if (this.props.subAudiolist && this.props.subAudiolist.records && this.props.subAudiolist.records.length === 0 && this.state.items.length === 0) {
            this.setState({ noRecordsFound: true });
        } else if (this.props.subAudiolist && this.props.subAudiolist.records && this.props.subAudiolist.records.length > 0 && this.state.items.length === 0) {
            this.setState({ noRecordsFound: false });
        }
        // this.props.dispatch(buActions.ClearBUList());
    }

    changeOrder(e) {
        const values = this.props.queryParams;
        var order_by = "";
        if (this.state.sort_by === e.target.dataset.sort_by) {
            order_by = this.state.order_by === "DESC" ? "ASC" : "DESC";
        }
        else {
            order_by = "DESC";
        }

        this.setState({ order_by: order_by, sort_by: e.target.dataset.sort_by, isScroll: true, selectedAudio: [], totalRecords: null, hasMore: true });
        this.state.items = [];
        this.state.activePage = 1;

        var model = {};
        model.sort_by = e.target.dataset.sort_by;
        model.order_by = order_by;
        model.page = 1;
        model.page = this.state.activePage;
        if (values.type == 'specialty') {
            model.speciality_id = values.id;
            model.stakeholder_id = '';
        } else if (values.type == 'stakeholder') {
            model.speciality_id = '';
            model.stakeholder_id = values.id;
        }
        model.product_id = values.product_id;
        model.search = this.state.search;
        this.props.dispatch(audioActions.GetAudios(model));
    }

    audioSelection(e) {
        var id = e.target.id;
        if (e.target.checked) {
            if (this.state.selectedAudio.length + 1 === this.state.items.length) {
                this.state.selectedAudio.push(id);
                this.state.isSelectAll = true;
                this.setState(this.state);
            }
            else {
                this.state.selectedAudio.push(id);
                this.setState(this.state);
            }
        }
        else {
            this.setState({
                isSelectAll: false,
                selectedAudio: this.state.selectedAudio.filter((id) => id !== id)
            });
        }
    }

    selectAll(e) {
        if (e.target.checked) {
            this.state.selectedAudio = this.state.items.map((item) => {
                return item.id;
            });
            this.state.isSelectAll = true;
        }
        else {
            this.state.selectedAudio = [];
            this.state.isSelectAll = false;
        }
        this.setState(this.state);
    }

    handleSearchChange(e) {
        if (e.target.name === "search") {
            this.setState({ search: e.target.value });
        }
    }

    search() {
        this.state.isSearch = !this.state.isSearch;
        if (this.state.isSearch) {
            this.searchInput.focus();
        }
        else {
            const values = this.props.queryParams;
            this.setState({ activePage: 1, search: '', items: [], isScroll: true, totalRecords: null, hasMore: true });
            var model = {};
            model.sort_by = this.state.sort_by;
            model.order_by = this.state.order_by;
            model.page = this.state.activePage;
            if (values.type == 'specialty') {
                model.speciality_id = values.id;
                model.stakeholder_id = '';
            } else if (values.type == 'stakeholder') {
                model.speciality_id = '';
                model.stakeholder_id = values.id;
            }
            model.product_id = values.product_id;
            model.search = '';
            model.page = this.state.activePage;
            this.props.dispatch(audioActions.GetAudios(model));
        }
    }

    clearAudioSelection() {
        this.state.isSelectAll = false;
        this.state.selectedAudio = [];
        this.setState(this.state);
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber, selectedAudio: [], isSelectAll: false });
        var model = {};
        model.sort_by = this.state.sort_by;
        model.order_by = this.state.order_by;
        if (this.state.search.toLowerCase() !== "") {
            model.search = this.state.search.toLowerCase();
        }
        model.page = pageNumber;
        this.props.dispatch(audioActions.GetAudios(model));
        window.scrollTo(0, 0);
    }

    fetchMoreData() {
        const values = this.props.queryParams;
        this.state.activePage += 1;
        this.state.isScroll = true;
        var model = {};
        model.sort_by = this.state.sort_by;
        model.order_by = this.state.order_by;
        if (this.state.search.toLowerCase() !== "") {
            model.search = this.state.search.toLowerCase();
        }
        model.page = this.state.activePage;
        if (values.type == 'specialty') {
            model.speciality_id = values.id;
            model.stakeholder_id = '';
        } else if (values.type == 'stakeholder') {
            model.speciality_id = '';
            model.stakeholder_id = values.id;
        }
        model.product_id = values.product_id;
        model.search = '';
        this.props.dispatch(audioActions.GetAudios(model));
    }

    componentDidUpdate(prevProps, prevState) {

    }

    appendExistingContent(exContent) {
        exContent.map(item => {
            if (this.state.content.filter(x => x.id === item.id).length === 0) {
                this.state.content.push(item);
            }
            if (this.state.content.filter(x => x.id === item.id).length === 0) {
                this.state.content.push(item);
            }
        });
        this.setState(this.state);
    }

    removeFile(e) {
        var isDelete = e.target.dataset.delete;
        if (isDelete) {
            var id = parseInt(e.target.dataset.id);
            this.state.deletecontent.push(id);
        }
        if (id !== undefined) {
            var id = parseInt(e.target.dataset.id);
            var index = this.state.items.findIndex(x => x.id === id);
            this.state.items.splice(index, 1);
        }
        var i = parseInt(e.target.dataset.index);
        this.state.content.splice(i, 1);
        this.setState(this.state);
    }

    removeItems(items) {

        if (items[0].id) {
            items.forEach(id => {
                var index = this.state.items.findIndex(x => x.id == id);
                this.state.items.splice(index, 1);
            });
        } else {
            items.forEach(id => {
                var index = this.state.items.findIndex(x => x.id == id);
                this.state.items.splice(index, 1);
            });
        }

        this.state.totalRecords--;
    }

    onUpdateSuccess(itemData) {
        var index = this.state.items.findIndex(x => x.id == itemData.id);
        let items = [...this.state.items];
        let item = { ...items[index] };
        item.name = itemData.name;
        item.description = itemData.description;
        items[index] = item;
        this.setState({ items });
    }

    keyPress(e) {
        const values = this.props.queryParams;
        var randomError = this.generateRandomMesage(this.state.randomError);
        this.setState({ search: e.target.value.toLowerCase(), items: [], isScroll: true, totalRecords: null, hasMore: true, randomError: randomError });
        this.state.activePage = 1;
        var model = {};
        this.state.search = e.target.value.toLowerCase();
        model.sort_by = this.state.sort_by;
        model.order_by = this.state.order_by;
        model.page = this.state.activePage;
        if (values.type == 'specialty') {
            model.speciality_id = values.id;
            model.stakeholder_id = '';
        } else if (values.type == 'stakeholder') {
            model.speciality_id = '';
            model.stakeholder_id = values.id;
        }
        model.product_id = values.product_id;
        model.search = e.target.value.toLowerCase();
        this.props.dispatch(audioActions.GetAudios(model));

    }

    enableScroll() {
        this.setState({ items: [], isScroll: true, selectedAudio: [], totalRecords: null, hasMore: true });
    }

    render() {

        const { activePage, isSelectAll, audio_base_url, sort_by, order_by, selectedAudio, IsAudioAddUpdateModelOpen, IsDeleteConfirmModalOpen, search, isSearch,
            isScroll, items, totalRecords, hasMore, randomError, IsAddToContentsModalOpen, content, strexcontent } = this.state;
        const { loading, error, subAudiolist, specialitylist, stackholderlist, productlist, queryParams, res } = this.props;

        if (subAudiolist !== null && subAudiolist.records !== null && subAudiolist.records !== undefined) {
            subAudiolist.records.map(x => {
                if (x.file_name !== null) {
                    x.extension = x.file_name.slice(x.file_name.lastIndexOf('.')).replace('.', '')
                }
            });
        }

        if (!loading && isScroll && subAudiolist != null && subAudiolist.records && subAudiolist.records.length > 0 && this.state.items.length < subAudiolist.total_records) {
            if (!items.find(x => x.id == subAudiolist.records[0].id)) {
                var hasMoreRef = ((items.length + subAudiolist.records.length) == subAudiolist.total_records) ? false : true;
                var selectedUserList = [];
                if (isSelectAll) {
                    selectedUserList = subAudiolist.records.map((item) => {
                        return item.id;
                    });
                }
                this.setState({
                    isScroll: false,
                    totalRecords: subAudiolist.total_records,
                    // selectedUsers: selectedUsers.concat(selectedUserList),
                    items: items.concat(subAudiolist.records),
                    hasMore: hasMoreRef
                });

            }
        }

        const loadingClass = { 'textAlign': 'center', 'fontSize': '30px' };

        const wordBreak = {
            wordBreak: 'break-all',
            whiteSpace: 'pre-line'
        };

        // const DragHandle = SortableHandle(() => <span className="pull-right"><i class="la la-bars"></i></span>);

        // const SortableItem = SortableElement(({ file, index }) => {

        //     if (file.id !== undefined) {
        //         return (
        //             <li key={file.index}>
        //                 <DragHandle />
        //                 <a data-index={file.index} data-id={file.id} data-delete={file.assigned} className="remove-file" onClick={this.removeFile}><i data-delete={file.assigned} data-index={file.index} data-id={file.id} className="la la-times-circle"></i></a>
        //                 <div className="file-info">
        //                     <p className="file-name">{file.name}</p>
        //                     <ul className="file-progress">
        //                         <li>{file.computedSize}</li>
        //                         {file.existing === true && <li className="text-info"><i className="la la-cube"></i> Selected from Content </li>}
        //                         {(file.uploaded === true || file.assigned === true) && <li className="text-success"><i className="la la-check-circle"></i> uploaded </li>}
        //                         {/*file.assigned === true && <li className="text-warning"><i className="la la-check-circle"></i> Assigned </li>*/}
        //                     </ul>
        //                 </div>
        //             </li>
        //         );
        //     }
        //     else {
        //         return (
        //             <li key={file.index}>
        //                 <DragHandle />
        //                 <a data-index={file.index} className="remove-file" onClick={this.removeFile}><i data-index={file.index} className="la la-times-circle"></i></a>
        //                 <div className="file-info">
        //                     <p className="file-name">{file.name}</p>
        //                     <ul className="file-progress">
        //                         <li>{file.computedSize}</li>
        //                         {file.loaded > 0 && file.loaded < 100 && <li>{file.loaded}%... </li>}
        //                         {file.uploaded === true && <li className="text-success"><i className="la la-check-circle"></i> uploaded </li>}
        //                         {file.error === true && <li className="text-danger"><i className="la la-exclamation-triangle"></i> {file.errorMsg} <a href="javascript:void(0);" data-index={file.index} onClick={this.reUpload} className="btn-retry text-danger">Retry</a></li>}
        //                         {file.existing === true && <li className="text-info"><i className="la la-check-circle"></i> Selected from Content </li>}

        //                     </ul>
        //                 </div>
        //                 <div className="progress">
        //                     <div className="progress-bar" role="progressbar" aria-valuenow={file.loaded}
        //                         aria-valuemin="0" aria-valuemax="100" style={{ width: '' + file.loaded + '%' }}>
        //                         <span className="sr-only">{file.loaded}% Complete</span>
        //                     </div>
        //                 </div>
        //             </li>
        //         );
        //     }

        // });

        // const SortableList = SortableContainer(({ items }) => {
        //     items.map((file, i) => {
        //         file.index = i
        //     });
        //     return (
        //         <ul className="upload-list upload-list-page">
        //             {items.map((value, i) => (
        //                 <SortableItem key={`item-${i}`} index={i} file={value} />
        //             ))}
        //         </ul>
        //     );
        // });

        return (

            <div>
                <div>

                    {/* <div className="content-bredcrum-search">
                        <div className="tab-parent"></div>
                    </div> */}


                    <div className="tab-audio talk-tracks-wrap">
                        <div role="tabpanel" className="tab-pane active" id="home">
                            <div className="action-row audio-action-row">
                                <button className="btn btn-rounded btn-primary" onClick={this.openAduioAddUpdateModal}>
                                    <i className="la la-plus"></i>
                                </button>
                                <div className="action_btn_group">
                                    {/* <button className="btn btn-default" onClick={this.openAddToContentsModal}>
                                        <i class="la la-cube"></i>SELECT FORM CONTENT
                                    </button> */}

                                    {/* <button className="btn btn-default btn-delete" onClick={this.openDeleteConfirmModal} disabled={selectedAudio.length === 0}>
                                        <i className="la la-trash-o"></i>DELETE FILES
                                    </button> */}

                                    <div className="tab_search_box btn-right">
                                        <div className="search-form">
                                            <input ref={(searchInput) => { this.searchInput = searchInput; }}
                                                type="search" name="search" value={search}
                                                className="form-control"
                                                onChange={this.keyPress} />
                                            <button ref={(searchButton) => { this.searchButton = searchButton; }}
                                                type="submit" className="search-btn" onClick={this.search}>
                                                <i className="fa fa-search" aria-hidden="true" ></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="search-form btn-filter">
                                    {subAudiolist.records && subAudiolist.records && subAudiolist.records &&
                                        <AudioFilter page={activePage}
                                            enableScroll={this.enableScroll}
                                            orderby={orderby}
                                            subAudiolist={subAudiolist.records}
                                            sortby={sortby}
                                            search={this.search} />}
                                </div> */}
                            </div>

                            {items.length > 0 &&
                                <div className="table-container bu-table-wrap audio-table table-responsive">
                                    <table className="table hover-row" id="userTable">
                                        <thead>
                                            <tr>
                                                {/* <th className="custom-checkbox">
                                                    <input type="checkbox" id="chk1" onChange={this.selectAll} checked={isSelectAll} />
                                                    <label htmlFor="chk1"></label>
                                                </th> */}
                                                <th className="audio-icon">

                                                </th>
                                                <th>
                                                    <span className={(sort_by === 'name' && order_by === 'ASC') ? 'bottom-border th-name active-asc' : ((sort_by === 'name' && order_by === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sort_by="name" onClick={this.changeOrder.bind(this)}>
                                                        Name
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className="th-name">
                                                        Description
                                                </span>
                                                </th>
                                                <th>
                                                    <span className="th-name">
                                                        Type
                                                </span>
                                                </th>
                                                <th className="last-modified">
                                                    <span className={(sort_by === 'updated_date' && order_by === 'ASC') ? 'bottom-border th-name active-asc' : ((sort_by === 'updated_date' && order_by === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sort_by="updated_date" onClick={this.changeOrder.bind(this)}>
                                                        Last Modified
                                                </span>
                                                </th>

                                                <th className="actions"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {items.map((item, i) => (
                                            <tr key={item.id} className={(selectedAudio.indexOf(item.id) > -1) ? 'active' : ''}>
                                                {/* <td className="custom-checkbox">
                                                        <input type="checkbox" id={item.id} onChange={this.audioSelection} checked={selectedAudio.filter(c => c == item.id).length > 0} />
                                                        <label htmlFor={item.id}></label>
                                                    </td> */}
                                                <td className="audio-icon">
                                                    {(item.status == '' || item.status == undefined || item.status == 'pause') && <i className="la la-play" data-id={item.id} onClick={this.play}></i>}
                                                    {item.status == 'play' && <i class="la la-pause" data-id={item.id} onClick={this.pause}></i>}
                                                    <audio ref={item.id} src={audio_base_url + item.file_name}></audio>
                                                    <img className="music-img-icon" src="../images/music-img-icon.jpg" />
                                                </td>
                                                <td>
                                                    <span style={{ maxWidth: '24vw', width: '24vw' }} className="align-middle truncate"> {item.name} </span>
                                                </td>
                                                <td>
                                                    <span style={{ maxWidth: '20vw', width: '20vw' }} className="align-middle truncate">{item.description}</span>
                                                </td>
                                                <td>
                                                    {item.type == 'sp' && <span className="text-gray align-middle">Specialty</span>}
                                                    {item.type == 'st' && <span className="text-gray align-middle">Stakeholder</span>}
                                                </td>
                                                <td>
                                                    <span className="text-gray align-middle">{item.updated_date}</span>
                                                </td>

                                                <td className="actions">
                                                    <SubAudioActions action="subaudio-delete"
                                                        search={search} page={activePage}
                                                        orderby={order_by}
                                                        sortby={sort_by}
                                                        item={item}
                                                        removeItems={this.removeItems}
                                                        queryParams={queryParams}
                                                        onUpdateSuccess={this.onUpdateSuccess}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>}

                            {(items.length !== totalRecords) && items.length > 0 &&
                                <button className="btn btn-primary loadmore-btn" onClick={this.fetchMoreData}>
                                    Load More <i className="la la-angle-right"></i>
                                </button>
                            }

                            {subAudiolist !== null && subAudiolist.records !== undefined && subAudiolist.records.length === 0 && <div className="text-center">
                                {isSearch && <label className="lblNoRecord">{randomError}</label>}
                                {!isSearch && <label className="lblNoRecord">There are no Audio Tracks available</label>}
                            </div>}
                            {/* <div className="upload-detail selected-file-list mCustomScrollbar" data-mcs-theme="dark">
                                <SortableList items={content} onSortEnd={this.onSortEnd} useDragHandle={true} helperClass="list_sortable clearfix" />
                            </div> */}
                        </div>
                    </div>
                </div>

                <Modal open={IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal}
                        title="Delete Files"
                        message="Are you sure you want to delete selected file(s)?"
                        selectedAudio={selectedAudio}
                        action="multiple-audio-delete"
                        clearAudioSelection={this.clearAudioSelection}
                        search={search} page={activePage}
                        removeItems={this.removeItems}
                        orderby={order_by}
                        sortby={sort_by} />
                </Modal>

                <Modal open={IsAudioAddUpdateModelOpen} center showCloseIcon={false} onClose={this.closeAudioAddUpdateModal}>
                    <AddUpdateAudioModal closeAudioAddUpdateModal={this.closeAudioAddUpdateModal} action="add-audio"
                        search={search} page={activePage}
                        enableScroll={this.enableScroll}
                        orderby={order_by}
                        sortby={sort_by}
                        specialitylist={specialitylist}
                        stackholderlist={stackholderlist}
                        productlist={productlist}
                        queryParams={queryParams}
                        onAddSuccess={this.onAddSuccess} />
                </Modal>
                <Modal open={IsAddToContentsModalOpen} center showCloseIcon={false} onClose={this.closeAddToContentsModal}>
                    <AddToContentModal closeAddToContentsModal={this.closeAddToContentsModal}
                        Id={queryParams.id} appendExistingContent={this.appendExistingContent} strexcontent={strexcontent} updatedContent={content} />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.audioReducer.loading,
    error: state.audioReducer.error,
    res: state.audioReducer.res,
    subAudiolist: state.audioReducer.subAudiolist,
    stackholderlist: state.audioReducer.stackholderlist,
    productlist: state.audioReducer.productlist,
    specialitylist: state.audioReducer.specialitylist
})

export default connect(mapStateToProps)(SubAudio);