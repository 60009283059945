import { BASE_URL } from '../../constants/constants';
import { IMAGE_FORMATS, AUDIO_FORMATS, VIDEO_FORMATS } from '../../constants/constants';
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router-dom';
import DeleteConfirmModal from '../common/deleteConfirmModal';
import AddUpdateAudioModal from './addUpdateAudioModal';
import * as  audioActions from '../../actions/audioActions';
import queryString from 'query-string';

class AudioDetails extends Component {

    constructor() {
        super();
        this.state = {
            image_base_url: BASE_URL.replace("api/v7/", "") + "uploads/pages/",
            imageFormats: IMAGE_FORMATS,
            audioFormats: AUDIO_FORMATS,
            videoFormats: VIDEO_FORMATS,
            type: '',
            orderby: '',
            sortby: '',
            id: '',
            audio_name: '',
            file_name: '',
            is_sharing: '',
            file_size: '',
            uploaded_date: '',
            updated_date: '',
            folder_path: '',
            extension: '',
            IsAudioAddUpdateModalOpen: false,
            IsDeleteConfirmModalOpen: false,
            redirect: false,
            search: '',
            selectedPages: [],
            numPages: null,
            pageNumber: 1,
            isSearch: false,
            isDownloading: false
        }

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.pageSelection = this.pageSelection.bind(this);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.download = this.download.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.getUpdatedDetails = this.getUpdatedDetails.bind(this);
    }   

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/audio' />
        }
    }

    openAudioAddUpdateModal = () => {
        this.setState({ IsAudioAddUpdateModalOpen: true });
    };

    closeAudioAddUpdateModal = () => {
        this.setState({ IsAudioAddUpdateModalOpen: false });
    };

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        console.log(values.audio_id);

        if (values.audio_id) {
            this.state.id = values.audio_id;
            this.props.dispatch(audioActions.GetAudioDetails({ audio_id: values.audio_id }));            
        }

        if (this.props.location.state !== undefined) {
            this.state.type = this.props.location.state.type;
            this.state.orderby = this.props.location.state.orderby;
            this.state.sortby = this.props.location.state.sortby;
            this.setState(this.state);
        }      
    }

    handleSearchChange(e) {
        if (e.target.name === "search") {
            this.setState({ search: e.target.value });
        }
    }  

    async download() {
        var url = '';
        
        if (this.state.folder_path) {
            url = this.state.image_base_url + this.state.folder_path + '/' + this.state.file_name;
        } else {
            url = this.state.image_base_url + this.state.file_name;
        }

        this.setState({ isDownloading: true });        
        let blob = await fetch(url).then(r => r.blob());        
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob, { type: blob.type });
        a.download = this.state.file_name;
        document.body.appendChild(a);
        a.click();
        this.setState({ isDownloading: false })
        document.body.removeChild(a);
    }

    getUpdatedDetails() {
        const values = queryString.parse(this.props.location.search);
        if (values.audio_id) {
            this.props.dispatch(audioActions.GetAudioDetails({ audio_id: values.audio_id }));
        }
    }

    render() {

        if (this.props.location.search === undefined || this.props.location.search === '') {
            return <Redirect to="/audio" />
        }

        const { pageNumber, type, sortby, orderby, id, audio_name, file_name, is_sharing, file_size, uploaded_date, updated_date, extension, image_base_url, search,
            audioFormats, imageFormats, videoFormats, isSearch, folder_path, isDownloading} = this.state;

        const { details, audiopagelist, selectedpages, res } = this.props;

        if (res != null && res.success === "1") {
            toastr.success("Success !", res.message);            
            var model = {};
            model.sort_by = "name";
            model.order_by = "DESC";
            model.search = this.state.search;            
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        const bytesToSize = (bytes) => {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes === 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
        }

        if (details !== null && details !== undefined) {
            this.state.id = details.id;
            this.state.audio_name = details.audio_name;
            this.state.file_name = details.file_name;
            this.state.extension = this.state.audio_name.slice(this.state.audio_name.lastIndexOf('.'), this.state.length).replace('.', '');
            this.state.is_sharing = details.is_sharing;
            this.state.file_size = bytesToSize(details.file_size);
            this.state.updated_date = details.updated_date;
            this.state.uploaded_date = details.created_date;
            if (details.folder_path) {
                this.state.folder_path = details.folder_path;
            }
            this.setState(this.state);
            this.props.dispatch(audioActions.Clear());
        }

        return (
            <div className="container">
                {this.renderRedirect()}
                <h2 className="sub-title title-grey"> {audio_name}</h2>
                <div className="action-row clearfix add-action-row">
                    <div className=" btn-left">
                        <Link to='/audio' className="btn btn-default btn-border">
                            <i className="la la-mail-reply"></i> BACK
                        </Link>
                    </div>
                    <div className="btn-right">
                        <button className="btn btn-default save-btn" onClick={this.download}>
                            <i className="la la-download"></i>
                            {!isDownloading && <span>DOWNLOAD</span>}
                            {isDownloading && <span>Downloading ...</span>}
                            </button>
                        <button className="btn btn-default black-btn" onClick={this.openAudioAddUpdateModal}>
                            <i className="la la-rotate-right"></i> UPDATE
                        </button>
                        <button className="btn btn-default red-btn" onClick={this.openDeleteConfirmModal}>
                            <i className="la la-trash-o"></i> DELETE
                        </button>
                    </div>
                </div>
                <div className="row preview-row width_set">
                    <div className="col-md-9 col-sm-12 left_width">

                        {imageFormats.indexOf(extension.toLowerCase()) == -1 && videoFormats.indexOf(extension.toLowerCase()) == -1 && audioFormats.indexOf(extension.toLowerCase()) == -1 && <div className="preview-box-doc">
                            <iframe src={"https://docs.google.com/gview?url=" + image_base_url + file_name + "&embedded=true"}></iframe>
                        </div>}
                        {imageFormats.indexOf(extension.toLowerCase()) > -1 && <div className="preview-box"><img src={(folder_path == '') ? image_base_url + file_name : image_base_url + folder_path + '/' + file_name} className="img-responsive" /></div>}
                        {videoFormats.indexOf(extension.toLowerCase()) > -1 && <div className="preview-box"><video controls style={{ width: '100%' }}>
                            <source src={(folder_path == '') ? image_base_url + file_name : image_base_url + folder_path + '/' + file_name} type="video/mp4" />
                        </video></div>}
                        {audioFormats.indexOf(extension.toLowerCase()) > -1 && <div className="preview-box"><audio controls style={{ width: '100%' }}>
                            <source src={(folder_path == '') ? image_base_url + file_name : image_base_url + folder_path + '/' + file_name} type="audio/mpeg" />
                        </audio></div>}
                    </div>
                    <div className="col-md-3 col-sm-12 right_width">
                        <div className="form-container">
                            <form>
                                <h3 className="form-title"> Details </h3>
                                <div className="form-group">
                                    <label>File Name</label>
                                    <input type="text" value={audio_name} className="form-control" disabled />
                                </div>
                                <div className=" form-group">
                                    <label>SHARING</label>
                                    <select className="form-control" value={is_sharing} disabled>
                                        <option className="text-success" value="1">On</option>
                                        <option className="text-success" value="0">off</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Size</label>
                                    <input type="text" value={file_size} className="form-control" disabled />
                                </div>
                                <div className="form-group">
                                    <label>UPLOADED</label>
                                    <input type="text" value={uploaded_date} className="form-control" disabled />
                                </div>
                                <div className="form-group">
                                    <label>LAST MODIFIED</label>
                                    <input type="text" value={updated_date} className="form-control" disabled />
                                </div>
                            </form>
                        </div>
                        <div className="assigned-pages" style={{ marginTop: '80px' }}>
                            <h3 className="form-title"> Assigned Pages </h3>
                            <div className="action-row clearfix">
                                <div className="search-form search-left">
                                    <input ref={(searchInput) => { this.searchInput = searchInput; }}
                                        type="text" className="form-control" value={search} name="search"
                                        onKeyUp={this.keyPress} onChange={this.handleSearchChange} />
                                    <button type="submit" className="search-btn" onClick={this.search}>
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            {audiopagelist !== null && audiopagelist.records !== undefined && audiopagelist.records.length > 0 &&
                                <div className="pages-list mCustomScrollbar" data-mcs-theme="dark">
                                    <ul>
                                        {audiopagelist != null && audiopagelist.records !== undefined && audiopagelist.records.map(item => (
                                            <li key={item.id}>
                                                <div className="custom-checkbox">
                                                    <input type="checkbox" id={item.id} onChange={this.pageSelection} checked={(selectedpages.indexOf(item.id) > -1)} />
                                                    <label htmlFor={item.id}>
                                                        <img src="../images/camera.png" className="file-img img-responsive" alt="" />
                                                        <span className="page-info " style={{ maxWidth: '5vw', width: '5vw', cursor: 'pointer' }}>
                                                        <span className="page-name truncate">{item.name}</span>
                                                        <span className="ellipsis page-text truncate">{item.subtitle}</span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>}
                            {audiopagelist !== null && audiopagelist.records !== undefined && audiopagelist.records.length === 0 && <div className="text-center">
                                {isSearch && <label className="lblNoRecord">Nothing matches that search. Sorry!</label>}
                                {!isSearch && <label className="lblNoRecord">No Record Found!</label>}
                            </div>}
                        </div>
                    </div>
                </div>
                <Modal open={this.state.IsAudioAddUpdateModalOpen} center showCloseIcon={false} onClose={this.closeAudioAddUpdateModal}>
                    <AddUpdateAudioModal closeAudioAddUpdateModal={this.closeAudioAddUpdateModal} action="update" Id={id} getUpdatedDetails={this.getUpdatedDetails} />
                </Modal>
                <Modal open={this.state.IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal} onDeleteSuccess={this.setRedirect} title="Delete Audio" message="Are you sure you want to delete this audio?"
                        Id={id} action="audio-delete-from-detail" type={type}
                        orderby={orderby} sortby={sortby} />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.audioReducer.loading,
    error: state.audioReducer.error,
    details: state.audioReducer.details,
    audiopagelist: state.audioReducer.audiopagelist,
    selectedpages: state.audioReducer.selectedpages,
    res: state.audioReducer.res,
    details: state.audioReducer.details
});

export default connect(mapStateToProps)(AudioDetails);