import { returnResponse } from './baseReducer';

const initialState = {    
    loading: false,
    error: null,
    logindetail: null,
    logout: true
};

export default function loginReducer(state = {}, action) {
    return returnResponse(state, action);     
}