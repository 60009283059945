import { BASE_URL, errOptions } from '../../constants/constants';
import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import * as  contentActions from '../../actions/contentActions';
import InfiniteScroll from "../common/infinitescroll";

class AddToPagesModal extends Component {

    constructor() {
        super()
        this.state = {
            image_base_url: BASE_URL.replace("api/v7/", "") + "uploads/pages/",
            selectedPages: [],
            search: '',
            isSelectAll: false,
            activePage: 1,
            isSearch: false,
            items: [],
            isScroll: true,
            totalRecords: null,
            hasMore: true,
            randomError: ''
        };

        this.pageSelection = this.pageSelection.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
    }


    componentWillReceiveProps() {
        this.props.dispatch(contentActions.ClearContentPageList());
    }

    componentDidMount() {
        var model = {};
        model.sort_by = 'name';
        model.order_by = 'ASC';
        model.page = this.state.activePage;
        this.props.dispatch(contentActions.GetPages(model));
    }

    generateRandomMesage(randomError) {
        var str = errOptions[Math.floor(Math.random() * Math.floor(5))];
        if (randomError == str) {
            str = this.generateRandomMesage();
        }
        return str;
    }

    pageSelection(e) {

        var id = e.target.id.replace("chk", "");
        if (e.target.checked) {
            if (this.state.selectedPages.length + 1 === this.state.items.length) {
                this.setState({ isSelectAll: true, selectedPages: [...this.state.selectedPages, id] });
            }
            else {
                this.setState({ selectedPages: [...this.state.selectedPages, id] });
            }
        }
        else {
            this.setState({
                isSelectAll: false,
                selectedPages: this.state.selectedPages.filter((eid) => eid !== id)
            });
        }
    }

    selectAll(e) {
        if (e.target.checked) {
            this.state.selectedPages = this.state.items.map((item) => {
                return item.id;
            });
            this.state.isSelectAll = true;
        }
        else {
            this.state.selectedPages = [];
            this.state.isSelectAll = false;
        }
        this.setState(this.state);
    }

    handleSearchChange(e) {
        if (e.target.name === "search") {
            this.setState({ search: e.target.value });
        }
        var randomError = this.generateRandomMesage(this.state.randomError);
        this.setState({ activePage: 1, items: [], isScroll: true, selectedPages: [], totalRecords: null, hasMore: true, randomError: randomError });
        var model = {};
        model.sort_by = 'name';
        model.order_by = 'ASC';
        model.search = e.target.value.toLowerCase();
        model.page = 1;
        this.props.dispatch(contentActions.GetPages(model));
    }

    search() {
        this.state.isSearch = !this.state.isSearch;
        if (this.state.isSearch) {
            this.searchInput.focus();
        }
        else {
            this.setState({ activePage: 1, search: '', items: [], isScroll: true, selectedPages: [], totalRecords: null, hasMore: true });
            var model = {};
            model.sort_by = 'name';
            model.order_by = 'ASC';
            model.search = '';
            model.page = 1;
            this.props.dispatch(contentActions.GetPages(model));
        }
    }

    handleSubmit() {
        var model = {};
        model.content_id = this.props.selectedContents.map(function (item) {
            return item.id;
        });
        model.page_id = this.state.selectedPages;

        this.props.dispatch(contentActions.AddContentToPages(model));
    }

    fetchMoreData() {
        this.state.activePage += 1;
        this.state.isScroll = true;
        var model = {};
        model.sort_by = 'name';
        model.order_by = 'ASC';
        if (this.state.search !== "") {
            model.search = this.state.search;
        }
        model.page = this.state.activePage;
        this.props.dispatch(contentActions.GetPages(model));
    }

    render() {

        const { image_base_url, selectedPages, isSelectAll, search,
            activePage, isScroll, items, totalRecords, hasMore, randomError, isSearch } = this.state;
        const { loading, error, contentpagelist, res } = this.props;

        if (!loading && isScroll && contentpagelist != null && contentpagelist.records && contentpagelist.records.length > 0 && this.state.items.length < contentpagelist.total_records) {

            if (!items.find(x => x.id == contentpagelist.records[0].id)) {

                var hasMoreRef = ((items.length + contentpagelist.records.length) == contentpagelist.total_records) ? false : true;
                var selectedcontentpagelist = [];
                if (isSelectAll) {
                    selectedcontentpagelist = contentpagelist.records.map((item) => {
                        return item.id;
                    });
                }
                this.setState({
                    isScroll: false,
                    totalRecords: contentpagelist.total_records,
                    selectedPages: selectedPages.concat(selectedcontentpagelist),
                    items: items.concat(contentpagelist.records),
                    hasMore: hasMoreRef
                });
            }
        }

        if (res != null && res.success === "1") {

            toastr.success("Success !", res.message);
            this.props.clearContentSelection();
            this.props.closeAddToPagesModal();
            var model = {};
            model.content_type = this.props.type;
            model.sort_by = this.props.sortby;
            model.order_by = this.props.orderby;
            model.search = this.props.search;
            if (this.props.folder_id > 0) {
                model.folder_id = this.props.folder_id;
            }
            model.page = this.props.page;
            this.props.dispatch(contentActions.GetContents(model));
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        const loadingClass = { 'textAlign': 'center', 'fontSize': '30px' };

        return (
            <div className="addcontent_modal add_page_modal add-to-page-modal">
                <div className="modal-dialog" style={{ margin: '0px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add To Pages</h4>
                        </div>
                        <div className="modal-body">
                            <h2 className="modal-para-small">
                                <p>Select which pages you would like to add this content to.</p>
                            </h2>
                            <div className="assigned-pages">
                                <div className="action-row clearfix action-row-checkbox">
                                    <div className="custom-checkbox pull-left">
                                        <input type="checkbox" id="chk11" onChange={this.selectAll} checked={isSelectAll} />
                                        <label htmlFor="chk11"></label>
                                    </div>
                                    <div className="search-form search-form-modal pull-right">
                                        <input ref={(searchInput) => { this.searchInput = searchInput; }}
                                            type="search" className="form-control" value={search} name="search"
                                            onChange={this.handleSearchChange} />
                                        <button type="submit" className="search-btn addToPagesSearch" onClick={this.search}>
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                {items.length > 0 &&
                                    <div id="scrollableDiv" className="pages-list mCustomScrollbar" data-mcs-theme="dark">
                                        {items.length > 0 && <InfiniteScroll
                                            target="list"
                                            dataLength={items.length}
                                            scrollThreshold="300px"
                                            next={this.fetchMoreData}
                                            hasMore={hasMore}
                                            scrollableTarget="scrollableDiv"
                                            loader={<li className="bottom-0" style={loadingClass}>Loading...</li>} >

                                            {items.map(item => (
                                                <li key={item.id}>
                                                    <div className="custom-checkbox">
                                                        <input type="checkbox" id={'chk' + item.id} onChange={this.pageSelection} checked={(selectedPages.indexOf(item.id) > -1)} />
                                                        <label htmlFor={'chk' + item.id}>
                                                            {item.page_logo !== '' && <img src={image_base_url + item.page_logo} className="file-img img-responsive" alt="page logo" />}
                                                            {item.page_logo === '' && <img src="images/camera.png" className="file-img img-responsive" alt="page logo" />}
                                                            <span className="page-info">
                                                                <span className="page-name">{item.name}</span>
                                                                <span className="ellipsis page-text">{item.description}</span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </li>
                                            ))}
                                        </InfiniteScroll>}
                                    </div>}
                                {contentpagelist !== null && contentpagelist.records !== undefined && contentpagelist.records.length === 0 && <div className="text-center">
                                    {isSearch && <label className="lblNoRecord">{randomError}</label>}
                                    {!isSearch && <label className="lblNoRecord">No Record Found !</label>}
                                </div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="modal-footer-inner">
                                <button type="button" className="btn btn-default" onClick={this.props.closeAddToPagesModal}>CANCEL</button>
                                <button type="button" className="btn btn-primary" onClick={this.handleSubmit} disabled={selectedPages.length === 0}>ADD TO SELECTED PAGES</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.contentReducer.loading,
    error: state.contentReducer.error,
    contentpagelist: state.contentReducer.contentpagelist,
    res: state.contentReducer.res
});

export default connect(mapStateToProps)(AddToPagesModal);