import { BEGIN, FAILURE, BASE_URL, AUDIO_LIST, CLEAR_AUDIO_LIST, RES, AUDIO_DETAILS, CLEAR_AUDIO_DETAILS, SPECIALITYLIST, STACKHOLDER_LIST, PRODUCT_LIST, AUDIO_BU_LIST, ADD_AUDIO_CATEGORY, SUB_AUDIO_LIST, ADD_PRODUCT_CATEGORY } from '../constants/constants';
import { fetchBegin, fetchSuccess, fetchFailure } from './actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import axios from 'axios';

export const GetAudios = (model) => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_AUDIO_LIST));
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/gettalktrackaudiolist";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, SUB_AUDIO_LIST));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const DeleteAudio = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/removetalktracksaudio";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const GetAudioDetails = (model) => dispatch => {

    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/admincontentdetail";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, AUDIO_DETAILS));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const UpdateAudioContent = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/edittalktracksaudiocontent";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const GetSpecialities = () => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/specialty";
    dispatch(showLoading());
    axios.post(url)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, SPECIALITYLIST));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const GetSpecialitiesForList = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/specialty";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, AUDIO_LIST));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const GetStackholders = () => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/stakeholdertalktrackList";
    dispatch(showLoading());
    axios.post(url)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, STACKHOLDER_LIST));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const GetStackholdersForList = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/stakeholdertalktrackList";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, AUDIO_LIST));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const GetProducts = () => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/productslist";
    dispatch(showLoading());
    axios.post(url)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, PRODUCT_LIST));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const ShowLoading = () => dispatch => {
    dispatch(showLoading());
}

export const HideLoading = () => dispatch => {
    dispatch(hideLoading());
}

export const initiateState = () => dispatch => {
    dispatch(fetchBegin(BEGIN));
}

export const Clear = () => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_AUDIO_DETAILS));
}

export const GetAudioBUList = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "businessunit/adminbusinessunitlist";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, AUDIO_BU_LIST));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const AddAudioCategory = (model) => dispatch => {
    dispatch(fetchSuccess({}, ADD_AUDIO_CATEGORY));
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/addCategory";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const AddProductForCategory = (model) => dispatch => {
    dispatch(fetchSuccess({}, ADD_PRODUCT_CATEGORY));
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/addproduct";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const UpdateAudioCategory = (model) => dispatch => {
    dispatch(fetchSuccess({}, ADD_AUDIO_CATEGORY));
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/editCategory";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const GetTalkTrackDetails = (model) => dispatch => {

    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/getTalkTrackDetail";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, AUDIO_DETAILS));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const DeleteSpecialtyAudio = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/deletespecialty";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const DeleteStackholderAudio = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/deletestakeholder";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const updateAudioDetail = (model) => dispatch => {
    dispatch(fetchSuccess({}, ADD_AUDIO_CATEGORY));
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/editDetail";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const updateAudioProbingQuestion = (model) => dispatch => {
    dispatch(fetchSuccess({}, ADD_AUDIO_CATEGORY));
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/editProbingQuestions";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const updateAudioTalkingPoints = (model) => dispatch => {
    dispatch(fetchSuccess({}, ADD_AUDIO_CATEGORY));
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/editTalkingPoints";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const GetSubAudioList = (model) => dispatch => {

    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/gettalktrackaudiolist";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, SUB_AUDIO_LIST));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const DeleteSubAudio = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/removetalktracksaudio";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const DeleteProduct = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "trial/deleteproduct";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}
