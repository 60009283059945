import React, { Component } from "react";
import Modal from 'react-responsive-modal';
import DeleteConfirmModal from '../common/deleteConfirmModal';
import AddUpdateCategoryModal from './addUpdateCategoryModel';
import AudioUpdateModal from './audioUpdateModal';
import Popover from 'react-simple-popover';

class AudioActions extends Component {

    constructor() {
        super();
        this.state = {
            IsCategoryAddUpdateModelOpen: false,
            IsAudioContentUpdateModalOpen: false,
            IsAudioUpdateModalOpen: false,
            IsDeleteConfirmModalOpen: false,
            open: false
        }
    }

    handleClick(e) {
        this.setState({ open: !this.state.open });
    }

    handleClose(e) {
        this.setState({ open: false });
    }

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    openAudioAddUpdateModal = () => {
        this.setState({ IsCategoryAddUpdateModelOpen: true });
    };

    closeAudioAddUpdateModal = () => {
        ;
        this.setState({ IsCategoryAddUpdateModelOpen: false });
    };

    openAudioContentUpdateModal = () => {
        this.setState({ IsCategoryAddUpdateModelOpen: true });
    };

    closeAudioContentUpdateModal = () => {
        this.setState({ IsCategoryAddUpdateModelOpen: false });
    };

    closeCategorAddUpdateModal = () => {
        this.setState({ IsCategoryAddUpdateModelOpen: false });
    };

    openAudioUpdateModal = () => {
        this.setState({ IsAudioUpdateModalOpen: true });
    };

    closeAudioUpdateModal = () => {
        this.setState({ IsAudioUpdateModalOpen: false });
    };

    onAddSuccess = () => {
        this.props.onAddSuccess();
    }


    render() {
        var deleteMessage = "Are you sure you want to delele " + this.props.item.name + "? This will delete all talk tracks under this category."
        return (
            <div>
                <button type="button" className="btn btn-default" ref="useraction" onClick={this.handleClick.bind(this)}>
                    <i className="la la-ellipsis-h"></i>
                </button>
                <Popover
                    placement='bottom'
                    target={this.refs.useraction}
                    show={this.state.open}
                    style={{ padding: '0px' }}
                    containerStyle={{ marginLeft: '20px', zIndex: 999 }}
                    onHide={this.handleClose.bind(this)} >

                    {/* <button className="edit-action" onClick={this.openAudioUpdateModal}>
                        <i className="la la-pencil"></i> EDIT AUDIO
                            </button> */}
                    <button className="edit-action" onClick={this.openAudioContentUpdateModal}>
                        <i className="la la-pencil"></i> EDIT
                            </button>
                    <button className="delete-action" onClick={this.openDeleteConfirmModal}>
                        <i className="la la-trash-o"></i> DELETE
                            </button>
                </Popover>
                <Modal open={this.state.IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal} title="Delete Files" message={deleteMessage}
                        Id={this.props.item.id} action={this.props.type == 'specialty' ? 'audio-specialty-delete' : 'audio-stackholder-delete'} search={this.props.search} page={this.props.page}
                        removeItems={this.props.removeItems}
                        orderby={this.props.orderby} sortby={this.props.sortby} />
                </Modal>
                <Modal open={this.state.IsCategoryAddUpdateModelOpen} center showCloseIcon={false} onClose={this.closeAudioContentUpdateModal}>
                    <AddUpdateCategoryModal closeCategorAddUpdateModal={this.closeAudioContentUpdateModal}
                        search={this.props.search} page={this.props.page}
                        action="edit"
                        typeCategory={this.props.type}
                        orderby={this.props.orderby} sortby={this.props.sortby} updateItem={this.props.updateItem}
                        item={this.props.item}
                        onAddSuccess={this.onAddSuccess}
                    />
                </Modal>
                {/* <Modal open={this.state.IsAudioUpdateModalOpen} center showCloseIcon={false} onClose={this.closeAudioUpdateModal}>                    
                    <AudioUpdateModal closeAudioUpdateModal={this.closeAudioUpdateModal} action="update-audio"
                        search={this.props.search} page={this.props.page}
                        orderby={this.props.orderby} sortby={this.props.sortby}
                        id={this.props.item.id} />
                </Modal> */}


            </div>
        );
    }
}

export default AudioActions;