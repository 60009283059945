import { BASE_URL, BEGIN, FAILURE, LOGIN_DETAIL, LOGOUT, LOGIN} from '../constants/constants';
import { fetchBegin, fetchSuccess, fetchFailure } from '../actions/actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import axios from 'axios';

export const Login = (model) => dispatch => {    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "user/adminlogin";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {        
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, LOGIN));            
        })
        .catch(function (error) {          
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));            
        });
}

export const Logout = () => dispatch => {
    dispatch(fetchSuccess({}, LOGOUT));
}

export const GetLoginDetails = () => dispatch => {
    dispatch(fetchSuccess({}, LOGIN_DETAIL));
}