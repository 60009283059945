import { returnResponse } from './baseReducer';

const initialState = {    
    loading: false,
    error: null,
    res: null,
    contentlist: {},
    contentpagelist: {},    
    details: null,    
    selectedpages: [],
    bulist: []
};

export default function contentReducer(state = initialState, action) {
    return returnResponse(state, action);
}