import { BEGIN, FAILURE, BASE_URL, RES, BU_LIST, CLEAR_BU_LIST, BU_SP_LIST, CLEAR_SP_LIST, CLEAR_CP_LIST, BU_CP_LIST, EDIT_BU_RES, BU_DETAILS, CLEAR_BU_DETAILS } from '../constants/constants';
import { fetchBegin, fetchSuccess, fetchFailure } from '../actions/actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import axios from 'axios';

export const GetBUList = (model) => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_BU_LIST));
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "businessunit/adminbusinessunitlist";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, BU_LIST));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));            
        });
}

export const AddBU = (model) => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_BU_LIST));
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "businessunit/adminbuadd";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const EditBU = (model) => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_BU_LIST));
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "businessunit/adminbuedit";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());            
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const DeleteBU = (bu_id) => dispatch => {    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "businessunit/adminbudelete";
    dispatch(showLoading());
    axios.post(url, { id: bu_id })
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));            
        });
}

export const DeleteBulkBU = (bu_id) => dispatch => {    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "businessunit/adminbubulkdelete";
    dispatch(showLoading());
    axios.post(url, { id: bu_id })
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const GetBUSpecialityList = (model) => dispatch => {      
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "businessunit/adminbusinessunitspecialtieslist";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, BU_SP_LIST));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const GetBUCompititorList = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "businessunit/adminbusinessunitcompetitorslist";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, BU_CP_LIST));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const GetBUDetails = (model) => dispatch => {

    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "businessunit/adminbudetail";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, BU_DETAILS));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const ShowLoading = () => dispatch => {    
    dispatch(showLoading());
}

export const HideLoading = () => dispatch => {
    dispatch(hideLoading());
}

export const initiateState = () => dispatch => {
    dispatch(fetchBegin(BEGIN));
}

export const ClearBUList = () => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_BU_LIST));
}


export const ClearBUSpecialityList = () => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_SP_LIST));
}

export const ClearBUCompititorList = () => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_CP_LIST));
}

export const Clear = () => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_BU_DETAILS));
}

