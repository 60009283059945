import { BEGIN, FAILURE, BASE_URL, BU_CP_RES, COMPITITOR_LIST, CLEAR_COMPITITOR_LIST } from '../constants/constants';
import { fetchBegin, fetchSuccess, fetchFailure } from './actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import axios from 'axios';

export const GetCompititorList = (model) => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_COMPITITOR_LIST));
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "businessunit/admingetcompetitors";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, COMPITITOR_LIST));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const AddCompititorToBU = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "businessunit/adminbuaddcompetitors";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, BU_CP_RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}


export const ShowLoading = () => dispatch => {
    dispatch(showLoading());
}

export const HideLoading = () => dispatch => {
    dispatch(hideLoading());
}

export const initiateState = () => dispatch => {
    dispatch(fetchBegin(BEGIN));
}

export const ClearCompititorList = () => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_COMPITITOR_LIST));
}

