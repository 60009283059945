import { returnResponse } from './baseReducer';

const initialState = {    
    loading: false,
    error: null,
    res: null,
    audiolist: {},
    specialitylist: {},
    stackholderlist: {},
    productlist: {},
    audiobulist:{},
    editaudiores: null,
    subAudiolist: {}
    
};

export default function audioReducer(state = initialState, action) {
    return returnResponse(state, action);
}