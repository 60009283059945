import React, { Component } from "react";
import Modal from 'react-responsive-modal';
import DeleteConfirmModal from '../common/deleteConfirmModal';
import EditUser from './editUser';
import Popover from 'react-simple-popover';

class UserActions extends Component {

    constructor() {
        super();
        this.state = {
            IsEditUserModalOpen: false,
            IsDeleteConfirmModalOpen: false,
            open: false
        }
    }

    handleClick(e) {
        this.setState({ open: !this.state.open });
    }

    handleClose(e) {
        this.setState({ open: false });
    }

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    openEditUserModal = () => {
        this.setState({ IsEditUserModalOpen: true });
    };

    closeEditUserModal = () => {
        this.setState({ IsEditUserModalOpen: false });
    };



    render() {

        return (
            <div>
                <button type="button" className="btn btn-default" ref="useraction" onClick={this.handleClick.bind(this)}>
                    <i className="la la-ellipsis-h"></i>
                </button>
                <Popover
                    placement='bottom'
                    target={this.refs.useraction}
                    show={this.state.open}
                    style={{ padding: '0px' }}
                    containerStyle={{ marginLeft: '20px', zIndex: 999 }}
                    onHide={this.handleClose.bind(this)} >
                    <button className="edit-action" onClick={this.openEditUserModal}>
                        <i className="la la-pencil"></i> Edit
                            </button>
                    <button className="delete-action" onClick={this.openDeleteConfirmModal}>
                        <i className="la la-trash-o"></i> Delete
                            </button>
                </Popover>
                {/*
                  <div className="dropdown">
                    <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <i className="la la-ellipsis-h"></i>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                        <li>
                            <button className="edit-action" onClick={this.openEditUserModal}>
                                <i className="la la-pencil"></i> Edit
                            </button>
                        </li>
                        <li>
                            <button className="delete-action" onClick={this.openDeleteConfirmModal}>
                                <i className="la la-trash-o"></i> Delete
                            </button>
                        </li>
                    </ul>
                </div>
                 */}
                <Modal open={this.state.IsEditUserModalOpen} center showCloseIcon={false} onClose={this.closeEditUserModal}>
                    <EditUser closeEditUserModal={this.closeEditUserModal} Id={this.props.Id}
                        UserDetails={this.props.UserDetails} orderby={this.props.orderby}
                        sortby={this.props.sortby} role={this.props.role} search={this.props.search} page={this.props.page}
                        updateItem={this.props.updateItem} />
                </Modal>
                <Modal open={this.state.IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal} title="Delete User" message="Are you sure you want to delete this user?"
                        Id={this.props.Id} action="user-delete" role={this.props.role} search={this.props.search} page={this.props.page}
                        orderby={this.props.orderby} sortby={this.props.sortby}
                        removeItems={this.props.removeItems} />
                </Modal>
            </div>
        );
    }
}

export default UserActions;