import {
    BEGIN, FAILURE, BASE_URL, PAGE_LIST, RES, CONTENT_LIST,
    PAGE_CONTENT_ASSIGNED, CLEAR_PAGE_CONTENT_LIST, PAGE_CONTENT_LIST, CLEAR_PAGE_LIST, PAGE_BU_LIST
} from '../constants/constants';
import { fetchBegin, fetchSuccess, fetchFailure } from '../actions/actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import axios from 'axios';

export const GetPages = (model) => dispatch => {    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "pages/adminpagelist";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, PAGE_LIST));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));           
        });
}

export const DeletePage = (page_id) => dispatch => {
    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "pages/adminpagedelete";
    dispatch(showLoading());
    axios.post(url, { page_id: page_id })
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));           
        });
}

export const ChnageVisibility = (model) => dispatch => {
    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "pages/adminchangevisibility";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));           
        });
}

export const GetContents = (model) => dispatch => {
    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/admincontentlist";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, PAGE_CONTENT_LIST));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));           
        });
}

export const AddContentToPages = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/adminaddcontenttopage";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));           
        });
}

export const GetPageContents = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "pages/adminpagecontentlist";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, PAGE_CONTENT_ASSIGNED));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));           
        });
}

export const GetPageBUList = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "pages/adminpagebusinessunit";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, PAGE_BU_LIST));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const EditPageTags = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "pages/adminpagebusinessunitupdate";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}


export const ClearPageContentList = () => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_PAGE_CONTENT_LIST));
}

export const ShowLoading = () => dispatch => {    
    dispatch(showLoading());
}

export const HideLoading = () => dispatch => {
    dispatch(hideLoading());
}

export const initiateState = () => dispatch => {
    dispatch(fetchBegin(BEGIN));
}

export const ClearPageList = () => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_PAGE_LIST));
}

export const DeletePageContent = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "pages/adminpagecontentdelete";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}
