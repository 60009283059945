import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import * as pagesActions from '../../actions/pagesActions';

class ChangePageVisibilityModal extends Component {

    constructor() {
        super();

        this.state = {    
            status: '-1',
            validations: {
                status: ''
            },
            isValidated: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        
        
        switch (e.target.id){
            case "status":
                if (e.target.value === "-1") {                    
                    this.setState({ isValidated: false, status: e.target.value, validations: { ...this.state.validations, status: 'Visibility is required' } });
                }
                else {                   
                    this.setState({ isValidated: true, status: e.target.value, validations: { ...this.state.validations, status: '' } });
                }
                break;
            default:
                break;
        }
    }


    handleSubmit(e) {        
        var model = {};
        model.id = this.props.selectedPages;
        model.status = this.state.status;
        
        this.props.dispatch(pagesActions.ChnageVisibility(model));
        
    }

    render() {
        const { status, validations, isValidated } = this.state;        
        const { res } = this.props;

        
        if (res != null && res.success === "1") {            
            toastr.success("Success !", res.message);                                  
            var model = {};
            model.id = res.records.id;
            model.status = res.records.status;
            this.props.updateVisibility(model);
            this.props.clearPageSelection();
            this.props.dispatch(pagesActions.initiateState());            
            this.props.closeChangeVisibilityModal();     
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        return (
            
            <div className="modal-dialog" style={{ margin: '0px' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Change visibility for selected page(s)</h4>
                    </div>
                    <div className="modal-body form-container">                        
                            <div className="form-group">
                                <label>VISIBILITY</label>
                                <select className="form-control" id="status" value={status} onChange={this.handleChange}>
                                    <option value="-1">--Select visibility--</option>
                                    <option value="1">Active</option>
                                    <option value="0">Hidden</option>
                                </select>
                                {validations.status !== "" && <span className={validations.status === "" ? "hidden" : "has-error"}>{validations.status}</span>}
                            </div>                        
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={this.props.closeChangeVisibilityModal}>CANCEL</button>
                        <button type="button" className="btn btn-primary" disabled={!isValidated} onClick={this.handleSubmit}>SAVE CHANGES</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.usersReducer.loading,
    error: state.usersReducer.error,
    res: state.usersReducer.res
});

export default connect(mapStateToProps)(ChangePageVisibilityModal);