import { ENCRYPTION_KEY } from '../../constants/constants';
import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import * as contentActions from '../../actions/contentActions';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class ChangeShareSettingModal extends Component {

    constructor() {
        super();

        this.state = {
            status: '-1',
            validations: {
                status: ''
            },
            isValidated: false,
            selectedbulist: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateTags = this.updateTags.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(contentActions.GetBuList());
    }

    handleChange(e) {
        switch (e.target.id) {
            case "status":
                if (e.target.value === "-1") {
                    this.setState({ isValidated: false, status: e.target.value, validations: { ...this.state.validations, status: 'Sharing is required' } });
                }
                else {
                    this.setState({ isValidated: true, status: e.target.value, validations: { ...this.state.validations, status: '' } });
                }
                break;
            default:
                break;
        }
    }

    handleSubmit(e) {
        var model = {};
        model.content_id = this.props.selectedContents.map(function (item) {
            return item.id;
        });
        model.is_sharing = this.state.status;
        model.bu_id = this.state.selectedbulist;
        model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
        model.status = this.state.selectedbulist.length > 0 ? 'true' : 'false';
        this.props.dispatch(contentActions.ChangeSharing(model));
    }

    updateTags(e) {
        var bu_id = parseInt(e.target.dataset.buid);
        var isSelected = e.target.dataset.status == 'false' ? true : false;
        if (isSelected) {
            this.props.bulist.records.find(x => x.id == bu_id).IsSelected = true;
            this.state.selectedbulist.push(bu_id);
            this.setState(this.state);
        } else {
            this.props.bulist.records.find(x => x.id == bu_id).IsSelected = false;
            this.setState({ selectedbulist: this.state.selectedbulist.filter(x => x != bu_id) });
        }
    }

    render() {
        const { status, validations, isValidated, selectedbulist } = this.state;

        const { res, bulist } = this.props;

        if (res != null && res.success === "1") {
            toastr.success("Success !", res.message);
            this.props.clearContentSelection();
            this.props.closeChangeShareSettingModal();
            var model = {};
            model.content_id = res.content_id;
            model.status = this.state.status;
            this.props.dispatch(contentActions.initiateState());
            model.is_sharing = '';
            this.props.updateItem(model, 'change_share_settings');

        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        if (bulist && bulist.records && bulist.records.length > 0) {
            bulist.records.forEach(x => {
                x.id = parseInt(x.id);
                x.IsSelected = selectedbulist.find(y => y == x.id) == undefined ? false : true;
            });
        }

        return (

            <div className="modal-dialog change-setting-modal" style={{ margin: '0px' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Change Share Settings</h4>
                    </div>
                    <div className="modal-body form-container">
                        <div className="form-group">
                            <label>SHARING</label>
                            <select className="form-control" id="status" value={status} onChange={this.handleChange}>
                                <option value="-1">--Select sharing--</option>
                                <option value="0">Off</option>
                                <option value="1">On</option>
                            </select>
                            {validations.status !== "" && <span className={validations.status === "" ? "hidden" : "has-error"}>{validations.status}</span>}
                        </div>
                        <div className="form-group">
                            {bulist && bulist.records && bulist.records.length > 0 && <div className="form-group">
                                <label>TAGS</label>
                                <div className="ub-list">
                                    {bulist.records.map(item => (
                                        <div Key={item.id} className={item.IsSelected == true ? 'ub active' : 'ub'} data-buid={item.id} data-status={item.IsSelected} onClick={this.updateTags.bind(this)}>{item.name}</div>
                                    ))}
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={this.props.closeChangeShareSettingModal}>CANCEL</button>
                        <button type="button" className="btn btn-primary" disabled={!isValidated} onClick={this.handleSubmit}>SAVE CHANGES</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.contentReducer.loading,
    error: state.contentReducer.error,
    res: state.contentReducer.res,
    bulist: state.contentReducer.bulist
});

export default connect(mapStateToProps)(ChangeShareSettingModal);