import { ENCRYPTION_KEY } from '../../constants/constants';
import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import * as contentActions from '../../actions/contentActions';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class EditFolderNameModal extends Component {

    constructor() {
        super();
        this.state = {
            name:'',
            validations: {
                name:''
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({ name: this.props.content_name });
    }

    handleSubmit() {
        var model = {};
        model.folder_id = this.props.folder_id;
        model.folder_name = this.state.name;
        model.user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id;
        this.props.dispatch(contentActions.EditFolderName(model));
    }

    handleChange(e) {

        switch (e.target.id) {
            case "name":
                if (e.target.value === "") {
                    this.setState({ name: e.target.value, validations: { ...this.state.validations, name: 'Name is required' } });
                }
                else {
                    this.setState({ name: e.target.value, validations: { ...this.state.validations, name: '' } });
                }
                break;
            default:
                break;
        }
    }

    render() {
        var isValidated = false;
        const { name, validations } = this.state;

        if (validations.name === "") {
            if (name !== "") {
                isValidated = true;
            }
        }
        else {
            isValidated = false;
        }

        const { res } = this.props;

        if (res != null && res.success === "1") {
            toastr.success("Success !", res.message);            
            this.props.closeEditFolderNameModal();
            var model = {};
            model.content_type = this.props.type;
            model.sort_by = this.props.sortby;
            model.order_by = this.props.orderby;
            model.search = this.props.search;
            model.page = this.props.page;            
            this.props.dispatch(contentActions.GetContents(model));
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        return (
            <div className="modal-dialog" style={{ margin: '0px' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Edit Name</h4>
                    </div>
                    <div className="modal-body form-container">
                        <div className="form-group">
                            <label>Folder Name</label>
                            <input type="text" value={name} className="form-control" id="name" placeholder="Folder Name" onChange={this.handleChange} />
                            {validations.name !== "" && <span className={validations.name === "" ? "hidden" : "has-error"}>{validations.name}</span>}
                        </div>                      
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={this.props.closeEditFolderNameModal}>CANCEL</button>
                        <button type="button" className="btn btn-primary" disabled={!isValidated} onClick={this.handleSubmit}>Save changes</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.contentReducer.loading,
    error: state.contentReducer.error,
    res: state.contentReducer.res
});

export default connect(mapStateToProps)(EditFolderNameModal);