import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import usersReducer from './usersReducer';
import contentReducer from './contentReducer';
import buReducer from './buReducer';
import specialityReducer from './specialityReducer';
import compititorReducer from './compititorReducer';
import pagesReducer from './pagesReducer';
import audioReducer from './audioReducer';
import menuReducer from './menuReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { loadingBarReducer } from 'react-redux-loading-bar'

export default combineReducers({    
    loginReducer,
    usersReducer,
    contentReducer,
    buReducer,
    specialityReducer,
    compititorReducer,
    pagesReducer,
    audioReducer,
    menuReducer,
    loadingBar: loadingBarReducer,
    toastr: toastrReducer
});