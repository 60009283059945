import React, { Component } from "react";
import Popover from 'react-simple-popover';
import Select from 'react-select';
import { connect } from 'react-redux';

import * as  audioActions from '../../actions/audioActions';

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
];

class AudioFilter extends Component {

    constructor() {
        super();
        this.state = {                    
            open: false,            
            specialitylist: [],
            stackholderlist: [],
            productlist: [],
            selectedSpeciality: null,
            selectedStackholder: null,
            selectedProduct: null,
            selectedOption: null,
        }
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.reset = this.reset.bind(this);
    }

    handleClick(e) {
        this.setState({ open: !this.state.open });
    }

    handleClose(e) {
        this.setState({ open: false });
    }

    componentDidMount() {
        // this.props.specialitylist.map(x => {
        //     var model = {};
        //     model.value = x.id;
        //     model.label = x.specialty;
        //     this.state.specialitylist.push(model);
        // });

        // this.props.stackholderlist.map(x => {
        //     var model = {};
        //     model.value = x.id;
        //     model.label = x.title;
        //     this.state.stackholderlist.push(model);
        // });
        
        // this.props.productlist.map(x => {
        //     x.product.map(y => {
        //         var model = {};
        //         model.value = y.id;
        //         model.label = y.name;
        //         this.state.productlist.push(model);
        //     });            
        // });
        this.setState(this.state);
    }

    handleSubmit() {
        var model = {};        

        if (this.state.selectedSpeciality) {
            model.speciality_id = this.state.selectedSpeciality.map(function (x) { return parseInt(x.value) })
        }

        if (this.state.selectedStackholder) {
            model.stakeholder_id = this.state.selectedStackholder.map(function (x) { return parseInt(x.value) })
        }

        if (this.state.selectedProduct) {
            model.product_id = this.state.selectedProduct.map(function (x) { return parseInt(x.value) })
        }

        model.sort_by = this.props.sortby;
        model.order_by = this.props.orderby;
        model.page = 1;

        this.props.search(model);
    }

    reset() {
        this.state.selectedSpeciality = null;
        this.state.selectedStackholder = null;
        this.state.selectedProduct = null;
        this.setState(this.state);
        var model = {};        
        model.sort_by = "name";
        model.order_by = "ASC";
        model.page = 1;
        this.props.search(model);
    }

    handleSpecialityChange = (selectedSpeciality) => {
        this.setState({ selectedSpeciality });
        console.log(`Option selected:`, selectedSpeciality);
    };

    handleStackholderChange = (selectedStackholder) => {        
        this.setState({ selectedStackholder });
        console.log(`Option selected:`, selectedStackholder);
    };

    handleProductChange = (selectedProduct) => {
        this.setState({ selectedProduct });
        console.log(`Option selected:`, selectedProduct);
    };

    render() {

        const { audio, selectedOption,
            specialitylist,
            stackholderlist,
            productlist,
            selectedSpeciality,
            selectedStackholder,
            selectedProduct  } = this.state;
        
        return (
            <div>
                <button type="button" className="btn btn-default" ref="audiofilter" onClick={this.handleClick.bind(this)}>
                    Filter <i className="fa fa-filter"></i>
                </button>
                <Popover
                    placement='bottom'
                    target={this.refs.audiofilter}
                    show={this.state.open}
                    style={{ padding: '10px' }}
                    containerStyle={{ marginLeft: '20px', zIndex: 999 }}
                    onHide={this.handleClose.bind(this)} >
                    
                    <div className="form-group">
                        <label>Specialty</label>                                            
                        <Select
                            id= "speciality_id"
                            value={selectedSpeciality}
                            onChange={this.handleSpecialityChange}
                            options={specialitylist}
                            isMulti="true"
                            isSearchable="true"
                        />
                    </div>
                    <div className="form-group">
                        <label>Stakeholder</label>                                            
                        <Select
                            id="stakeholder_id"
                            value={selectedStackholder}
                            onChange={this.handleStackholderChange}
                            options={stackholderlist}
                            isMulti="true"
                            isSearchable="true"
                        />
                    </div>
                    <div className="form-group">
                        <label>Product</label>                     
                        <Select
                            id="product_id"
                            value={selectedProduct}
                            onChange={this.handleProductChange}
                            options={productlist}
                            isMulti="true"
                            isSearchable="true"
                        />
                    </div>
                    <div className="clearfix">                                               
                        <button type="button" className="btn btn-primary blue-btn pull-left" onClick={this.reset}>Reset</button>
                        <button type="button" className="btn btn-primary blue-btn pull-right" onClick={this.handleSubmit}>Search</button>
                    </div>
                </Popover>                
            </div>
        );
    }
}

export default connect()(AudioFilter);