import { BASE_URL, ENCRYPTION_KEY } from '../../constants/constants';
import React, { Component } from "react";
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import Modal from 'react-responsive-modal';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    arrayMove,
} from 'react-sortable-hoc';
import { Redirect } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import AddToContentModal from './addToContentModal';
import axios from 'axios';
import * as  pageActions from '../../actions/pagesActions';
import DeleteConfirmModal from '../common/deleteConfirmModal';
import { Link } from 'react-router-dom';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class AddPage extends Component {

    constructor() {
        super()
        this.state = {
            image_base_url: BASE_URL.replace("api/v7/", "") + "uploads/pages/",
            item: {},
            isSearch: false,
            id: '',
            action: '',
            name: '',
            subtitle: '',
            description: '',
            tags: '',
            status: '0',
            page_logo: null,
            content: [],
            excontent: [],
            finalcontent: [],
            strexcontent: [],
            deletecontent: [],
            validations: {
                name: '',
                subtitle: ''
            },
            redirect: false,
            uploadedImg: null,
            IsAddToContentsModalOpen: false,
            items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'],
            login_user_id: null,
            page_logo_max_size: false,
            page_logo_size: null,
            page_logo_name: '',
            isInvalidFile: false,
            IsDeleteConfirmModalOpen: false,
            IsDeleteContentConfirmModalOpen: false,
            selectedPages: [],
            pageContentId: 0,
            pageContentList: []
        };

        this.postPage = this.postPage.bind(this);
        this.contentUpload = this.contentUpload.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleselectedFile = this.handleselectedFile.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.appendExistingContent = this.appendExistingContent.bind(this);
        this.reUpload = this.reUpload.bind(this);
        this.uploadLogo = this.uploadLogo.bind(this);
        this.btnUploadLogo = React.createRef();
        this.updateTags = this.updateTags.bind(this);
        this.openDeleteContentConfirmModal = this.openDeleteContentConfirmModal.bind(this)
        this.removeItems = this.removeItems.bind(this);
    }

    openAddToContentsModal = () => {
        this.setState({ IsAddToContentsModalOpen: true });
    };

    closeAddToContentsModal = () => {
        this.setState({ IsAddToContentsModalOpen: false });
    };

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/pages' />
        }
    }

    uploadLogo() {
        this.props.dispatch(pageActions.ShowLoading());
        this.btnUploadLogo.current.click();
        this.props.dispatch(pageActions.HideLoading());
    }

    onDrop(files) {

        files.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            computedSize: this.bytesToSize(file.size),
            loaded: 0,
            error: false
        }))

        files.forEach(file => {
            this.state.content.push(file);
        })

        this.setState(this.state);
    }

    removeItems(items) {
        var index = this.state.pageContentList.findIndex(x => x.id == items[0]);
        this.state.pageContentList.splice(index, 1);
    }

    bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
    }

    handleChange(e) {
        switch (e.target.id) {
            case "name":
                if (e.target.value === "") {
                    this.setState({ name: e.target.value, validations: { ...this.state.validations, name: 'Name is required' } });
                }
                else {
                    var remaining = 35 - e.target.value.length;
                    this.setState({ name: e.target.value, validations: { ...this.state.validations, name: '' } });
                }
                break;
            case "subtitle":
                if (e.target.value === "") {
                    this.setState({ subtitle: e.target.value, validations: { ...this.state.validations, subtitle: 'Subtitle is required' } });
                }
                else {
                    this.setState({ subtitle: e.target.value, validations: { ...this.state.validations, subtitle: '' } });
                }
                break;
            default:
                this.setState({ [e.target.id]: e.target.value });
                break;
        }
    }

    handleselectedFile(e) {

        if (e.target.files[0].size <= 200000) {
            if (e.target.files[0].type.indexOf("image/") == 0) {
                this.state.page_logo = e.target.files[0];
                this.state.isInvalidFile = false;
                this.state.page_logo_name = e.target.files[0].name;
                let that = this;
                var reader = new FileReader();
                reader.onload = function (e) {
                    that.state.uploadedImg = e.target.result;
                    that.state.page_logo_max_size = false;
                    that.state.page_logo_size = null;
                    that.setState(that.state);
                };
                reader.readAsDataURL(e.target.files[0]);
            }
            else {
                this.setState({ isInvalidFile: true, page_logo_name: '', uploadedImg: null });
                e.target.value = null;

            }
        }
        else {
            this.setState({ page_logo_max_size: true, page_logo_size: this.bytesToSize(e.target.files[0].size), page_logo_name: '', uploadedImg: null });
            e.target.value = null;
        }
    }

    postPage() {
        const formData = new FormData();

        if (this.state.action === "edit" && this.state.id !== "") {
            formData.append('id', this.state.id);
        }
        formData.append('name', this.state.name);
        formData.append('subtitle', this.state.subtitle);
        formData.append('description', this.state.description);
        formData.append('tags', this.state.tags);
        formData.append('status', this.state.status);

        if (this.state.page_logo != null && this.state.page_logo.name !== undefined) {
            formData.append('page_logo', this.state.page_logo, this.state.page_logo.name);
        }

        var url = BASE_URL + "pages/adminpageadd";
        this.props.dispatch(pageActions.ShowLoading());
        let that = this;
        axios.post(url, formData)
            .then(function (res) {

                if (res.data.success == "1") {
                    if (that.state.content.length > 0 || that.state.deletecontent.length > 0) {
                        that.contentUpload(res.data.records.id);
                        if (that.state.action === "add" && that.state.id === "") {
                            that.state.id = res.data.records.id;
                            that.setState(that.state);
                        }
                    } else {
                        if (that.state.action === "add") {
                            that.setRedirect();
                        }
                    }
                    toastr.success("Success", res.data.message);
                    that.props.dispatch(pageActions.HideLoading());
                }
                else {
                    toastr.error("Error", res.data.message);
                    that.props.dispatch(pageActions.HideLoading());
                }

            })
            .catch(function (error) {

            });
    }

    contentUpload(pageId) {
        this.props.dispatch(pageActions.ShowLoading());
        let that = this;

        if (this.state.deletecontent.length > 0) {
            var model = {};
            model.page_id = that.state.id;
            model.content_id = that.state.deletecontent;

            var url = BASE_URL + "content/adminupdatepagecontentlist";

            axios.post(url, model)
                .then(function (res) {

                    that.props.dispatch(pageActions.HideLoading());
                    if (res.data.success === "1") {
                        //toastr.success("Success", res.data.message);
                        if (that.state.content.filter(x => !x.assigned).length === 0) {
                            if (that.state.action === "add") {
                                that.setRedirect();
                            }
                        }
                    }
                    else {
                        toastr.error("Error", res.data.message);
                    }

                })
                .catch(function (error) {

                });
        }


        this.state.content.forEach(function (content, index) {
            if (content.assigned !== true) {
                if (content.id === undefined) {
                    const formData = new FormData();
                    var page_id = [];
                    page_id.push(pageId);
                    formData.append('page_id', page_id);
                    formData.append('content', content, content.name);

                    formData.append('user_id', that.state.login_user_id);

                    var url = BASE_URL + "content/admincontentadd";

                    axios.post(url, formData, {
                        onUploadProgress: ProgressEvent => {

                            that.state.content[index].loaded = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100);
                            that.setState(that.state);
                        }
                    })
                        .then(function (res) {

                            if (res.data.success === "1") {
                                that.state.content[index].uploaded = true;
                                that.setState(that.state);
                            }
                            else {
                                that.state.content[index].error = true;
                                that.state.content[index].errorMsg = res.data.message;
                                that.setState(that.state);
                            }

                            var errContent = that.state.content.filter(x => x.error === true);
                            if (index === that.state.content.length - 1) {
                                if (errContent.length > 0) {
                                    toastr.error("Error", errContent.length + " Errors in file upload !");
                                }
                                else {
                                    toastr.success("Success", res.data.message);
                                    if (that.state.action === "add") {
                                        that.setRedirect();
                                    }
                                }
                                that.props.dispatch(pageActions.HideLoading());
                            }

                        })
                        .catch(function (error) {
                            that.state.content[index].error = true;
                            that.state.content[index].errorMsg = error;
                            that.setState(that.state);
                            that.props.dispatch(pageActions.HideLoading());
                        });
                }
                else {
                    var url = BASE_URL + "content/adminaddcontenttopage";
                    var model = {};
                    model.content_id = [];
                    model.content_id.push(content.id);
                    model.page_id = [];
                    model.page_id.push(pageId);

                    axios.post(url, model)
                        .then(function (res) {

                            that.state.content[index].uploaded = true;
                            that.setState(that.state);
                            if (index === that.state.content.length - 1 && that.state.content.filter(x => x.error === true).length === 0) {
                                that.props.dispatch(pageActions.HideLoading());
                                toastr.success("Success", res.data.message);
                                that.setRedirect();
                            }
                        })
                        .catch(function (error) {

                        });
                }
            } else {
                that.props.dispatch(pageActions.HideLoading());
            }
        });
    }

    reUpload(e) {
        var index = e.target.dataset.index;
        var pageId = this.state.id;
        let that = this;
        const formData = new FormData();
        var page_id = [];
        page_id.push(pageId);
        var content = this.state.content[index];

        formData.append('page_id', page_id);
        formData.append('content', content, content.name);
        formData.append('user_id', that.state.login_user_id);

        var url = BASE_URL + "content/admincontentadd";
        this.props.dispatch(pageActions.ShowLoading());
        axios.post(url, formData, {
            onUploadProgress: ProgressEvent => {
                that.state.content[index].loaded = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100);
                that.setState(that.state);
            }
        })
            .then(function (res) {

                if (res.data.success === "1") {
                    that.state.content[index].uploaded = true;
                    that.setState(that.state);
                }
                else {
                    that.state.content[index].error = true;
                    that.state.content[index].errorMsg = res.data.message;
                    that.setState(that.state);
                    toastr.error("Error", res.data.message);
                }

                that.props.dispatch(pageActions.HideLoading());
            })
            .catch(function (error) {
                that.state.content[index].error = true;
                that.state.content[index].errorMsg = error;
                that.setState(that.state);
                that.props.dispatch(pageActions.HideLoading());
            });
    }

    componentDidMount() {
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.action === "add") {
                this.state.content = [];
                this.state.excontent = [];
                this.state.finalcontent = [];
                this.state.strexcontent = [];
                this.state.deletecontent = [];
                this.state.login_user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id
                this.state.action = this.props.location.state.action;
            } else if (this.props.location.state.action === "edit") {
                this.state.action = this.props.location.state.action;
                this.state.content = [];
                this.state.excontent = [];
                this.state.finalcontent = [];
                this.state.strexcontent = [];
                this.state.deletecontent = [];
                this.state.id = this.props.location.state.id;
                this.state.name = this.props.location.state.item.name;
                this.state.subtitle = this.props.location.state.item.subtitle;
                this.state.description = this.props.location.state.item.description;
                this.state.page_logo = this.props.location.state.item.page_logo;
                this.state.tags = this.props.location.state.item.tags;
                this.state.status = this.props.location.state.item.status;
                this.state.login_user_id = JSON.parse(cryptr.decrypt(Storage.get('user'))).id
                this.setState(this.state);
                var model = {};
                model.page_id = this.props.location.state.id;
                this.props.dispatch(pageActions.GetPageContents(model));
                this.props.dispatch(pageActions.GetPageBUList(model));
            }
        }
        else {
            this.setRedirect();
        }
    }

    removeFile(e) {
        var isDelete = e.target.dataset.delete;
        if (isDelete) {
            var id = parseInt(e.target.dataset.id);
            this.state.deletecontent.push(id);
        }
        if (id !== undefined) {
            var id = parseInt(e.target.dataset.id);
            var index = this.state.strexcontent.findIndex(x => x.id === id);
            this.state.strexcontent.splice(index, 1);
        }
        var i = parseInt(e.target.dataset.index);
        this.state.content.splice(i, 1);
        this.setState(this.state);
    }

    onSortEnd({ oldIndex, newIndex }) {
        var old = this.state.content;
        this.state.content = arrayMove(old, oldIndex, newIndex);
        //this.setState({
        //    content: arrayMove(this.state.content, oldIndex, newIndex),
        //});        
        this.setState(this.state);
    }

    appendExistingContent(exContent) {
        exContent.map(item => {
            if (this.state.strexcontent.filter(x => x.id === item.id).length === 0) {
                this.state.strexcontent.push(item);
            }
            if (this.state.content.filter(x => x.id === item.id).length === 0) {
                this.state.content.push(item);
            }
        });
        this.setState(this.state);
    }

    updateTags(e) {

        let model = {};
        model.page_id = this.state.id;
        model.bu_id = e.target.dataset.buid;
        model.status = e.target.dataset.status == 'false' ? 'true' : 'false';

        this.props.dispatch(pageActions.EditPageTags(model));
    }

    search() {
        this.state.isSearch = !this.state.isSearch;
        if (this.state.isSearch) {
            this.searchInput.focus();
        }
        else {
            this.setState({ search: '' });
            var model = {};
            model.page_id = this.state.pageDetail.id;
            model.search = '';
            this.props.dispatch(pageActions.GetPageContents(model));
        }
    }

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    openDeleteContentConfirmModal = (e) => {
        this.state.pageContentId = e.target.dataset.contentid;
        this.setState({ IsDeleteContentConfirmModalOpen: true });
    };

    closeDeleteContentConfirmModal = () => {
        this.setState({ IsDeleteContentConfirmModalOpen: false });
    };

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/pages' />
        }
    }

    render() {
        var remainingcharacters = '35 CHARACTERS REMAINING';
        var isValidated = false;
        const { page_logo_name, page_logo_size, page_logo_max_size, isInvalidFile, items, item, strexcontent, image_base_url, id, uploadedImg, name, subtitle, description, tags, page_logo, status, content, IsAddToContentsModalOpen, validations, IsDeleteConfirmModalOpen, isSearch, pageContentList } = this.state;
        const { loading, pagelist } = this.props;
        if (validations.name === "" && validations.subtitle === "") {
            if (name !== "") {
                var remaining = 35 - name.length;
                remainingcharacters = remaining + ' CHARACTERS REMAINING';
            }
            if (name !== "" && subtitle !== "") {
                isValidated = true;
            }
        }
        else {
            isValidated = false;
        }


        const { pagecontentlist, pagebulist, res, contentassigned } = this.props;

        const bytesToSize = (bytes) => {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes === 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
        }

        contentassigned.map(x => {
            x.computedSize = bytesToSize(x.file_size)
        });

        contentassigned.map(x => {
            if (x.file_name !== null) {
                x.extension = x.file_name.slice(x.file_name.indexOf('.')).replace('.', '')
            }
        });

        if (contentassigned && contentassigned.length > 0) {
            this.state.pageContentList = contentassigned;
        }

        if (pagecontentlist.length > 0) {
            pagecontentlist.map((item, index) => {

                if (strexcontent.filter(x => x.id === item.id).length === 0) {
                    this.state.strexcontent.push(item);
                }

                if (content.filter(x => x.id === item.id).length === 0) {
                    var model = {};
                    model.id = item.id;
                    var bytes = item.file_size;
                    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                    if (bytes === 0) return '0 Byte';
                    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

                    model.computedSize = Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
                    model.name = item.content_name;
                    model.existing = false;
                    model.uploaded = false;
                    model.assigned = true;
                    this.state.content.push(model);
                }

                if (index === pagecontentlist.length - 1) {
                    this.props.dispatch(pageActions.ClearPageContentList());
                }
            })
        }


        const DragHandle = SortableHandle(() => <span className="pull-right"><i class="la la-bars"></i></span>);

        const SortableItem = SortableElement(({ file, index }) => {

            if (file.id !== undefined) {
                return (
                    <li key={file.index}>
                        <DragHandle />
                        <a data-index={file.index} data-id={file.id} data-delete={file.assigned} className="remove-file" onClick={this.removeFile}><i data-delete={file.assigned} data-index={file.index} data-id={file.id} className="la la-times-circle"></i></a>
                        <div className="file-info">
                            <p className="file-name">{file.name}</p>
                            <ul className="file-progress">
                                <li>{file.computedSize}</li>
                                {file.existing === true && <li className="text-info"><i className="la la-cube"></i> Selected from Content </li>}
                                {(file.uploaded === true || file.assigned === true) && <li className="text-success"><i className="la la-check-circle"></i> uploaded </li>}
                                {/*file.assigned === true && <li className="text-warning"><i className="la la-check-circle"></i> Assigned </li>*/}
                            </ul>
                        </div>
                    </li>
                );
            }
            else {
                return (
                    <li key={file.index}>
                        <DragHandle />
                        <a data-index={file.index} className="remove-file" onClick={this.removeFile}><i data-index={file.index} className="la la-times-circle"></i></a>
                        <div className="file-info">
                            <p className="file-name">{file.name}</p>
                            <ul className="file-progress">
                                <li>{file.computedSize}</li>
                                {file.loaded > 0 && file.loaded < 100 && <li>{file.loaded}%... </li>}
                                {file.uploaded === true && <li className="text-success"><i className="la la-check-circle"></i> uploaded </li>}
                                {file.error === true && <li className="text-danger"><i className="la la-exclamation-triangle"></i> {file.errorMsg} <a href="javascript:void(0);" data-index={file.index} onClick={this.reUpload} className="btn-retry text-danger">Retry</a></li>}
                                {file.existing === true && <li className="text-info"><i className="la la-check-circle"></i> Selected from Content </li>}

                            </ul>
                        </div>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" aria-valuenow={file.loaded}
                                aria-valuemin="0" aria-valuemax="100" style={{ width: '' + file.loaded + '%' }}>
                                <span className="sr-only">{file.loaded}% Complete</span>
                            </div>
                        </div>
                    </li>
                );
            }

        });

        const SortableList = SortableContainer(({ items }) => {
            items.map((file, i) => {
                file.index = i
            });
            return (
                <ul className="upload-list upload-list-page">
                    {items.map((value, i) => (
                        <SortableItem key={`item-${i}`} index={i} file={value} />
                    ))}
                </ul>
            );
        });

        if (res != null && res.success === "1") {
            toastr.success("Success !", res.message);
            var model = {};
            model.page_id = id;
            this.props.dispatch(pageActions.GetPageBUList(model));
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        const header = { 'display': 'flex', 'height': '90px' };
        const pointer = { cursor: 'pointer' };

        return (
            <div className="container">
                {this.renderRedirect()}

                {/* <div style={header}>
                    <h1 className="page-title">Page Editer</h1>
                </div> */}

                <div className="detail-title">


                    <button className="btn btn-default" onClick={this.setRedirect}><i className="la la-mail-reply"></i> BACK</button>

                    <h1>Page Editor</h1>
                </div>

                <div className="action-row clearfix add-action-row">
                    <div className="btn-left">
                        {/* onClick={this.setRedirect} <button className="btn btn-default btn-border"><i className="la la-mail-reply"></i> BACK</button> */}

                        <button className="btn btn-default save-btn" disabled={!isValidated} onClick={this.postPage}><i className="la la-check-circle"></i> SAVE</button>
                    </div>
                    {this.state.action === "edit" && <div className="btn-right">
                        <button className="btn btn-default btn-delete" onClick={this.openDeleteConfirmModal}>
                            <i className="la la-trash-o"></i> DELETE
                    </button>

                    </div>}
                </div>
                <div className="form-container pageeditor_block">
                    <div className="row">
                        <div className="col-md-3">
                            <ul className="add_page_list">
                                {/* <li><button style={{ color: '#009ddc' }}>Page Details</button></li>                       */}
                            </ul>
                        </div>
                        <div className="col-md-6">

                            <h3 className="form-title">
                                Details
                            </h3>
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label>Title*</label>
                                    <input type="text" id="name" value={name} className="form-control" onChange={this.handleChange} maxLength="35" />
                                    <label className="pull-right remain_char">{remainingcharacters}</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <label>SubTitle*</label>
                                    <input type="text" id="subtitle" value={subtitle} className="form-control" onChange={this.handleChange} maxLength="40" />
                                </div>
                                {pagebulist && pagebulist.records && pagebulist.records.length > 0 && <div className="col-md-12 form-group">
                                    <label>Tags</label>
                                    <div className="ub-list">
                                        {pagebulist.records.map(item => (
                                            <div Key={item.id} className={item.status == 'true' ? 'ub active' : 'ub'} data-buid={item.id} data-status={item.status} onClick={this.updateTags.bind(this)}>{item.name}</div>
                                        ))}
                                    </div>
                                </div>}
                                <div className="col-md-12 form-group">
                                    <label>Description</label>
                                    <textarea rows="6" id="description" value={description} onChange={this.handleChange}></textarea>
                                </div>
                                <div className="col-md-12 form-group img_upload_block">
                                    <label>icon</label>
                                    <p>Select an icon for your page. Square images under 200KB work best. If your image is too large, don't worry! We'll do our best to resize it for you.</p>
                                    <div className="show_upload_img_block">
                                        {uploadedImg === null && page_logo !== null && <img src={image_base_url + page_logo} width="100" height="100" />}
                                        {uploadedImg !== null && <img src={uploadedImg} width="100" height="100" />}
                                    </div>
                                    <div className="upload_img_aria">
                                        <label style={{ 'textTransform': 'initial' }}>Please upload an icon image</label>
                                        <input type="text" className="form-control" value={page_logo_name} />
                                        <input ref={this.btnUploadLogo} type="file" className="form-control hidden" accept="image/*" onChange={this.handleselectedFile} />
                                        {page_logo_max_size && <label className="text-danger" style={{ marginTop: '10px' }}>File size {page_logo_size} exeeds 200KB !</label>}
                                        {isInvalidFile && <label className="text-danger" style={{ marginTop: '10px' }}>Please upload image file.</label>}
                                        {/*<button className="btn btn-default black-btn upload_img_btn" data-toggle="modal" data-target="#importModal">UPLOAD ICON IMAGE</button>*/}
                                        <button className="btn btn-default black-btn upload_img_btn" onClick={this.uploadLogo}><i class="la la-level-up"></i>UPLOAD ICON IMAGE</button>
                                    </div>
                                </div>
                                <div className="col-md-5 form-group">
                                    <label>visibility</label>
                                    <select className="form-control" id="status" value={status} onChange={this.handleChange}>
                                        <option className="text-danger" value="0">Hidden</option>
                                        <option className="text-success" value="1">Active</option>
                                    </select>
                                </div>
                                <div className="col-md-12">
                                    <h3 className="form-title">Content</h3>
                                    {/* <label className="content_desc">Add, remove and manage content will be displayed on this page.</label> */}

                                    <Dropzone

                                        onDrop={this.onDrop.bind(this)} >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <div className="upload-img custom-border">
                                                    <i className="la la-cloud-upload"></i>
                                                    <p>Drag and drop content here</p>
                                                    <span>or</span>
                                                    <span className="btn black-btn choose-file">CHOOSE FILES</span>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                    <label><b>Note : </b>Click Save to upload.</label>
                                    <div className="img_set_block">
                                        <button className="btn btn-default save-btn" onClick={this.openAddToContentsModal}>
                                            <i className="la la-cube"></i>SELECT EXISTING CONTENT HERE
                                    </button>
                                    </div>
                                    <div className="sortable_head">
                                        <label>Uploaded &amp; selected content</label>
                                        <ul className="sortable_head_list">
                                            <li>{this.state.pageContentList.length} files </li>
                                            <li>|</li>
                                            <li className="text-danger">  {content.filter(x => x.error === true).length} Errors</li>
                                        </ul>
                                    </div>
                                    {this.state.pageContentList !== null && this.state.pageContentList.length > 0 &&
                                        <div className="pages-list mCustomScrollbar" data-mcs-theme="dark">
                                            <ul>
                                                {this.state.pageContentList.map((item, index) => (
                                                    <li key={index} className="page-content-item">
                                                        <i className="fa fa-close" data-contentId={item.id} style={{ cursor: 'pointer' }} onClick={this.openDeleteContentConfirmModal}></i> &nbsp;
                                                        <Link to={{ pathname: '/content/details?content_id=' + item.id, state: { item: item } }} target="_blank">
                                                            <label style={{ cursor: 'pointer' }}>
                                                                {item.content_type === 'folder' && item.is_child === true && <img src="../images/content-file.png" style={pointer} className="file-img img-responsive" data-id={item.id} data-name={item.content_name} onClick={this.addParent} />}
                                                                {/* {item.content_type !== 'folder' && item.content_type === 'videos' && item.extension === 'mp4' && <img src="../images/content-video.png" className="file-img img-responsive" />}
                                                    {item.content_type !== 'folder' && item.content_type === 'files' && item.extension === 'mp3' && <img src="../images/content-video.png" className="file-img img-responsive" />}
                                                    {item.content_type !== 'folder' && item.content_type === 'files' && item.extension === 'xlsx' && <img src="../images/excel.png" className="file-img img-responsive" />}
                                                    {item.content_type !== 'folder' && item.content_type === 'files' && item.extension === 'docx' && <img src="../images/word.png" className="file-img img-responsive" />}
                                                    {item.content_type !== 'folder' && item.path !== null && item.content_type === 'files' && (item.extension !== 'mp4' && item.extension !== 'mp3' && item.extension !== 'xlsx' && item.extension !== 'docx') && <img src={image_base_url + item.path + item.file_name} className="file-img img-responsive" onError={(e) => { e.target.onerror = null; e.target.src = "../images/no-image.png" }} />}
                                                    {item.content_type !== 'folder' && item.path === null && item.content_type === 'files' && (item.extension !== 'mp4' && item.extension !== 'mp3' && item.extension !== 'xlsx' && item.extension !== 'docx') && <img src={image_base_url + item.file_name} className="file-img img-responsive" onError={(e) => { e.target.onerror = null; e.target.src = "../images/no-image.png" }} />} */}
                                                                {item.content_type === 'files' && item.file_name === '' && <img style={{ display: 'inline-block', width: '30px' }} src="../images/no-image.png" className="img-responsive" />}
                                                                {item.content_type === 'files' && item.file_name !== '' && (item.extension === 'png' || item.extension === 'jpg' || item.extension === 'jpeg' || item.extension === 'pjpeg') && <img src={item.file_path} className="img-responsive" />}
                                                                {item.content_type === 'files' && item.file_name !== '' && (item.extension !== 'png' && item.extension !== 'jpg' && item.extension !== 'jpeg' && item.extension !== 'pjpeg') && <img src="../images/file-icon-48X48.png" className="img-responsive" />}
                                                                <span className="page-info">
                                                                    <span className="page-name ellipsis">{item.content_name}</span>
                                                                    <span className="ellipsis page-text">{item.computedSize}</span>
                                                                </span>
                                                            </label>
                                                        </Link>

                                                    </li>
                                                ))}

                                            </ul>
                                        </div>}
                                    {contentassigned !== null && contentassigned.length === 0 && <div className="text-center">
                                        {isSearch && <label className="lblNoRecord">Nothing matches that search. Sorry!</label>}
                                        {!isSearch && <label className="lblNoRecord">No Record Found!</label>}
                                    </div>
                                    }
                                    <div className="upload-detail selected-file-list mCustomScrollbar" data-mcs-theme="dark">
                                        <SortableList items={content} onSortEnd={this.onSortEnd} useDragHandle={true} helperClass="list_sortable clearfix" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Modal open={this.state.IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal} title="Delete Page" message="Are you sure you want to delete this page?"
                        Id={this.state.id} action="page-delete-from-detail"
                        orderby={'asc'} sortby={'name'} setRedirect={this.setRedirect} />
                </Modal>
                <Modal open={IsAddToContentsModalOpen} center showCloseIcon={false} onClose={this.closeAddToContentsModal}>
                    <AddToContentModal closeAddToContentsModal={this.closeAddToContentsModal}
                        Id={id} appendExistingContent={this.appendExistingContent} strexcontent={strexcontent} updatedContent={content} />
                </Modal>

                <Modal open={this.state.IsDeleteContentConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteContentConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteContentConfirmModal} title="Delete Page Content" message="Are you sure you want to delete this page content?"
                        Id={this.state.id} PageContentId={this.state.pageContentId} action="page-content-delete-from-detail"
                        orderby={'asc'} sortby={'name'} removeItems={this.removeItems} />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.pagesReducer.loading,
    error: state.pagesReducer.error,
    pagecontentlist: state.pagesReducer.pagecontentlist,
    pagebulist: state.pagesReducer.pagebulist,
    res: state.pagesReducer.res,
    contentassigned: state.pagesReducer.contentassigned
});

export default connect(mapStateToProps)(AddPage);