import * as $ from 'jquery'; 

export const Initialize = () => {
    $(document).on('click', '.sb-content .parent > span i.fa', function () {
        $(this).parents(".parent_class").next("ul").slideToggle();
        $(this).parents(".parent_class").toggleClass("open_menu");      
    });  

    $(document).on('click', '.log-out', function () {
        $('body').removeClass('menu-open');
    });  
}