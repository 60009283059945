import { BASE_URL, errOptions } from '../../constants/constants';
import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import * as  specialityActions from '../../actions/specialityActions';
import * as  buActions from '../../actions/buActions';
import InfiniteScroll from "../common/infinitescroll";

class SpecialityModal extends Component {

    constructor() {
        super()
        this.state = {
            image_base_url: BASE_URL.replace("api/v7/", "") + "uploads/pages/",
            selectedSpeciality: [],
            search: '',
            isSelectAll: false,
            activePage: 1,
            isSearch: false,
            items: [],
            isScroll: true,
            totalRecords: null,
            hasMore: true,
            randomError: ''
        };

        this.specialitySelection = this.specialitySelection.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
    }


    componentWillReceiveProps() {
        this.props.dispatch(specialityActions.ClearSpecialityList());
    }

    componentDidMount() {
        var model = {};
        model.sort_by = 'name';
        model.order_by = 'ASC';
        model.page = this.state.activePage;
        this.props.dispatch(specialityActions.GetSpecialityList(model));
    }

    keyPress(e) {
        var randomError = this.generateRandomMesage(this.state.randomError);
        this.setState({ activePage: 1, items: [], isScroll: true, selectedSpeciality: [], totalRecords: null, hasMore: true, randomError: randomError });
        var model = {};
        model.sort_by = 'name';
        model.order_by = 'ASC';
        model.search = this.state.search.toLowerCase();
        model.page = 1;
        this.props.dispatch(specialityActions.GetSpecialityList(model));
    }

    generateRandomMesage(randomError) {
        var str = errOptions[Math.floor(Math.random() * Math.floor(5))];
        if (randomError == str) {
            str = this.generateRandomMesage();
        }
        return str;
    }

    specialitySelection(e) {        
        var id = e.target.id.replace("chk", "");
        if (e.target.checked) {
            if (this.state.selectedSpeciality.length + 1 === this.state.items.length) {
                this.setState({ isSelectAll: true, selectedSpeciality: [...this.state.selectedSpeciality, id] });
            }
            else {
                this.setState({ selectedSpeciality: [...this.state.selectedSpeciality, id] });
            }
        }
        else {
            this.setState({
                isSelectAll: false,
                selectedSpeciality: this.state.selectedSpeciality.filter((eid) => eid !== id)
            });
        }
    }

    selectAll(e) {        
        if (e.target.checked) {
            this.state.selectedSpeciality = this.state.items.map((item) => {
                return item.id;
            });
            this.state.isSelectAll = true;
        }
        else {
            this.state.selectedSpeciality = [];
            this.state.isSelectAll = false;
        }
        this.setState(this.state);
    }

    handleSearchChange(e) {
        if (e.target.name === "search") {
            this.setState({ search: e.target.value });
        }
    }

    search() {
        this.state.isSearch = !this.state.isSearch;
        if (this.state.isSearch) {
            this.searchInput.focus();
        }
        else {
            this.setState({ activePage: 1, search: '', items: [], isScroll: true, selectedSpeciality: [], totalRecords: null, hasMore: true });
            var model = {};
            model.sort_by = 'name';
            model.order_by = 'ASC';
            model.search = '';
            model.page = 1;
            this.props.dispatch(specialityActions.GetSpecialityList(model));
        }
    }

    handleSubmit() {
        var model = {};
        model.bu_id = this.props.buid;
        model.sp_id = this.state.selectedSpeciality;

        this.props.dispatch(specialityActions.AddSpecialityToBU(model));
    }

    fetchMoreData() {
        this.state.activePage += 1;
        this.state.isScroll = true;
        var model = {};
        model.sort_by = 'name';
        model.order_by = 'ASC';
        if (this.state.search !== "") {
            model.search = this.state.search;
        }
        model.page = this.state.activePage;
        this.props.dispatch(specialityActions.GetSpecialityList(model));
    }

    render() {

        const { selectedSpeciality, isSelectAll, search,
            isScroll, items, hasMore, randomError, isSearch } = this.state;
        const { loading, specialitylist, buspres } = this.props;
        
        if (!loading && isScroll && specialitylist != null && specialitylist.records && specialitylist.records.length > 0 && this.state.items.length < specialitylist.total_records) {
            
            if (!items.find(x => x.id == specialitylist.records[0].id)) {

                var hasMoreRef = ((items.length + specialitylist.records.length) == specialitylist.total_records) ? false : true;
                var selectedSpecialitylist = [];

                selectedSpecialitylist = this.props.selectedSpecialities.map((item) => {
                    return item.sp_id;
                    });

                this.setState({
                    isScroll: false,
                    totalRecords: specialitylist.total_records,
                    selectedSpeciality: selectedSpeciality.concat(selectedSpecialitylist),
                    items: items.concat(specialitylist.records),
                    isSelectAll: specialitylist.records.length == selectedSpecialitylist.length ? true : false,
                    hasMore: hasMoreRef
                });
            }
        }

        if (buspres != null && buspres.success === "1") {            
           
            toastr.success("Success !", buspres.message);            
         
            var model = {};
            model.bu_id = [];
            model.bu_id.push(this.props.buid);
            model.sort_by = "name";
            model.order_by = "ASC";
            model.page = this.state.activePage;
            model.search = "";            

            this.props.dispatch(buActions.initiateState());
            this.props.dispatch(buActions.ClearBUSpecialityList());
            this.props.enableScroll();
            this.props.dispatch(buActions.GetBUSpecialityList(model));              
            this.props.closeSpecialityModal();
        }
        else if (buspres != null && buspres.success === "0") {
            toastr.error('Error', buspres.message);
        }

        const loadingClass = { 'textAlign': 'center', 'fontSize': '30px' };

        return (
            <div className="addcontent_modal add_page_modal specialities_modal">
                <div className="modal-dialog" style={{ margin: '0px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Specialties</h4>
                        </div>
                        <div className="modal-body">
                            <div className="assigned-pages">
                                <div className="action-row clearfix action-row-checkbox">
                                    <div className="custom-checkbox pull-left">
                                        <input type="checkbox" id="chk0" onChange={this.selectAll} checked={isSelectAll} />
                                        <label htmlFor="chk0"></label>
                                    </div>
                                    <div className="search-form search-form-modal pull-right">
                                        <input ref={(searchInput) => { this.searchInput = searchInput; }}
                                            type="search" className="form-control" value={search} name="search"
                                            onKeyDown={this.keyPress} onChange={this.handleSearchChange} />
                                        <button type="submit" className="search-btn addToPagesSearch" onClick={this.search}>
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                {items.length > 0 &&
                                    <div id="scrollableDiv" className="pages-list mCustomScrollbar" data-mcs-theme="dark">
                                        {items.length > 0 && <InfiniteScroll
                                            target="list"
                                            dataLength={items.length}
                                            scrollThreshold="300px"
                                            next={this.fetchMoreData}
                                            hasMore={hasMore}
                                            scrollableTarget="scrollableDiv"
                                            loader={<li className="bottom-0" style={loadingClass}>Loading...</li>} >

                                            {items.map(item => (
                                                <li key={item.id}>
                                                    <div className="custom-checkbox">
                                                        <input type="checkbox" id={'chk' + item.id} onChange={this.specialitySelection} checked={(selectedSpeciality.indexOf(item.id) > -1)} />
                                                        <label htmlFor={'chk' + item.id}>                                                            
                                                            <span className="page-info">
                                                                <span className="page-name">{item.name}</span>                                                                
                                                            </span>
                                                        </label>
                                                    </div>
                                                </li>
                                            ))}
                                        </InfiniteScroll>}
                                    </div>}
                                {specialitylist !== null && specialitylist.records !== undefined && specialitylist.records.length === 0 && <div className="text-center">
                                    {isSearch && <label className="lblNoRecord">{randomError}</label>}
                                    {!isSearch && <label className="lblNoRecord">No Record Found !</label>}
                                </div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="modal-footer-inner">
                                <button type="button" className="btn btn-default" onClick={this.props.closeSpecialityModal}>CANCEL</button>
                                <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>UPDATE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.specialityReducer.loading,
    error: state.specialityReducer.error,
    specialitylist: state.specialityReducer.specialitylist,
    buspres: state.specialityReducer.buspres
});

export default connect(mapStateToProps)(SpecialityModal);