import { ENCRYPTION_KEY } from '../../constants/constants';
import React, { Component } from "react";
import FileUpload from '../common/fileUpload';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class AudioUpdateModal extends Component {

    constructor() {
        super();
    }

    render() {

        var actionName = "Update Talk Track Audio";
        var desc = "Choose either of the methods below to upload updated talk track audio. This will remove and replace the current talk track audio.";

        return (
            <div className="addcontent_modal">
                <div className="modal-dialog" style={{ margin: '0px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{actionName}</h4>
                        </div>
                        <div className="modal-body">

                            <h2 className="modal-para modal-para-small">{desc}</h2>
                           
                            <FileUpload closeModal={this.props.closeAudioUpdateModal} action={this.props.action} 
                                orderby={this.props.orderby}
                                sortby={this.props.sortby}
                                search={this.props.search}                                
                                page={this.props.page}
                                enableScroll={this.props.enableScroll}
                                getUpdatedDetails={this.props.getUpdatedDetails}      
                                id={this.props.id}
                                valid="true" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AudioUpdateModal;