import React, { Component } from "react";
import Modal from 'react-responsive-modal';
import DeleteConfirmModal from '../common/deleteConfirmModal';
import AddUpdateContentModal from './addUpdateContentModal';
import EditFolderNameModal from './editFolderNameModal';
import UngroupConfirmModal from './ungroupConfirmModal';
import Popover from 'react-simple-popover';

class ContentActions extends Component {

    constructor() {
        super();
        this.state = {            
            IsContentAddUpdateModalOpen: false,
            IsDeleteConfirmModalOpen: false,
            IsEditFolderNameModalOpen: false,
            IsUnGroupModalOpen: false,
            open: false
        }


    }

    handleClick(e) {
        this.setState({ open: !this.state.open });
    }

    handleClose(e) {
        this.setState({ open: false });
    }

    openUnGroupConfirmModal = () => {
        this.setState({ IsUnGroupModalOpen: true });
    };

    closeUnGroupConfirmModal = () => {
        this.setState({ IsUnGroupModalOpen: false });
    };

    openEditFolderNameModal = () => {
        this.setState({ IsEditFolderNameModalOpen: true });
    };

    closeEditFolderNameModal = () => {
        this.setState({ IsEditFolderNameModalOpen: false });
    };

    openDeleteConfirmModal = () => {        
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    openContentAddUpdateModal = () => {
        this.setState({ IsContentAddUpdateModalOpen: true });
    };

    closeContentAddUpdateModal = () => {
        this.setState({ IsContentAddUpdateModalOpen: false });
    };



    render() {

        let message =  '';
        if(this.props.filesCount > 1){
            message = "Are you sure you want to delete " + this.props.filesCount + " files?"
        } else {
            message = "Are you sure you want to delete " + this.props.filesCount + " file?"
        }

        return (
            <div>
                <button type="button" className="btn btn-default" ref="useraction" onClick={this.handleClick.bind(this)}>
                    <i className="la la-ellipsis-h"></i>
                </button>
                <Popover
                    placement='bottom'
                    target={this.refs.useraction}
                    show={this.state.open}
                    style={{ padding: '0px' }}
                    containerStyle={{ marginLeft: '20px', zIndex: 999 }}
                    onHide={this.handleClose.bind(this)} >
                  
                    {this.props.content_type === 'files' && <button className="edit-action" onClick={this.openContentAddUpdateModal}>
                        <i className="la la-pencil"></i> EDIT
                            </button>}
                    {this.props.content_type === 'folder' && <button className="edit-action" onClick={this.openEditFolderNameModal}>
                        <i className="la la-pencil"></i> EDIT NAME
                            </button>}
                    {this.props.content_type === 'folder' && <button className="edit-action" onClick={this.openUnGroupConfirmModal}>
                        <i className="la la-arrows"></i> UNGROUP
                            </button>}
                    <button className="delete-action" onClick={this.openDeleteConfirmModal}>
                        <i className="la la-trash-o"></i> DELETE
                            </button>
                </Popover>
                <Modal open={this.state.IsContentAddUpdateModalOpen} center showCloseIcon={false} onClose={this.closeContentAddUpdateModal}>
                    <AddUpdateContentModal closeContentAddUpdateModal={this.closeContentAddUpdateModal}
                        type={this.props.type} search={this.props.search} page={this.props.page}
                        orderby={this.props.orderby} sortby={this.props.sortby}
                        folder_id={this.props.folder_id} getUpdatedDetails={this.props.getUpdatedDetails} enableScroll={this.props.enableScroll}
                        Id={this.props.Id} action="update"/>
                </Modal>
                <Modal open={this.state.IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal} title="Delete Content" message={message}
                        Id={this.props.Id} action="content-delete" type={this.props.type} search={this.props.search} page={this.props.page}
                        folder_id={this.props.folder_id}
                        removeItems={this.props.removeItems} 
                        orderby={this.props.orderby} sortby={this.props.sortby} content_type={this.props.content_type}/>
                </Modal>
                <Modal open={this.state.IsEditFolderNameModalOpen} center showCloseIcon={false} onClose={this.closeEditFolderNameModal}>
                    <EditFolderNameModal closeEditFolderNameModal={this.closeEditFolderNameModal}
                        type={this.props.type} search={this.props.search} page={this.props.page}
                        orderby={this.props.orderby} sortby={this.props.sortby}                        
                        folder_id={this.props.Id} content_name={this.props.content_name}/>
                </Modal>
                <Modal open={this.state.IsUnGroupModalOpen} center showCloseIcon={false} onClose={this.closeUnGroupConfirmModal}>
                    <UngroupConfirmModal closeUnGroupConfirmModal={this.closeUnGroupConfirmModal}
                        type={this.props.type} search={this.props.search} page={this.props.page}
                        orderby={this.props.orderby} sortby={this.props.sortby} enableScroll={this.props.enableScroll}
                        folder_id={this.props.Id} />
                </Modal>
            </div>
        );
    }
}

export default ContentActions;