import { BEGIN, FAILURE, BASE_URL, CONTENT_LIST, CLEAR_CONTENT_LIST, CLEAR_CONTENT_PAGE_LIST, RES, CONTENT_DETAILS, CLEAR_CONTENT_DETAILS, CONTENT_PAGE_LIST, CONTENT_PAGE_ASSIGNED, BU_LIST} from '../constants/constants';
import { fetchBegin, fetchSuccess, fetchFailure } from '../actions/actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import axios from 'axios';

export const GetContents = (model) => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_CONTENT_LIST));
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/admincontentlist";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, CONTENT_LIST));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));            
        });
}

export const DeleteContent = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/adminfoldercontentdelete";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));            
        });
}

export const ChangeSharing = (model) => dispatch => {
    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/admincontentchangesharing";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));            
        });
}

export const ChangeSharingFromDetail = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/admincontentchangesharing";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));            
        });
}


export const GetPages = (model) => dispatch => {    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "pages/adminpagelist";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, CONTENT_PAGE_LIST));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));            
        });
}


export const AddContentToPages = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/adminaddcontenttopage";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));           
        });
}

export const GetContentPages = (model) => dispatch => {
    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/admincontentpagelist";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, CONTENT_PAGE_ASSIGNED));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));            
        });
}

export const GetContentDetails = (model) => dispatch => {
    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/admincontentdetail";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, CONTENT_DETAILS));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));            
        });
}

export const UpdateContentToPage = (model) => dispatch => {
    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/adminupdatecontentpagelist";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {     
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));            
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));            
        });
}

export const CreateGroup = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/adminfolderadd";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const EditFolderName = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/adminfolderupdate";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const UnGroup = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/adminungroup";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}


export const DeleteFolder = (model) => dispatch => {
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/adminfolderdelete";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));

        });
}

export const ContentBusinessUnitUpdate = (model) => dispatch => {

    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "content/admincontentbusinessunittagging";
    dispatch(showLoading());
    axios.post(url, model)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, RES));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const GetBuList = () => dispatch => {    
    dispatch(fetchBegin(BEGIN));
    var url = BASE_URL + "businessunit/adminbusinessunitlist";
    dispatch(showLoading());
    axios.post(url)
        .then(function (res) {
            dispatch(hideLoading());
            dispatch(fetchSuccess(res.data, BU_LIST));
        })
        .catch(function (error) {
            dispatch(hideLoading());
            dispatch(fetchFailure(error, FAILURE));
        });
}

export const Clear = () => dispatch => {    
    dispatch(fetchSuccess({}, CLEAR_CONTENT_DETAILS));   
}

export const ShowLoading = () => dispatch => {    
    dispatch(showLoading());
}

export const HideLoading = () => dispatch => {
    dispatch(hideLoading());
}

export const initiateState = () => dispatch => {
    dispatch(fetchBegin(BEGIN));
}

export const ClearContentPageList = () => dispatch => {
    dispatch(fetchSuccess({}, CLEAR_CONTENT_PAGE_LIST));
}

