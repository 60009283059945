import React, { Component } from "react";
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import DeleteConfirmModal from '../common/deleteConfirmModal';
import Popover from 'react-simple-popover';
import BusinessUnitAddEdit from './businessUnitAddEdit';

class BusinessUnitActions extends Component {

    constructor() {
        super();
        this.state = {                  
            IsDeleteConfirmModalOpen: false,
            IsEditBUModalOpen: false,
            open: false
        }
    }    

    handleClick(e) {
        this.setState({ open: !this.state.open });
    }

    handleClose(e) {
        this.setState({ open: false });
    }

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    openEditBUModal = () => {
        this.setState({ IsEditBUModalOpen: true });
    };

    closeEditBUModal = () => {
        this.setState({ IsEditBUModalOpen: false });
    };

    render() {        

        return (
            <div>
                <button type="button" className="btn btn-default" ref="useraction" onClick={this.handleClick.bind(this)}>
                    <i className="la la-ellipsis-h"></i>
                </button>
                <Popover
                    placement='bottom'
                    target={this.refs.useraction}
                    show={this.state.open}
                    style={{ padding: '0px' }}
                    containerStyle={{ marginLeft: '20px', zIndex: 999 }}
                    onHide={this.handleClose.bind(this)} >
                    <button className="edit-action" onClick={this.openEditBUModal}>
                        <i className="la la-pencil"></i> Edit
                            </button>
                    <button className="delete-action" onClick={this.openDeleteConfirmModal}>
                        <i className="la la-trash-o"></i> Delete
                            </button>
                </Popover>
                {/*
                  <div className="dropdown">
                    <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <i className="la la-ellipsis-h"></i>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                        <li>
                            <Link to={{ pathname: '/pages/edit', state: { action: 'edit', id: this.props.Id, item: this.props.item } }} className="page-action edit-action">
                                <i className="la la-pencil"></i> Edit
                            </Link>
                        </li>
                        <li>
                            <button className="delete-action" onClick={this.openDeleteConfirmModal}>
                                <i className="la la-trash-o"></i> Delete
                            </button>
                        </li>
                    </ul>
                </div>
                 */}
               
                <Modal open={this.state.IsEditBUModalOpen} center showCloseIcon={false} onClose={this.openEditBUModal}>
                    <BusinessUnitAddEdit closeEditBUModal={this.closeEditBUModal} Id={this.props.Id} action="edit"
                        item={this.props.item} orderby={this.props.orderby} updateItem={this.props.updateItem}
                        sortby={this.props.sortby}/>
                </Modal>
                <Modal open={this.state.IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal} title="Delete Business Unit" message="Are you sure you want to delete this business unit?"
                        Id={this.props.item.id} action="bu-delete"
                        orderby={this.props.orderby} sortby={this.props.sortby}
                        removeItems={this.props.removeItems} />
                </Modal>
            </div>
        );
    }
}

export default BusinessUnitActions;