import React, { Component } from "react";
import { func } from 'prop-types';
import CSVReader from '../common/CSVReader';

import CSV from '../../content/csv/User_Add_Demo.csv'

var exportData = [];

class ImportUserModal extends Component {

    constructor() {
        super();
        this.state = {
            csvError: { blankValue: '', invalidEmail: '', invalidRole: '' },
            isValidCSV: null,
            showProgress: false,
            fileName: '',
            fileSize: '',
            fileLoaded: 0,
            fileUploaded: false,
            invalidCsvFileError: ''
        }

        this.readCSVFile = this.readCSVFile.bind(this);
        this.invalidFile = this.invalidFile.bind(this);
    }

    readCSVFile(data, fileName, fileSize) {
        
        exportData = [];
        var userData = data.slice(1, data.length).filter(x => x != "");

        this.setState({ csvError: { blankValue: '', invalidEmail: '', invalidRole: '' } });

        var count = this.props.count;

        for (var item = 0; item < userData.length; item++) {

            if (userData[item][0] !== undefined && userData[item][1] !== undefined && userData[item][2] !== undefined && userData[item][3] !== undefined) {
                var model = {};

                model.count = ++count;
                if (this.state.csvError.blankValue === '' || this.state.csvError.invalidEmail === '' || this.state.csvError.invalidRole === '') {
                    if (userData[item][0].trim().toLowerCase() == "" || userData[item][1].trim().toLowerCase() == "" || userData[item][2].trim().toLowerCase() == "" || userData[item][3].trim().toLowerCase() == "") {
                        this.setState({ csvError: { ...this.state.csvError, blankValue: "Few columns contains blank value" } });
                    }
                    else {
                        model.firstName = userData[item][0].trim().toLowerCase();
                        model.firstNameError = "";
                        model.lastName = userData[item][1].trim().toLowerCase();
                        model.lastNameError = "";
                    }

                    if (!this.validateEmail(userData[item][2].trim().toLowerCase())) {
                        this.setState({ csvError: { ...this.state.csvError, invalidEmail: "Few emails are invalid" } });
                    }
                    else {
                        model.email = userData[item][2].trim().toLowerCase();
                        model.emailError = "";
                    }

                    if (userData[item][3].trim().toLowerCase() != "user" && userData[item][3].trim().toLowerCase() != "admin") {
                        this.setState({ csvError: { ...this.state.csvError, invalidRole: "Few user roles are invalid" } });
                    }
                    else {
                        model.role = userData[item][3].trim().toLowerCase();
                        model.roleError = "";
                    }

                    model.error = "";
                    exportData.push(model);
                }
                else {
                    break;
                }
            }
        }
        
        if (this.state.csvError.blankValue == '' && this.state.csvError.invalidEmail == '' && this.state.csvError.invalidRole == '') {
            this.setState({ fileName: fileName, fileSize: fileSize, showProgress: true });
            
            var that = this;
            var progress = setInterval(() => {
                if (that.state.fileLoaded < 100) {
                    that.setState({ fileLoaded: that.state.fileLoaded + 10 });
                } else {
                    that.setState({ isValidCSV: true, fileUploaded: true });
                    clearInterval(progress);
                    that.props.submitFile(exportData);
                }
            }, 300);
        }
        else {
            this.setState({ isValidCSV: true, showProgress: false, fileUploaded: false, fileLoaded:0 });
        }
    }

    invalidFile(errorMessage) {
        this.setState({ isValidCSV: false, invalidCsvFileError: errorMessage,  showProgress: false, fileUploaded: false, fileLoaded: 0 });
    }

    validateEmail(email) {
        var regex = /[\w-]+@([\w-]+\.)+[\w-]+/;
        if (regex.test(email)) {
            return true;
        } else {
            return false;
        }
    }

    render() {

        const { csvError, isValidCSV, invalidCsvFileError, fileName, fileSize, showProgress, fileLoaded, fileUploaded } = this.state;

        //const acceptFile = () => {
        //    this.props.submitFile(exportData);
        //};

        const focuOut = () => {
            this.importButton.blur();
        }

        return (
            <div className="importuser_modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Import Users</h4>
                        </div>
                        <div className="modal-body mCustomScrollbar custom_body" data-mcs-theme="dark">
                            <h3 className="modal-para-small"> Use this method to import multiple users at once! </h3>
                            <div className="text-center">
                                <a ref={(importButton) => { this.importButton = importButton; }} download
                                href={CSV} className="btn black-btn btn-block import-btn" onClick={focuOut}>DOWNLOAD THE IMPORT FILE</a>
                                </div>
                            <ul className="step-list">
                                <li>
                                    <b>Step 1</b>
                                    <p className="step-info">Download the import file above and open it with Microsoft Excel.</p>
                                </li>
                                <li>
                                    <b>Step 2</b>
                                    <p className="step-info">Copy and paste your information in the labeled columns</p>
                                    <p className="step-info"><b>Do not change any of the column header information.</b></p>
                                </li>
                                <li>
                                    <b>Step 3</b>
                                    <p className="step-info">Save your modified import file as a <b>.csv</b> file, and then drop your shiny new file below. You're all done!</p>
                                </li>
                            </ul>
                            <CSVReader
                                cssClass="btn black-btn choose-file"
                                inputId="ObiWan"
                                inputStyle={{ color: 'red' }}
                                onFileLoaded={this.readCSVFile}
                                onError={this.invalidFile} />
                          
                            {isValidCSV === true && (csvError.blankValue !== '' || csvError.invalidEmail !== '' || csvError.invalidRole !== '') &&
                                (<div className="csv-error">
                                    <p className="text-center"><b>Invalid CSV file. There are few errors found while processing CSV file. Below are those errors:</b></p>
                                    {csvError.blankValue !== '' && <p>- {csvError.blankValue}</p>}
                                    {csvError.invalidEmail !== '' && <p>- {csvError.invalidEmail}</p>}
                                    {csvError.invalidRole !== '' && <p>- {csvError.invalidRole}</p>}
                                </div>)}

                            { showProgress &&
                                (<ul className="upload-list upload-list-csv">
                                    <li>
                                        <div className="file-info">
                                            <p className="file-name">{fileName}</p>
                                            <ul className="file-progress">
                                                <li>{fileSize}</li>
                                                {fileUploaded === false && fileLoaded > 0 && fileLoaded <= 100 && <li>{fileLoaded}%... </li>}
                                                {fileUploaded === true && <li className="text-success"><i className="la la-check-circle"></i> uploaded </li>}
                                            </ul>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" aria-valuenow={fileLoaded}
                                                aria-valuemin="0" aria-valuemax="100" style={{ width: '' + fileLoaded + '%' }}>
                                                <span className="sr-only">{fileLoaded}% Complete</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>)}

                            {/*(isValidCSV === true && csvError.blankValue === '' && csvError.invalidEmail === '' && csvError.invalidRole === '') &&
                                (<div className="csv-valid">
                                    <p className="text-center"><b>Please click on submit to get csv data.</b></p>
                                </div>)*/}

                            {isValidCSV === false &&
                                (<div className="csv-error">
                                <p className="text-center"><b>{invalidCsvFileError}</b></p>
                                </div>)}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" onClick={this.props.closeDeleteConfirmModal}>CANCEL</button>
                            {/*<button type="button" className="btn btn-primary" disabled={!isValidCSV} onClick={acceptFile}>Submit</button>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

ImportUserModal.propTypes = {
    submitFile: func
};

export default ImportUserModal;