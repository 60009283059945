import { errOptions } from '../../constants/constants'
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import DeleteConfirmModal from '../common/deleteConfirmModal';
import BusinessUnitActions from './businessUnitActions';
import BusinessUnitAddEdit from './businessUnitAddEdit';
import * as  buActions from '../../actions/buActions';

class BusinessUnits extends Component {

    constructor() {
        super();
        this.state = {
            IsDeleteConfirmModalOpen: false,
            IsAddBUModalOpen: false,
            sortby: 'name',
            orderby: 'ASC',
            selectedBU: [],
            search: '',
            isSelectAll: false,
            activePage: 1,
            isSearch: false,
            items: {},
            isScroll: true,
            totalRecords: null,
            hasMore: true,
            randomError: '',
            noRecordsFound: false
        }

        this.buSelection = this.buSelection.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.clearBUSelection = this.clearBUSelection.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.removeItems = this.removeItems.bind(this);
        this.updateItem = this.updateItem.bind(this);
    }

    componentDidMount() {
        var model = {};
        model.sort_by = "name";
        model.order_by = "ASC";
        //model.page = this.state.activePage;
        this.props.dispatch(buActions.GetBUList(model));
        //this.props.dispatch(menuActions.ActivatePage("Pages"));
    }

    componentWillReceiveProps() {
        if (this.props.bulist && this.props.bulist.records && this.props.bulist.records.length === 0 && this.state.items.length === 0) {
            this.setState({ noRecordsFound: true });
        } else if (this.props.bulist && this.props.bulist.records && this.props.bulist.records.length > 0 && this.state.items.length === 0) {
            this.setState({ noRecordsFound: false });
        }
        this.props.dispatch(buActions.ClearBUList());
    }

    openDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: true });
    };

    closeDeleteConfirmModal = () => {
        this.setState({ IsDeleteConfirmModalOpen: false });
    };

    openAddBUModal = () => {
        this.setState({ IsAddBUModalOpen: true });
    };

    closeAddBUModal = () => {
        this.setState({ IsAddBUModalOpen: false });
    };

    keyPress(e) {
        var randomError = this.generateRandomMesage(this.state.randomError);
        this.setState({ search: e.target.value.toLowerCase(), activePage: 1, items: [], isScroll: true, selectedBU: [], totalRecords: null, hasMore: true, randomError: randomError });
        var model = {};
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby;
        model.search = e.target.value.toLowerCase();
        model.page = 1;
        this.props.dispatch(buActions.GetBUList(model));
    }

    generateRandomMesage(randomError) {
        var str = errOptions[Math.floor(Math.random() * Math.floor(5))];
        if (randomError == str) {
            str = this.generateRandomMesage();
        }
        return str;
    }

    buSelection(e) {
        var id = e.target.id;
        if (e.target.checked) {
            if (this.state.selectedBU.length + 1 === this.state.items.length) {
                this.setState({ isSelectAll: true, selectedBU: [...this.state.selectedBU, id] });
            } else {
                this.setState({ selectedBU: [...this.state.selectedBU, id] });
            }
        }
        else {
            this.setState({
                isSelectAll: false,
                selectedBU: this.state.selectedBU.filter((eid) => eid !== id)
            });
        }
    }

    selectAll(e) {
        if (e.target.checked) {
            this.state.selectedBU = this.state.items.map((item) => {
                return item.id;
            });
            this.state.isSelectAll = true;
        }
        else {
            this.state.isSelectAll = false;
            this.state.selectedBU = [];
        }
        this.setState(this.state);
    }

    handleSearchChange(e) {
        if (e.target.name === "search") {
            this.setState({ search: e.target.value });
        }
    }

    search() {
        this.state.isSearch = !this.state.isSearch;
        if (this.state.isSearch) {
            this.searchInput.focus();
        }
        else {
            this.setState({ activePage: 1, search: '', items: [], isScroll: true, selectedBU: [], totalRecords: null, hasMore: true });
            var model = {};
            model.sort_by = this.state.sortby;
            model.order_by = this.state.orderby;
            model.search = '';
            model.page = 1;
            this.props.dispatch(buActions.GetBUList(model));
        }
    }

    clearBUSelection() {
        this.state.isSelectAll = false;
        this.state.selectedBU = [];
        this.setState(this.state);
    }

    fetchMoreData() {
        this.state.activePage += 1;
        this.state.isScroll = true;
        var model = {};
        model.sort_by = this.state.sortby;
        model.order_by = this.state.orderby.toLowerCase();
        if (this.state.search !== "") {
            model.search = this.state.search;
        }
        model.page = this.state.activePage;
        this.props.dispatch(buActions.GetBUList(model));
    }

    removeItems(Ids) {
        let self = this;
        Ids.forEach(id => {
            var index = self.state.items.findIndex(x => x.id == id);
            self.state.items.splice(index, 1);
        });
        this.state.totalRecords--;
    }

    changeOrder(e) {

        var orderby = "";
        if (this.state.sortby === e.target.dataset.sortby) {
            orderby = this.state.orderby === "DESC" ? "ASC" : "DESC";
        }
        else {
            orderby = "DESC";
        }

        this.setState({ activePage: 1, orderby: orderby, sortby: e.target.dataset.sortby, items: [], isScroll: true, selectedBU: [], totalRecords: null, hasMore: true });

        var model = {};
        model.sort_by = e.target.dataset.sortby;
        model.order_by = orderby.toLowerCase();
        if (this.state.search.toLowerCase() !== "") {
            model.search = this.state.search.toLowerCase();
        }
        this.props.dispatch(buActions.GetBUList(model));
    }

    updateItem(item) {
        var index = this.state.items.findIndex(x => x.id == item.id);
        this.state.items[index].name = item.name;
    }

    render() {

        const { activePage, isSelectAll, sortby, orderby, selectedBU, IsDeleteConfirmModalOpen, search, isSearch,
            isScroll, items, totalRecords, hasMore, randomError, noRecordsFound } = this.state;

        const { loading, error, bulist } = this.props;

        if (bulist != null && bulist.records && bulist.records.length > 0) {
            this.setState({ items: bulist.records });
        }

        const loadingClass = { 'textAlign': 'center', 'fontSize': '30px' };
        const header = { 'display': 'flex', 'height': '90px' };
        let isError = noRecordsFound;
        const wordBreak = {
            wordBreak: 'break-all',
            whiteSpace: 'pre-line'
        };

        return (

            <div className="container">
                <div className="main-title title-with-search">
                    <h1>Tags</h1>

                    <div className="tab_search_box">
                        <div className="search-form">
                            <input ref={(searchInput) => { this.searchInput = searchInput; }}
                                type="search" name="search" value={search}
                                className="form-control"
                                onChange={this.keyPress} />
                            <button ref={(searchButton) => { this.searchButton = searchButton; }}
                                type="submit" className="search-btn" onClick={this.search}>
                                <i className="fa fa-search" aria-hidden="true" ></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="tab-parent">
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active">
                            <div className="action-row content-action-row">
                                <button className="btn btn-rounded btn-primary" onClick={this.openAddBUModal}>
                                    <i className="la la-plus"></i>
                                </button>
                                <div className="action_btn_group">
                                    <button className="btn btn-default btn-delete" onClick={this.openDeleteConfirmModal} disabled={selectedBU.length === 0}>
                                        <i className="la la-trash-o"></i> DELETE
                                    </button>
                                </div>

                            </div>

                            {items != null && items.length > 0 &&
                                <div className="table-container bu-table table-responsive bu-table-wrap">
                                    <table className="table hover-row" id="userTable">
                                        <thead>
                                            <tr>
                                                <th className="custom-checkbox">
                                                    <input type="checkbox" id="chk1" onChange={this.selectAll} checked={isSelectAll} />
                                                    <label htmlFor="chk1"></label>
                                                </th>
                                                <th>
                                                    <span className={(sortby === 'name' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'name' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="name" onClick={this.changeOrder.bind(this)}>
                                                        Name
                                                </span>
                                                </th>
                                                <th>

                                                    <span className={(sortby === 'updated_date' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'updated_date' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="updated_date" onClick={this.changeOrder.bind(this)}>
                                                        Last Modified
                                                </span>
                                                </th>
                                                <th>
                                                    <span className={(sortby === 'specialities' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'specialities' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="specialities" onClick={this.changeOrder.bind(this)}>
                                                        Specialties
                                                        </span>
                                                </th>
                                                <th>
                                                    <span className={(sortby === 'competitors' && orderby === 'ASC') ? 'bottom-border th-name active-asc' : ((sortby === 'competitors' && orderby === 'DESC') ? 'bottom-border th-name active-desc' : 'th-name')} data-sortby="competitors" onClick={this.changeOrder.bind(this)}>
                                                        Competitors
                                                </span>
                                                </th>
                                                <th className="actions"></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {items.map((item, i) => (
                                                <tr key={item.id} className={(selectedBU.indexOf(item.id) > -1) ? 'active' : ''}>
                                                    <td className="custom-checkbox">
                                                        <input type="checkbox" id={item.id} onChange={this.buSelection} checked={(selectedBU.indexOf(item.id) > -1)} />
                                                        <label htmlFor={item.id}></label>
                                                    </td>
                                                    <td className="bu-name">
                                                        <span className="align-middle truncate">
                                                            <Link to={{ pathname: this.props.match.url + '/details?bu_id=' + item.id, state: { item: item, orderby: orderby, sortby: sortby } }} target="_self">
                                                                {item.name}
                                                            </Link>
                                                        </span>
                                                    </td>

                                                    <td>
                                                        <span className="align-middle truncate">{item.updated_date}</span>
                                                    </td>
                                                    <td>
                                                        <span className="align-middle truncate">{item.specialities}</span>
                                                    </td>

                                                    <td>
                                                        <span className="align-middle truncate">{item.competitors}</span>
                                                    </td>
                                                    <td className="actions">
                                                        <BusinessUnitActions Id={item.id} item={item}
                                                            orderby={orderby}
                                                            sortby={sortby}
                                                            updateItem={this.updateItem}

                                                            removeItems={this.removeItems} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>}
                            {items !== null && noRecordsFound && <div className="text-center">
                                {isSearch && <label className="lblNoRecord">{randomError}</label>}
                                {!isSearch && <label className="lblNoRecord">No Record Found!</label>}
                            </div>}
                        </div>
                    </div>
                </div>
                <Modal open={this.state.IsAddBUModalOpen} center showCloseIcon={false} onClose={this.closeAddBUModal}>
                    <BusinessUnitAddEdit closeEditBUModal={this.closeAddBUModal} action="add"
                        orderby={this.state.orderby}
                        sortby={this.state.sortby} />
                </Modal>
                <Modal open={IsDeleteConfirmModalOpen} center showCloseIcon={false} onClose={this.closeDeleteConfirmModal}>
                    <DeleteConfirmModal closeDeleteConfirmModal={this.closeDeleteConfirmModal}
                        title="Delete Business Units"
                        message="Are you sure you want to delete selected business unit(s)?"
                        selectedBU={selectedBU}
                        action="multiple-bu-delete"
                        orderby={orderby}
                        sortby={sortby}
                        search={search} page={activePage}
                        clearBUSelection={this.clearBUSelection}
                        removeItems={this.removeItems}
                    />
                </Modal>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    loading: state.contentReducer.loading,
    error: state.contentReducer.error,
    bulist: state.buReducer.bulist
});

export default connect(mapStateToProps)(BusinessUnits);