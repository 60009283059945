import { ENCRYPTION_KEY } from '../../constants/constants';
import React, { Component } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import * as usersActions from '../../actions/usersActions';


const Cryptr = require('cryptr');
const cryptr = new Cryptr(ENCRYPTION_KEY);
var Storage = require('local-storage');

class EditUser extends Component {

    constructor() {
        super();

        this.state = {
            id: null,
            first_name: '',
            last_name: '',
            email: '',
            user_type: '',            
            validations: {
                firstname: '',
                lastname: '',
                email: '',
                role: ''
            },
            login_user_type: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        var user = this.props.UserDetails;
        this.setState({
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            user_type: user.user_type,
            login_user_type: JSON.parse(cryptr.decrypt(Storage.get('user'))).user_type
        });        
    }

    handleChange(e) {
                
        switch (e.target.id){
            case "firstname":
                if (e.target.value === "") {                      
                    this.setState({ first_name: e.target.value, validations: { ...this.state.validations, firstname: 'First Name is required' } });
                }
                else
                {                                
                    this.setState({ first_name: e.target.value, validations: { ...this.state.validations, firstname: '' } });
                }
                break;
            case "lastname":
                if (e.target.value === "") {                                     
                    this.setState({ last_name: e.target.value, validations: { ...this.state.validations, lastname: 'Last Name is required' } });
                }
                else {                 
                    this.setState({ last_name: e.target.value, validations: { ...this.state.validations, lastname: '' } });
                }
                break;
            case "email":
                if (e.target.value === "") {                   
                    this.setState({ email: e.target.value, validations: { ...this.state.validations, email: 'Email is required' } });
                }
                else {
                    var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                    if (regex.test(e.target.value)) {                      
                        this.setState({ email: e.target.value, validations: { ...this.state.validations, email: '' } });
                    } else
                    {      
                        this.setState({ email: e.target.value, validations: { ...this.state.validations, email: 'Enter valid email' } });
                    }
                    
                }                              
                break;
            case "role":
                if (e.target.value === "0") {                    
                    this.setState({ user_type: e.target.value, validations: { ...this.state.validations, role: 'Role is required' } });
                }
                else {                   
                    this.setState({ user_type: e.target.value, validations: { ...this.state.validations, role: '' } });
                }
                break;
            default:
                break;
        }
    }


    handleSubmit(e) {        
        var data = [];
        var model = {};
        model.id = this.state.id;
        model.first_name = this.state.first_name;
        model.last_name = this.state.last_name;
        model.email = this.state.email;
        model.user_type = this.state.user_type;        
        data.push(model);
        this.props.dispatch(usersActions.UpdateUser(data));        
    }

    render() {
        var isValidated = false;

        const { id, first_name, last_name, email, user_type, validations, login_user_type } = this.state;

        if (validations.firstname === "" && validations.lastname === "" && validations.email === "" && validations.role === "") {
            if (first_name !== "" && last_name !== "" && email !== "" && user_type !== "") {
                isValidated = true;
            }
        }
        else {
            isValidated = false;
        }    

        const { res } = this.props;       
        if (res != null && res.success === "1") {          
            toastr.success("Success !", res.message);            
            var model = {};
            model.id = this.state.id;
            model.first_name = res.records[0].first_name;
            model.last_name = res.records[0].last_name;
            model.email = res.records[0].email;
            model.user_type = res.records[0].user_type;
            this.props.updateItem(model);           
            this.props.dispatch(usersActions.initiateState());
            this.props.closeEditUserModal();            
        }
        else if (res != null && res.success === "0") {
            toastr.error('Error', res.message);
        }

        return (
            
            <div className="modal-dialog" style={{ margin: '0px' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Edit User</h4>
                    </div>
                    <div className="modal-body form-container">                        
                            <div className="form-group">
                            <label>First name</label>
                            <input type="text" value={first_name} className="form-control" id="firstname" placeholder="First name" onChange={this.handleChange} />
                                {validations.firstname !== "" && <span className={validations.firstname === "" ? "hidden" : "has-error"}>{validations.firstname}</span>}
                            </div>
                            <div className="form-group">
                            <label>LAST NAME</label>
                            <input type="text" value={last_name} className="form-control" id="lastname" placeholder="Last Name" onChange={this.handleChange} />
                                {validations.lastname !== "" && <span className={validations.lastname === "" ? "hidden" : "has-error"}>{validations.lastname}</span>}
                            </div>
                            <div className="form-group">
                                <label>email</label>
                                <input type="email" value={email} className="form-control" id="email" placeholder="Email" onChange={this.handleChange} />
                                {validations.email !== "" && <span className={validations.email === "" ? "hidden" : "has-error"}>{validations.email}</span>}
                            </div>
                            {login_user_type !== "admin" && <div className="form-group">
                                <label>ROLE</label>
                                <select className="form-control" id="role" value={user_type} onChange={this.handleChange}>
                                    <option value="0">--Select role--</option>
                                    <option value="admin">Admin</option>
                                    <option value="user">User</option>
                                </select>
                                {validations.role !== "" && <span className={validations.role === "" ? "hidden" : "has-error"}>{validations.role}</span>}
                            </div>}                        
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={this.props.closeEditUserModal}>CANCEL</button>
                        <button type="button" className="btn btn-primary" disabled={!isValidated} onClick={this.handleSubmit}>Save changes</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.usersReducer.loading,
    error: state.usersReducer.error,
    res: state.usersReducer.res
});

export default connect(mapStateToProps)(EditUser);